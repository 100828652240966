<template lang="pug">
.base.mb-4.mt-4
  .form-check.me-3(v-if='enableSaveTemplate')
    input#flexCheckDefault.form-check-input(
      @change='$emit("save-template", $event.target.checked)'
      type='checkbox'
    )
    label.form-check-label(for='flexCheckDefault') Salvar como modelo
  .me-3
    button.btn.btn-thin.btn-thin--envelope-bottom(@click='saveAndClose')
      span.text Salvar e fechar
  div
    button.btn.btn-primary(:disabled='disableButtonNextStep' @click='nextStep')
      span.text {{ textButtonNextStep }}
</template>
<script>
export default {
  name: 'EnvelopeBottom',
  props: {
    enableSaveTemplate: {
      type: Boolean,
      default: false,
    },
    textButtonNextStep: {
      type: String,
      default: 'Avançar',
    },
    disableButtonNextStep: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveAndClose() {
      this.$emit('saveAndClose');
    },
    nextStep() {
      this.$emit('nextStep');
    },
  },
};
</script>
<style lang="stylus">
.base
  height: 70px
  display: flex
  vertical-align: middle
  flex-direction: row
  width: inherit
  align-items: center
  justify-content: flex-end
.form-check
  color: #063D52
</style>
