export const REDIRECT_STEPS = {
  ACCEPT_INVITATION: 'organization_invitation_accept',
  VALIDATE_DOCUMENT: 'validate_document',
  RECIPIENT_ACTION: 'show_recipient_action',
  SHOW_ENVELOPE: 'show_envelope',
  PAYMENT_LIST: 'show_payment_list',
  SHARED_ITEM: 'show_shared_item',
  SHOW_ENVELOPE_COMPLETED: 'show_envelope_completed',
  SHOW_RECIPIENT_ENVELOPE_COMPLETED: 'show_recipient_envelope_completed',
};

export default {
  name: 'routes',

  routes: [
    {
      path: '/_redirect',
      name: 'actions',
      redirect: (to) => {
        if (to.query.action === REDIRECT_STEPS.RECIPIENT_ACTION) {
          return { path: `/business/envelopes/viewer/${to.query.token}` };
        }

        if (to.query.action === REDIRECT_STEPS.ACCEPT_INVITATION) {
          return {
            path: 'business/invitation',
            query: { token: to.query.token, email: to.query.email },
          };
        }

        if (to.query.action === REDIRECT_STEPS.VALIDATE_DOCUMENT) {
          return { path: 'public/checker', query: { token: to.query.code } };
        }

        if (
          [
            REDIRECT_STEPS.SHOW_ENVELOPE,
            REDIRECT_STEPS.SHOW_ENVELOPE_COMPLETED,
          ].includes(to.query.action)
        ) {
          return { path: `business/documents/info/${to.query.id}` };
        }

        if (to.query.action === REDIRECT_STEPS.SHOW_ENVELOPE_COMPLETED) {
          return { path: `business/documents/info/${to.query.id}` };
        }
        if (
          to.query.action === REDIRECT_STEPS.SHOW_RECIPIENT_ENVELOPE_COMPLETED
        ) {
          return { path: `/business/envelopes/download/${to.query.token}` };
        }
        return '/home';
      },
    },
  ],
};
