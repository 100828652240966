<template lang="pug">
ValidationObserver(ref='form' v-slot='{ handleSubmit, invalid }')
  modal#edit-contact-modal(
    :disableProcessButton='invalid'
    @closed='onCloseModal'
    @opened='key += 1'
    @process='submit'
    close-button-label='Cancelar'
    process-button-label='Salvar'
    ref='modal'
    size='sm'
    title='Editar contato'
  )
    form(@submit.prevent='handleSubmit(submit)')

    ValidationProvider(
      name='nome'
      rules='required'
      v-slot='{ errors }'
    )
      .form-group
        label Nome
        .form-control-icon
          icon(name='user')
          input.form-control(
            :class='{ "border border-danger": errors[0] }'
            autocomplete='off'
            placeholder='Digite um nome completo'
            type='text'
            v-model.trim='editableContact.name'
          )
        small.text.text-danger {{ errors[0] }}

    ValidationProvider(
      name='e-mail'
      rules='required|email'
      v-slot='{ errors }'
    )
      .form-group
        label Endereço de e-mail
        .form-control-icon
          icon(name='at')
          input.form-control(
            :class='{ "border border-danger": errors[0] }'
            autocomplete='off'
            placeholder='Digite um e-mail'
            type='text'
            v-model.trim='editableContact.email'
          )
        small.text.text-danger {{ errors[0] }}

    ValidationProvider(
      name='phone'
      rules='intBrazilianPhone'
      v-slot='{ errors }'
      vid='phone'
    )
      .form-group(:key='key')
        label(for='phone') Telefone
        .form-control-icon
          input-tel#phone.form-control(
            autocomplete='off'
            type='text'
            v-mask='"## #####-####"'
            v-model='editableContact.phone'
          )
          small.text.text-danger.d-flex.mt-1(v-if='errors.length > 0')
            icon(name='xmark')
            span.text.ms-2 {{ errors[0] }}
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import Icon from '@/ui/atoms/Icon.vue';
import ContactService from '@/modules/account/services/http/ContactsService';
import InputTel from '@/ui/molecules/InputTel.vue';

export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },
    title: String,
  },
  components: {
    Modal,
    Icon,
    ImageLoader,
    InputTel,
  },
  data() {
    return {
      editableContact: {
        email: '',
        name: '',
        phone: '',
      },
      errors: {},
      key: 1,
    };
  },
  watch: {
    contact(newValue) {
      Object.assign(this.editableContact, newValue);
    },
  },
  methods: {
    onCloseModal() {
      Object.assign(this.editableContact, this.contact);
    },
    submit() {
      this.$loading(true);
      ContactService.supressDefault = true;
      ContactService.update(this.editableContact.id, this.editableContact)
        .then(() => {
          this.$refs.modal.close();
          this.$emit('refresh');
        })
        .catch((err) => {
          this.$refs.form.setErrors({ phone: [err.response.data.message] });
        })
        .finally(() => {
          ContactService.supressDefault = false;
          this.$loading(false);
        });
    },
  },
};
</script>
