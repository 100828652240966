import { render, staticRenderFns } from "./PendingInvitesCardTable.vue?vue&type=template&id=27bf89a1&lang=pug"
import script from "./PendingInvitesCardTable.vue?vue&type=script&lang=js"
export * from "./PendingInvitesCardTable.vue?vue&type=script&lang=js"
import style0 from "./PendingInvitesCardTable.vue?vue&type=style&index=0&id=27bf89a1&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports