<template lang="pug">
modal#delete-envelope-modal.text-center(
  :buttonColor='buttonColor'
  :loading='loading'
  @process='deleteEnvelope'
  close-button-label='Cancelar'
  process-button-label='Excluir'
  processButtonClass='btn-danger'
  ref='modal'
  size='sm'
  title='Excluir rascunho'
)
  .text-start Ao excluir um rascunho, você perde todas as configurações do envelope.
    |
    | Essa ação não poderá ser desfeita, deseja continuar?
  img.mt-4.mb-3(
    alt='draft-delete-warning'
    src='/img/ilustrations/draft-delete-warning.svg'
  )
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import alert from '@/modules/account/common/alert';

export default {
  name: 'DeleteEnvelopeModal',
  props: {
    envelopeId: [Number, String],
  },
  components: {
    Modal,
    ImageLoader,
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
    };
  },
  methods: {
    deleteEnvelope() {
      this.loading = true;
      EnvelopesService.deleteEnvelope(this.envelopeId).then(() => {
        this.$emit('refresh');
        this.loading = false;
        this.$refs.modal.close();
        alert.fireAlert('Rascunho removido com sucesso', {
          styles:
            'background-color: #D4EDDA; border: solid 1px #AACFB3; color: #0F6642;',
          icon: 'circle-check',
        });
      });
    },
  },
};
</script>
