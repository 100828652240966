<template lang="pug">
modal#update-avatar-modal(
  :loading='loading'
  @closed='onCloseModal'
  @process='submit'
  close-button-label='Cancelar'
  process-button-label='Salvar'
  ref='modal'
  size='lg'
  title='Recortar'
)
  .img-container
    img(:src='image' ref='image')
</template>

<script>
import AccountService from '@/services/AccountHttp';
import formHandler from '@/modules/account/common/formHandler';
import Modal from '@/ui/molecules/Modal.vue';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import alert from '@/modules/account/common/alert';

export default {
  components: { Modal, Cropper },
  props: {
    file: Blob,
  },

  data() {
    return {
      loading: false,
      cropper: {},
      image: null,
    };
  },
  watch: {
    file() {
      this.loadFile();
    },
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    loadFile() {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onloadend = (event) => {
        this.image = event.currentTarget.result;
        this.$nextTick(() => {
          this.startCropper();
        });
      };
    },

    startCropper() {
      this.cropper = new Cropper(this.$refs.image, {
        zoomable: true,
        scalable: false,
        dragMode: 'none',
        cropBoxResizable: false,
        cropBoxMovable: true,
        movable: false,
        aspectRatio: 1 / 1,
        autoCropArea: 0.7,
      });
    },

    onCloseModal() {
      this.cropper.destroy();
    },

    submit() {
      const formData = new FormData();
      AccountService.supressDefault = true;

      this.cropper.getCroppedCanvas().toBlob(async (blob) => {
        formData.append('avatar', blob);
        formHandler.submit(this, () => AccountService.createAvatar(formData), {
          successHandler: (response) => {
            if (response.status === 200) {
              alert.fireAlert('Imagem salva com sucesso.', {
                classes: 'alert-success',
                styles:
                  'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
                icon: 'circle-check',
              });
            }
            this.$emit('refresh', this.cropper.getCroppedCanvas().toDataURL());
            this.cropper.destroy();
          },

          errorHandler: (error) => {
            if (error.response.status === 422) {
              alert.fireAlert('Imagem não deve ser maior que 2048 kilobytes.', {
                classes: 'alert-danger',
                styles:
                  'background-color: #f8d7da; border-color: #f5c6cb; color: #721c24;',
                icon: 'triangle-exclamation',
              });
            }
            if (error.response.status === 401) {
              alert.fireAlert(
                'Não foi possível completar a ação, entidade não processável',
                {
                  classes: 'alert-danger',
                  styles:
                    'background-color: #f8d7da; border-color: #f5c6cb; color: #721c24;',
                  icon: 'triangle-exclamation',
                }
              );
            }
          },

          finallyHandler: () => {
            this.$refs.modal.close();
          },
        });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>

.img-container
  max-width: 100%;

  img
    max-width: 100%;
    width: 400px;

  .cropper-view-box
    box-shadow: 0 0 0 1px #39f
    outline: 0
    border-radius: 50%

  .cropper-face
    background-color:inherit !important

  .cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw,
  .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line
    display:none !important
</style>
