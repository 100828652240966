<template lang="pug">
modal.rubrica-settings-modal(
  :disable-process-button='invalidForm'
  @process='process'
  close-button-label='Cancelar'
  process-button-label='Salvar'
  ref='modal'
  size='sm'
  title='Rubrica'
)
  .col-12(v-if='element.recipient')
    .form-group
      label Assinante
      .form-control-icon
        icon(name='at')
        input.form-control(
          :value='element.recipient.addressees[0].value'
          disabled
          placeholder='Digite o nome do campo'
          type='text'
        )
    .form-check.form-switch
      input#spread.form-check-input(
        @input='validate'
        type='checkbox'
        v-model='element.spread'
      )
      label.form-check-label(for='spread') Replicar rubrica em todas as páginas
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';

export default {
  components: {
    Icon,
    Modal,
  },
  data() {
    return {
      element: {},
      invalidForm: true,
    };
  },
  methods: {
    process() {
      this.$emit('settings', this.element);
      this.$refs.modal.close();
    },
    validate() {
      this.invalidForm = false;
    },
  },
};
</script>
<style lang="stylus">
.rubrica-settings-modal
  .form-check-label
    margin: .2rem 0 0 .5rem
</style>
