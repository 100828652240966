<template lang="pug">
modal.checkbox-settings-modal(
  :disable-process-button='invalidForm'
  @opened='onOpenModal'
  @process='process'
  close-button-label='Cancelar'
  process-button-label='Salvar'
  ref='modal'
  size='sm'
  title='Caixa de seleção'
)
  .col-12
    form.needs-validation(novalidate)
      .form-group
        label.mb-2(for='field-checkbox-name')
          | Insira um texto informativo para o destinatário marcar a caixa de seleção.
        .form-control-icon
          icon(name='square-check')
          input#field-checkbox-name.form-control(
            @blur='validate'
            @input='validate'
            autocomplete='off'
            maxlength='40'
            placeholder='Adicione uma descrição'
            required
            type='text'
            v-model='element.title'
          )
          .invalid-feedback Por favor, preencha o campo com um nome
      .form-group
        .form-check.form-switch
          .d-flex.align-items-center
            input#field-checkbox-required.form-check-input(
              type='checkbox'
              v-model='element.required'
            )
            .ms-3
              label.m-0(for='field-checkbox-required') Prenchimento obrigatório
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';

export default {
  components: {
    Modal,
    Icon,
  },
  data() {
    return {
      element: {},
      invalidForm: true,
    };
  },
  methods: {
    onOpenModal() {
      this.validate();
      if (!this.element.title) {
        window
          .jQuery('#field-checkbox-name')
          .removeClass('is-valid')
          .removeClass('is-invalid');
      }
    },
    process() {
      this.element.name = this.element.title;
      this.$emit('settings', this.element);
      this.$refs.modal.close();
    },
    validate() {
      if (this.element.title) {
        this.invalidForm = false;
      } else {
        this.invalidForm = true;
      }
    },
  },
};
</script>
<style lang="stylus">
.checkbox-settings-modal
  .form-check.form-switch
    .form-check-label
      margin: .2rem 0 0 .5rem
</style>
