<template lang="pug">
main
  .page.bg-auth(style='height: 100vh')
    .text-center.mb-4
      header-component
    .card.mt-5(style='max-width: 520px; margin: 0 auto 2rem auto')
      .card-body.p-5(style='padding-bottom: 2rem !important')
        h3.card-title.card-title--gray.text-center.card-title {{ feedback.title }}
        p.card-text(style='text-align: left; padding: 30px 0px')
          | {{ feedback.message }}
</template>

<script>
import HeaderComponent from '@/ui/organisms/SimpleHeader.vue';

export default {
  name: 'WaitingPage',
  props: {
    feedback: {
      type: Object,
      default: function defaultValue() {
        return {
          title: 'Aguarde um momento',
          message: 'Estamos validando suas informações...',
        };
      },
    },
  },
  components: {
    HeaderComponent,
  },
};
</script>

<style scoped lang="stylus">
.bg-auth
  background-image: url('../../../../public/img/bg-auth.png')
  background-repeat:no-repeat;
  background-size:cover;
  .card
    box-shadow: none;
</style>
