<template lang="pug">
modal#remove-theme-modal(
  :buttonColor='buttonColor'
  :loading='loading'
  @process='removeTheme'
  close-button-label='Cancelar'
  process-button-label='Restaurar'
  ref='modal'
  title='Restaurar tema'
)
  p Essa ação excluirá todas as configurações criadas e retornará para o tema
    |
    | padrão da plataforma.
  .text Tem certeza que deseja continuar?.
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import WhiteLabelService from '@/modules/business/services/http/organizations/WhiteLabelService';
import formHandler from '@/modules/account/common/formHandler';
import alert from '@/modules/account/common/alert';

export default {
  name: 'RemoveThemeModal',
  components: {
    Modal,
    WhiteLabelService,
    ImageLoader,
  },
  props: {
    teamId: [Number, String],
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
    };
  },
  methods: {
    async removeTheme() {
      formHandler.submit(
        this,
        () =>
          WhiteLabelService.deleteTheme(
            this.$store.state.organizations.selected.id
          ),
        {
          successHandler: (response) => {
            if (response.status === 204) {
              alert.fireAlert('Tema restaurado com sucesso', {
                classes: 'alert-success',
                styles:
                  'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
                icon: 'circle-check',
              });
              this.loading = false;
              this.$refs.modal.close();
              this.$emit('refresh');
            }
          },
        }
      );
    },
  },
};
</script>
