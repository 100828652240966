<template lang="pug">
.recipient.br.animate(:style='`height: ${height}; width: ${width};`')
</template>

<script>
export default {
  name: 'SchimmmerRectangle',
  props: {
    height: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.recipient
  background: #777;
  margin-top: 10px;
@keyframes fullView
  100%
    width 100%
.animate
  animation: shimmer 2s infinite linear
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)
  background-size: 1000px 100%
@keyframes shimmer
  0%
    background-position -1000px 0
  100%
    background-position 1000px 0
</style>
