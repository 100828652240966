<template lang="pug">
modal.rubrica-settings-modal(
  :disable-process-button='invalidForm'
  @opened='onOpenModal'
  @process='process'
  close-button-label='Cancelar'
  process-button-label='Salvar'
  ref='modal'
  size='sm'
  title='Data'
)
  .col-12
    form.needs-validation
      .form-group
        label(for='field-date') Escolha uma data
        .form-control-icon
          icon(name='calendar-days')
          date-picker-input#field-date.form-control(
            :key='element.id'
            @blur='validate'
            @change='validate'
            placeholder='Clique para escolher uma data'
            required
            type='text'
            v-model='element.value'
          )
          .invalid-feedback Por favor, escolha uma data
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';
import DatePickerInput from '@/ui/molecules/DatePickerInput.vue';

export default {
  components: {
    Modal,
    DatePickerInput,
    Icon,
  },
  data() {
    return {
      element: {},
      invalidForm: true,
    };
  },
  methods: {
    onOpenModal() {
      this.validate();
      if (!this.element.value) {
        window
          .jQuery('#field-date + .date-input')
          .removeClass('is-valid')
          .removeClass('is-invalid');
      }
    },
    process() {
      this.$emit('settings', this.element);
      this.$refs.modal.close();
    },
    validate() {
      if (this.element.value) {
        window
          .jQuery('#field-date + .date-input')
          .addClass('is-valid')
          .removeClass('is-invalid');
        this.invalidForm = false;
      } else {
        window
          .jQuery('#field-date + .date-input')
          .removeClass('is-valid')
          .addClass('is-invalid');
        this.invalidForm = true;
      }
    },
  },
};
</script>
<style lang="stylus">
.rubrica-settings-modal
  .form-check-label
    margin: .2rem 0 0 .5rem
</style>
