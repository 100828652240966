<template lang="pug">
.envelope-step-bar.px-0.mb-5.mt-4
  .row
    .col
      .timeline-steps
        hr.step-bar-line
        .timeline-step(:class='{ done: isDone(1), valid: isValid(1) }')
          .timeline-content
            .inner-circle
              a(v-if='isDone(1)')
                icon.default(name='check')
              span(v-else) 1
            p.h6.mt-1.mb-0.mb-lg-0.step-title.font-color-blue Selecionar arquivos
        .timeline-step(:class='{ done: isDone(2), valid: isValid(2) }')
          .timeline-content
            .inner-circle
              a(v-if='isDone(2)')
                icon.default(name='check')
              span(v-else) 2
            p.h6.mt-1.mb-0.mb-lg-0.font-color-blue Adicionar destinatários
        .timeline-step(:class='{ done: isDone(3), valid: isValid(3) }')
          .timeline-content
            .inner-circle
              a(v-if='isDone(3)')
                icon.default(name='check')
              span(v-else) 3
            p.h6.mt-1.mb-0.mb-lg-0.font-color-blue Configurar envelope
        .timeline-step(:class='{ done: isDone(4), valid: isValid(4) }')
          .timeline-content
            .inner-circle
              a(v-if='isDone(4)')
                icon.default(name='check')
              span(v-else) 4
            p.h6.mt-1.mb-0.mb-lg-0.font-color-blue Detalhes e envio
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'EnvelopeStepBar',
  components: {
    Icon,
  },
  props: {
    step: {
      type: Number,
      default: 1,
      validator(value) {
        return value >= 1 && value <= 4;
      },
      runBeforeNavigate: {
        default: () => {},
      },
    },
    runBeforeNavigate: {
      type: Function || Promise,
      default: () => {},
    },
  },
  methods: {
    isDone(step) {
      return step < this.step;
    },
    isValid(step) {
      return step <= this.step;
    },
    editStep(step) {
      this.$emit('edit-step', step);
    },
    stepCanBeEditable(step) {
      return this.isDone(step);
    },
    async goTo(name) {
      await this.runBeforeNavigate();
      this.$router.replace({
        name,
        params: { id: this.$route.params.id, isNew: this.$route.params.isNew },
      });
    },
  },
};
</script>
<style scoped lang="stylus">
.timeline-steps
  display: flex
  justify-content: center
  position: relative
  .timeline-step
    position: relative
    display: flex
    align-items: center
    width: calc((100% - 300px) / 3);
    min-width: 5.5em
    flex-direction: column
    margin: .5em 0
    &.valid .timeline-content .inner-circle
      background-color: #3ECB90
    &.done
      .timeline-content
        .inner-circle
          background-color: #013D52
          .default
            color: #FFFFFF
    .timeline-content
      text-align center
      .inner-circle
        padding: .875em
        border-radius: 50%
        height: 1em
        width: 1em
        display: inline-flex
        align-items: center
        justify-content: center
        color: #013D52
        font-size: 1em
        font-weight: 600
        background-color: #CCCCCC
        line-height: 0em
  .step-bar-line
    border: 0px solid #CCCCCC
    padding: 0
    height: 0.05em
    background: #CCCCCC
    opacity: 1
    width: calc(100% - 300px)
    min-width: 15em
    position: absolute
    margin-top: 20px
</style>
