<script>
import vSelect from 'vue-select';

export default {
  name: 'AOLSelect',
  extends: vSelect,
  props: {
    clearable: { default: false },
  },
};
</script>
<style lang="stylus">
.vs__search::placeholder
  color: black
  opacity: 1
  font-size: 1rem
.vs__dropdown-toggle
  height: 3rem
.v-select
  .vs__selected,
  .vs__search,
  .vs__search:focus
    line-height: 2
    font-size: 1rem
    color: #013D52
    padding: 0
    .icon, .img
      margin: 0
      margin-right: 0.5em
      width: 1em
      max-width: 34px
    .img
      height: 1rem
.vs__dropdown-option
  color: #013D52
  line-height: 1.8
  font-size: 1rem
  padding-left: 1em
  .icon, .img
    width: 1em
    max-width: 34px
    margin-right: 0.5em
.vs__dropdown-option--highlight,
.vs__dropdown-option--selected
  background-color: #F7F7F7
  color: #013D52
.vs__open-indicator
  fill: #013D52
.vs__no-options
  color: #013D52
.vs__dropdown-menu
  .icon
    font-size: 1rem
  .img
    height: 1rem
.vs__selected-options
  margin-left: 0.75rem
  max-width: calc(100% - 2rem)
.vs__actions
  margin-right: .5em
.v-select
  .vs__selected
    margin-left: 0
    padding-left: 0
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    width: 100%
    display: block
  .vs__dropdown-option
    div, small
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
</style>
