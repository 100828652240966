<template lang="pug">
.alert.alert-warning.alert-dismissible.fade.show(role='alert')
  icon.me-2(name='triangle-exclamation')
  span.text(v-html='text')
  button.close(
    aria-label='Close'
    data-dismiss='alert'
    type='button'
  )
    span &times;
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'AlertMolecule',
  components: {
    Icon,
  },
  props: {
    text: String,
  },
};
</script>
<style lang="stylus">
.alert
  font-weight: 400
.close-btn
  .icon
    color: #cacaca
</style>
