<template lang="pug">
.text-to-image.text-center
  .fonts
    template(v-for='(font, index) in fonts')
      span(:style='`font-family: ${font};`') {{ font }}
  canvas(
    :height='`${height}`'
    :width='`${width}`'
    ref='canvas'
    v-if='ready'
  )
  .loading(v-if='loading') Carregando...
</template>
<script>
/* eslint-disable max-len */
export default {
  props: {
    text: {
      type: String,
      default: 'Example',
    },
    font: {
      type: String,
      default: 'Caveat',
    },
  },
  data() {
    return {
      ready: false,
      loading: true,
      width: 200,
      height: 100,
      fonts: [
        'Caveat',
        'Great Vibes',
        'Mrs Saint Delafield',
        'Sacramento',
        'Satisfy',
        'Tangerine',
      ],
    };
  },
  watch: {
    text() {
      this.render();
    },
    font() {
      this.render();
    },
  },
  created() {
    this.$emit('fonts', this.fonts);
  },
  mounted() {
    this.height = this.$el.parentElement.clientHeight;
    this.width = this.$el.parentElement.clientHeight * 2;
    this.ready = true;
    setTimeout(() => {
      this.render();
      this.loading = false;
    }, 2000);
  },
  methods: {
    render() {
      if (!this.$refs.canvas) {
        return;
      }
      const context = this.$refs.canvas.getContext('2d');
      context.clearRect(0, 0, this.width, this.height);
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.font = `18px ${this.font}`;

      const textSizePercentage =
        (this.width * 0.9) / context.measureText(this.text).width;
      const fontSize = 18 * textSizePercentage;

      context.font = `${fontSize > 80 ? 80 : fontSize}px ${this.font}`;
      context.fillText(this.text, this.width * 0.5, this.height * 0.55);

      context.canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        this.blobToBase64(blob).then((image) => {
          this.$emit('change', {
            blob,
            image,
          });
        });
      });
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Great+Vibes&family=Mrs+Saint+Delafield&family=Sacramento&family=Satisfy&family=Tangerine');
.text-to-image {
  min-height: 40px;
  display: flex;
}
.text-to-image canvas {
  margin: auto;
}
.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 1.7em;
  margin: auto;
  z-index: 2;
}
.fonts {
  visibility: hidden;
  position: absolute;
  width: 1px;
}
</style>
