<template lang="pug">
modal#select-folder(
  :disableProcessButton='disableSelectButton'
  :process-button-label='hasItems'
  @closed='onClose'
  @opened='getItems'
  @process='submit'
  close-button-label='Cancelar'
  ref='modal'
  size='lg'
  title='Selecionar Pasta'
)
  files-list-mini.mt-3(
    :enable-folder-creation='true'
    :enable-search='true'
    :enableFolderCreation='!canNotBeCreateFolders'
    :enableRootDirectory='enableRootDirectory'
    :filter-folder='true'
    @newFoderCreated='newFoderCreated'
    @select-folder='selectFolder'
    ref='filesListMini'
  )
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import FilesListMini from '@/ui/organisms/FilesListMini.vue';

export default {
  components: {
    Modal,
    FilesListMini,
  },
  props: {
    enableRootDirectory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemsAndParent: '',
    };
  },
  computed: {
    hasParent() {
      return !this.itemsAndParent.parent;
    },
    hasItems() {
      return (this.itemsAndParent.items &&
        this.itemsAndParent.items.length > 0) ||
        !this.hasParent
        ? 'Selecionar'
        : null;
    },
    disableSelectButton() {
      if (this.enableRootDirectory) {
        return false;
      }
      return this.hasParent;
    },
    canNotBeCreateFolders() {
      return (
        this.itemsAndParent.parent && this.itemsAndParent.parent.depth >= 5
      );
    },
  },
  methods: {
    submit() {
      this.$emit('selected-folder', this.itemsAndParent.parent);
      this.$refs.modal.close();
    },
    selectFolder(itemsAndParent) {
      this.itemsAndParent = itemsAndParent;
    },
    newFoderCreated() {
      this.$emit('newFoderCreated');
    },
    onClose() {
      this.$refs.filesListMini.cleanVariables();
      this.getItems();
    },
    getItems() {
      this.$refs.filesListMini.getItems();
    },
  },
};
</script>
