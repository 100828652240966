export default {
  isInvalidFile(file, maxFileSizeMb, allowedFileType, allowedFileExtensions) {
    const fileExtension = file.name.split('.').pop().toLowerCase();

    const maxFileSizeBytes = maxFileSizeMb * 1024 * 1024;
    if (file.type && !allowedFileType.includes(file.type)) {
      return 'INVALID_FILE_TYPE';
    }
    if (!allowedFileExtensions.includes(fileExtension)) {
      return 'INVALID_FILE_TYPE';
    }
    if (file.size > maxFileSizeBytes) {
      return 'INVALID_FILE_SIZE';
    }
  },
};
