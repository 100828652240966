<template lang="pug">
.nav-documents-bottom
  .nav-documents-bottom__actions
    button.btn-sign.mb-3.mb-lg-0.btn.btn-primary(
      :disabled='disableOptions'
      @click='sign'
      v-if='showOptions || userIsViewer'
    )
      span.text {{ buttonOkLabel }}
    button.btn-cancel.btn.btn-danger(
      @click='buttonCancelRejectAction'
      style='border-width: 1px'
      v-if='showOptions'
    )
      span.text {{ buttonCancelRejectLabel }}
  div(v-if='!(showOptions || userIsViewer)')
    IconStatus.me-1(:iconStatus='this.payload.envelope.state')
    span.text(style='color: #555555' v-html='warningText')
  reject-envelope-modal(
    :token='this.$route.params.token'
    @refresh='rejected'
    ref='rejectEnvelopeModal'
  )
  bird-id-sign-envelope-modal(
    @acquiredToken='execAction'
    @callSignatureFailureModal='callSignatureFailureModal'
    ref='signBirdIdEnvelopeModal'
  )
  cancel-envelope-modal(
    :envelopeId='payload.envelope.id'
    @refresh='canceled'
    ref='cancelEnvelopeModal'
  )
  rejected-envelope-modal(
    :is-public='isPublic'
    @envelope-rejected='redirect'
    ref='rejectedEnvelopeModal'
  )
  canceled-envelope-modal(ref='canceledEnvelopeModal')
  approve-envelope-modal(
    :is-public='isPublic'
    :subject='payload.envelope.subject'
    ref='approveEnvelopeModal'
  )
  follow-up-envelope-modal(
    :is-public='isPublic'
    @confirm='redirect'
    ref='followUpEnvelopeModal'
  )
  collect-recipient-photo-modal(
    @photo='fillPhoto'
    ref='collectRecipientPhotoModal'
  )
  select-qualified-signature-type-modal(
    @update-type='onSelectQualifiedSignatureType'
    ref='selectQualifiedSignatureTypeModal'
  )
  local-plugin-signature-flow(
    :envelope='payload'
    :location='location'
    @certificate='certificate = $event'
    @signed-hashes='onSignHashes'
    @tcn='tcn = $event'
    ref='localPluginSignatureFlow'
  )
</template>
<script>
import RecipientsService from '@/modules/business/services/http/envelopes/RecipientsService';
import EnvelopesPublicService from '@/modules/business/services/http/envelopes/EnvelopesPublicService';
import RecipientsIdentifiersService from '@/modules/business/services/http/envelopes/RecipientsIdentifiersService';
import RejectEnvelopeModal from '@/modules/business/components/organisms/modals/RejectEnvelopeModal.vue';
import BirdIdSignEnvelopeModal from '@/modules/business/components/organisms/modals/SignEnvelopeModal.vue';
import CancelEnvelopeModal from '@/modules/business/components/organisms/modals/CancelEnvelopeModal.vue';
import RejectedEnvelopeModal from '@/modules/business/components/organisms/modals/RejectedEnvelopeModal.vue';
import CanceledEnvelopeModal from '@/modules/business/components/organisms/modals/CanceledEnvelopeModal.vue';
import ApproveEnvelopeModal from '@/modules/business/components/organisms/modals/ApproveEnvelopeModal.vue';
import FollowUpEnvelopeModal from '@/modules/business/components/organisms/modals/FollowUpEnvelopeModal.vue';
// eslint-disable-next-line max-len
import ShowRequestSignatureSentModal from '@/modules/business/components/organisms/modals/RequestSignatureSentModal.vue';
import CollectRecipientPhotoModal from '@/modules/business/components/organisms/modals/CollectRecipientPhotoModal.vue';
// eslint-disable-next-line max-len
import SelectQualifiedSignatureTypeModal from '@/modules/business/components/organisms/modals/SelectQualifiedSignatureTypeModal.vue';

import LocalPluginSignatureFlow from '@/modules/business/components/organisms/LocalPluginSignatureFlow.vue';

import IconStatus from '@/ui/atoms/IconStatus.vue';
import EnvelopeState from '@/enums/EnvelopeState';
import RecipientState from '@/enums/RecipientState';
import RecipientType from '@/enums/RecipientType';

export default {
  components: {
    RejectEnvelopeModal,
    BirdIdSignEnvelopeModal,
    IconStatus,
    CancelEnvelopeModal,
    RejectedEnvelopeModal,
    CanceledEnvelopeModal,
    ApproveEnvelopeModal,
    ShowRequestSignatureSentModal,
    FollowUpEnvelopeModal,
    CollectRecipientPhotoModal,
    SelectQualifiedSignatureTypeModal,
    LocalPluginSignatureFlow,
  },
  props: {
    payload: [Object, String],
    disableOptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      location: {
        latitude: null,
        longitude: null,
      },
      photo: '',
      signedDocumentsHashes: '',
      tcn: '',
      certificate: '',
    };
  },
  created() {
    this.getUserLocation();
  },
  computed: {
    envelopeCanBeSigned() {
      return EnvelopeState.isOpened(this.payload.envelope.state);
    },
    userCanSing() {
      return (
        (this.payload.type === RecipientType.SIGNER ||
          this.payload.type === RecipientType.APPROVER) &&
        this.payload.state === RecipientState.WAITING
      );
    },
    userCanApprove() {
      return (
        this.payload.type === RecipientType.APPROVER &&
        this.payload.state === RecipientState.WAITING
      );
    },
    userIsViewer() {
      return this.payload.state === RecipientState.FOLLOWING;
    },
    buttonOkLabel() {
      if (this.userCanApprove) {
        return 'Aprovar';
      }
      return 'Concluir';
    },
    isCancelAction() {
      if (this.$root.user?.email === this.payload.envelope.sender.email) {
        return true;
      }
      return false;
    },
    buttonCancelRejectLabel() {
      return this.isCancelAction ? 'Cancelar envelope' : 'Rejeitar envelope';
    },
    buttonCancelRejectAction() {
      return this.isCancelAction ? this.cancelEnvelope : this.rejectEnvelope;
    },
    warningText() {
      let baseText = 'Nenhuma alteração poderá ser realizada no documento.';
      if (this.envelopeCanBeSigned && !this.userCanSing) {
        baseText = 'Aguardando assinatura de terceiros.';
      }
      const warningType = EnvelopeState.getText(this.payload.envelope.state);
      return `<strong>Envelope ${warningType}.</strong> ${baseText}`;
    },
    showOptions() {
      return this.userCanSing && this.envelopeCanBeSigned;
    },
    isPublic() {
      return this.$route.name.split('.').shift() === 'public';
    },
  },
  methods: {
    execAction(params = {}) {
      this.$loading(true);
      RecipientsService.supressDefault = true;
      const options = {
        ...this.location,
        ...params,
      };
      if (this.signedDocumentsHashes && this.tcn) {
        options.documents = this.signedDocumentsHashes;
        options.tcn = this.tcn;
        options.certificates = [this.certificate];
      }
      RecipientsService.execAction(this.$route.params.token, options)
        .then((response) => {
          this.photo = '';
          const envelope = response.data.envelope;
          if (envelope.state === 'halted') {
            this.callSignatureFailureModal();
            return;
          }
          this.$emit('signed', response);
        })
        .catch(() => {
          this.callSignatureFailureModal();
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    sign() {
      if (
        this.payload.identifiers
          .filter((i) => i.code === 'PHOTO' && i.is_required)
          .shift() &&
        !this.photo
      ) {
        this.$refs.collectRecipientPhotoModal.$refs.modal.open();
        return;
      }
      if (this.userIsViewer) {
        this.$refs.followUpEnvelopeModal.$refs.modal.open();
        return;
      }
      if (
        this.payload.signature_type === 'qualified' &&
        !this.signedDocumentsHashes
      ) {
        if (this.$root.isMobile) {
          this.$refs.signBirdIdEnvelopeModal.$refs.modal.open();
          return;
        }
        this.$refs.selectQualifiedSignatureTypeModal.$refs.modal.open();
        return;
      }
      this.execAction();
    },
    rejectEnvelope() {
      this.$refs.rejectEnvelopeModal.$refs.modal.open();
    },
    cancelEnvelope() {
      this.$refs.cancelEnvelopeModal.$refs.modal.open();
    },
    canceled(response) {
      this.$emit('canceled', response);
      this.$refs.canceledEnvelopeModal.$refs.modal.open();
    },
    rejected(response) {
      this.$emit('rejected', response);
      this.$refs.rejectedEnvelopeModal.$refs.modal.open();
    },
    redirect() {
      if (this.isPublic) {
        this.$router.push('/public');
      } else {
        this.$router.push('/business/documents');
      }
    },
    callSignatureFailureModal() {
      this.$emit('signatureFailure');
    },
    getUserLocation() {
      if (!window.navigator) return;

      const sucessFunction = (userLocation) => {
        this.location.latitude = userLocation.coords.latitude;
        this.location.longitude = userLocation.coords.longitude;
      };

      window.navigator.geolocation.getCurrentPosition(sucessFunction);
    },
    fillPhoto(photo) {
      this.photo = photo;
      this.$loading(true);
      RecipientsIdentifiersService.fillPhotoIdentifier(
        this.$route.params.token,
        'PHOTO',
        photo
      ).then(() => {
        this.$loading(false);
        this.sign();
      });
    },
    async onSelectQualifiedSignatureType(type) {
      if (type === 'birdId') {
        this.$refs.signBirdIdEnvelopeModal.$refs.modal.open();
        return;
      }
      try {
        this.$loading(true);
        const res = await EnvelopesPublicService.getCessSettings(
          this.$route.params.token
        );
        this.$loading(false);
        this.$refs.localPluginSignatureFlow.license = res.data.license;
        await this.$refs.localPluginSignatureFlow.init();
      } catch (error) {
        console.log('error on get cess settings');
      }
    },
    onSignHashes(hashes) {
      this.signedDocumentsHashes = hashes;
      this.sign();
    },
  },
};
</script>
<style lang="stylus">
.nav-documents-bottom
  padding: 1rem 0
  .btn-sign, .btn-cancel
    width: 100%
@media(min-width: 992px)
  // .nav-documents-bottom
  .nav-documents-bottom__actions
    display: flex
    .btn-sign, .btn-cancel
      width: auto
    .btn-sign
      order: 2
      margin-left: 1rem
    .btn-cancel
      order: 1
      margin-left: auto
</style>
