<template lang="pug">
modal#create-contact-modal(
  :loading='loading'
  @closed='onCloseModal'
  @process='submit'
  close-button-label='Cancelar'
  process-button-label='Salvar'
  ref='modal'
  size='sm'
  title='Novo contato'
)
  ValidationObserver(ref='form' v-slot='{ handleSubmit }')
    form(@submit.prevent='handleSubmit(submit)')

    ValidationProvider(
      name='nome'
      rules='required'
      v-slot='{ errors }'
    )
      .form-group
        label Nome
        .form-control-icon
          icon(name='user')
          input.form-control(
            :class='{ "border border-danger": errors[0] }'
            autocomplete='off'
            placeholder='Digite um nome completo'
            type='text'
            v-model.trim='contact.name'
          )
        small.text.text-danger {{ errors[0] }}

    ValidationProvider(
      name='e-mail'
      rules='required|email'
      v-slot='{ errors }'
    )
      .form-group
        label Endereço de e-mail
        .form-control-icon
          icon(name='at')
          input.form-control(
            :class='{ "border border-danger": errors[0] }'
            autocomplete='off'
            placeholder='Digite um e-mail'
            type='text'
            v-model.trim='contact.email'
          )
        small.text.text-danger {{ errors[0] }}

    ValidationProvider(name='telefone' v-slot='{ errors }')
      .form-group
        label Telefone (opcional)
        .form-control-icon
          icon(name='phone')
          input.form-control(
            :class='{ "border border-danger": errors[0] }'
            autocomplete='off'
            placeholder='Digite um telefone'
            type='text'
            v-mask='"+## ## # ####-####"'
            v-model.trim='contact.phone'
          )
        small.text.text-danger {{ errors[0] }}
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import '@/services/Validator';
import Icon from '@/ui/atoms/Icon.vue';
import formHandler from '@/modules/account/common/formHandler';
import ContactService from '@/modules/account/services/http/ContactsService';

export default {
  name: 'CreateContactModal',
  components: {
    Modal,
    Icon,
  },
  data() {
    return {
      loading: false,
      contact: {
        name: '',
        email: '',
        phone: '',
      },
    };
  },

  methods: {
    cleanInputContac() {
      this.contact = {
        emails: null,
        teams: null,
        roles: null,
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    async submit() {
      formHandler.submit(this, () => ContactService.create(this.contact), {
        fieldsByPropertyName: {
          name: 'nome',
          email: 'e-mail',
        },
        successHandler: (response) => {
          formHandler.defaultSuccess(response);
          this.cleanInputContac();
          this.$refs.modal.close();
          this.$emit('refresh');
        },
      });
    },

    onCloseModal() {
      this.cleanInputContac();
    },
  },
};
</script>
