<template lang="pug">
modal#collect-recipient-modal(
  :no-footer='true'
  @closed='onCloseModal'
  ref='modal'
  size='sm'
  title='Assinatura com foto'
)
  div(v-show='step === 1')
    p Para validar e concluir a sua assinatura é necessário que você tire uma foto com seu documento pessoal.
    p Certifique-se de:
    ul
      li Estar em um lugar iluminado;
      li Tirar a selfie com o documento pessoal próximo ao seu rosto;
      li Verficar se não há objetos ou outras pessoas aparecendo na tela de captura.
    .text-center.mt-5
      img.img-fluid(src='/img/ilustrations/user-collect-photo.svg')
    .text-end.mt-4
      button.btn.btn-primary.ms-2(
        @click='step += 1'
        style='border-width: 1px'
        type='button'
      )
        span.text Avançar
  div(v-show='step === 2')
    camera-photo(
      @photo='photo = $event'
      ref='photo'
      style='min-height: 230px'
    )
    .text-end.mt-4
      button.btn.btn-primary.ms-2(
        @click='takePhoto'
        style='border-width: 1px'
        type='button'
      )
        span.text Tirar foto
  div(v-show='step === 3')
    img(:src='photo' v-if='photo')
    .text-end(style='margin-top: 1.9rem')
      button.btn.btn-secondary(
        @click='step -= 1'
        style='border-width: 1px'
        type='button'
      )
        span.text Tirar outra foto
      button.btn.btn-primary.ms-4(
        @click='send'
        style='border-width: 1px'
        type='button'
      )
        span.text Enviar
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import CameraPhoto from '@/ui/molecules/CameraPhoto.vue';

export default {
  name: 'CollectRecipientPhotoModal',
  props: {
    envelopeId: [Number, String],
  },
  components: {
    Modal,
    CameraPhoto,
  },
  data() {
    return {
      step: 1,
      photo: '',
    };
  },
  watch: {
    step(v) {
      if (v === 2) {
        this.$refs.photo.start();
      }
    },
  },
  methods: {
    takePhoto() {
      this.step += 1;
      this.$refs.photo.capture();
    },
    onCloseModal() {
      this.$refs.photo.stopStream();
      this.step = 1;
      this.photo = '';
    },
    send() {
      this.$emit('photo', this.photo);
      this.$refs.modal.close();
    },
  },
};
</script>
