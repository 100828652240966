<template lang="pug">
.face-to-face-page
  template(v-if='envelopes.length === 0')
    .text-center
      img.img-fluid(
        src='/img/ilustrations/no-signature.svg'
        style='max-height: 260px'
      )
      p.mb-5 Sem documentos para assinar no momento.
      button.btn.btn-secondary(@click='updateList')
        icon.icon--blue(name='arrows-rotate')
        span.text Atualizar página
  template(v-else)
    button.d-block.d-sm-none.btn.btn-secondary.w-100.mb-4(@click='updateList')
      icon.icon--blue(name='arrows-rotate')
      span.text Atualizar página
    button.d-none.d-sm-block.btn-reload-envelopes.btn.btn-secondary(
      @click='updateList'
    )
      icon.icon--blue(name='arrows-rotate')
      span.text Atualizar página
    .row
      .col-12.col-lg-6.mb-4(v-for='(envelope, index) in envelopes')
        .envelope
          h6.envelope__title.mb-3.text-overflow-ellipse
            strong ID: {{ envelope.id }} - {{ envelope.title || envelope.subject }}
          .envelope__signer(v-for='(signer, index) in envelope.signers')
            .name.text-overflow-ellipse {{ signer.name }}
            template(v-for='(identifier, index) in signer.identifiers')
              .text-overflow-ellipse(v-if='identifier.value') {{ identifier.title }}: {{ identifier.value }}
            div
              icon.icon--blue.me-2(name='file-signature')
              span.text Assinatura presencial
            div
              icon-status.me-2(:icon-status='signer.status')
              span.text {{ $t(signer.status) }}
            router-link(
              :to='{ name: "business.envelopes.faceToFaceEnvelope", params: { token: signer.token } }'
            )
              button.btn.btn-primary.w-100.d-block.d-sm-none.mt-3.mb-1 Assinar
              button.btn.btn-primary.d-none.d-sm-block.envelope__signer__sign-button Assinar
</template>
<script>
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';

export default {
  name: 'FaceToFaceList',
  components: {
    Icon,
    IconStatus,
  },
  data() {
    return {
      envelopes: [],
    };
  },
  created() {
    this.$store.dispatch('page/setClass', 'face-to-face-list');
    this.$store.dispatch('page/setTitle', 'Assinaturas pendentes');
    this.$store.dispatch('page/setHead', {
      title: 'Assinaturas pendentes',
      action: () => {
        this.$router.replace({
          name: 'business',
        });
      },
    });
    this.loadEnvelopes();
  },
  methods: {
    loadEnvelopes() {
      this.$loading(true);
      EnvelopesService.listFaceToFace('filter[state]=waiting,signing')
        .then((res) => {
          const list = res.data.data.reduce((ac, item) => {
            if (!item.envelope) {
              return ac;
            }
            ac.push({
              id: item.envelope.hashid,
              title: item.envelope.title,
              subject: item.envelope.subject,
              signers: [
                {
                  id: item.id,
                  name: item.name,
                  status: item.state,
                  token: item.action_token,
                  identifiers: item.identifiers,
                },
              ],
            });
            return ac;
          }, []);
          this.envelopes = list.reduce((ac, item) => {
            const localItem = ac.filter((i) => i.id === item.id).shift();
            if (localItem) {
              localItem.signers.push({
                id: item.signers[0].id,
                name: item.signers[0].name,
                email: item.signers[0].email,
                status: item.signers[0].status,
                token: item.signers[0].token,
              });
              ac = ac.filter((i) => i.id !== item.id);
              ac.push(localItem);
            } else {
              ac.push(item);
            }
            return ac;
          }, []);
          this.$loading(false);
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    updateList() {
      this.loadEnvelopes();
    },
  },
};
</script>
<style lang="stylus">
.face-to-face-list
  .face-to-face-page
    position: relative
    .btn-reload-envelopes
      position: absolute
      right: 0
      top: -3.7rem
    .envelope
      border: 1px solid #ccc
      padding: 1.5rem
    .envelope__title
      color: #555555
    .envelope__signer
      border-bottom: 1px solid #ccc
      padding-bottom: 1rem
      margin-bottom: 1rem
      position: relative
      &:last-child
        border-bottom: none
        margin-bottom: 0
        padding-bottom: 0
    .envelope__signer__sign-button
      position: absolute
      top: 0
      right: 0
      bottom: 0
      height: 3em
      margin: auto
</style>
