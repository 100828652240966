<template lang="pug">
modal#new-developer-client-modal(
  :close='onCloseModal'
  @closed='onCloseModal'
  @process='submit'
  close-button-label='Cancelar'
  process-button-label='Adicionar'
  ref='modal'
  title='Nova aplicação'
)
  ValidationObserver(ref='form')
    form.form(@submit.prevent='submit')
      .row
        .col-12
          ValidationProvider(
            name='nome da aplicação'
            rules='required'
            v-slot='{ errors }'
          )
            .form-group
              label(for='name') Nome da aplicação*
              .form-control-icon
                icon(name='building')
                input#name.form-control(
                  :class='{ "is-invalid": errors.length }'
                  autocomplete='off'
                  placeholder='Digite o nome da aplicação'
                  type='name'
                  v-model='name'
                )
              small.text.text-danger {{ errors[0] }}
        .col-12
          ValidationProvider(
            name='url de redirecionamento'
            rules='required|url'
            v-slot='{ errors }'
          )
            .form-group
              label(for='email') URL de redirecionamento*
              .form-control-icon
                icon(name='globe')
                input#email.form-control(
                  :class='{ "is-invalid": errors.length }'
                  placeholder='Exemplo: https://url.com.br'
                  type='text'
                  v-model='url'
                )
              small.text.text-danger {{ errors[0] }}
        .col-12
          .form-switch.d-flex.align-items-center
            input#checked-public.form-check-input(
              type='checkbox'
              v-model='public'
            )
            span(style='margin-left: 0.5rem; vertical-align: bottom') Tornar aplicação pública
            icon(
              data-placement='top'
              data-toggle='tooltip'
              name='circle-question'
              style='cursor: pointer; margin-left: 0.5rem'
              v-tooltip:top='tooltipText'
            )
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import Icon from '@/ui/atoms/Icon.vue';
import OauthService from '@/services/Oauth';
import alert from '@/modules/account/common/alert';

export default {
  name: 'NewDeveloperClientModal',
  components: {
    Modal,
    Icon,
  },
  data() {
    return {
      name: '',
      url: '',
      public: false,
    };
  },
  methods: {
    onCloseModal() {
      this.name = '';
      this.url = '';
      this.public = false;
      this.$refs.form.reset();
    },
    submit() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) {
          return;
        }
        this.$loading(true);
        const pattern = /^((http|https|ftp):\/\/)/;
        if (!pattern.test(this.url)) {
          this.url = `http://${this.url}`;
        }
        OauthService.createClient({
          name: this.name,
          redirect: this.url,
          confidential: !this.public,
        })
          .then((result) => {
            this.$refs.modal.close();
            alert.fireAlert('Aplicação criada com sucesso', {
              classes: 'alert-success',
              styles:
                'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
              icon: 'circle-check',
            });
            this.$emit('refresh');
            if (result.data.plain_secret) {
              this.$emit('showClientSecret', result.data.plain_secret);
              return;
            }
            this.$emit('showInfoModal');
          })
          .finally(() => {
            this.$loading(false);
          });
      });
    },
  },
  computed: {
    tooltipText() {
      // eslint-disable-next-line max-len
      return 'Uma aplicação pública pode ser instalada e executada na máquina do cliente ou executada pelo navegador (front-end). Esse modelo permite acesso aos identificadores e tokens da aplicação.';
    },
  },
};
</script>

<style scoped lang="stylus">
#new-developer-client-modal
  z-index: 1060;
</style>
