import { render, staticRenderFns } from "./CreateFolderButton.vue?vue&type=template&id=090f720e&scoped=true&lang=pug"
import script from "./CreateFolderButton.vue?vue&type=script&lang=js"
export * from "./CreateFolderButton.vue?vue&type=script&lang=js"
import style0 from "./CreateFolderButton.vue?vue&type=style&index=0&id=090f720e&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "090f720e",
  null
  
)

export default component.exports