import Http from '@/services/Http';

const path = 'invitations';
const invitations = new Http(path);

invitations.invite = (payload) => invitations.post(payload);
invitations.list = (params) =>
  invitations.get('', '', `${params === undefined ? '' : `?${params}`}`);
invitations.deleteInvite = (email) => invitations.delete(email);
invitations.inviteFromFile = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return invitations.post(formData, `/${path}/import`);
};
invitations.resend = (email) => invitations.post('', `${path}/${email}/resend`);

export default invitations;
