import Http from '@/services/Http';

const path = 'organizations/invitations';

const invited = new Http(path);

invited.acceptInvited = (token) => invited.post('', `${path}/${token}`);

invited.getInvited = (token) => invited.get(token);

export default invited;
