<template lang="pug">
.signatures-list
  ul.mb-0
    li.signature-item(:key='signer.id' v-for='signer in signers')
      .signature-name.text-overflow-ellipse(:title='signer.addressees[0].name')
        span {{ signer.addressees[0].name }}
      .signature-status(:class='signer.state')
        icon-status(:icon-status='signer.state')
        span.text.ms-2(style='font-weight: 600') {{ recipientStateText(signer.state) }}
    li.signature-item
      .signature-status(:class='envelope.state')
        icon-status(:icon-status='envelope.state')
        span.text.ms-2(style='font-weight: 600') Envelope {{ envelopeStateText }}
      .signature-name {{ envelopeStateDescription }}
</template>

<script>
import IconStatus from '@/ui/atoms/IconStatus.vue';
import RecipientState from '@/enums/RecipientState';
import EnvelopeState from '@/enums/EnvelopeState';

export default {
  name: 'signaturesList',
  components: {
    IconStatus,
  },
  props: {
    envelope: {
      type: Object,
      required: true,
    },
  },
  methods: {
    recipientStateText(state) {
      return RecipientState.getText(state);
    },
  },
  computed: {
    signers() {
      const signers = this.envelope.recipients.filter(
        (recipient) => recipient.type !== RecipientState.CARBON_COPY
      );
      return signers.sort((a, b) => a.routing_order - b.routing_order);
    },
    envelopeStateText() {
      return EnvelopeState.getText(this.envelope.state).toLowerCase();
    },
    envelopeStateDescription() {
      return EnvelopeState.getDescription(this.envelope.state);
    },
  },
};
</script>

<style scoped lang="stylus">
.signatures-list
    ul
      margin: 0 0 20px 0
      padding: 1rem
      list-style: none
      border: 1px solid rgba(204, 204, 204, .7)
      border-radius: 5px

      li
        padding: 0.75rem 0
        border-bottom: 1px solid rgba(204, 204, 204, .7)
        height: auto

        &:first-child
          padding-top: 0

        &:last-child
          padding-bottom: 0
          border-bottom: none

        .signature-status
          &.done i
            color: green

          &.waiting i
            color: #F7E60A

          &.rejected i
            color: red

          &.signing i
            color: blue

          span
            font-size: 0.85rem
</style>
