class ElementResponsiveConverter {
  constructor() {
    this.parentElementWidth = 0;
    this.parentElementHeight = 0;
    this.width = 0;
    this.height = 0;
    this.x = 0;
    this.y = 0;
    this.fontSize = 16;
  }

  setParentElementSize(width, height) {
    this.parentElementWidth = width;
    this.parentElementHeight = height;
    return this;
  }

  setElementSize(width, height) {
    this.width = width;
    this.height = height;
    return this;
  }

  setElementPosition(x, y) {
    this.x = x;
    this.y = y;
    return this;
  }

  setElementFontSize(fontSize) {
    this.fontSize = fontSize;
    return this;
  }

  getElementSizePercentage() {
    return {
      width: this.width / this.parentElementWidth,
      height: this.height / this.parentElementHeight,
    };
  }

  getElementPositionPercentage() {
    return {
      x: this.x / this.parentElementWidth,
      y: this.y / this.parentElementHeight,
    };
  }

  getElementFontSizePercentage() {
    return this.fontSize / this.width;
  }
}

export default ElementResponsiveConverter;
