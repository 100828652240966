<template lang="pug">
modal#collect-recipient-modal(
  :no-footer='true'
  ref='modal'
  size='sm'
  title='Autenticação'
)
  p Para validarmos a sua assinatura é necessário que o
    | <strong class='ms-1'>Soluti Sign Plugin</strong> esteja em execução no seu computador.
  p Este plugin é totalmente seguro e necessário para autenticar a sua assinatura.
  p.mb-4 Após finalizar o download e abrir o plugin, clique no botão “Avançar”.
  .my-3.text-center
    template(v-if='osInfo === "windows-64"')
      button.btn.btn-thin(
        @click='downloadPlugin'
        style='border-width: 1px'
        type='button'
      )
        icon(name='windows' type='brands')
        span.text Baixar Plugin
    template(v-if='osInfo === "windows-32"')
      button.btn.btn-thin(
        @click='downloadPlugin'
        style='border-width: 1px'
        type='button'
      )
        icon(name='windows' type='brands')
        span.text Baixar Plugin
    template(v-if='osInfo === "linux"')
      button.btn.btn-thin(
        @click='downloadPlugin'
        style='border-width: 1px'
        type='button'
      )
        icon(name='linux' type='brands')
        span.text Baixar Plugin
    template(v-if='osInfo === "mac"')
      button.btn.btn-thin(
        @click='downloadPlugin'
        style='border-width: 1px'
        type='button'
      )
        icon(name='apple' type='brands')
        span.text Baixar Plugin
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/laptop-download.svg')
  .text-end.mt-5
    button.btn.btn-primary.ms-4(
      @click='close'
      style='border-width: 1px'
      type='button'
    ) Avançar
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'QualifiedSignatureDownloadPluginModal',
  components: {
    Icon,
    Modal,
  },
  data() {
    return {
      osInfo: '',
    };
  },
  created() {
    this.getOsInfo();
  },
  methods: {
    close() {
      this.$emit('close');
      this.$refs.modal.close();
    },
    getOsInfo() {
      if (!window.navigator) {
        return;
      }
      if (
        navigator.userAgent.includes('WOW64') ||
        navigator.userAgent.includes('Win64')
      ) {
        this.osInfo = 'windows-64';
        return;
      }
      if (
        navigator.userAgent.includes('WOW32') ||
        navigator.userAgent.includes('Win32')
      ) {
        this.osInfo = 'windows-32';
        return;
      }
      if (
        navigator.userAgent.includes('WOW32') ||
        navigator.userAgent.includes('Win32')
      ) {
        this.osInfo = 'windows-32';
        return;
      }
      if (navigator.userAgent.includes('Linux')) {
        this.osInfo = 'linux';
        return;
      }
      if (navigator.userAgent.includes('Mac OS')) {
        this.osInfo = 'mac';
        return;
      }
    },
    downloadPlugin() {
      if (this.osInfo === 'linux') {
        window.open(
          'https://s3-sa-east-1.amazonaws.com/repo.birdid.com.br/plugin/latest/Linux/Linux-64/BirdID-Sign-Plugin.AppImage',
          'blank'
        );
      }
      if (this.osInfo === 'mac') {
        window.open(
          'https://s3-sa-east-1.amazonaws.com/repo.birdid.com.br/plugin/latest/MacOS/BirdID-Sign-Plugin.dmg',
          'blank'
        );
      }
      if (this.osInfo === 'windows-32') {
        window.open(
          'https://s3-sa-east-1.amazonaws.com/repo.birdid.com.br/plugin/latest/Windows/Windows-32/birdid-sign-plugin.exe',
          'blank'
        );
      }
      if (this.osInfo === 'windows-64') {
        window.open(
          'https://s3-sa-east-1.amazonaws.com/repo.birdid.com.br/plugin/latest/Windows/Windows-64/birdid-sign-plugin.exe',
          'blank'
        );
      }
    },
  },
};
</script>
