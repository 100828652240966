<template lang="pug">
modal#delete-archive-modal(
  :buttonColor='true'
  @process='deleteFolder'
  close-button-label='Cancelar'
  process-button-label='Excluir'
  processButtonClass='btn-danger'
  ref='modal'
  title='Excluir pasta'
)
  p.text-left Ao excluir uma pasta, todo o conteúdo contido nela também
    |
    | será excluído e a ação não poderá ser desfeita.
  .text-center.mt-4
    img.img-fluid.mb-4(src='/img/ilustrations/draft-delete-warning.svg')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import StorageHttp from '@/services/StorageHttp';
import alert from '@/modules/account/common/alert';

export default {
  name: 'DeleteFolderModal',
  components: {
    Modal,
  },
  data() {
    return {
      buttonColor: true,
    };
  },
  props: {
    folderId: {
      type: String,
      required: true,
      default: '',
    },
  },
  methods: {
    deleteFolder() {
      this.$loading(true);
      StorageHttp.supressDefault = true;
      StorageHttp.removeItem(this.folderId)
        .then((res) => {
          if (res.status === 204) {
            alert.fireAlert('Arquivo removido com sucesso', {
              classes: 'alert-success',
              styles:
                'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
              icon: 'circle-check',
            });
          }
          this.$emit('deleted');
        })
        .catch((err) => {
          if (err.response.status === 404) {
            alert.fireAlert('Erro na operação. Pasta não encontrada.', {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #f5c6cb; color: #721c24;',
              icon: 'triangle-exclamation',
            });
          }
        })
        .finally(() => {
          this.$refs.modal.close();
          this.$loading(false);
        });
    },
  },
};
</script>
