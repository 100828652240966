<template lang="pug">
modal#rename-file-modal(
  :noFooter='true'
  @closed='close'
  ref='modal'
  title='Renomear'
)
  ValidationObserver(ref='form' v-slot='{ valid, passes, handleSubmit }')
    form.form(@submit.prevent='handleSubmit(renameFile)')
      .row
        .col-12
          .form-group
            label(for='file-name') Nome do Arquivo
            .col-12.d-flex
              .form-control-icon
                icon(name='file')
            ValidationProvider(
              name='name'
              rules='required'
              v-slot='{ errors }'
            )
              input#file-name.form-control(
                :class='{ "border border-danger": errors[0] }'
                aria-describedby='file-extension'
                aria-label='Digite um nome'
                autocomplete='off'
                placeholder='Digite um nome'
                type='text'
                v-model='form.editableArchiveName'
              )
              small.text.text-danger.d-flex(v-if='errors.length > 0')
                icon(name='xmark')
                span.text.ms-2 {{ errors[0] }}
      .text-end.mt-2
        button.btn.btn-secondary(@click='close' type='button') Cancelar
        button.btn.btn-primary.ms-2(:disabled='!valid' type='submit')
          | Salvar
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';
import StorageHttp from '@/services/StorageHttp';
import alert from '@/modules/account/common/alert';

export default {
  components: {
    Modal,
    Icon,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        editableArchiveName: '',
        fileExtension: '',
      },
      errors: [],
    };
  },
  watch: {
    file(newValue) {
      [this.form.editableArchiveName, this.form.fileExtension] =
        newValue.name.split('.');
    },
  },
  methods: {
    renameFile() {
      this.$loading(true);
      StorageHttp.supressDefault = true;
      StorageHttp.renameItem(
        this.file.id,
        `${this.form.editableArchiveName}.${this.form.fileExtension}`
      )
        .then(() => {
          this.$emit('renamed');
          this.$refs.modal.close();
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.$refs.form.setErrors(err.response.data.errors);
          }

          if (err.response.status === 404) {
            this.$refs.modal.close();
            alert.fireAlert('Esse item não foi encontrado pelo sistema', {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #f5c6cb; color: #721c24;',
              icon: 'triangle-exclamation',
            });
          }
        })
        .finally(() => {
          this.$loading(false);
          StorageHttp.supressDefault = false;
        });
    },
    resetForm() {
      [this.form.editableArchiveName] = this.file.name.split('.');
      this.$refs.form.reset();
    },
    close() {
      this.resetForm();
      this.$refs.modal.close();
    },
  },
};
</script>

<style scoped lang="stylus">
#file-name
  padding: 0px 45px
</style>
