<template lang="pug">
div
  .row
    .col-12
      .component-title.d-flex.justify-content-between
        span.text Plano atual
        span
          button.cancel-button(@click='cancelPlan()')
            .text Cancelar plano
      .card.card--plan-details
        .card-body
          .data.d-flex.justify-content-between
            .detail.d-flex.align-items-center
              .type.card-title
                span.text {{ plan.name }}
              .price
                span.money-cipher R$
                span.money-number {{ planPrice[0] }}
                span.money-cents ,{{ planPrice[1] }}/{{ subscriptionType }}
            .actionStatus.d-flex.align-items-center
              .status
                .alert-simple(:class='planStatus.class' role='alert')
                  icon(:name='planStatus.icon' v-if='planStatus.icon')
                  span.text {{ planStatus.name }}
              .action
                button.btn.btn-primary(
                  @click='upgrade()'
                  type='button'
                  v-if='planStatus.type != "cancelled"'
                ) Fazer Upgrade
                button.btn.btn-primary(
                  @click='reactivePlan()'
                  type='button'
                  v-if='planStatus.type == "cancelled"'
                ) Reativar Plano
    .col-12(v-if='creditCard')
      .component-title
        span.text Cartão cadastrado
      .card.card--plan-details
        .card-body
          .data.d-flex.justify-content-between
            .detail.d-flex.align-items-center
              .credicard-flag.card-title--creditcard
                span.text {{ creditCard.cardBrand }}
              .credicard-details
                .text **** {{ creditCard.cardLast4Digits }}
                .text(:style='colorExpirationDate') Expira em {{ expirationDate }}
            .action
              button.btn.btn-thin(type='button') Trocar Cartão
          .information(v-if='alertUpcomingDueDate')
            .alert-simple.alert-simple-warning(
              :class='alertUpcomingDueDate.class'
              role='alert'
            )
              icon(name='circle-exclamation')
              span.text {{ alertUpcomingDueDate.text }}
    .col-12(v-if='invoices')
      .component-title
        span.text Faturas
      .card.card--rounded
        .card-body
          .alert-simple.alert-simple--bigger(
            :class='invoiceAlert.class'
            role='alert'
            v-if='invoiceAlert'
          )
            i.icon.fas.fa-fw.fa-leaf
            span.text(v-html='invoiceAlert.text')
          plan-details-card-table(:data='invoices')
          simple-paginator(
            :payload='invoicesPagination'
            @navigate='loadData'
            style='margin-top: 0.625rem'
          )
</template>

<script>
import PlanDetailsCardTable from '@/modules/account/components/organisms/card-table/PlanDetailsCardTable.vue';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import BillingHttp from '@/services/BillingHttp';
import Icon from '@/ui/atoms/Icon.vue';
import moment from 'moment';

export default {
  name: 'PlanDetails',
  components: { Icon, PlanDetailsCardTable, SimplePaginator },
  data() {
    return {
      invoices: {},
      invoicesPagination: {},
      filters: {
        search: '',
      },
    };
  },
  props: {
    plan: Object,
    creditCard: [Object, null],
    planStatus: Object,
  },
  async created() {
    this.$loading(true);
    try {
      await this.loadData();
    } finally {
      this.$loading(false);
    }
  },
  computed: {
    invoiceAlert() {
      if (this.invoices.length > 0) {
        if (this.$store.state.subscription.plan.state === 'recurring') {
          return {
            text: `Próxima fatura: <b>R$${
              this.$store.state.subscription.plan.plan.value_cents / 100
            } em
                  ${moment(
                    this.$store.state.subscription.next_recurrence_at
                  ).format('DD/MM/YYYY')}</b>`,
            class: 'alert-simple-success',
          };
        }

        if (this.$store.state.subscription.plan.state === 'cancelled') {
          return {
            text: `<b>Sua última fatura venceu em: ${moment(
              this.invoices[0].due_date
            ).format('DD/MM/YYYY')}</b>`,
            class: 'alert-simple-danger',
          };
        }
      }
      return null;
    },
    planPrice() {
      return this.breakPriceInPieces(this.plan.value_cents);
    },
    expirationDate() {
      if (this.creditCard.cardExpiration) {
        const [year, month] = this.creditCard.cardExpiration.split('/');
        return `${month}/${year}`;
      }
      return null;
    },
    colorExpirationDate() {
      if (this.alertUpcomingDueDate.class == 'alert-simple-danger') {
        return { color: '#DC342B' };
      }
      return null;
    },
    alertUpcomingDueDate() {
      if (this.creditCard.cardExpiration) {
        const [year, month] = this.creditCard.cardExpiration
          .split('/')
          .map(Number);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;

        const totalMonthsDiff =
          (year - currentYear) * 12 + (month - currentMonth);

        if (totalMonthsDiff === 1 || totalMonthsDiff === 0) {
          return {
            text: 'Seu cartão vai expirar em breve. Atualize para evitar a interrupção do seu plano.',
            class: 'alert-simple-warning',
          };
        } else if (totalMonthsDiff < 0) {
          return {
            text: 'Seu cartão expirou. Atualize agora para reativar o serviço!',
            class: 'alert-simple-danger',
          };
        } else {
          return false;
        }
      }
      return null;
    },
    subscriptionType() {
      return this.plan.interval == 12 ? 'ano' : 'mês';
    },
  },
  methods: {
    async loadData(page = 1) {
      this.$loading(true);
      const params = new URLSearchParams({
        q: this.filters.search,
        page,
      });
      const invoices = await BillingHttp.getInvoices(params);
      this.invoices = invoices.data.data;
      this.invoicesPagination = invoices.data;
      this.$loading(false);
    },
    breakPriceInPieces(price) {
      return (price / 100).toFixed(2).split('.');
    },
    upgrade() {
      this.$router.push('/account/billing/plan?tab=plans');
    },
    async cancelPlan() {
      this.$loading(true);
      try {
        await BillingHttp.cancelSubscription();
      } finally {
        this.$loading(false);
      }
    },
    async reactivePlan() {
      this.$loading(true);
      try {
        await BillingHttp.reactivatePlan();
      } finally {
        this.$loading(false);
      }
    },
  },
};
</script>
<style scoped>
.cancel-button {
  color: #0080a3;
  font-size: 0.75rem;
}
</style>
