<template lang="pug">
.row
  .col-12.mb-3
    ul#my-tab.nav.nav-tabs(role='tablist')
      li.nav-item(role='presentation' v-if='permissionToEditOrganization')
        button#settings-tab.nav-link.active(
          aria-controls='settings'
          aria-selected='true'
          data-bs-target='#settings'
          data-bs-toggle='tab'
          role='tab'
          type='button'
          v-bind:class='{ active: !personalWorkspace }'
        ) Configurações
      li.nav-item(role='presentation' v-if='permissionToEditOrganization')
        button#themes-tab.nav-link(
          aria-controls='themes'
          aria-selected='true'
          data-bs-target='#themes'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) Temas
      li.nav-item(role='presentation' v-if='permissionToEditBilling')
        button#plans-tab.nav-link(
          aria-controls='plans'
          aria-selected='true'
          data-bs-target='#plans'
          data-bs-toggle='tab'
          role='tab'
          type='button'
          v-bind:class='{ active: permissionToEditBilling && !permissionToEditOrganization }'
        ) Planos
    #myTabContent.tab-content.pt-3
      #settings.tab-pane.fade.show(
        aria-labelledby='settings-tab'
        role='tabbusiness'
        v-bind:class='{ active: !personalWorkspace }'
        v-if='!personalWorkspace && permissionToEditOrganization'
      )
        form.form.row
          .col-12.col-sm-6
            .form-group
              label(for='name') Nome da Organização*
              ValidationProvider(
                mode='passive'
                name='nome da organização'
                ref='organizationName'
                rules='required'
                v-slot='{ errors }'
              )
                .form-control-icon
                  icon(:fix-width='true' name='sitemap')
                  input#name.form-control(
                    autocomplete='off'
                    placeholder='Digite um nome'
                    type='text'
                    v-model='organizationName'
                  )
                small.text.text-danger {{ errors[0] }}
          .col-12.text-end
            button.btn.btn--cancel-red-text.mt-2.shadow(
              :disabled='loading'
              data-bs-target='#remove-organization-modal'
              data-bs-toggle='modal'
              style='margin-right: 1rem'
              type='button'
            ) Excluir Organização
            button.btn.btn-secondary.mt-2(
              :class='{ loading: loading }'
              :disabled='loading'
              @click='saveOrganizationData'
              type='button'
            ) Salvar
      #themes.tab-pane.fade.show(
        aria-labelledby='themes-tab'
        role='tabbusiness'
        v-if='!personalWorkspace && permissionToEditOrganization'
      )
        organization-theme-form
      #plans.tab-pane.show(
        aria-labelledby='plans-tab'
        role='tab-plans'
        v-bind:class='{ active: contentPlanActived, fade: !contentPlanActived }'
        v-if='permissionToEditBilling'
      )
        .row
          .col-12
            p Veja abaixo nossos planos e escolha um que se encaixe com suas necessidades.
            | Caso tenha alguma dúvida, por favor entre em contato com nosso comercial
            | pelo telefone (62) 3210-6644 ou pelo e-mail comercial@soluti.com.br.
          .col-12
            .plans-list.mt-3
              .row
                .col-12.col-sm-6.col-md-4.col-lg-3.col-xl-3(
                  :key='index'
                  v-for='(plan, index) in plans'
                )
                  .card.card--plans
                    .card-body
                      .card-title {{ plan.name }}
                      .card--plans__price
                        //- span R$
                        span(style='font-size: 1.4rem') {{ plan.price.formatted }}
                        span(style='font-weight: 400') /mês
                      p(style='min-height: 50px') {{ plan.description }}
                      //- ul.card--plans__features-list
                        li.list-item {{plan.}}
                        li.list-item Criação de 1 organização
                        li.list-item Times ilimitados
                        li.list-item Funções ilimitadas
                        li.list-item Até 20 usuários
                      button.btn.btn-thin.disabled(
                        disabled
                        type='button'
                        v-if='isCurrentPlan(plan)'
                      ) Plano Atual
                      router-link(
                        :to='`/account/billing/subscription/${plan.id}/${organization.id}`'
                        v-else
                      )
                        button.btn.btn-thin(type='button') Escolher plano
  remove-organization-modal(
    :organization-id='this.organization.id'
    @change-organization='changeOrganization'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import OrganizationThemeForm from '@/modules/business/components/organisms/forms/OrganizationTheme.vue';
import RemoveOrganizationModal from '@/modules/business/components/organisms/modals/RemoveOrganizationModal.vue';
import ProductsHttp from '@/services/ProductsHttp';
import BillingHttp from '@/services/BillingHttp';

export default {
  components: {
    Icon,
    OrganizationThemeForm,
    RemoveOrganizationModal,
  },
  computed: {
    organization() {
      return this.$store.state.organizations.selected;
    },
    headTitle() {
      return `${this.organization.name} - Organizações`;
    },
    personalWorkspace() {
      return this.$store.state.organizations.personalWorkspace;
    },
    permissionToEditOrganization() {
      if (!this.personalWorkspace) {
        if (
          !this.organization.is_owner &&
          this.organization.permissions &&
          !this.organization.permissions.edit_organization
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    permissionToEditBilling() {
      if (!this.personalWorkspace) {
        if (
          !this.organization.is_owner &&
          this.organization.permissions &&
          !this.organization.permissions.manage_billing
        ) {
          return false;
        }
      }
      return true;
    },
    contentPlanActived() {
      return this.permissionToEditBilling && !this.permissionToEditOrganization;
    },
  },
  data() {
    return {
      loading: false,
      errors: {},
      organizationName: '',
      companyName: '',
      companySegment: '',
      userPosition: '',
      avatar_background_color: '',
      avatar_text_color: '',
      plans: '',
      subscriptionPlan: null,
    };
  },
  created() {
    this.$store.dispatch('page/setTitle', 'Configurações da Organização');
    this.$store.dispatch('page/setHead', {
      title: 'Configurações da Organização',
    });
    this.$loading(true);
    this.getSubscriptionPlan();
    ProductsHttp.getProductPlans()
      .then((ret) => {
        this.plans = ret.data.data;
      })
      .finally(() => {
        this.$loading(false);
      });
  },
  beforeUpdate() {
    this.organizationName = this.organization.name;
    const { hash } = window.document.location;
    if (hash === '#plans') {
      window.document.querySelector('#plans-tab').click();
    }
  },
  methods: {
    saveOrganizationData() {
      this.$refs.organizationName.validate().then((success) => {
        this.loading = true;
        if (!success.valid) {
          this.loading = false;
          return;
        }

        this.$store
          .dispatch('organizations/updateItem', {
            id: this.organization.id,
            name: this.organizationName,
          })
          .then(() => {
            this.$store.state.organizations.selected.name =
              this.organizationName;
            this.$store.dispatch(
              'organizations/setSelected',
              this.$store.state.organizations.selected
            );
            this.loading = false;
          });
      });
    },
    changeOrganization() {
      this.$store.dispatch('organizations/setSelected', {
        organization: { id: null },
      });
    },
    getSubscriptionPlan() {
      BillingHttp.getSubscription().then((response) => {
        this.subscriptionPlan = response.data;
      });
    },
    isCurrentPlan(plan) {
      if (!this.subscriptionPlan) {
        return false;
      }

      const subscriptionNextRecurrenceDate = new Date(
        this.subscriptionPlan.next_recurrence_at
      );
      const subscriptionIsValid =
        subscriptionNextRecurrenceDate.valueOf() > new Date().valueOf();

      return this.subscriptionPlan.plan.id === plan.id && subscriptionIsValid;
    },
  },
};
</script>
<style lang="stylus">
.block-title
  color: #013D52
  font-weight: 700
</style>
