<template lang="pug">
modal#remove-role-modal(
  :buttonColor='buttonColor'
  :loading='loading'
  :title='modalTitle || "Excluir Função"'
  @closed='onCloseModal'
  @opened='onOpenModal'
  @process='removeRole'
  close-button-label='Cancelar'
  process-button-label='Excluir'
  ref='modal'
)
  p Ao excluir uma função, a ação não poderá ser desfeita.
    a Os usuários vinculados a ela passarão a ter função de usuário padrão.
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import Icon from '@/ui/atoms/Icon.vue';
import RolesService from '@/modules/business/services/http/organizations/roles/RolesService';
import formHandler from '@/modules/account/common/formHandler';
import alert from '@/modules/account/common/alert';

export default {
  props: {
    organizationId: [Number, String],
    roleId: [Number, String],
    title: String,
  },
  components: {
    Modal,
    Icon,
    ImageLoader,
    RolesService,
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
      errors: {},
      name: '',
      logo: '',
      organization: {},
    };
  },
  computed: {
    modalTitle() {
      return this.title;
    },
  },
  methods: {
    onRemoveLogo() {
      this.logo = '';
    },
    onCloseModal() {
      this.organization = {};
      this.name = '';
      this.logo = '';
      this.loading = true;
    },
    onOpenModal() {
      this.loading = false;
    },
    async removeRole() {
      RolesService.supressDefault = true;
      formHandler.submit(this, () => RolesService.remove(this.roleId), {
        successHandler: (response) => {
          if (response.status === 204) {
            alert.fireAlert('Função removido com sucesso', {
              classes: 'alert-success',
              styles:
                'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
              icon: 'circle-check',
            });
            this.$router.push('/business/users/').catch(() => {});
          }
        },

        errorHandler: (error) => {
          if (error.response.status === 403) {
            alert.fireAlert('Esta função não pode ser removida.', {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #f5c6cb; color: #721c24;',
              icon: 'triangle-exclamation',
            });
          }
        },

        finallyHandler: () => {
          this.loading = false;
          this.$refs.modal.close();
        },
      });
    },
  },
};
</script>
