<template lang="pug">
.signatures-progress(v-tooltip:top='descriptionText')
  .progress-unit(
    :class='{ active: index <= dotsFilled }'
    :key='index'
    v-for='index in qtyElements'
  )
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'EnvelopeSignaturesProgress',
  components: {
    Icon,
  },
  props: {
    recipientsStats: {
      type: Object,
      default: () => ({}),
    },
    qtyElements: {
      type: Number,
      default: () => 6,
    },
  },
  computed: {
    dotsFilled() {
      const percentDone =
        this.recipientsStats.done / this.recipientsStats.total;
      return Math.round(percentDone * this.qtyElements);
    },
    descriptionText() {
      return `${this.recipientsStats.done}/${this.recipientsStats.total} concluídos`;
    },
  },
};
</script>
<style scoped lang="stylus">
.signatures-progress
  display: flex
  .progress-unit
    width: 7px
    height: 7px
    border-radius: 50%
    background-color: #e6e6e6
    margin-right: 3px
    &.active
      background-color: #39D5C5
</style>
