import config from '@/config';
import Http from './Http';

const client = new Http('', config.api);

client.getUser = () => client.get('me');
client.createAvatar = (formData) => client.post(formData, 'me/avatar', '');
client.deleteAvatar = (id) => client.delete(id);

client.resendConfirmation = () =>
  client.post(null, 'me/resend-email-verification');
client.setUser = (user) =>
  client.patch('me', {
    name: user.name,
    phone: user.phone,
  });
client.updatePassword = (currentPassword, password) =>
  client.put('me/password', {
    current_password: currentPassword,
    password,
  });
client.logout = () => client.post('', config.logoutPath);

export default client;
