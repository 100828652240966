import Http from '@/services/Http';

const path = 'envelopes';
const envelopes = new Http(path);

envelopes.create = (data) => envelopes.post(data, path);
envelopes.update = (envelopeId, data) =>
  envelopes.patch(envelopeId, data, path);
envelopes.getItem = (id) => envelopes.get(id);
envelopes.addRecipient = (envelopeId, data) =>
  envelopes.post(data, `${path}/${envelopeId}/recipients`);
envelopes.updateRecipient = (envelopeId, recipientId, data) =>
  envelopes.patch(recipientId, data, `${path}/${envelopeId}/recipients`);
envelopes.removeRecipient = (envelopeId, recipientId) =>
  envelopes.delete(recipientId, '', `${path}/${envelopeId}/recipients`);
envelopes.getExtendedEnvelope = (envelopeId) =>
  envelopes.get(`${envelopeId}?extended=true`);
envelopes.addDocumentContent = (envelopeId, documentId, data) => {
  if (data.type === 'image') {
    const formData = new FormData();
    formData.append('value', data.value);
    formData.append('type', data.type);
    formData.append('page', data.page);
    formData.append('x_axis', data.x_axis);
    formData.append('y_axis', data.y_axis);
    formData.append('width', data.width);
    formData.append('height', data.height);
    formData.append('font_size', data.font_size);
    formData.append('font_family', data.font_family);

    return envelopes.post(
      formData,
      `${path}/${envelopeId}/documents/${documentId}/contents`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }
  return envelopes.post(
    data,
    `${path}/${envelopeId}/documents/${documentId}/contents`
  );
};
envelopes.addDocumentField = (envelopeId, documentId, data) =>
  envelopes.post(data, `${path}/${envelopeId}/documents/${documentId}/fields`);
envelopes.removeDocumentField = (envelopeId, documentId, fieldId) =>
  envelopes.delete(
    fieldId,
    '',
    `${path}/${envelopeId}/documents/${documentId}/fields`
  );
envelopes.removeDocumentContent = (envelopeId, documentId, contentId) =>
  envelopes.delete(
    contentId,
    '',
    `${path}/${envelopeId}/documents/${documentId}/contents`
  );

envelopes.updateDocumentContent = (envelopeId, documentId, elementId, data) => {
  if (data.type === 'image') {
    const formData = new FormData();
    formData.append('value', data.value);
    formData.append('_method', 'PATCH');
    formData.append('type', data.type);
    formData.append('page', data.page);
    formData.append('x_axis', data.x_axis);
    formData.append('y_axis', data.y_axis);
    formData.append('width', data.width);
    formData.append('height', data.height);
    formData.append('font_size', data.font_size);
    formData.append('font_family', data.font_family);

    return envelopes.post(
      formData,
      `${path}/${envelopeId}/documents/${documentId}/contents/${elementId}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }
  return envelopes.patch(
    elementId,
    data,
    `${path}/${envelopeId}/documents/${documentId}/contents`
  );
};
envelopes.updateDocumentField = (envelopeId, documentId, elementId, data) =>
  envelopes.patch(
    elementId,
    data,
    `${path}/${envelopeId}/documents/${documentId}/fields`
  );

envelopes.listSent = (pathParams) =>
  envelopes.get('', '', `${pathParams === undefined ? '' : `?${pathParams}`}`);

envelopes.listFaceToFace = (pathParams) =>
  envelopes.get(
    '',
    '',
    `/face-to-face${pathParams === undefined ? '' : `?${pathParams}`}`
  );

envelopes.listReceived = (pathParams) =>
  envelopes.get(
    '',
    '',
    `/inbox${pathParams === undefined ? '' : `?${pathParams}`}`
  );

envelopes.listEvents = (envelopeId, params) =>
  envelopes.get(`${envelopeId}/events?sort=-created_at&page=${params.page}`);

envelopes.listEspecificEnvelope = (envelopeId, params) =>
  envelopes.get(envelopeId, params, '');

envelopes.sendEnvelope = (envelopeId) =>
  envelopes.post({}, `${path}/${envelopeId}/send`);

envelopes.cancelEnvelope = (envelopeId) =>
  envelopes.post('', `${path}/${envelopeId}/cancel`);

envelopes.deleteEnvelope = (envelopeId) =>
  envelopes.delete(envelopeId, '', path);

envelopes.addDocument = (envelopeId, file) =>
  envelopes.post({ file }, `${path}/${envelopeId}/documents`);

envelopes.addDocumentFile = (envelopeId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return envelopes.post(formData, `${path}/${envelopeId}/documents`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

envelopes.removeDocument = (envelopeId, fileId) =>
  envelopes.delete(fileId, '', `${path}/${envelopeId}/documents`);

envelopes.listEnvelopeNotes = (envelopeId) =>
  envelopes.get('', '', `/${envelopeId}/notes`);

envelopes.reNotify = (envelopeId) =>
  envelopes.post('', `${path}/${envelopeId}/renotify`);

envelopes.download = (envelopeId) => {
  const config = {
    responseType: 'blob',
  };
  return envelopes.get('', '', `/${envelopeId}/download`, config);
};

envelopes.downloadBackgroundSheet = (envelopeToken) => {
  const config = {
    responseType: 'blob',
  };
  return envelopes.get(
    '',
    '',
    `/${envelopeToken}/background-sheet/download`,
    config
  );
};

envelopes.getRecipientsState = (envelopeId) =>
  envelopes.get('', '', `/${envelopeId}/recipients`);

envelopes.copyTo = (into, envelopeId) =>
  envelopes.post({ into }, `${path}/${envelopeId}/copy`);

envelopes.deleteTemplate = (templateId) =>
  envelopes.delete('', '', `/${templateId}`);
export default envelopes;
