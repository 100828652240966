<template lang="pug">
.draft-envelopes-card-table
  card-table(:total-items='data.length' breakpoint-size='lg')
    template(v-for='(item, index) in data' v-slot:[`card-${index}`]='{}')
      router-link(:to='getRoute(item)')
      .mb-1.text-overflow-ellipse(style='width: 90%') {{ getEnvelopeName(item) }}
      .mb-1 ID: {{ item.hashid }}
      .mb-0 Data de criação: {{ item.created_at | dateTime }}
      template(v-if='$root.isMobile')
        button(
          data-bs-target='#new-envelope-mobile-modal'
          data-bs-toggle='modal'
          style='position: absolute; top: 0.75rem; right: 0.45rem'
          title='Utilizar'
          type='button'
        )
          icon(name='pen-to-square')
      template(v-else)
        router-link(
          :to='getRoute(item)'
          style='color: inherit; position: absolute; top: 0.75rem; right: 0.8rem'
          title='Utilizar'
        )
          icon(name='pen-to-square')
      button(
        @click.stop='selectedEnvelopeId = item.id'
        data-bs-target='#delete-envelope-modal'
        data-bs-toggle='modal'
        style='position: absolute; bottom: 1rem; right: 0.5rem'
        title='Excluir'
      )
        icon.icon--canceled(name='trash-can')
    template(v-slot:table-head)
      th(style='width: 5%') ID
      th(style='width: 60%') Nome
      th(style='width: 30%')
        .d-flex
          span.text Data de criação
          button(
            @click='addSort("created_at", "DESC")'
            style='margin-top: -2px'
            v-show='sort.created_at === "ASC"'
          )
            icon.icon--blue(name='angle-down')
          button(
            @click='addSort("created_at", "ASC")'
            style='margin-top: -2px'
            v-show='sort.created_at !== "ASC"'
          )
            icon.icon--blue(name='angle-up')
      th(style='width: 5%')
        span.text Ações
    template(v-for='(item, index) in data' v-slot:[`table-row-${index}`]='{}')
      td {{ item.hashid }}
      td
        .draft-envelopes-card-table__name.text-overflow-ellipse(
          :title='getEnvelopeName(item)'
        ) {{ getEnvelopeName(item) }}
      td {{ item.created_at | dateTime }}
      td
        .d-flex
          template(v-if='$root.isMobile')
            button(
              @click.stop=''
              data-bs-target='#new-envelope-mobile-modal'
              data-bs-toggle='modal'
              title='Utilizar'
              type='button'
            )
              icon.icon--blue(name='pen-to-square')
          template(v-else)
            router-link(
              :to='getRoute(item)'
              style='color: inherit'
              title='Utilizar'
            )
              icon.icon--blue(name='pen-to-square')
          button.ms-2(
            @click.stop='selectedEnvelopeId = item.id'
            data-bs-target='#delete-envelope-modal'
            data-bs-toggle='modal'
            title='Excluir'
          )
            icon.icon--canceled(name='trash-can')
  delete-envelope-modal(
    :envelope-id='selectedEnvelopeId'
    @refresh='$emit("item-deleted", selectedEnvelopeId)'
  )
  new-envelope-mobile-modal
</template>
<script>
import EnvelopeState from '@/enums/EnvelopeState';
import RecipientState from '@/enums/RecipientState';
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import CardTable from '@/ui/organisms/CardTable.vue';
import EnvelopeSignaturesProgress from '@/ui/molecules/EnvelopeSignaturesProgress.vue';
import DeleteEnvelopeModal from '@/modules/business/components/organisms/modals/DeleteEnvelopeModal.vue';
import NewEnvelopeMobileModal from '@/modules/business/components/organisms/modals/NewEnvelopeMobileModal.vue';

export default {
  name: 'ReceivedEnvelopesCardTable',
  components: {
    Icon,
    IconStatus,
    CardTable,
    EnvelopeSignaturesProgress,
    DeleteEnvelopeModal,
    NewEnvelopeMobileModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      EnvelopeState,
      RecipientState,
      sort: {
        created_at: '',
      },
      selectedEnvelopeId: '',
    };
  },
  methods: {
    addSort(column, type) {
      this.sort[column] = type;
      this.$emit('sort', {
        column: column,
        desc: type === 'DESC',
      });
    },

    checkNextOrderByColumnIsDesc(column) {
      if (column === this.sort.column) {
        return !this.sort.desc;
      }
      return false;
    },
    getRoute(item) {
      const route = {
        name: 'business.envelope.documents',
        params: {
          id: item.id,
        },
      };
      if (
        item.state === EnvelopeState.IN_TRANSIT &&
        item.state !== RecipientState.DONE
      ) {
        route.name = 'business.envelopes.viewer';
      }
      return route;
    },
    getEnvelopeName(item) {
      return item.title && item.title !== 'old' ? item.title : item.subject;
    },
  },
};
</script>
<style lang="stylus">
.draft-envelopes-card-table__name
  max-width: 180px;
@media (min-width: 576px)
  .draft-envelopes-card-table__name
    max-width: 180px
@media (min-width: 768px)
  .draft-envelopes-card-table__name
    max-width: 180px
@media (min-width: 992px)
  .draft-envelopes-card-table__name
    max-width: 500px
@media (min-width: 1200px)
  .draft-envelopes-card-table__name
    max-width: 470px
@media (min-width: 1400px)
  .draft-envelopes-card-table__name
    max-width: 640px
</style>
