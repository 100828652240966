<template lang="pug">
modal#reject-envelope-modal(
  :no-footer='true'
  ref='modal'
  size='sm'
  title='Rejeitar envelope'
)
  p.justify-center Indique um motivo para a rejeição
  ValidationObserver(ref='form')
    ValidationProvider(
      :custom-messages='customMessagesMotivo'
      rules='required'
      v-slot='{ errors }'
    )
      textarea.form-control(
        :class='{ "border border-danger": errors[0] }'
        placeholder='Digite seu motivo'
        style='height: 120px'
        v-model='form.reason'
      )
      small.text.text-danger.d-flex(v-if='errors.length > 0')
        icon(name='xmark')
        span.text.ms-2 {{ errors[0] }}
    .text-end.mt-3
      button.btn.btn-secondary(@click='close' type='button')
        | Cancelar
      button.btn.btn-danger.ms-2(
        @click='rejectEnvelope'
        style='border-width: 1px'
        type='button'
      )
        | Rejeitar
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import RecipientsService from '@/modules/business/services/http/envelopes/RecipientsService';
import alert from '@/modules/account/common/alert';
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'RejectEnvelopeModal',
  components: {
    Modal,
    Icon,
  },
  props: {
    token: [Number, String],
  },
  data() {
    return {
      form: {
        reason: '',
      },
      customMessagesMotivo: {
        required: 'É necessário inserir um motivo',
      },
    };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    async rejectEnvelope() {
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.$loading(true);
        RecipientsService.rejectEnvelope(this.token, this.form.reason)
          .then((response) => {
            this.$emit('refresh', response);
          })
          .catch((error) => {
            alert.fireAlert(
              `Erro ao rejeitar envelope. ${error.response.data.message}`,
              {
                classes: 'alert-danger',
                styles:
                  'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
                tag: 'httpAlert',
                icon: 'triangle-exclamation',
              }
            );
          })
          .finally(() => {
            this.$loading(false);
            this.close();
          });
      }
    },
  },
};
</script>
