<template lang="pug">
div
  .row
    .col-12.mb-3
      ul#my-tab.nav.nav-tabs(role='tablist')
        li.nav-item(role='presentation')
          button#signature-code-tab.nav-link.active(
            aria-controls='signature-code'
            aria-selected='true'
            data-bs-target='#signature-code'
            data-bs-toggle='tab'
            role='tab'
            type='button'
          ) Código de assinatura
        li.nav-item(role='presentation')
          button#document-tab.nav-link(
            aria-controls='document'
            aria-selected='true'
            data-bs-target='#document'
            data-bs-toggle='tab'
            role='tab'
            type='button'
          ) Integridade do documento
      #myTabContent.tab-content.pt-3
        #signature-code.tab-pane.fade.show.active(
          aria-labelledby='signature-code-tab'
          role='tab-organization-settings'
        )
          .col-12
            p
              .text Insira o código de assinatura do documento para verificar sua autenticidade.
          .col-12.col-sm-12.col-md-12.col-xl-4
            .card.shadow
              .card-body.card-sign
                .form-group
                  label(for='name') Assinatura
                  ValidationProvider(
                    mode='passive'
                    name='código da assinatura'
                    ref='subscriptionCode'
                    rules='required'
                    v-slot='{ errors }'
                  )
                    .form-control-icon
                      icon(name='signature')
                      input#name.form-control(
                        :class='{ "border border-danger": errors[0] }'
                        autocomplete='off'
                        placeholder='Digite o código da assinatura'
                        type='text'
                        v-model='subscriptionCode'
                      )
                    small.text.text-danger {{ errors[0] }}
          .row.mt-4
            .col-12.text-end
              .btn.btn-secondary.mt-2(@click='submitSignature' type='button') Verificar
        #document.tab-pane.fade.show(
          aria-labelledby='document-tab'
          role='tab-organization-settings'
        )
          .col-12
            .vertical-wizzard.p-5
              .form
                .step(:class='{ active: step === 1 }')
                  .step-number(@click='changeStep(1)') 1
                  h6.step-name Selecionar Arquivo
                  .step-content
                    .col-12.col-sm-12.col-md-12.col-xl-5
                      upload-area(
                        @change='loadFile'
                        style='border-color: #01afec'
                      )
                    .col-12.text-end
                      button.btn.btn-primary.shadow(
                        :disabled='!this.file'
                        @click='process'
                        type='button'
                      )
                        span.text Próximo
                .step(:class='{ active: step === 2 }')
                  .step-number(@click='changeStep(2)') 2
                  h6.step-name Código de assinatura
                  .step-content
                    .col-12.col-sm-12.col-md-12.col-xl-4
                      .card.shadow
                        .card-body.card-sign
                          .form-group
                            label(for='name') Assinatura
                            ValidationProvider(
                              mode='passive'
                              name='código da assinatura'
                              ref='subscriptionCode2'
                              rules='required'
                              v-slot='{ errors }'
                            )
                              .form-control-icon
                                icon(name='signature')
                                input#name.form-control(
                                  :class='{ "border border-danger": errors[0] }'
                                  autocomplete='off'
                                  placeholder='Digite o código da assinatura'
                                  type='text'
                                  v-model='subscriptionCode2'
                                )
                              small.text.text-danger {{ errors[0] }}
                    .col-12.text-end
                      button.btn.btn-primary.shadow(
                        @click='submitPdfFile'
                        type='button'
                      )
                        span.text Verificar
  success-checker-modal(:signInfo='signInfo' ref='successCheckerModal')
  subscription-information-modal(
    :signInfo='signInfo'
    ref='subscriptionInformationModal'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import UploadArea from '@/ui/molecules/UploadArea.vue';
import SuccessCheckerModal from '@/modules/business/components/organisms/modals/SuccessCheckerModal.vue';
// eslint-disable-next-line max-len
import SubscriptionInformationModal from '@/modules/business/components/organisms/modals/SubscriptionInformationModal.vue';
import ValidateService from '@/modules/business/services/http/validate/ValidateService';
import sha1 from 'js-sha1';
import alert from '@/modules/account/common/alert';

export default {
  name: 'DocumentsPage',
  components: {
    Icon,
    IconStatus,
    UploadArea,
    SuccessCheckerModal,
    SubscriptionInformationModal,
  },
  data() {
    return {
      sha1File: '',
      file: '',
      subscriptionCode: '',
      subscriptionCode2: '',
      signInfo: '',
      step: 1,
    };
  },
  created() {
    this.$store.dispatch('page/setTitle', 'Verificador');
    this.$store.dispatch('page/setHead', { title: 'Verificador' });
    this.$store.dispatch('page/setClass', 'business checker');
    try {
      this.$loading(true);
      if (this.$route.query.token) {
        this.subscriptionCode = this.$route.query.token;
        this.checkSignature(this.subscriptionCode).then(() => {
          this.$refs.subscriptionInformationModal.$refs.modal.open();
        });
      }
    } finally {
      this.$loading(false);
    }
  },
  methods: {
    process() {
      if (this.file) {
        switch (this.step) {
          case 1:
          default:
            this.step += 1;
            break;
        }
      }
    },
    changeStep(step) {
      if (this.file) {
        this.step = step;
      }
    },
    async submitSignature() {
      this.$loading(true);
      try {
        this.$refs.subscriptionCode.validate().then(async (success) => {
          if (!success.valid) {
            this.$loading(false);
            return;
          }
          this.checkSignature(this.subscriptionCode).then(() => {
            this.$refs.subscriptionInformationModal.$refs.modal.open();
          });
        });
      } catch {
        this.$loading(false);
      }
    },
    async submitPdfFile() {
      try {
        this.$refs.subscriptionCode2.validate().then(async (success) => {
          this.$loading(true);
          if (!success.valid) {
            this.$loading(false);
            return;
          }
          this.checkSignature(this.subscriptionCode2).then(() => {
            this.checkPdfFile();
          });
        });
      } finally {
        this.$loading(false);
      }
    },
    async checkSignature(subscriptionCode) {
      this.$loading(true);
      try {
        await ValidateService.setErrorHandler((error) => {
          let messageError;
          if (error.response.data.errors) {
            messageError = error.response.data.errors.code;
          } else {
            messageError = error.response.data.message;
          }
          alert.fireAlert(messageError, {
            classes: 'alert-danger',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
            icon: 'triangle-exclamation',
          });
        }, true)
          .validate(subscriptionCode)
          .then(async (result) => {
            this.signInfo = result.data;
          });
      } finally {
        this.$loading(false);
      }
    },
    async checkPdfFile() {
      await this.extractSha1OfFile();
      if (this.signInfo.sha1 === this.sha1File) {
        this.$refs.subscriptionInformationModal.$refs.modal.open();
      }
      if (this.signInfo.sha1 !== this.sha1File) {
        alert.fireAlert('Documento enviado diferente do qual foi assinado.', {
          classes: 'alert-danger',
          styles:
            'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
          tag: 'httpAlert',
          icon: 'triangle-exclamation',
        });
      }
    },
    loadFile(blob) {
      this.file = blob;
    },
    async extractSha1OfFile() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const self = this;
        reader.onload = async (event) => {
          self.sha1File = await sha1(event.target.result);
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(this.file);
      });
    },
  },
};
</script>
