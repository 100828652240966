import Http from '@/services/Http';

const path = 'onboardings';
const client = new Http(path, {}, 'bff');

client.getOnboardings = () => client.get();

client.updateOnboardings = (params) => client.put(params);

export default client;
