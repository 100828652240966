var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modal",attrs:{"id":"invite-user-modal","loading":_vm.loading,"title":_vm.modalTitle || "Convidar usuário","close-button-label":"Cancelar","process-button-label":"Convidar"},on:{"closed":_vm.onCloseModal,"opened":_vm.onOpenModal,"process":_vm.submit}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"exampleInputEmail"}},[_vm._v("Endereço de e-mail *")]),_c('div',{staticClass:"form-control-icon"},[_c('icon',{attrs:{"name":"at"}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],staticClass:"form-control",class:{ "border border-danger": errors[0] },attrs:{"id":"email","placeholder":"Digite um e-mail ou mais","type":"text"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})],1),_c('small',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"função","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"role"}},[_vm._v("Funções")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.rolesUser),expression:"rolesUser"}],staticClass:"form-select form-select-lg mb-8",class:{ "border border-danger": errors[0] },attrs:{"id":"role"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.rolesUser=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.roles),function(role){return _c('option',{key:role.id,domProps:{"value":role.id}},[_vm._v(_vm._s(role.name === 'Usuário' ? 'Usuário padrão' : role.name))])}),0),_c('small',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"team"}},[_vm._v("Time")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.team),expression:"team"}],staticClass:"form-select form-select-lg mb-8",class:{ "border border-danger": errors[0] },attrs:{"id":"team"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.team=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.teams),function(team){return _c('option',{key:team.id,domProps:{"value":team.id}},[_vm._v(_vm._s(team.name))])}),0),_c('small',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }