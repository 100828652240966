<template lang="pug">
.row
  .col-12.mb-3
    ul#my-tab.nav.nav-tabs(role='tablist')
      li.nav-item(role='presentation')
        router-link.nav-link(
          :class='{ active: isTabActive("manageapplications") }'
          :to='{ name: "account.developer.manageapplications" }'
        ) Gerenciar aplicações
      li.nav-item(role='presentation')
        router-link.nav-link(
          :class='{ active: isTabActive("documentation") }'
          :to='{ name: "account.developer.documentation" }'
        ) Documentação
    #myTabContent.tab-content.pt-3
      router-view
</template>

<script>
export default {
  name: 'DeveloperPage',
  methods: {
    isTabActive(type) {
      return this.$route.name.split('.').pop() === type;
    },
  },
};
</script>
