<template lang="pug">
modal#delete-developer-client-modal(
  :loading='loading'
  @process='publish'
  close-button-label='Não'
  process-button-label='Sim'
  ref='modal'
  title='Publicar'
)
  .text-center
    p.text-start Você está prestes a publicar a aplicação no ambiente de produção. Deseja continuar?

    img.mt-4.mb-3(
      alt='developer-delete-warning'
      src='/img/ilustrations/publish-application.svg'
    )
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import OauthService from '@/services/Oauth';
import alert from '@/modules/account/common/alert';

export default {
  name: 'PublishApplicationModal',
  props: {
    clientId: [Number, String],
  },
  components: {
    Modal,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    publish() {
      this.loading = true;
      OauthService.deploy(this.clientId)
        .then(() => {
          this.listClients();
          alert.fireAlert('Aplicação movida para produção', {
            classes: 'alert-success',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
            icon: 'circle-check',
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$refs.modal.close();
          this.$emit('refresh');
        });
    },
  },
};
</script>
