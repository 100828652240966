<template lang="pug">
modal.qualified-signature-plugin-authorize-modal(
  :no-footer='true'
  @closed='$emit("closed")'
  ref='modal'
  size='lg'
  title='Autorização'
)
  iframe.iframe(
    :class='{ hide: hide }'
    :src='link'
    height='560'
    ref='iframe'
    width='100%'
  )
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'QualifiedSignaturePluginAuthorizeModal',
  components: {
    Icon,
    Modal,
  },
  data() {
    return {
      link: '',
      hide: true,
    };
  },
  mounted() {
    this.$refs.iframe.onload = () => {
      this.$refs.iframe.src = this.link;
      setTimeout(() => {
        this.hide = false;
      }, 500);
    };
  },
};
</script>
<style lang="stylus">
.qualified-signature-plugin-authorize-modal
  .iframe
    opacity: 1
    &.hide
      opacity: 0
</style>
