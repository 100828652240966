<template lang="pug">
#received.tab-pane.fade.show.active(
  aria-labelledby='received-tab'
  role='tabpanel'
)
  .row(v-if='!showFeedbackNoTemplates')
    .mb-3.col-12.col-lg-6.col-xl-5
      .form-control-icon--end-line.flex-grow-1
        input.form-control.rounded-3(
          @input='searchTemplate()'
          autocomplete='off'
          placeholder='Buscar por nome ou ID'
          v-model='filters.search'
        )
        icon.icon--blue.font-size-subtitle(name='magnifying-glass')
  .row(v-if='!isEmptyList')
    .col-12
      template-envelopes.mb-5(
        :data='envelopesTemplate.data'
        @item-deleted='fetchTemplate'
        @sort='setSortColumn'
      )
  .text-center.mt-5(v-if='showFeedbackNoTemplates')
    img.img(
      src='/img/ilustrations/no-document-template.svg'
      style='max-height: 260px'
    )
    .mt-4.text-center Você ainda não tem modelos para utilizar.<br>
      | Você poderá criar um quando estiver finalizando o envio de um envelope.

  .text-center.mt-5(v-if='showFeedbackNoResults')
    img.img-fluid(src='/img/ilustrations/no-contacts-found.svg')
    p.font-color-blue.text-center.mt-4
      | Não encontramos resultados para sua pesquisa.
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import debounce from 'debounce';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import TableComponent from '@/ui/organisms/Table.vue';
import alert from '@/modules/account/common/alert';
import TemplateEnvelopes from '@/modules/business/components/organisms/card-table/TemplateEnvelopesCardTable.vue';

export default {
  name: 'DocumentsTemplatePage',
  components: {
    Icon,
    IconStatus,
    SimplePaginator,
    TableComponent,
    TemplateEnvelopes,
  },
  data() {
    return {
      isLocalLoading: false,
      envelopesTemplate: {
        data: [],
        links: {},
        meta: {},
      },
      filters: {
        search: '',
        sort: '-created_at',
      },
    };
  },
  async created() {
    this.$store.dispatch('page/setTitle', 'Modelos de Envelopes');
    this.$store.dispatch('page/setHead', { title: 'Envelopes' });
    this.$store.dispatch('page/setClass', 'business documents');
    this.fetchTemplate();
  },
  computed: {
    isEmptyList() {
      return this.envelopesTemplate.data.length === 0;
    },
    isSearching() {
      return this.filters.search.length > 0;
    },
    showFeedbackNoTemplates() {
      return this.isEmptyList && !this.isSearching;
    },
    showFeedbackNoResults() {
      return this.isEmptyList && this.isSearching;
    },
  },
  methods: {
    fetchTemplate(page = 1) {
      this.$loading(true);
      this.envelopesTemplate.data = [];
      const params = new URLSearchParams({
        ...(this.filters.search && { q: this.filters.search }),
        ...{ page },
        ...{ 'filter[state]': 'template' },
        ...{ sort: this.filters.sort },
      });
      EnvelopesService.listSent(params.toString())
        .then((response) => {
          this.envelopesTemplate = response.data;
        })
        .catch((error) => {
          alert.fireAlert(
            `Erro ao consultar os modelos salvos. ${error.response.data.message}`,
            {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
              icon: 'triangle-exclamation',
            }
          );
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    searchTemplate: debounce(function searchTemplate() {
      this.fetchTemplate();
    }, 300),
    setSortColumn(column) {
      this.filters.sort = `${column.desc ? '-' : ''}${column.column}`;
      this.fetchTemplate();
    },
  },
};
</script>

<style lang="stylus" scoped>
.data
  padding-left: 13px
@media screen and (min-width: 768px)
  .form-control-icon--end-line
    max-width: 260px
.router-icon
  color: #013D52
  font-size: 1.25rem
.form-group
  margin-bottom: 0.5rem
</style>
