<template lang="pug">
modal#subscription-information-modal(
  close-button-label='Ok'
  ref='modal'
  title='Informações do código de assinatura'
  v-if='this.signInfo'
)
  .text Arquivo
  ul
    li
      small.text Nome do arquivo: {{ this.signInfo.name }}
    li
      small.text Criado em: {{ this.signInfo.created_at | dateTime }}
  .text.mt-4 Envelope:
  ul
    li
      small.text Assunto: {{ this.signInfo.envelope.subject }}
    li
      small.text Status: {{ $t(this.signInfo.envelope.state) }}
    li
      small.text Criado em: {{ this.signInfo.envelope.created_at | dateTime }}
  .text.mt-4 Assinantes:
  .card.car--recipients.mt-3
    .card-body.card-body--recipients.recipients-detail
      ul.nav.flex-column
        div(
          :key='recipient.id'
          v-for='recipient in this.signInfo.envelope.recipients'
        )
          li
            small.text {{ recipient.addressees[0].value }}
          li
            IconStatus(:iconStatus='recipient.state')
            span.text(style='padding-left: 0.4rem') {{ $t(recipient.state) }}
        <hr>
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';

export default {
  name: 'SubscriptionInformationModal',
  props: {
    signInfo: [Object, String],
  },
  components: {
    Modal,
    IconStatus,
  },
};
</script>

<style lang="stylus">
.car--recipients
  -webkit-box-shadow: none
  -moz-box-shadow: none
  box-shadow: none
.card-body--recipients
  border: 0.1rem solid #CCCCCC
  border-radius: 0.2rem
  .text
    vertical-align: baseline
    font-size: 14px
</style>
