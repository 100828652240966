<template lang="pug">
div
  .page.bg-auth(style='height: 100vh')
    .text-center.mb-2
      header-component
    .card.rounded-3
      .card-body.p-5
        .text-center
          img(src='/img/Error404.svg')
          h3.text-center.font-size-title.my-4
            | Oops! Página não encontrada
          .text.text-center.font-size-1
            | A página que procura não foi encontrada ou não existe.
          .d-grid
            router-link.btn.btn-form-submit.mt-4(
              :to='{ name: "business.dashboard" }'
            )
              | Página inicial
</template>

<script>
import HeaderComponent from '@/ui/organisms/SimpleHeader.vue';

export default {
  name: 'PageNotFound',
  components: {
    HeaderComponent,
  },
  created() {
    this.$store.dispatch('page/setTitle', 'Página não encontrada');
    this.$loading(false);
  },
};
</script>

<style scoped lang="stylus">
.bg-auth
  background-image: url('../../../../public/img/bg-auth.png')
  background-repeat:no-repeat
  background-size:cover
  .card
    box-shadow: none
    max-width: 420px;
    margin: 0 auto 2rem auto
  h3
    font-weight: bold
    color: #013D52
  .text
    color: #013D52
</style>
