<template lang="pug">
.account-contacts
  .row
    .col-12
      ul#my-tab.nav.nav-tabs(role='tablist')
        li.nav-item.active(role='presentation')
          .nav-link.active Lista de Contatos

  .row.mt-4
    .col-12.col-md-4
      .form-group.d-flex.flex-md-row
        .form-control-icon--end-line(style='width: 400px')
          input#name.form-control.rounded-3(
            @input='searchContacts'
            autocomplete='off'
            placeholder='Buscar por nome, e-mail ou telefone'
            v-model='filters.search'
          )
          icon(name='magnifying-glass')

  .row(v-if='!hasContacts && isSearchingContacts')
    .col-12.mt-5
      .text-center.mt-5
        img.img-fluid.img(
          src='/img/ilustrations/no-contacts-found.svg'
          style='max-height: 260px'
        )
        p.mt-4 Não encontramos resultados para sua pesquisa.

  .row(v-else-if='hasContacts')
    .col-12
      contacts-card-table(
        :data='contacts.data'
        @item-deleted='listContacts'
        @item-edited='listContacts'
      )
      simple-paginator.mt-3(:payload='contacts' @navigate='listContacts')

  .row(v-else-if='!hasContacts && isSearchingContacts')
    .col-12.mt-5
      .text-center.mt-5
        img.img-fluid.img(
          src='/img/ilustrations/mailbox-no-contacts.svg'
          style='max-height: 260px'
        )
        p.mt-4 Você ainda não possui contatos.
          br
          | Eles são adicionados automaticamente no envio de um envelope.
  create-contact-modal(@refresh='listContacts' ref='createContactModal')
  new-envelope-mobile-modal
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import ImageHolder from '@/ui/atoms/ImageHolder.vue';
import ContactService from '@/modules/account/services/http/ContactsService';
import CreateContactModal from '@/modules/account/components/organisms/modals/CreateContactModal.vue';
import debounce from 'debounce';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import NewEnvelopeMobileModal from '@/modules/business/components/organisms/modals/NewEnvelopeMobileModal.vue';
import ContactsCardTable from '@/modules/account/components/organisms/card-table/ContactsCardTable.vue';

export default {
  name: 'ContactsPage',
  components: {
    Icon,
    ImageHolder,
    NewEnvelopeMobileModal,
    CreateContactModal,
    SimplePaginator,
    ContactsCardTable,
  },
  data() {
    return {
      contacts: {
        data: [],
        links: {},
        meta: {},
      },
      filters: {
        search: '',
      },
      selectedContact: {},
      queryField: '',
    };
  },
  created() {
    this.$store.dispatch('page/setTitle', 'Contatos');
    this.$store.dispatch('page/setHead', 'Contatos');
    this.$store.dispatch('page/setClass', 'account contacts');
    this.listContacts();
  },
  computed: {
    tableHead() {
      return [
        { label: 'Nome' },
        { label: 'Endereço de e-mail' },
        { label: 'Telefone' },
        { label: ' ' },
      ];
    },
    hasContacts() {
      return this.contacts.data.length > 0;
    },
    isSearchingContacts() {
      return this.filters.search !== '';
    },
    showCallToCreateNewContact() {
      return !this.hasContacts && !this.isSearchingContacts;
    },
    showContactsFormSearch() {
      return this.hasContacts || this.isSearchingContacts;
    },
  },
  methods: {
    async listContacts(page = 1) {
      this.$loading(true);
      const params = new URLSearchParams({
        q: this.filters.search,
        page,
      });
      const result = await ContactService.list(params);
      this.contacts = result.data;
      this.$loading(false);
    },
    createNewContactModel() {
      this.$refs.createContactModal.$refs.modal.open();
    },
    openEditContactModal(contact) {
      this.selectedContact = contact;
      this.$nextTick(this.$refs.editContactModal.$refs.modal.open);
    },
    openDeleteContactModal(contact) {
      this.selectedContact = contact;
      this.$nextTick(this.$refs.deleteContactModal.$refs.modal.open);
    },
    searchContacts: debounce(function searchContacts() {
      this.listContacts();
    }, 300),
  },
};
</script>
<style lang="stylus">
.account-contacts
  .action
    min-width: 100px
    button
      .icon
        font-size: 1.1rem
        margin-right: 5px
    button:nth-child(1)
      .icon
        color: #013D52
  .contatc-name
    position: relative
    .contact-icon
      position: absolute
      top: 30%
@media screen and (max-width: 768px)
  .account-contacts
    .table
      tbody
        tr
          position: relative
          display: flex
          flex-direction: row
          flex-wrap: wrap
          padding-left: 0
          td
            padding-left: 13px
            flex: 0 0 80%
    .subject
      order: 1
      .paper-icon
        display: none
    .data
      order: 3
    .action
      order: 2
      .dropdown-toggle
        position: absolute
        top: 50%
        margin-top: -0.65rem
        &:nth-child(1)
          right: 55px
</style>
