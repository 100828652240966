<template lang="pug">
.plan-comparison
  .row(style='margin-top: 32px; margin-bottom: 32px')
    .col-12
      .card.card--plan-comparison
        a(
          aria-controls='collapseExample'
          aria-expanded='false'
          data-bs-toggle='collapse'
          href='#collapseExample'
          role='button'
        )
          .card-body
            span.text Tabela comparativa de planos
            icon(name='chevron-down')
        #collapseExample.collapse
          .col-12.col-table
            table.table.table-bordered
              tbody
                tr
                  th(style='vertical-align: bottom; padding-bottom: 1rem')
                    .text(style='font-weight: 600; font-size: 1rem') Funcionalidades
                  th
                    .header-plan
                      .header-plan-title
                        i.icon.fas.fa-fw.fa-leaf
                        span.text Básico
                      .header-plan-description
                        .text Perfeito para iniciantes ou autônomos, oferecendo uma solução prática e econômica
                          | para enviar e assinar documentos
                      .header-plan-button
                        button.btn.btn-primary(type='button') Escolher Plano
                  th 
                    .header-plan
                      .header-plan-title
                        i.icon.fas.fa-fw.fa-leaf
                        span.text Básico
                      .header-plan-description
                        .text Perfeito para iniciantes ou autônomos, oferecendo uma solução prática e econômica
                          | para enviar e assinar documentos
                      .header-plan-button
                        button.btn.btn-primary(type='button') Escolher Plano
                  th 
                    .header-plan
                      .header-plan-title
                        i.icon.fas.fa-fw.fa-leaf
                        span.text Básico
                      .header-plan-description
                        .text Perfeito para iniciantes ou autônomos, oferecendo uma solução prática e econômica
                          | para enviar e assinar documentos
                      .header-plan-button
                        button.btn.btn-primary(type='button') Escolher Plano
                  th 
                    .header-plan
                      .header-plan-title
                        i.icon.fas.fa-fw.fa-leaf
                        span.text Básico
                      .header-plan-description
                        .text Perfeito para iniciantes ou autônomos, oferecendo uma solução prática e econômica
                          | para enviar e assinar documentos
                      .header-plan-button
                        button.btn.btn-primary(type='button') Escolher Plano
                tr.linha-cinza
                  th 
                    .text Utilização
                  th(colspan='4')
                tr
                  th 
                    .text Quantidade de usuários
                  th
                    .text até 5
                  th 
                    .text até 10
                  th 
                    .text até 20
                  th 
                    .text sob medida
                tr
                  th 
                    .text Quantidade de assinantes
                  th
                    .text ilimitados
                  th 
                    .text ilimitados
                  th 
                    .text ilimitados
                  th 
                    .text ilimitados
                tr
                  th 
                    .text Divisão por documentos/ envelopes
                  th
                    .text -
                  th 
                    .text -
                  th 
                    .text -
                  th 
                    .text -
                tr.linha-cinza
                  th 
                    .text Armazenamento
                  th(colspan='4')
                tr
                  th 
                    .text Espaço para armazenar documentos
                  th
                    .text 500 MB
                  th 
                    .text 1 Gb
                  th 
                    .text 3 Gb
                  th 
                    .text sob medida
                tr
                  th 
                    .text Armazenamento de documentos originais
                  th
                    .text -
                  th 
                    .text -
                  th 
                    .text -
                  th 
                    .text -
                tr
                  th 
                    .text Criar pastas
                  th
                    .text -
                  th 
                    .text -
                  th 
                    .text -
                  th 
                    .text -
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'PlanComparison',
  components: { Icon },
};
</script>
