<template lang="pug">
modal#update-avatar-modal(
  :loading='loading'
  @closed='onCloseModal'
  @process='submit'
  close-button-label='Cancelar'
  process-button-label='Salvar'
  ref='modal'
  size='lg'
  title='Recortar'
)
  .img-container
    img(:src='image' ref='image')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

export default {
  components: { Modal, Cropper },
  props: {
    file: Blob,
    dimensions: Object,
  },

  data() {
    return {
      loading: false,
      cropper: {},
      image: null,
    };
  },
  watch: {
    file() {
      this.loadFile();
    },
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    loadFile() {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onloadend = (event) => {
        this.image = event.currentTarget.result;
        this.$nextTick(() => {
          this.startCropper();
        });
      };
    },

    startCropper() {
      this.cropper = new Cropper(this.$refs.image, {
        zoomable: true,
        scalable: false,
        dragMode: 'none',
        cropBoxResizable: false,
        cropBoxMovable: true,
        movable: false,
        aspectRatio: this.dimensions.width / this.dimensions.height,
      });
    },

    onCloseModal() {
      this.cropper.destroy();
    },

    submit() {
      this.cropper.getCroppedCanvas().toBlob(async (blob) => {
        this.$emit('processCroppedImage', blob);
        this.$refs.modal.close();
      });
    },
  },
};
</script>

<style lang="stylus">

.img-container
  max-width: 100%;

  img
    max-width: 100%;
    width: 400px;

  .cropper-view-box
    box-shadow: 0 0 0 1px #39f

  .cropper-face
    background-color:inherit !important

  .cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw,
  .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line
    display:none !important
</style>
