<template lang="pug">
#element.folder-button--element-button.input-group.collapsed.w-100
  icon(name='folder-open')
  input#simp-comments.form-control(
    :placeholder='placeHolder'
    autocomplete='off'
    type='text'
    v-model='folderName'
  )
  button.btn.btn-thin.button-input-group(@click='interactWithTheButton') {{ buttonText }}
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'AnimateInputGroup',
  components: {
    Icon,
  },
  props: {
    textBeforeClick: String,
    textAfterClick: String,
    placeHolder: String,
  },
  data() {
    return {
      buttonText: this.textBeforeClick,
      folderName: '',
    };
  },
  methods: {
    interactWithTheButton() {
      const el = document.getElementById('element');
      if (el.classList.contains('collapsed')) {
        el.classList.remove('collapsed');
        this.buttonText = this.textAfterClick;
      } else {
        if (this.folderName) {
          this.$emit('createFolder', this.folderName);
          this.folderName = '';
        }
        this.buttonText = this.textBeforeClick;
        el.classList.add('collapsed');
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
  .folder-button--element-button
    display: inline;
    margin-left: auto;
    margin-right: auto;
    input
      padding-left: 3em;
    .icon
      position: absolute;
      top: 0;
      bottom: 0;
      font-size: 1em;
      margin: auto;
      left: 1em;
      color: rgba(0,0,0,0.1);
      z-index: 9;
      display: inline
    .button-input-group
      height: 3.188rem;
      top: -0.13rem;
      border-radius: 0 0.313rem 0.313rem 0
    .form-control
      transition: max-width 1s, min-width 1s;
      max-width: 14rem;
      min-width: 14rem;
      display: inline;
      box-shadow: none
    &.collapsed
      input
        right: -60px
      .icon
        display none
      .form-control
        max-width: 0;
        min-width: 0;
        width: 0;
      .button-input-group
        border-radius: 0.313rem !important
@media screen and (max-width: 768px)
  .folder-button--element-button
    width: 100%
    height: 10px
    &.collapsed
      input
        position: absolute
        top: -15px
        right: 0px
      .icon
        display none
      .button-input-group
        width: 100%
</style>
