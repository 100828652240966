<template lang="pug">
modal#delete-invite-user-modal.text-center(
  :buttonColor='buttonColor'
  :loading='loading'
  @process='deleteInvite'
  close-button-label='Cancelar'
  process-button-label='Excluir'
  processButtonClass='btn-danger'
  ref='modal'
  size='sm'
  title='Excluir usuário'
)
  .text-start Deseja realmente excluir este convite? <br>
    | O usuário não poderá mais aceitar o convite para ingressar na sua organização.
  img.mt-4.mb-3(
    alt='user-delete-warning'
    src='/img/ilustrations/delete-invite-user.svg'
  )
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import InvitationService from '@/services/InvitationService';
import alert from '@/modules/account/common/alert';

export default {
  name: 'DeleteInviterUserModal',
  props: {
    email: String,
  },
  components: {
    Modal,
    ImageLoader,
    InvitationService,
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
    };
  },
  methods: {
    deleteInvite() {
      this.$loading(true);
      InvitationService.deleteInvite(this.email)
        .then(() => {
          this.$emit('refresh');
          this.$refs.modal.close();
          alert.fireAlert('Convite excluído com sucesso!', {
            styles:
              'background-color: #D4EDDA; border: solid 1px #AACFB3; color: #0F6642;',
            icon: 'circle-check',
          });
        })
        .catch((error) => {
          alert.fireAlert(
            `Não foi possível excluir este convite! ${error.response.data.message}`,
            {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
              icon: 'circle-exclamation',
            }
          );
        })
        .finally(() => {
          this.$loading(false);
        });
    },
  },
};
</script>
