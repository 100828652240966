import Http from '@/services/Http';

const path = 'p/envelopes/recipients';

const client = new Http('');

client.fillIdentifier = (token, code, value) =>
  client.put(code, { value }, `${path}/${token}/identifiers`);

client.fillPhotoIdentifier = async (token, code, value) => {
  const file = await client.getFileObjectFromBase64(value);
  const formData = new FormData();
  formData.append('value', file);
  formData.append('_method', 'PUT');
  return client.post(formData, `${path}/${token}/identifiers/${code}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default client;
