<template lang="pug">
modal#rejected-envelope-modal(
  :no-footer='isPublic'
  :show-cancel-button='false'
  @process='$emit("envelope-rejected")'
  process-button-label='Ok'
  ref='modal'
  size='sm'
  title='Rejeição enviada'
)
  p.justify-center Sua rejeição foi enviada para o remetente e o envelope foi cancelado.
  .text-center.mt-4
    img.img-fluid(src='/img/ilustrations/canceled-rejected-envelope.svg')
  buttons-login-and-create-account.mt-4(v-if='isPublic')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ButtonsLoginAndCreateAccount from '@/modules/business/components/molecules/ButtonsLoginAndCreateAccount.vue';

export default {
  name: 'RejectEnvelopeModal',
  components: {
    Modal,
    ButtonsLoginAndCreateAccount,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
