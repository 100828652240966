<template lang="pug">
.face-to-face-document(v-if='payload.id')
  .recipient-name.mb-4
    icon.me-2(name='signature')
    span.text {{ payload.name }}
  documents-viewer.viewer(
    :document='payload.envelope.documents[0]'
    :documents='payload.envelope.documents'
    :envelope='payload.envelope'
    :recipient-id='payload.id'
    :signature-support='["draw"]'
    @element-updated='onElementUpdate'
    @empty-fields='emptyFields = $event'
    @loaded='documentLoaded = $event'
    @signature-updated='onSignatureUpdate'
  )
  bottom-action(
    :class='{ loaded: documentLoaded }'
    :disableOptions='emptyFields.length > 0'
    :payload='payload'
    @canceled='canceled'
    @rejected='rejected'
    @signatureFailure='signatureFailure'
    @signed='signed'
  )
  show-signature-failure-modal(ref='showSignatureFailureModal')
  approve-envelope-modal(
    :subject='payload.envelope.name || payload.envelope.subject'
    ref='approveEnvelopeModal'
  )
  show-signature-sent-modal(ref='showRequestSignatureSentModal')
  show-envelope-done-modal(
    :envelopeId='payload.envelope.id'
    ref='showEnvelopeDoneModal'
    route-name='business.envelopes.faceToFaceList'
  )
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import RecipientsService from '@/modules/business/services/http/envelopes/RecipientsService';
import DocumentsViewer from '@/ui/organisms/DocumentsPDFViewer.vue';
import EnvelopeManager from '@/modules/business/services/EnvelopeManager';
import BottomAction from '@/ui/organisms/DocumentsBottom.vue';
import RecipientType from '@/enums/RecipientType';
import ShowSignatureFailureModal from '@/modules/business/components/organisms/modals/ShowSignatureFailureModal.vue';
import ShowSignatureSentModal from '@/modules/business/components/organisms/modals/RequestSignatureSentModal.vue';
import ApproveEnvelopeModal from '@/modules/business/components/organisms/modals/ApproveEnvelopeModal.vue';
import ShowEnvelopeDoneModal from '@/modules/business/components/organisms/modals/ShowEnvelopeDoneModal.vue';
import SignatureService from '@/services/SignatureHttp';

export default {
  name: 'FaceToFaceDocument',
  components: {
    DocumentsViewer,
    ShowSignatureFailureModal,
    ShowSignatureSentModal,
    ApproveEnvelopeModal,
    ShowEnvelopeDoneModal,
    BottomAction,
    Icon,
  },
  data() {
    return {
      payload: {},
      isAllowed: false,
      emptyFields: [],
      documentLoaded: false,
    };
  },
  created() {
    this.$store.dispatch('page/setClass', 'face-to-face-document');
    this.$store.dispatch('page/setTitle', 'Coleta de assinatura');
    this.$store.dispatch('page/setHead', {
      title: 'Coleta de assinatura',
      action: () => {
        this.$router.replace({
          name: 'business.envelopes.faceToFaceList',
        });
      },
    });
    this.loadEnvelope();
  },
  methods: {
    loadEnvelope() {
      RecipientsService.listEspecificRecipientEnvelope(
        this.$route.params.token
      ).then((res) => {
        this.processEnvelope(res);
      });
    },
    processEnvelope(res) {
      this.payload = res.data;
      this.payload.envelope.documents = res.data.envelope.documents.map(
        (doc) => {
          const document = doc;
          document.src = document.links.display;
          // eslint-disable-next-line max-len
          document.elements = EnvelopeManager.extratElementsFromDocument(
            document,
            this.payload.envelope.recipients,
            this.signatures,
            this.payload.value,
            this.payload.state
          );
          return document;
        }
      );
    },
    onElementUpdate() {},
    onSignatureUpdate(signatureFile) {
      SignatureService.savePublicSignature(
        this.$route.params.token,
        'signature',
        signatureFile.blob
      );
    },
    rejected(response) {
      this.processEnvelope(response);
    },
    canceled(response) {
      this.processEnvelope(response);
    },
    signed(response) {
      if (response.status === 202) {
        this.fetchEnvelope();
        this.$refs.showRequestSignatureSentModal.$refs.modal.open();
        return;
      }
      this.processEnvelope(response);
      if (this.payload.type === RecipientType.APPROVER) {
        this.$refs.approveEnvelopeModal.$refs.modal.open();
        return;
      }
      this.$refs.showEnvelopeDoneModal.$refs.modal.open();
    },
    signatureFailure() {
      this.$refs.showSignatureFailureModal.$refs.modal.open();
    },
  },
};
</script>
<style lang="stylus">
.face-to-face-document
  .recipient-name
    color: #013d52
    font-size: 1.4rem
    font-weight: 600
  .pdf-render.loading
    top: 1.7rem
  .nav-documents-bottom
    display: none
    &.loaded
      display: block
@media(max-width: 576px)
  .face-to-face-document
    padding-bottom: 100px
</style>
