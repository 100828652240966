<template lang="pug">
.row
  .col-12
    .text-center.mt-5
      img.img(
        src='/img/ilustrations/developer-documentation.svg'
        style='max-height: 260px'
      )
      p.m-auto.mt-4.mb-3(style='max-width: 420px') Consulte a documentação completa com todos os detalhes da API.
      a.btn.btn-primary(:href='docsUrl' target='_blank') Ver documentação completa
</template>

<script>
import AppConfig from '@/config';

export default {
  created() {
    this.$store.dispatch('page/setTitle', 'Desenvolvedor');
    this.$store.dispatch('page/setHead', 'Desenvolvedor');
    this.$store.dispatch('page/setClass', 'account developer');
  },
  computed: {
    docsUrl() {
      return AppConfig.docsUrl || '';
    },
  },
};
</script>

<style lang="stylus">
a
  text-decoration: none
</style>
