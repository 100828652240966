<template lang="pug">
input.input-tel(v-model='interface')
</template>
<script>
export default {
  name: 'InputTel',
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      iti: '',
    };
  },
  computed: {
    interface: {
      set(v) {
        this.$emit('input', this.iti.getNumber());
        return v;
      },
      get() {
        return '';
      },
    },
  },
  mounted() {
    this.iti = window.intlTelInput(this.$el, {
      initialCountry: 'br',
      preferredCountries: ['br', 'ar', 'uy'],
      hiddenInput: 'phone',
      separateDialCode: true,
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js',
    });
    this.$el.addEventListener('countrychange', () => {
      this.$emit('input', this.iti.getNumber());
    });
    if (!this.value) {
      return;
    }
    this.iti.setNumber(this.value);
  },
  destroyed() {
    this.$emit('input', this.iti.getNumber());
  },
};
</script>
<style lang="stylus">
.iti
  width: 100%
  .iti__selected-flag
    padding: 0 1em
</style>
