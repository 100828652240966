import Http from '@/services/Http';

const path = 'users';
const users = new Http(path);

users.list = (params) =>
  users.get('', '', `${params === undefined ? '' : `?${params}`}`);

users.deleteUser = (userId) => users.delete(userId, '');

users.create = (data) => users.post(data, path);

export default users;
