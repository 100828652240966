<template lang="pug">
div
  Waiting(:feedback='feedback' v-if='step === 1')
  Error404(v-else-if='step === 2')
  invitation-error-modal(ref='invitationErrorModal')
</template>

<script>
import Waiting from '@/modules/account/pages/Waiting.vue';
import Error404 from '@/modules/account/pages/Error404.vue';
import InvitedService from '@/modules/business/services/http/organizations/invitations/InvitedService';
import InvitationErrorModal from '@/modules/business/components/organisms/modals/InvitationErrorModal.vue';

export default {
  name: 'AcceptInvitation',
  components: {
    Waiting,
    Error404,
    InvitationErrorModal,
  },

  data() {
    return {
      feedback: {
        title: 'Aguarde um momento',
        message: 'Estamos validando seu convite...',
      },
      step: 1,
    };
  },

  created() {
    this.invited();
  },

  methods: {
    invited() {
      this.$loading(true);
      InvitedService.supressDefault = true;
      InvitedService.getInvited(this.$route.query.token)
        .then((response) => {
          if (response.status === 200) {
            this.step = 1;
            this.acceptInvited(this.$route.query.token);
          }
        })
        .catch(() => {
          this.$refs.invitationErrorModal.$refs.modal.open();
        })
        .finally(() => {
          InvitedService.supressDefault = false;
          this.$loading(false);
        });
    },

    async acceptInvited(token) {
      await InvitedService.acceptInvited(token)
        .then((response) => {
          if (response.status === 201) {
            this.$router.push('/business/dashboard');
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.step = 2;
            console.error(error);
          } else {
            this.step = 2;
            console.error(error);
          }
        });
    },
  },
};
</script>
