<template lang="pug">
modal.select-settings-modal(
  :disable-process-button='invalidForm'
  @closed='onCloseModal'
  @opened='onOpenModal'
  @process='process'
  close-button-label='Cancelar'
  process-button-label='Salvar'
  ref='modal'
  size='sm'
  title='Opções'
)
  .col-12(v-if='element')
    form.needs-validation(novalidate)
      .form-group
        label(for='field-select-name') Nome do campo
        .form-control-icon
          icon(name='rectangle-list')
          input#field-select-name.form-control(
            @blur='validate'
            @input='validate'
            autocomplete='off'
            maxlength='40'
            placeholder='Adicione uma descrição'
            required
            type='text'
            v-model='element.title'
          )
          .invalid-feedback Por favor, preencha o campo com um nome
      .form-group
        label(for='field-select-options') Opções
        .form-control-icon(:class='{ hideIcon: hasOptions }' ref='options')
          icon(name='rectangle-list')
          VueTagsInput.input.tags-input.form-control(
            :placeholder='placeholder'
            :tags='tags'
            @tags-changed='updateOptions'
            autocomplete='off'
            v-model='tag'
          )
          <div id="custom-feedback-validation" v-if='hasOptions'>
            <div class="custom-feedback length mt-1">
              .text Use a tecla "Enter" para separar as opções
            </div>
          </div>
      .form-group
        .form-check.form-switch
          .d-flex.align-items-center
            input#field-select-required.form-check-input(
              type='checkbox'
              v-model='element.required'
            )
            .ms-3
              label.m-0(for='field-select-required') Preenchimento obrigatório
</template>
<script>
/* eslint-disable max-len */
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  name: 'SelectSettingsModalOrganism',
  components: {
    Modal,
    Icon,
    VueTagsInput,
  },
  data() {
    return {
      element: {},
      invalidForm: true,
      tag: '',
      tags: [],
    };
  },
  computed: {
    hasOptions() {
      return this.tags.length > 0;
    },
    placeholder() {
      return this.hasOptions
        ? ''
        : 'Use a tecla "Enter" para separar as opções';
    },
  },
  methods: {
    onOpenModal() {
      this.validate();
      if (!this.element.title) {
        window
          .jQuery('#field-select-name')
          .removeClass('is-valid')
          .removeClass('is-invalid');
      }
      if (this.element.options.length === 0) {
        window
          .jQuery('#field-select-options')
          .removeClass('is-valid')
          .removeClass('is-invalid');
      }
    },
    onCloseModal() {
      this.tags = [];
    },
    process() {
      const arrayOfString = [];
      this.tags.forEach((options) => {
        arrayOfString.push(options.text);
      });
      this.element.options = arrayOfString.toString();
      this.$emit('settings', this.element);
      this.$refs.modal.close();
    },
    validate() {
      if (this.element.title && this.tags.length > 0) {
        this.invalidForm = false;
      } else {
        this.invalidForm = true;
      }
    },
    updateOptions(newTags) {
      this.tags = newTags;
      this.validate();
    },
  },
};
</script>
<style lang="stylus" scoped>
.hideIcon
  .form-control
    padding-left: 0
  .icon
    display: none
.tags-input
  height: auto
.checkbox-settings-modal
  .form-check.form-switch
    .form-check-label
      margin: .2rem 0 0 .5rem
.custom-feedback
  font-size: 0.875rem
  padding-left: calc(1.5em + 0.75rem)
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-circle-fill' viewBox='0 0 16 16'%3E%3Ccircle fill='%23ccc' cx='8' cy='8' r='8'/%3E%3C/svg%3E")
  background-repeat: no-repeat
  background-position: left calc(0.375em + 0.1875rem) center
  background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem)
  color: #949494
</style>
