<template lang="pug">
modal#collect-recipient-modal(
  :no-footer='true'
  ref='modal'
  size='sm'
  title='Atenção'
)
  p Este documento requer <strong>assinatura com Certificado Digital</strong>.
  p Qual Certificado Digital deseja utilizar?
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/safe-screen.svg')
  .text-end.mt-5
    button.btn.btn-secondary(
      @click='selectType("birdId")'
      style='border-width: 1px'
      type='button'
    )
      icon(name='bird-id' type='brands')
      span.text Bird Id
    button.btn.btn-primary.ms-4(
      @click='selectType("plugin")'
      style='border-width: 1px'
      type='button'
    )
      span.text Certificado A1 ou A3
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'QualifiedSignatureLoadPluginModal',
  components: {
    Icon,
    Modal,
  },
  data() {
    return {};
  },
  methods: {
    selectType(type) {
      this.$emit('update-type', type);
      this.$refs.modal.close();
    },
  },
};
</script>
