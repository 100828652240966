import EnvelopesPublicService from '@/modules/business/services/http/envelopes/EnvelopesPublicService';
import RecipientsService from '@/modules/business/services/http/envelopes/RecipientsService';
import FileSaver from 'file-saver';
import alert from '@/modules/account/common/alert';
import EnvelopeDownloads from './EnvelopeDownloads';
import axios from 'axios';

export default class EnvelopePublicDownloads extends EnvelopeDownloads {
  constructor(action_token) {
    super();
    this.action_token = action_token;
    this.envelopeData = null;
  }

  async _getEnvelopeData() {
    this.envelopeData = await RecipientsService.listEspecificRecipientEnvelope(
      this.action_token
    );
  }

  async downloadDocuments(subject) {
    //Fetch dos dados do envelope
    await this._getEnvelopeData();

    //Extração do nome do arquivo
    var [subjectWithoutExtension] = subject.split('.');

    try {
      if (this.envelopeData.data.envelope.documents.length > 1) {
        /**
         * Download do arquivo .zip no caso do
         * envelope ter mais de um documento
         */
        const response = await EnvelopesPublicService.download(
          this.action_token
        );
        FileSaver.saveAs(response.data, `${subjectWithoutExtension}.zip`);
        return;
      }

      /**
       * Download do arquivo .pdf no caso do
       * envelope ter apenas um documento
       */
      const response = await axios.get(
        this.envelopeData.data.envelope.documents[0].links.download,
        { responseType: 'blob' }
      );
      var file = new Blob([response.data], {
        type: 'application/pdf;charset=utf-8',
      });
      FileSaver.saveAs(file, `${subjectWithoutExtension}.pdf`);
    } catch (error) {
      if (error.response.status === 404) {
        alert.fireAlert('Envelope não encontrado.', {
          classes: 'alert-danger',
          styles:
            'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
          tag: 'httpAlert',
          icon: 'triangle-exclamation',
        });
      }
    }
  }

  async downloadSignaturePage(subject) {
    //Extração do nome do arquivo
    var [subjectWithoutExtension] = subject.split('.');

    //Download do arquivo .zip
    try {
      const response = await EnvelopesPublicService.downloadBackgroundSheet(
        this.action_token
      );
      FileSaver.saveAs(response.data, `${subjectWithoutExtension}.zip`);
    } catch (error) {
      if (error.response.status === 404) {
        alert.fireAlert('Envelope não encontrado.', {
          classes: 'alert-danger',
          styles:
            'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
          tag: 'httpAlert',
          icon: 'triangle-exclamation',
        });
      }
    }
  }
}
