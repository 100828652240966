<template lang="pug">
modal#onboarding-fields-modal(
  :cancelAction='backAction'
  :process-button-label='processButtonLabel'
  :show-cancel-button='showCancelButton'
  @process='process'
  closeButtonLabel='Voltar'
  ref='modal'
  size='sm'
  title='Configurar envelope'
)
  .col-12
    template(v-if='step === 1')
      p Selecione o documento que deseja configurar.
      .text-center
        img.img-fluid(
          src='/img/ilustrations/onboarding-envelope-fields-select-document.gif'
        )
    template(v-if='step === 2')
      p Selecione o assinante e configure o documento arrastando os campos até o local desejado.
      .text-center
        img.img-fluid(
          src='/img/ilustrations/onboarding-envelope-fields-add-fields.gif'
        )
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      step: 1,
      finalStep: 2,
    };
  },
  computed: {
    processButtonLabel() {
      return this.step === this.finalStep ? 'Ok' : 'Próximo';
    },
    showCancelButton() {
      return this.step > 1;
    },
  },
  methods: {
    process() {
      if (this.step === this.finalStep) {
        this.$refs.modal.close();
        this.$emit('finished');
        return;
      }
      this.step += 1;
    },
    backAction() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
  },
};
</script>
