import axios from 'axios';

import appConfig from '@/config';
import alert from '@/modules/account/common/alert';
import LocalStorage from '@/services/LocalStorage';

class Http {
  constructor(path = '', config, server = 'api') {
    this.path = path;
    this.supressDefault = false;
    this.treatment = null;
    const fromConfigFile = appConfig[server];

    fromConfigFile.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    this.axios = axios.create({ ...fromConfigFile, ...config });
    if (path !== 'organizations') {
      this.axios.interceptors.request.use((axiosConfig) => {
        const organization = LocalStorage.get('organization');
        if (organization && organization !== 'undefined') {
          // eslint-disable-next-line no-param-reassign
          axiosConfig.headers['X-Organization'] = organization;
        }
        return axiosConfig;
      });
    }

    this.axios.interceptors.response.use(null, (error) =>
      this.customInterceptor(error)
    );
  }

  customInterceptor(error) {
    if (this.treatment) {
      this.treatment(error);
    } else {
      Http.defaultErrorFlowHandler(error);
    }

    if (!this.supressDefault) {
      if (error.response?.status !== 402) {
        Http.defaultErrorMessageHandler(error);
      }
    }
    throw error;
  }

  static defaultErrorFlowHandler(error) {
    if (
      error.response?.status === 401 ||
      (error.response?.status === 403 &&
        error.response?.data.message === 'You must complete your registration.')
    ) {
      window.location.assign(
        `${appConfig.loginPath}?continue=${window.location}`
      );
      return;
    }
    if (
      error.response?.status === 403 &&
      error.response?.data.message === 'Your email address is not verified.'
    ) {
      window.location.assign(
        `${appConfig.verifyEmailPath}?continue=${window.location}`
      );
      return;
    }
  }

  static defaultErrorMessageHandler() {
    alert.fireAlert('Não foi possível completar a ação, tente novamente', {
      classes: 'alert-danger',
      styles:
        'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
      tag: 'httpAlert',
      icon: 'triangle-exclamation',
    });
  }

  setErrorHandler(treatment, supressDefault) {
    if (supressDefault !== undefined) {
      this.supressDefault = supressDefault;
    }
    this.treatment = treatment;
    return this;
  }

  get(id = '', params = '', path = '', config) {
    let queryParams = '';
    if (typeof params === 'object') {
      queryParams = `?${new URLSearchParams(params).toString()}`;
    }
    return this.axios.get(
      id
        ? `${this.path}${path}/${id}${queryParams}`
        : `${this.path}${path}${queryParams}`,
      config
    );
  }

  post(data, path, config) {
    if (!config) {
      // eslint-disable-next-line no-param-reassign
      config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      };
    }
    return this.axios.post(path || this.path, data, config);
  }

  put(id, data, path, config) {
    return this.axios.put(
      id ? `${path || this.path}/${id}` : `${this.path}${path}`,
      data,
      config
    );
  }

  delete(id, params, path, config) {
    let queryParams = '';
    if (typeof params === 'object') {
      queryParams = `?${new URLSearchParams(params).toString()}`;
    }
    return this.axios.delete(
      id ? `${path || this.path}/${id}${queryParams}` : `${this.path}${path}`,
      config
    );
  }

  patch(id, data, path, config) {
    if (id) {
      return this.axios.patch(`${path || this.path}/${id}`, data, config);
    }
    return this.axios.patch(`${path || this.path}`, data, config);
  }

  async getFileObjectFromBase64(base64) {
    const response = await fetch(base64);
    const blob = await response.blob();
    return new File([blob], 'image', { type: blob.type });
  }
}

export default Http;
