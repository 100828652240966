import PublicLayout from '@/ui/layouts/Public.vue';
import PublicStoragePage from '@/modules/public/pages/PublicStorage.vue';
import CheckerPage from '@/modules/business/pages/checker/Index.vue';
import WaitingPage from '@/modules/account/pages/Waiting.vue';
import AcceptInvitation from '@/modules/business/pages/invitation/AcceptInvitation.vue';
import EnvelopeViewerPage from '@/modules/business/pages/envelopes/Viewer.vue';
import EnvelopeDownloadPage from '@/modules/business/pages/envelopes/Download.vue';
import Error404 from '@/modules/account/pages/Error404.vue';

export default {
  name: 'public',
  routes: [
    {
      path: '/public',
      component: PublicLayout,
      redirect: {
        name: 'public.checker',
      },
      children: [
        {
          path: '/public/storage/:itemId',
          name: 'public.storage.item',
          component: PublicStoragePage,
        },
        {
          path: '/public/checker',
          name: 'public.checker',
          component: CheckerPage,
        },
        {
          path: '/public/envelopes/viewer/:token',
          name: 'public.envelopes.viewer',
          component: EnvelopeViewerPage,
          props: {
            noTitleBackButton: true,
          },
        },
        {
          path: '/public/envelopes/download/:token',
          name: 'public.envelopes.download',
          component: EnvelopeDownloadPage,
          props: {
            noTitleBackButton: true,
          },
        },
      ],
    },
    {
      path: '/',
      name: 'waiting',
      component: WaitingPage,
    },
    {
      path: '/business/invitation',
      name: 'business.accept.invitation',
      component: AcceptInvitation,
    },
    {
      path: '*',
      name: 'notfound',
      component: Error404,
    },
  ],
};
