<template lang="pug">
.text-end
  button.btn.btn-thin.me-3(@click='goToLogin')
    | Entrar
  button.btn.btn-primary(@click='goToCreateAccount')
    | Criar Conta
</template>

<script>
import appConfig from '@/config';

export default {
  name: 'ButtonsLoginAndCreateAccount',
  methods: {
    goToCreateAccount() {
      window.location.assign(appConfig.registerPath);
    },
    goToLogin() {
      window.location.assign(appConfig.loginPath);
    },
  },
};
</script>
