<template lang="pug">
modal.qualified-signature-list-certificates-plugin-modal(
  :disable-process-button='!certificate'
  @process='onProcess'
  close-button-label='Cancelar'
  process-button-label='Autenticar'
  ref='modal'
  size='lg'
  title='Certificado Digital'
)
  p(v-if='userEmail') Selecione um certificado para se autenticar no login
    | <strong class='ms-1'>{{ userEmail }}</strong>.
  p(v-else) Selecione um certificado para se autenticar

  template(v-if='certificates.length > 0')
    .card-list
      .card(v-for='(item, index) in certificates')
        label.card-body(:for='`radio - ${item.Id}`')
          .d-flex
            .form-check
              input.form-check-input(
                :id='`radio - ${item.Id}`'
                :value='item'
                type='radio'
                v-model='certificate'
              )
            .ms-2.w-100
              div Certificado: {{ item.CommonName }}
              .d-flex
                .me-1 E-mail: {{ item.Email }}
                .ms-auto Validade: {{ item.NotAfter | dateTime }}
  template(v-else)
    h5 Nenhum certificado disponível.
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import CardTable from '@/ui/organisms/CardTable.vue';

export default {
  name: 'QualifiedSignatureListCertificatesPluginModal',
  components: {
    Modal,
    CardTable,
  },
  props: {
    certificates: {
      type: Array,
      required: true,
    },
    userEmail: {
      type: String,
    },
  },
  data() {
    return {
      certificate: '',
    };
  },
  methods: {
    onProcess() {
      this.$emit('certificate-selected', this.certificate);
    },
  },
};
</script>
<style lang="stylus">
.qualified-signature-list-certificates-plugin-modal
  .card-list
    .card
      border-radius: 0
      margin-top: -1px
      &:first-child
        border-top-left-radius: .188rem
        border-top-right-radius: .188rem
        margin-top: 0
      &:last-child
        border-bottom-left-radius: .188rem
        border-bottom-right-radius: .188rem
</style>
