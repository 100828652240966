<template lang="pug">
modal#new-role-modal(
  :loading='loading'
  :title='"Nova Função"'
  @closed='onCloseModal'
  @opened='onOpenModal'
  @process='submit'
  close-button-label='Cancelar'
  process-button-label='Salvar'
  ref='modal'
)
  ValidationObserver(ref='form' v-slot='{ handleSubmit }')
    form(@submit.prevent='handleSubmit(submit)')
      ValidationProvider(
        name='nome da função'
        rules='required'
        v-slot='{ errors }'
      )
        .form-group
          label Nome da função *
          .form-control-icon
            icon(name='user-tie')
            input.form-control(
              :class='{ "border border-danger": errors[0] }'
              placeholder='Digite o nome da função'
              type='text'
              v-model.trim='roles.name'
            )
          small.text.text-danger {{ errors[0] }}

  .form-group.mb-0
    label Atribuições *
    .row
      .col-12.col-sm-6
        .form-check
          input#check-1.form-check-input(
            type='checkbox'
            v-model='roles.edit_organization'
            value='1'
          )
          label.form-check-label(for='check-1') Editar Organizações
      .col-12.col-sm-6
        .form-check
          input#check-2.form-check-input(
            type='checkbox'
            v-model='roles.manage_users'
            value='2'
          )
          label.form-check-label(for='check-2') Gerenciar Usuários
      .col-12.col-sm-6
        .form-check
          input#check-3.form-check-input(
            type='checkbox'
            v-model='roles.manage_teams'
            value='3'
          )
          label.form-check-label(for='check-3') Gerenciar Times
      .col-12.col-sm-6
        .form-check
          input#check-4.form-check-input(
            type='checkbox'
            v-model='roles.send_envelopes'
            value='4'
          )
          label.form-check-label(for='check-4') Pode enviar envelopes
      .col-12.col-sm-6
        .form-check
          input#check-5.form-check-input(
            type='checkbox'
            v-model='roles.manage_billing'
            value='5'
          )
          label.form-check-label(for='check-5') Gerenciar faturamento
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import Icon from '@/ui/atoms/Icon.vue';
import RolesService from '@/modules/business/services/http/organizations/roles/RolesService';
import formHandler from '@/modules/account/common/formHandler';

export default {
  name: 'OrganizationModal',

  components: {
    Modal,
    Icon,
    ImageLoader,
    RolesService,
  },
  data() {
    return {
      loading: false,
      roles: {
        name: '',
        send_envelopes: false,
        attach_organizations: false,
        edit_organization: false,
        manage_teams: false,
        manage_users: false,
        manage_billing: false,
      },
    };
  },

  methods: {
    onCloseModal() {
      this.cleanRoles();
      this.loading = true;
    },

    onOpenModal() {
      this.loading = false;
    },

    cleanRoles() {
      this.roles = {
        name: null,
        send_envelopes: false,
        attach_organizations: false,
        edit_organization: false,
        manage_teams: false,
        manage_users: false,
        manage_billing: false,
      };
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    async submit() {
      formHandler.submit(this, () => RolesService.create(this.roles), {
        fieldsByPropertyName: {
          name: 'nome da função',
        },
        successHandler: (response) => {
          formHandler.defaultSuccess(response);
          this.cleanRoles();
          this.$emit('refresh');
          this.$refs.modal.close();
        },
      });
    },
  },
};
</script>
