<template lang="pug">
modal#change-password-modal(
  :disableProcessButton='canSubmit'
  :loading='loading'
  @closed='onCloseModal'
  @process='submit'
  close-button-label='Cancelar'
  process-button-label='Salvar'
  ref='modal'
  title='Alterar senha'
)
  .col-12.col-sm-12.mt-4
    .form-group
      label(for='currentPassword') Senha atual
      .form-control-icon
        icon(name='key')
        input#currentPassword.form-control(
          :type='showCurrentPassword ? "text" : "password"'
          aria-describedby='passwordHelp'
          autocomplete='off'
          placeholder='Digite sua senha'
          v-model='currentPassword'
        )
        button.show-password(
          @click='showCurrentPassword = !showCurrentPassword'
          data-password-input-id='currentPassword'
          type='button'
        )
          icon(
            :fix-width='true'
            :name='showCurrentPassword ? "eye-slash" : "eye"'
          )
      small.text.text-danger.d-flex.mt-1(v-if='errors.length > 0')
        icon(name='xmark')
        span.text.ms-2 {{ errors[0] }}
  .col-12.col-sm-12.mt-4
    .form-group
      label(for='password') Nova senha
      .form-control-icon
        icon(name='key')
        input#password.form-control(
          :type='showNewPassword ? "text" : "password"'
          @input='passwordCheck'
          aria-describedby='passwordHelp2'
          autocomplete='off'
          placeholder='Digite sua senha'
          v-model='password'
        )
        button.show-password(
          @click='showNewPassword = !showNewPassword'
          data-password-input-id='password'
          type='button'
        )
          icon(:fix-width='true' :name='showNewPassword ? "eye-slash" : "eye"')
  .passwordValidation
    div(:class='{ "validation--passed": password.length > minPasswordLength }')
      icon(:name='password.length > minPasswordLength ? "check" : "circle"')
      span.text Mínimo de 6 caracteres
    div(:class='{ "validation--passed": has_lowercase }')
      icon(:name='has_lowercase ? "check" : "circle"')
      span.text Pelo menos 1 caractere minúsculo
    div(:class='{ "validation--passed": has_uppercase }')
      icon(:name='has_uppercase ? "check" : "circle"')
      span.text Pelo menos 1 caractere maiúsculo
    div(:class='{ "validation--passed": has_number }')
      icon(:name='has_number ? "check" : "circle"')
      span.text Pelo menos 1 número
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import Icon from '@/ui/atoms/Icon.vue';
import AccountService from '@/services/AccountHttp';
import '@/services/Validator';
import alert from '@/modules/account/common/alert';

export default {
  name: 'ChangePasswordModal',
  components: {
    Modal,
    Icon,
  },
  data() {
    return {
      currentPassword: '',
      password: '',
      loading: false,
      has_lowercase: false,
      has_uppercase: false,
      has_number: false,
      showCurrentPassword: false,
      showNewPassword: false,
      minPasswordLength: 7,
      errors: [],
    };
  },
  computed: {
    canSubmit() {
      return (
        !this.has_number ||
        !this.has_lowercase ||
        !this.has_uppercase ||
        this.password.length <= this.minPasswordLength ||
        !this.currentPassword
      );
    },
  },
  methods: {
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text';
        return;
      }
      this.type = 'password';
    },
    passwordCheck() {
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
    },
    onCloseModal() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$refs.modal.close();
    },
    async submit() {
      this.$loading(true);
      await AccountService.updatePassword(this.currentPassword, this.password)
        .then(() => {
          alert.fireAlert('Senha alterada com sucesso', {
            classes: 'alert-success',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
            icon: 'circle-check',
          });
          this.onCloseModal();
        })
        .catch((error) => {
          var alertMessage = 'Erro ao tentar processar atualização';
          if (error.response.data.errors.current_password) {
            alertMessage = 'A senha atual não está correta';
            document
              .querySelector('#currentPassword')
              .classList.add('border-danger');
            this.errors.push('Senha incorreta');
          }
          alert.fireAlert(alertMessage, {
            classes: 'alert-danger',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
            icon: 'triangle-exclamation',
          });
        })
        .finally(() => {
          this.$loading(false);
        });
    },
  },
};
</script>
<style lang="stylus">
.passwordValidation
  font-size: 0.875rem
  color: #767676
  .icon
    font-size: 0.5rem
    margin-right: 0.563rem
  .validation--passed
    color: #3ECB90
    .icon
      font-size: 1.125rem !important
.show-password
  color: #013D52
</style>
