import config from '@/config';
import Http from './Http';

const oauth = new Http('/oauth', config.assine);

oauth.getClients = () => oauth.get('clients');

oauth.createClient = (data) => oauth.post(data, '/oauth/clients');

oauth.refreshClientSecret = (clientId) =>
  oauth.post([], `/oauth/clients/${clientId}/refresh`);

oauth.deploy = (clientId) =>
  oauth.post([], `/oauth/clients/${clientId}/deploy`);

oauth.deleteClient = (clientId) => oauth.delete('', '', `/clients/${clientId}`);

export default oauth;
