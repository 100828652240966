<template lang="pug">
.contacts-card-table
  card-table(:total-items='data.length' breakpoint-size='lg')
    template(v-for='(item, index) in data' v-slot:[`card-${index}`]='{}')
      .mb-1 Nome: {{ item.name }}
      .mb-1 Email: {{ item.email }}
      .mb-1(v-if='item.phone') Telefone: {{ item.phone | phone }}
      .mb-0
        button.btn-edit-contact(
          @click.stop='openEditContactModal(item)'
          title='Utilizar'
        )
          icon(name='pen-to-square')
        button.btn-delete-contact(
          @click.stop='openDeleteContactModal(item)'
          title='Excluir'
        )
          icon.icon--canceled(name='trash-can')
    template(v-slot:table-head)
      th(style='width: 20%') Nome
      th(style='width: 40%') Email
      th(style='width: 34%') Telefone
      th(style='width: 6%')
    template(v-for='(item, index) in data' v-slot:[`table-row-${index}`]='{}')
      td {{ item.name }}
      td {{ item.email }}
      td {{ item.phone | phone }}
      td
        .text-end.d-flex
          button(@click.stop='openEditContactModal(item)' title='Utilizar')
            icon(name='pen-to-square')
          button(@click.stop='openDeleteContactModal(item)' title='Excluir')
            icon.icon--canceled(name='trash-can')
  edit-contact-modal(
    :contact='this.selectedContact'
    :title='this.selectedContact.name'
    @refresh='$emit("item-edited")'
    ref='editContactModal'
  )
  delete-contact-modal(
    :contact='this.selectedContact'
    @refresh='$emit("item-deleted")'
    ref='deleteContactModal'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import CardTable from '@/ui/organisms/CardTable.vue';
import EditContactModal from '@/modules/account/components/organisms/modals/EditContactModal.vue';
import DeleteContactModal from '@/modules/account/components/organisms/modals/DeleteContactModal.vue';

export default {
  name: 'UsersGuestCardTable',
  components: {
    Icon,
    CardTable,
    EditContactModal,
    DeleteContactModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedContact: {},
    };
  },
  methods: {
    openEditContactModal(contact) {
      this.selectedContact = contact;
      this.$nextTick(this.$refs.editContactModal.$refs.modal.open);
    },
    openDeleteContactModal(contact) {
      this.selectedContact = contact;
      this.$nextTick(this.$refs.deleteContactModal.$refs.modal.open);
    },
  },
};
</script>

<style lang="stylus">
.contacts-card-table
  .btn-edit-contact
    position: absolute
    top: 0.5rem
    right: 0.45rem
  .btn-delete-contact
    position: absolute
    bottom: 0.8rem
    right: 0.5rem
</style>
