<template lang="pug">
.users-guest-card-table
  card-table(:total-items='data.length' breakpoint-size='lg')
    template(v-for='(item, index) in data' v-slot:[`card-${index}`]='{}')
      .mb-1 Nome: {{ item.name }}
      .mb-1 Email: {{ item.email }}
      .mb-1 Usuário desde: {{ item.joined_at | dateTime }}
      .mb-0
        span.owner__organization(v-if='item.is_owner === true') Dono da organização
        button.btn--delete-user(
          @click.stop='openDeleteUserModal(item.id)'
          data-bs-target='#delete-user-modal'
          v-else
          v-tooltip:bottom='`Excluir usuário`'
        )
          icon.icon--canceled(name='trash-can')
    template(v-slot:table-head)
      th(style='width: 30%') Nome
      th(style='width: 30%') E-mail
      th(style='width: 20%') Usuario desde
      th(style='width: 20%')
    template(v-for='(item, index) in data' v-slot:[`table-row-${index}`]='{}')
      td {{ item.name }}
      td {{ item.email }}
      td {{ item.joined_at | dateTime }}
      td
        .text-end
          span.owner__organization(v-if='item.is_owner === true') Dono da organização
          button(
            @click.stop='openDeleteUserModal(item.id)'
            v-else
            v-tooltip:bottom='`Excluir usuário`'
          )
            icon.icon--canceled(name='trash-can')
  delete-user-modal(
    :user-id='this.selectedUserId'
    @refresh='$emit("item-deleted", selectedUserId)'
    ref='deleteUserModal'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import DeleteUserModal from '@/modules/account/components/organisms/modals/DeleteUserModal.vue';
import CardTable from '@/ui/organisms/CardTable.vue';

export default {
  name: 'UsersGuestCardTable',
  components: {
    Icon,
    DeleteUserModal,
    CardTable,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedUserId: '',
    };
  },
  methods: {
    openDeleteUserModal(id) {
      this.selectUserToSendModal(id);
      this.$refs.deleteUserModal.$refs.modal.open();
    },
    selectUserToSendModal(user) {
      this.selectedUserId = user;
    },
  },
};
</script>

<style lang="stylus">
.users-guest-card-table
  .btn-reinvite-user
    i
      color: #013D52;
  .btn--delete-user
    position: absolute
    top: 0.5rem
    right: 0.5rem
  .owner__organization
    font-weight: 600
    color: #013D52
</style>
