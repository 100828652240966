<template lang="pug">
modal#show-envelope-done-modal(
  :no-footer='isPublic'
  :showCancelButton='false'
  @process='showEnvelopeDetails'
  process-button-label='Ok'
  ref='modal'
  size='sm'
  title='Assinatura enviada'
)
  .inner-content
    p.justify-content Quando todas as partes assinarem, você receberá um email
      | &nbsp; com acesso ao download do documento assinado.
    .text-center.my-4
      img.img-fluid(src='/img/ilustrations/envelope-done.svg')
    div(v-if='isPublic')
      buttons-login-and-create-account.mt-5
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ButtonsLoginAndCreateAccount from '@/modules/business/components/molecules/ButtonsLoginAndCreateAccount.vue';

export default {
  name: 'showEnvelopeDoneModal',
  components: {
    Modal,
    ButtonsLoginAndCreateAccount,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
    envelopeId: {
      type: String,
      default: '',
    },
    routeName: {
      type: String,
      default: 'business.documents.inbox',
    },
  },
  computed: {
    closeButtonLabel() {
      return this.isPublic ? '' : 'Ok';
    },
  },
  methods: {
    showEnvelopeDetails() {
      this.$refs.modal.close();
      this.$router.push({ name: this.routeName });
    },
  },
};
</script>
