<template lang="pug">
main
  .page(:class='pageClass' style='padding-top: 60px')
    header-component(:user='$root.user' application='public')
    .container-fluid
      .row
        .col
          .content.mt-3(style='max-width: 1360px; margin: auto')
            top-page-bar
            router-view
      .row
        footer-component.col-12
</template>
<script>
import HeaderComponent from '@/ui/organisms/Header.vue';
import FooterComponent from '@/ui/organisms/Footer.vue';
import Icon from '@/ui/atoms/Icon.vue';
import topPageBar from '@/ui/organisms/TopPageBar.vue';

export default {
  name: 'PublicLayout',
  components: {
    HeaderComponent,
    FooterComponent,
    Icon,
    topPageBar,
  },
  computed: {
    pageClass() {
      return this.$store.state.page.class;
    },
    showSideBar() {
      return this.$store.state.page.sideBar;
    },
    nav() {
      return [
        {
          label: 'Verificador',
          link: '/public/checker',
        },
      ];
    },
  },
};
</script>
