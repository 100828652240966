<template lang="pug">
.sent-envelopes-card-table
  card-table(:total-items='data.length' breakpoint-size='lg')
    template(v-for='(item, index) in data' v-slot:[`card-${index}`]='{}')
      .position-relative
        .mb-1.text-overflow-ellipse(style='width: 90%') {{ getEnvelopeName(item) }}
        .mb-1 ID: {{ item.hashid }}
        .mb-1 Data de envio: {{ item.created_at | dateTime }}
        .mb-0
          span.text.me-2 Status do envelope:
          span.text.me-2 {{ $t(item.state) }}
          .d-none.d-sm-inline-block
            envelope-signatures-progress(
              :recipients-stats='item.recipients_stats'
              v-if='item.state === EnvelopeState.IN_TRANSIT'
            )
        button(
          :aria-controls='`collapse-card-${index}`'
          :data-bs-target='`#collapse-card-${index}`'
          @click='fetchRecipientsState(item.id)'
          aria-expanded='false'
          data-bs-toggle='collapse'
          style='position: absolute; bottom: 0; right: -0.5rem'
          type='button'
        )
          icon.icon--blue(name='user')
        .dropdown.text-end.mt-2(
          style='position: absolute; top: -1rem; right: -1rem; font-size: 1.5em'
        )
          button#envelopeActions.dropdown-toggle.px-0(
            data-bs-toggle='dropdown'
          )
            icon(name='ellipsis-vertical')
          .dropdown-menu(aria-labelledby='envelopeActions')
            button.dropdown-item(@click.stop='downloadEnvelope(item)') Baixar documentos
            button.dropdown-item(
              @click.stop='downloadSignaturePage(item)'
              v-if='item.state === EnvelopeState.COMPLETED'
            ) Baixar página de assinaturas
            router-link.dropdown-item(:to='getRoute(item)') Detalhes
      .collapse.collapse-card(
        :id='`collapse-card-${index}`'
        data-bs-parent='.sent-envelopes-card-table'
      )
        .collapse-card-block
          recipients-loader(v-if='isLocalLoading')
          ul.m-0(
            :class='{ "list-group-numbered": isRecipientsHaveOrder }'
            v-else
          )
            li.list-recipients-data(
              :key='recipient.id'
              v-for='(recipient, index) in envelopeRecipients.data'
            )
              span.text
                strong {{ recipient.addressees[0].name }}:
              IconStatus.ms-2(:iconStatus='recipient.state')
              span.text.ms-1 {{ $t(recipient.state) }}
    template(v-slot:table-head)
      th(style='width: 5%') ID
      th(style='width: 33%') Nome
      th(style='width: 17%')
        .d-flex
          span.text Data de envio
          button(
            @click='addSort("created_at", "DESC")'
            style='margin-top: -2px'
            v-show='sort.created_at === "ASC"'
          )
            icon.icon--blue(name='angle-down')
          button(
            @click='addSort("created_at", "ASC")'
            style='margin-top: -2px'
            v-show='sort.created_at !== "ASC"'
          )
            icon.icon--blue(name='angle-up')
      th(style='width: 24%')
        span.text Status do envelope
        icon.ms-2(
          name='circle-question'
          style='cursor: help'
          v-tooltip:top='`O status do envelope depende das ações dos destinatários envolvidos`'
        )
      th(style='width: 1%')
    template(v-for='(item, index) in data' v-slot:[`table-row-${index}`]='{}')
      .d-table-row
        td {{ item.hashid }}
        td
          .sent-envelopes-card-table__name.text-overflow-ellipse(
            :title='getEnvelopeName(item)'
          ) {{ getEnvelopeName(item) }}
        td {{ item.created_at | dateTime }}
        td
          span.text.me-2 {{ $t(item.state) }}
          .d-inline-block
            envelope-signatures-progress(
              :recipients-stats='item.recipients_stats'
              v-if='item.state === EnvelopeState.IN_TRANSIT'
            )
        td
          .d-flex
            button(
              :aria-controls='`collapse-row-${index}`'
              :data-bs-target='`#collapse-row-${index}`'
              @click='fetchRecipientsState(item.id)'
              aria-expanded='false'
              data-bs-toggle='collapse'
              type='button'
            )
              icon.icon--blue(name='user')
            .dropdown.text-end.mt-0
              button#envelopeActions.dropdown-toggle.px-0(
                data-bs-toggle='dropdown'
                style='width: 1em'
              )
                icon(name='ellipsis-vertical')
              .dropdown-menu(aria-labelledby='envelopeActions')
                button.dropdown-item(@click.stop='downloadEnvelope(item)') Baixar documentos
                button.dropdown-item(
                  @click.stop='downloadSignaturePage(item)'
                  v-if='item.state === EnvelopeState.COMPLETED'
                ) Baixar página de assinaturas
                router-link.dropdown-item(:to='getRoute(item)') Detalhes
      .d-table-row
        td.collapse-row.p-0(colspan='6')
          .collapse.collapse-row-block(
            :id='`collapse-row-${index}`'
            data-bs-parent='.sent-envelopes-card-table'
          )
            .p-3
              recipients-loader(v-if='isLocalLoading')
              ul.m-0.px-3(
                :class='{ "list-group-numbered": isRecipientsHaveOrder }'
                v-else
              )
                li(
                  :key='recipient.id'
                  v-for='(recipient, index) in envelopeRecipients.data'
                )
                  span.text
                    strong {{ recipient.addressees[0].name }}:
                  IconStatus.ms-2(:iconStatus='recipient.state')
                  span.text.ms-1 {{ $t(recipient.state) }}
</template>
<script>
import EnvelopeState from '@/enums/EnvelopeState';
import RecipientState from '@/enums/RecipientState';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import EnvelopeDownloadsFactory from '@/modules/business/common/EnvelopeDownloads/EnvelopeDownloadsFactory';
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import CardTable from '@/ui/organisms/CardTable.vue';
import EnvelopeSignaturesProgress from '@/ui/molecules/EnvelopeSignaturesProgress.vue';
import RecipientsLoader from '@/ui/molecules/RecipientsLoader.vue';

export default {
  name: 'SentEnvelopesCardTable',
  components: {
    Icon,
    IconStatus,
    CardTable,
    EnvelopeSignaturesProgress,
    RecipientsLoader,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      EnvelopeState,
      RecipientState,
      isLocalLoading: false,
      envelopeRecipients: {
        data: [],
        envelopeId: '',
      },
      sort: {
        created_at: '',
      },
    };
  },
  computed: {
    isRecipientsHaveOrder() {
      if (this.envelopeRecipients.data.length === 0) return null;
      return this.envelopeRecipients.data[0].routing_order;
    },
  },
  methods: {
    async downloadEnvelope(envelopeRecipient) {
      this.$loading(true);
      try {
        const envelopeDownloads =
          EnvelopeDownloadsFactory.createEnvelopeDownloads(
            envelopeRecipient.id,
            'private'
          );

        await envelopeDownloads.downloadDocuments(envelopeRecipient.title);
      } finally {
        this.$loading(false);
      }
    },
    async downloadSignaturePage(envelopeRecipient) {
      this.$loading(true);
      try {
        const envelopeDownloads =
          EnvelopeDownloadsFactory.createEnvelopeDownloads(
            envelopeRecipient.id,
            'private'
          );

        await envelopeDownloads.downloadSignaturePage(envelopeRecipient.title);
      } finally {
        this.$loading(false);
      }
    },
    addSort(column, type) {
      this.sort[column] = type;
      this.$emit('sort', {
        column: `${column}`,
        desc: type === 'DESC',
      });
    },

    checkNextOrderByColumnIsDesc(column) {
      if (column === this.sort.column) {
        return !this.sort.desc;
      }
      return false;
    },
    getRoute(item) {
      return {
        name: 'business.documents.info',
        params: {
          id: item.id,
        },
      };
    },
    getEnvelopeName(item) {
      return item.title && item.title !== 'old' ? item.title : item.subject;
    },
    fetchRecipientsState(id) {
      const isOpen = this.envelopeRecipients.envelopeId === id;
      if (isOpen) {
        this.envelopeRecipients = { envelopeId: null, data: [] };
        return;
      }
      this.isLocalLoading = true;
      this.envelopeRecipients.envelopeId = id;
      EnvelopesService.getRecipientsState(id)
        .then((res) => {
          this.envelopeRecipients.data = res.data;
          this.envelopeRecipients.data.sort(function (a, b) {
            return a.routing_order - b.routing_order;
          });
        })
        .finally(() => {
          this.isLocalLoading = false;
        });
    },
  },
};
</script>
<style lang="stylus">
.sent-envelopes-card-table__name
  max-width: 180px;
@media (min-width: 576px)
  .sent-envelopes-card-table__name
    max-width: 180px
@media (min-width: 768px)
  .sent-envelopes-card-table__name
    max-width: 180px
@media (min-width: 992px)
  .sent-envelopes-card-table__name
    max-width: 290px
@media (min-width: 1200px)
  .sent-envelopes-card-table__name
    max-width: 270px
@media (min-width: 1400px)
  .sent-envelopes-card-table__name
    max-width: 460px
.sent-envelopes-card-table
  td.collapse-row
    border-top: none !important
  .collapse-card-block
    margin: 1rem -1rem 0
    padding: 0 1rem
  .collapse-card
    &.show, &.collapsing
      border-top: 1px solid #ccc
      margin: 0 -1rem
      margin-top: 1rem
  .collapse-row-block
    border-top: 1px solid #dee2e6
</style>
