import Http from '@/services/Http';
import config from '@/config';

const path = 'contacts';
const contacts = new Http(path, config.api);

contacts.create = (data) => contacts.post(data);

contacts.list = (pathParams) =>
  contacts.get('', '', pathParams === undefined ? '' : `?${pathParams}`);

contacts.listEspecificContact = (contactId) => contacts.get(contactId);

contacts.search = (q) => contacts.get('', { q }, '');

contacts.update = (contactId, data) => contacts.put(contactId, data);

contacts.remove = (contactId) => contacts.delete(contactId);

export default contacts;
