<template lang="pug">
.row
  files-list(
    :folder-id='$route.params.itemId'
    :publicItems='true'
    v-on:folder-click='onFolderClick'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import FilesList from '@/ui/organisms/FilesList.vue';

export default {
  name: 'StoragePage',
  components: {
    FilesList,
    Icon,
  },
  created() {
    this.$store.dispatch('page/setTitle', 'Armazenamento');
    this.$store.dispatch('page/setClass', 'storage');
    this.$store.dispatch('page/setHead', { title: 'Armazenamento' });
  },
  methods: {
    onCreateFolder(folder) {
      console.log(folder);
    },
    onFolderClick(item) {
      this.$router.push(`/public/storage/${item.publish_token}`);
    },
  },
};
</script>
