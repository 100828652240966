<template lang="pug">
.received-envelopes-card-table
  card-table(:total-items='data.length' breakpoint-size='lg')
    template(v-for='(item, index) in data' v-slot:[`card-${index}`]='{}')
      router-link(:to='getRoute(item)')
        .mb-1.text-overflow-ellipse(style='width: 90%') {{ getEnvelopeName(item) }}
        .mb-1 ID: {{ item.envelope.hashid }}
        .mb-1 Data de recebimento: {{ item.notified_at | dateTime }}
        .mb-1
          span.text.me-2 Seu status:
          IconStatus(:iconStatus='item.state')
          span.text.ms-2 {{ $t(item.state) }}
        .mb-0
          span.text.me-2 Status do envelope:
          span.text.me-2 {{ $t(item.envelope.state) }}
          .d-none.d-sm-inline-block
            envelope-signatures-progress(
              :recipients-stats='item.envelope.recipients_stats'
              v-if='item.envelope.state === EnvelopeState.IN_TRANSIT'
            )
        .dropdown.text-end.mt-2(
          style='position: absolute; top: 0; right: 0; font-size: 1.5em'
        )
          button#envelopeActions.px-0.dropdown-toggle(
            data-bs-toggle='dropdown'
          )
            icon(name='ellipsis-vertical')
          .dropdown-menu(aria-labelledby='envelopeActions')
            button.dropdown-item(
              @click.stop='$event.preventDefault(); downloadEnvelope(item)'
            )
              | Baixar documentos
            button.dropdown-item(
              @click.stop='$event.preventDefault(); downloadSignaturePage(item)'
              v-if='item.envelope.state === EnvelopeState.COMPLETED'
            )
              | Baixar página de assinaturas
    template(v-slot:table-head)
      th(style='width: 5%') ID
      th(style='width: 25%') Nome
      th(style='width: 25%')
        .d-flex
          span.text Data de recebimento
          button(
            @click='addSort("created_at", "DESC")'
            style='margin-top: -2px'
            v-show='sort.created_at === "ASC"'
          )
            icon.icon--blue(name='angle-down')
          button(
            @click='addSort("created_at", "ASC")'
            style='margin-top: -2px'
            v-show='sort.created_at !== "ASC"'
          )
            icon.icon--blue(name='angle-up')
      th(style='width: 20%')
        span.text Seu status
        icon.ms-2(
          name='circle-question'
          style='cursor: help'
          v-tooltip:top='`Ações que você realizou dentro do envelope`'
        )
      th(style='width: 24%')
        span.text Status do envelope
        icon.ms-2(
          name='circle-question'
          style='cursor: help'
          v-tooltip:top='`O status do envelope depende das ações dos destinatários envolvidos`'
        )
      th(style='width: 1%')
    template(v-for='(item, index) in data' v-slot:[`table-row-${index}`]='{}')
      td {{ item.envelope.hashid }}
      td
        .received-envelopes-card-table__name.text-overflow-ellipse(
          :title='getEnvelopeName(item)'
        ) {{ getEnvelopeName(item) }}
      td {{ item.notified_at | dateTime }}
      td
        IconStatus(:iconStatus='item.state')
        span.text.ms-2 {{ $t(item.state) }}
      td
        span.text.me-2 {{ $t(item.envelope.state) }}
        .d-inline-block
          envelope-signatures-progress(
            :recipients-stats='item.envelope.recipients_stats'
            v-if='item.envelope.state === EnvelopeState.IN_TRANSIT'
          )
      td
        .dropdown.text-end.mt-0
          button#envelopeActions.dropdown-toggle.px-0(
            data-bs-toggle='dropdown'
            style='width: 1em'
          )
            icon(name='ellipsis-vertical')
          .dropdown-menu(aria-labelledby='envelopeActions')
            button.dropdown-item(@click.stop='downloadEnvelope(item)')
              | Baixar documentos
            button.dropdown-item(
              @click.stop='downloadSignaturePage(item)'
              v-if='item.envelope.state === EnvelopeState.COMPLETED'
            )
              | Baixar página de assinaturas
            router-link.dropdown-item(:to='getRoute(item)')
              span(v-if='item.state === "done"') Detalhes
              span(v-else) Assinar
</template>
<script>
import EnvelopeState from '@/enums/EnvelopeState';
import RecipientState from '@/enums/RecipientState';
import EnvelopeDownloadsFactory from '@/modules/business/common/EnvelopeDownloads/EnvelopeDownloadsFactory';
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import CardTable from '@/ui/organisms/CardTable.vue';
import EnvelopeSignaturesProgress from '@/ui/molecules/EnvelopeSignaturesProgress.vue';

export default {
  name: 'ReceivedEnvelopesCardTable',
  components: {
    Icon,
    IconStatus,
    CardTable,
    EnvelopeSignaturesProgress,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      EnvelopeState,
      RecipientState,
      sort: {
        created_at: '',
      },
    };
  },
  methods: {
    async downloadEnvelope(envelopeRecipient) {
      this.$loading(true);
      try {
        const envelopeDownloads =
          EnvelopeDownloadsFactory.createEnvelopeDownloads(
            envelopeRecipient.action_token
              ? envelopeRecipient.action_token
              : envelopeRecipient.envelope.id,
            envelopeRecipient.action_token ? 'public' : 'private'
          );

        await envelopeDownloads.downloadDocuments(
          envelopeRecipient.envelope.title
        );
      } finally {
        this.$loading(false);
      }
    },
    async downloadSignaturePage(envelopeRecipient) {
      this.$loading(true);
      try {
        const envelopeDownloads =
          EnvelopeDownloadsFactory.createEnvelopeDownloads(
            envelopeRecipient.action_token
              ? envelopeRecipient.action_token
              : envelopeRecipient.envelope.id,
            envelopeRecipient.action_token ? 'public' : 'private'
          );

        await envelopeDownloads.downloadSignaturePage(
          envelopeRecipient.envelope.title
        );
      } finally {
        this.$loading(false);
      }
    },
    addSort(column, type) {
      this.sort[column] = type;
      this.$emit('sort', {
        column: `envelope.${column}`,
        desc: type === 'DESC',
      });
    },

    checkNextOrderByColumnIsDesc(column) {
      if (column === this.sort.column) {
        return !this.sort.desc;
      }
      return false;
    },
    getRoute(item) {
      const route = {
        name: 'business.documents.info',
        params: {
          id: item.envelope.id,
          token: item.action_token,
        },
      };
      if (
        item.envelope.state === EnvelopeState.IN_TRANSIT &&
        item.state !== RecipientState.DONE
      ) {
        route.name = 'business.envelopes.viewer';
      }
      return route;
    },
    getEnvelopeName(item) {
      return item.envelope.title && item.envelope.title !== 'old'
        ? item.envelope.title
        : item.envelope.subject;
    },
  },
};
</script>
<style lang="stylus">
.received-envelopes-card-table__name
  max-width: 180px;
@media (min-width: 576px)
  .received-envelopes-card-table__name
    max-width: 180px
@media (min-width: 768px)
  .received-envelopes-card-table__name
    max-width: 180px
@media (min-width: 992px)
  .received-envelopes-card-table__name
    max-width: 180px
@media (min-width: 1200px)
  .received-envelopes-card-table__name
    max-width: 140px
@media (min-width: 1400px)
  .received-envelopes-card-table__name
    max-width: 300px
</style>
