export default {
  mimeTypes: [
    'application/pdf',
    'application/vnd.oasis.opendocument.text',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/rtf',
    'text/html',
  ],
  extensions: ['pdf', 'odt', 'doc', 'docx', 'rtf', 'html'],
};
