import { format } from 'date-fns';

function dateNormal(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

function dateTime(date) {
  return format(new Date(date), 'HH:mm');
}

export default { dateNormal, dateTime };
