<template lang="pug">
i.icon(v-html='data')
</template>

<script>
export default {
  name: 'IconAtom',
  props: {
    name: String,
    fixWidth: Boolean,
    blink: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: () => 'regular',
    },
  },
  data() {
    return {
      data: '',
      cache: {},
    };
  },
  created() {
    const url = `/libs/font-awesome/${this.type}/${this.name}.svg`;
    if (!this.$root.iconsCache[`${this.type}-${this.name}`]) {
      fetch(url, { cache: 'force-cache' })
        .then((res) => res.blob())
        .then((res) => res.text())
        .then((res) => {
          this.data = res.replace(/<!--(.*)-->/, '');
          this.$root.iconsCache[`${this.type}-${this.name}`] = this.data;
        });
      return;
    }
    this.data = this.$root.iconsCache[`${this.type}-${this.name}`];
  },
};
</script>

<style lang="stylus">
.icon
  display: inline-flex
  svg
    height: 1em
    width: 1em
    fill: currentColor
@keyframes icon-blink
  0%
    opacity: 1
  50%
    opacity: .6
  100%
    opacity: .2
.blink
  -webkit-animation: icon-blink .9s linear infinite
  -moz-animation: icon-blink .9s linear infinite
  -ms-animation: icon-blink .9s linear infinite
  -o-animation: icon-blink .9s linear infinite
  animation: icon-blink .9s linear infinite
</style>
