import treatFormErrors from '@/modules/account/common/treatFormErrors';
import alert from '@/modules/account/common/alert';

const defaultSuccessStatusCodes = [200, 201, 202, 203, 204];

function defaultSuccess(
  response,
  successStatusCodes = defaultSuccessStatusCodes
) {
  if (successStatusCodes.indexOf(response.status) !== -1) {
    alert.fireAlert('Informações salvas com sucesso', {
      classes: 'alert-success',
      styles:
        'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
      icon: 'circle-check',
    });
  }
}

function defaultError(vueElement, error, fieldsByPropertyName) {
  treatFormErrors.treatFormErrors(vueElement, error, fieldsByPropertyName);
}

function submit(vueElement, request, options = {}) {
  const successStatusCodes =
    options.successStatusCodes ?? defaultSuccessStatusCodes;
  const elementToValidate = options.elementToValidate ?? vueElement.$refs.form;
  const loadingProperty = options.loadingProperty ?? 'loading';
  const fieldsByPropertyName = options.fieldsByPropertyName ?? {};
  const successHandler =
    options.successHandler ??
    function successHandler(response) {
      return defaultSuccess(response, successStatusCodes);
    };
  const errorHandler =
    options.errorHandler ??
    function errorHandler(error) {
      return defaultError(vueElement, error, fieldsByPropertyName);
    };

  const validatePromise =
    elementToValidate?.validate ??
    function fakeValidate() {
      return new Promise((myResolve) => {
        myResolve(true);
      });
    };

  validatePromise().then(async (success) => {
    if (vueElement[loadingProperty] || !success) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    vueElement[loadingProperty] = true;
    vueElement.$loading(true);
    request()
      .then((response) => {
        successHandler(response);
      })
      .catch((error) => {
        errorHandler(error);
      })
      .finally(() => {
        // eslint-disable-next-line no-param-reassign
        vueElement[loadingProperty] = false;
        vueElement.$loading(false);
        if (options.finallyHandler !== undefined) {
          options.finallyHandler();
        }
      });
  });
}

export default { submit, defaultSuccess };
