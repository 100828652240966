<template lang="pug">
.image-loader
  .image-block(v-if='localImgSrc')
    .image-block__actions
      button.btn(
        @click='actionEdit()'
        title='Editar Imagem'
        type='button'
      )
        icon(name='pencil-alt')
      button.btn(
        @click='actionRemove()'
        title='Excluir Imagem'
        type='button'
      )
        icon(name='trash')
    img.img(
      :src='localImgSrc'
      crossorigin='use-credentials'
      ref='image'
    )
  .image-upload(@click='triggerLoad' v-if='!localImgSrc')
    .image-upload__action
      .image-upload__action-icon
        icon(name='upload')
      .image-upload__action-text
        strong Enviar Imagem
    input.input-file(
      @change='loadFile'
      accept='image/*'
      ref='fileInput'
      type='file'
    )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import Cropper from 'cropperjs';

export default {
  name: 'ImageLoader',
  props: {
    imgSrc: String,
  },
  components: {
    Icon,
  },
  data() {
    return {
      localImgSrc: this.imgSrc,
    };
  },
  watch: {
    imgSrc(value) {
      this.localImgSrc = value;
    },
  },
  methods: {
    loadFile() {
      const file = this.$refs.fileInput.files[0];
      const Reader = new FileReader();
      Reader.addEventListener(
        'load',
        (data) => {
          this.localImgSrc = data.currentTarget.result;
          setTimeout(() => {
            this.startCropper();
          }, 200);
        },
        false
      );
      Reader.readAsDataURL(file);
    },
    startCropper() {
      this.cropper = new Cropper(this.$refs.image, {
        aspectRatio: 1,
        dragMode: 'crop',
        responsive: true,
        modal: true,
        checkCrossOrigin: true,
        cropmove: this.actionCrop,
      });
    },
    restartCropper() {
      this.cropper.restart();
    },
    triggerLoad() {
      this.$refs.fileInput.click();
    },
    actionRemove() {
      this.localImgSrc = '';
      this.$emit('remove');
    },
    actionEdit() {
      this.startCropper();
    },
    actionCrop() {
      const dataUrl = this.cropper.getCroppedCanvas().toDataURL();
      this.dataToFile(dataUrl).then((file) => {
        this.$emit('update', file);
      });
    },
    async dataToFile(dataUrl) {
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      return new File([blob], 'image.png', {
        type: 'image/png',
      });
    },
  },
};
</script>

<style lang="stylus">
@import '../../../node_modules/cropperjs/dist/cropper.css';
.image-loader, .image-block, .image-upload
  max-height: inherit
  height: inherit
  width: 100%
.image-block .img
  height: inherit

.image-loader .cropper-container
  z-index: 2

.image-loader
  text-align: center

.image-block
  .img
    max-height: inherit

.image-upload
  border: 2px dashed #e0e6ed
  text-align: center
  position: relative
  cursor: pointer
  .input-file
    display: none

.image-upload__action
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  height: 3rem
  opacity: .5

.image-block
  position: relative
  &:after
    content: ''
    position: absolute
    background-color: rgba(0, 0, 0, 0)
    transition: background-color .3s
    top: 0
    bottom: 0
    left: 0
    right: 0
  &:hover
    &:after
      background-color: rgba(0, 0, 0, .5)
    .image-block__actions
      display: block

.image-block__actions
  position: absolute
  left: 0
  right: 0
  top: 0
  bottom: 0
  margin: auto
  height: 1.5rem
  display: none
  z-index: 2
  .btn
    padding: 0
</style>
