<template lang="pug">
icon(:class='selectClass' :name='selectIcon')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import EnvelopeState from '@/enums/EnvelopeState';
import RecipientState from '@/enums/RecipientState';
import EnvelopeStatus from '@/enums/EnvelopeStatus';

export default {
  name: 'IconStatus',
  props: {
    iconStatus: String,
  },
  components: {
    Icon,
  },
  computed: {
    selectIcon() {
      let icon = '';
      switch (this.iconStatus) {
        case 'sent':
        case 'processing':
        case EnvelopeState.IN_TRANSIT:
        case RecipientState.QUEUED:
        case RecipientState.WAITING:
        case EnvelopeStatus.PENDING:
          icon = 'clock';
          break;
        case RecipientState.SIGNING:
        case EnvelopeState.PROCESSING:
          icon = 'arrows-rotate';
          break;
        case EnvelopeState.CANCELLED:
        case EnvelopeState.HALTED:
        case RecipientState.FAILED:
          icon = 'triangle-exclamation';
          break;
        case EnvelopeState.EXPIRED:
          icon = 'stopwatch';
          break;
        case EnvelopeState.COMPLETED:
        case RecipientState.DONE:
          icon = 'circle-check';
          break;
        case EnvelopeState.STANDBY:
        case RecipientState.SUSPENDED:
          icon = 'circle-minus';
          break;
        case EnvelopeStatus.REJECTED:
        case RecipientState.REFUSED:
          icon = 'ban';
          break;
        case RecipientState.FOLLOWING:
          icon = 'eye';
          break;
        default:
          break;
      }
      return icon;
    },
    selectClass() {
      let iconClass = '';
      switch (this.iconStatus) {
        case 'sent':
        case 'processing':
        case EnvelopeState.IN_TRANSIT:
        case RecipientState.QUEUED:
        case RecipientState.WAITING:
        case EnvelopeStatus.PENDING:
          iconClass = 'icon--pending';
          break;
        case EnvelopeState.CANCELLED:
        case EnvelopeState.EXPIRED:
        case EnvelopeState.HALTED:
        case EnvelopeState.REFUSED:
        case RecipientState.FAILED:
          iconClass = 'icon--canceled';
          break;
        case EnvelopeState.COMPLETED:
        case RecipientState.DONE:
          iconClass = 'icon--finished';
          break;
        case EnvelopeState.STANDBY:
        case RecipientState.SUSPENDED:
        case RecipientState.SIGNING:
        case EnvelopeState.PROCESSING:
        case RecipientState.FOLLOWING:
          iconClass = 'icon--suspended';
          break;
        case EnvelopeStatus.REJECTED:
        case RecipientState.REFUSED:
          iconClass = 'icon--rejected';
          break;
        default:
          break;
      }
      return iconClass;
    },
  },
};
</script>
