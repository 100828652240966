<template lang="pug">
drag.debug.document-element(
  :aspectRatio='true'
  :h='height'
  :is-resizable='element.resizable'
  :key='key'
  :minh='minHeight'
  :minw='minWidth'
  :parent-h='parentHeight'
  :parent-limitation='true'
  :parent-w='parentWidth'
  :stickSize='8'
  :w='width'
  :x='x'
  :y='y'
  @dragging='onDragging'
  @resizing='onResizing'
  v-show='isReady'
)
  div
    button.btn-remove(@click='$emit("delete", element)')
      icon(name='circle-xmark')
    button.btn-settings(
      @click='$emit("element-click", element)'
      v-if='!element.parent && element.settings'
    )
      icon(name='gear')
    div
      .element(
        :class='{ "element--with-settings": element.settings }'
        :style='{ "font-size": `${fontSize}px`, height: `${height}px` }'
      )
        template(v-if='element.category === "content"')
          .element__text(v-if='element.type === "text"') {{ element.value }}
          .element__qrcode(v-if='element.type === "validation-qrcode"')
            img.img.img-fluid(src='/images/qr-code.svg')
          .element__comment.pe-4(v-if='element.type === "comment"') {{ element.value }}
          .element__image(v-if='element.type === "image"')
            img.img(:src='element.image.src')
          .element__date(v-if='element.type === "date"')
            span(v-if='element.value') {{ element.value | dateTime }}
        template(v-if='element.category === "field"')
          .element__signature(v-if='element.type === "signature"')
            .title {{ element.name }}
            .name.my-1(:title='element.recipient.addressees[0].name')
              | {{ element.recipient.addressees[0].name | signatureEmail }}
            .email(:title='element.recipient.addressees[0].value')
              | {{ element.recipient.addressees[0].value | signatureEmail }}
          .element__signature(v-if='element.type === "initial"')
            .title {{ element.name }}
            .name.my-1(:title='element.recipient.addressees[0].name')
              | {{ element.recipient.addressees[0].name | signatureEmail }}
            .email(:title='element.recipient.addressees[0].value')
              | {{ element.recipient.addressees[0].value | signatureEmail }}
          .element__checkbox(
            :title='element.title || element.name'
            v-if='element.type === "checkbox"'
          )
            icon(name='square-check' style='color: #000')
          .element__select(v-if='element.type === "dropdown"')
            .fake-select.d-flex
              span.text {{ element.title || element.name }}
              icon.ms-auto(name='chevron-down' style='color: #000')
          .element__input(v-if='element.type === "text"') {{ element.title || element.name }}
    //- .debug
      ul
        //- li Key: {{ key }}
        li font size: {{ fontSize }} - {{ element.fontSizePercentage }}
        //- li(v-if='element.positionPercentage') Position %: {{ element.positionPercentage.y }}
        //- li Size %: {{ element.sizePercentage.width }}
        //- li X: {{ x }}
        //- li Y: {{ y }}
        //- li W: {{ width }}
        //- li W: {{ this.element.pageSize.width }}
        //- li H: {{ height }}
        //- li parent height {{ parentHeight }}
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Drag from 'vue-drag-resize';

export default {
  components: {
    Drag,
    Icon,
  },
  props: {
    element: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      key: 1,
      minWidth: 60,
      minHeight: 60,
      isReady: false,
      width: 'auto',
      height: 'auto',
      x: 50,
      y: 50,
      fontSize: 16,
      parentWidth: 500,
      parentHeight: 500,
    };
  },
  beforeMount() {
    switch (this.element.format) {
      case 'name':
        this.$set(
          this.element,
          'value',
          this.element.recipient.addressees[0].name
        );
        break;
      case 'email':
        this.$set(
          this.element,
          'value',
          this.element.recipient.addressees[0].value
        );
        break;
      default:
        break;
    }
  },
  created() {
    if (this.element.x) {
      this.x = this.element.x;
    }
    if (this.element.y) {
      this.y = this.element.y;
    }
    if (this.element.width) {
      this.width = this.element.width;
    }
    if (this.element.height) {
      this.height = this.element.height;
    }
    if (this.element.fontSize) {
      this.fontSize = this.element.fontSize;
    }
  },
  mounted() {
    setTimeout(() => {
      this.loadParentSize();
      this.setSizeFromAuto();
      this.ready();
      if (this.element.inApi && !this.element.updated) {
        this.loadSizeAndPositionByPercentageFromApi();
      } else {
        this.loadSizeAndPositionByPercentage();
      }
    }, 100);
  },
  methods: {
    reRender() {
      this.key += 1;
    },
    onDragging(e) {
      if (!this.isReady) {
        return;
      }
      this.updatePosition(e.left, e.top, true);
      this.$emit('updated', this.element);
    },
    onResizing(e) {
      if (this.element.resizable !== true) {
        return;
      }
      if (!this.isReady) {
        return;
      }
      this.fontSize = (e.width / this.element.width) * this.fontSize;
      this.updateSize(e.width, e.height, true);
      this.$emit('updated', this.element);
    },
    setSizeFromAuto() {
      if (this.width === 'auto' && this.height === 'auto') {
        setTimeout(() => {
          this.reRender();
          this.updateSize(this.$el.clientWidth, this.$el.clientHeight, true);
          this.updatePosition(this.x, this.y, true);
        }, 200);
      }
    },
    ready() {
      this.isReady = true;
      this.$emit('ready', {
        parentSize: {
          width: this.parentWidth,
          height: this.parentHeight,
        },
      });
    },
    updateSize(width, height, changePercentage = false) {
      this.width = width;
      this.height = height;
      const data = {
        element: this.element,
        size: {
          width: width,
          height: height,
        },
      };
      if (changePercentage) {
        data.sizePercentage = {
          width: width / this.parentWidth,
          height: height / this.parentHeight,
        };
        data.fontSizePercentage = this.fontSize / this.width;
      }
      this.$emit('resize', data);
    },
    updatePosition(x, y, changePercentage = false) {
      this.x = x;
      this.y = y;
      const data = {
        element: this.element,
        position: {
          x: x,
          y: y,
        },
      };
      if (changePercentage) {
        data.positionPercentage = {
          x: x / this.parentWidth,
          y: y / this.parentHeight,
        };
      }
      this.$emit('change-position', data);
    },
    loadParentSize() {
      this.parentHeight = this.$el.parentElement.clientHeight;
      this.parentWidth = this.$el.parentElement.clientWidth;
      this.$emit('parent-size', {
        width: this.parentWidth,
        height: this.parentHeight,
      });
    },
    loadSizeAndPositionByPercentage() {
      if (this.element.sizePercentage) {
        this.reRender();
        this.updateSize(
          this.element.sizePercentage.width * this.element.parentSize.width,
          this.element.sizePercentage.height * this.element.parentSize.height
        );
      }
      if (this.element.positionPercentage) {
        this.reRender();
        this.updatePosition(
          this.element.positionPercentage.x * this.element.parentSize.width,
          this.element.positionPercentage.y * this.element.parentSize.height
        );
      }
      if (this.element.fontSizePercentage) {
        this.fontSize = this.element.fontSizePercentage * this.width;
      }
    },
    loadSizeAndPositionByPercentageFromApi() {
      if (!this.element.pageSize) {
        return;
      }
      this.updatePosition(
        (this.element.apiX / this.element.pageSize.width) * this.parentWidth,
        (this.element.apiY / this.element.pageSize.height) * this.parentHeight,
        true
      );
      this.updateSize(
        (this.element.apiWidth / this.element.pageSize.width) *
          this.parentWidth,
        (this.element.apiHeight / this.element.pageSize.height) *
          this.parentHeight,
        true
      );
      this.fontSize =
        (this.element.apiFontSize / this.element.apiWidth) * this.element.width;
      this.reRender();
    },
  },
  filters: {
    signatureEmail: (value) => {
      return value.length > 25 ? `${value.substring(0, 25)}...` : value;
    },
  },
};
</script>
<style lang="stylus">
.debug
  position: absolute
  background-color: white
  font-size: 12px
.document-element
  width: fit-content
  position: absolute !important
  z-index: 3 !important
  border-radius: 4px
.element--with-settings
  padding-right: 0
.element
  font-size: inherit
  line-height: 1.5em
  font-weight: 600
  cursor: move
.element__comment
  padding: 1em
  line-height: 1.5em
  white-space: pre-wrap
.element__input, .element__checkbox
  line-height: 1.1em
.element__select
  width: inherit
  height: inherit
  .fake-select
    background-color: #fff
    padding: 0.4em 1em
    border-radius: 0.3em
    border: 1px solid #ccc
    min-width: max-content
    line-height: 2em
    .text
      margin-right: 1em

.element__qrcode
  min-width: 60px
  min-height: 60px
  .img
    width: 100%
    position: absolute
.element__signature
  line-height: 1.2em
  height: inherit
.btn-remove, .btn-settings
  position: absolute
  top: -15px
  left: -15px
  color: #D00A00
  z-index: 2
  .icon
    svg
      background-color: #fff
      border-radius: 50%
.btn-settings
  left: auto
  top: -15px
  right: -15px
  color: #707070
.element__signature
  .title
    font-weight: 600
  .name, .email
    font-size: .9em
    font-weight: 500
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
.element__image
  .img
    width: 100%
    display: block
.element__comment,
.element__input,
.element__signature,
.element__text
.element__select
.element__checkbox,
.element__date
  padding: 1em
  border: 2px dashed #ccc
  background-color: #fff
  border-radius: .2em

.element__qrcode,
.element__image
  padding: 0
  height: inherit
  .img
    width: 100%
    max-width: 100%
    border: 2px dashed #ccc
    background-color: #fff
    border-radius: .2em

.element__comment,
.element__text,
.element__input,
.element__checkbox
  min-width: max-content
.element__input
  text-align: center
.element__checkbox
  text-align: center
  .icon
    font-size: 1.5em

.element__text
  white-space: pre-wrap
</style>
