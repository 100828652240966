<template lang="pug">
modal#file-loader-modal(
  :no-footer='true'
  @closed='onCloseModal'
  @opened='onOpenModal'
  ref='modal'
  size='lg'
  title='Buscar'
)
  ul.nav.nav-tabs(role='tablist')
    li.nav-item(role='presentation')
      button.nav-link.active(
        aria-controls='settings'
        aria-selected='true'
        data-bs-target='#computer'
        data-bs-toggle='tab'
        role='tab'
        type='button'
      )
        icon(name='laptop')
        span.text.ms-2 Computador
    li.nav-item(role='presentation' v-if='sourceStorage')
      button.nav-link(
        aria-controls='storage'
        aria-selected='true'
        data-bs-target='#storage'
        data-bs-toggle='tab'
        role='tab'
        type='button'
      )
        icon(name='box-open')
        span.text.ms-2 Armazenamento
  .tab-content
    #computer.tab-pane.fade.show.active(
      aria-labelledby='computer-tab'
      role='tab-computer'
    )
      .text-center.pt-3
        input(
          :accept='allowedFileType.join()'
          @change='loadFile'
          multiple='multiple'
          ref='inputFile'
          style='display: none'
          type='file'
        )
        .files-list--modal(v-if='hasUploadingFiles')
          .row
            .files-list--modal__item.col-12.col-md-6(
              :key='payload.index'
              v-for='(payload, index) in computerFiles'
            )
              file-upload-item(
                :allowedFileType='allowedFileType'
                :folderId='folderId'
                :key='payload.index'
                :maxFileSizeMb='maxFileSizeMb'
                :payload='payload'
                :ref='`file-` + payload.file.id'
                @removeFile='removeFile'
                @uploadFinished='onUploadFinished'
              )
        .card--upload-file(v-else)
          div(
            :class='["dropzone-area", dragging ? "dragenterClass" : ""]'
            @dragend='dragging = false'
            @dragenter='dragging = true'
            @dragleave='dragging = false'
            @dragover.prevent='dragover'
            @drop.prevent='drop'
          )
            label.notclickable.block.cursor-pointer(for='assetsFieldHandle')
              h3.card-title Arraste aqui seus documentos
              p.font-size-small.mb-0 Arquivos PDF com no máximo {{ maxFileSizeMb }}MB
              p.font-size-subtitle.mb-2 ou
              button.btn.btn-thin(@click='selectFile') Selecionar arquivos
  .modal-footer.p-0.mt-4
    div(v-if='hasUploadingFiles')
      button.btn.btn-thin.flex-fill(@click='selectFile')
        span(aria-hidden='true') Selecionar arquivos
    .d-flex.flex-grow-1.justify-content-end
      button.btn.btn-secondary(@click='cancel' type='button') Cancelar
      button.btn.btn-primary(
        :disabled='!hasSelectedFiles'
        @click='finishProcess'
        type='button'
      )
        | Ok
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Alert from '@/ui/molecules/Alert.vue';
import Modal from '@/ui/molecules/Modal.vue';
import FileUploadItem from '@/ui/molecules/FileUploadItem.vue';
import FilesListMini from '@/ui/organisms/FilesListMini.vue';

export default {
  components: {
    Icon,
    Alert,
    Modal,
    FilesListMini,
    FileUploadItem,
  },
  props: {
    folderId: String,
    sourceStorage: {
      type: Boolean,
      default: true,
    },
    maxFileSizeMb: {
      type: Number,
      default: 5,
    },
    allowedFileType: {
      type: Array,
      default: () => ['application/pdf'],
    },
    dragOnDrop: Boolean,
  },
  data() {
    return {
      filesListKey: 0,
      computerFiles: [],
      storageFiles: [],
      dragAndDropModal: false,
      dragging: false,
    };
  },
  computed: {
    sanitizedComputerFiles() {
      if (this.computerFiles.length === 0) {
        return [];
      }
      return this.computerFiles
        .filter((payload) => !(payload.file instanceof File))
        .map((payload) => payload.file);
    },
    hasUploadingFiles() {
      return this.computerFiles.length > 0;
    },
    hasStorageFiles() {
      return this.storageFiles.length > 0;
    },
    hasUploadedFiles() {
      if (!Array.isArray(this.sanitizedComputerFiles)) {
        return false;
      }
      return this.sanitizedComputerFiles.length > 0;
    },
    hasSelectedFiles() {
      return this.hasUploadedFiles || this.hasStorageFiles;
    },
    getSelectedFiles() {
      return this.storageFiles.concat(this.sanitizedComputerFiles);
    },
  },
  methods: {
    finishProcess() {
      this.$emit('finish-process');
      this.$refs.modal.close();
    },
    onCloseModal() {
      this.computerFiles = [];
      this.storageFiles = [];
      this.filesListKey += 1;
    },
    onOpenModal() {
      this.$refs.inputFile.value = '';
      this.computerFiles = [];
      this.storageFiles = [];
    },
    onUploadFinished(payload, NewFile) {
      const index = this.computerFiles.indexOf(payload);
      this.computerFiles.splice(index, 1, {
        index: payload.index,
        file: NewFile,
      });
    },
    async loadFile(e, dragOnDrop = false) {
      let recivedFiles;
      if (dragOnDrop) {
        recivedFiles = e.dataTransfer.files;
      } else {
        recivedFiles = e.target.files;
      }
      recivedFiles.forEach((file) => {
        this.computerFiles.push({
          index: new Date().getTime() + Math.random(),
          file,
        });
      });
    },
    selectFile() {
      this.$refs.inputFile.click();
    },
    selectedFiles(files) {
      this.storageFiles = files;
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.dragAndDropModal = true;
      this.loadFile(event, true);
      this.dragging = false;
    },
    removeFile(payload) {
      const index = this.computerFiles.indexOf(payload);
      this.computerFiles.splice(index, 1);
    },
    cancel() {
      if (!this.hasUploadedFiles) {
        this.$refs.modal.close();
        return;
      }
      this.sanitizedComputerFiles.forEach((file) => {
        this.$refs[`file-${file.id}`][0].removeFile();
      });
      this.$refs.modal.close();
    },
  },
};
</script>
<style lang="stylus">
.file-loader-modal
  .nav-tabs
    .nav-item
      .text
        margin-left: .5em
  .files-list--modal__item
    position: relative
    .list-file-preview
      width: 100%
      height: 160px
      margin-bottom: 1rem
      box-shadow: 1px 1px 5px #c1c1c1
      background-size: cover
      background-position: initial
      background-repeat: no-repeat
    .file-name
      background-color: #fff
      height: 45px
      line-height: 3.2em
      height: 3.2em
      font-size: 0.9rem
      overflow: hidden
      text-overflow: ellipsis
      position: absolute
      left: 0
      right: 0
      bottom: 1.1em
      margin: 0 0.8rem
.card--upload-file
  button
    margin-bottom: .5rem
  .dragenterClass
    cursor: grabbing
    cursor: -webkit-grabbing
    background:rgba(0,0,0,0.1)
    .notclickable
      pointer-events: none;
      h3
        pointer-events: none;
      p
        pointer-events: none;
</style>
