import Http from '@/services/Http';

const path = 'organizations';
const client = new Http(path);

client.update = (organizationId, data) =>
  client.put('', data, `/${organizationId}/theme`);

client.updateLogo = (organizationId, blob) => {
  const formData = new FormData();
  formData.append('logo', blob);
  return client.post(formData, `/organizations/${organizationId}/logo`);
};

client.deleteLogo = (organizationId) =>
  client.delete('', '', `/${organizationId}/logo`);

client.deleteTheme = (organizationId) =>
  client.delete('', '', `/${organizationId}/theme`);

client.list = (organizationId) =>
  client.get('', '', `/${organizationId}/theme`);

export default client;
