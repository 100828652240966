<template lang="pug">
.users-pending-screen
  .row(v-if='usersInvitations.data.length > 0')
    .col-12
      .row
        .col-12.col-md-5.col-lg-6
          .form-group.form-group--search
            .form-control-icon--end-line
              input#name.form-control.rounded-3(
                @input='searchUsersOrganization()'
                autocomplete='off'
                placeholder='Buscar usuário'
                v-model='filters.search'
              )
              icon(name='magnifying-glass')
        .col-12.col-md-3.col-lg-3.col--bar.mb-4
          .users-limit(v-if='usersPlanLimit')
            .bar
              icon.icon--blue(name='user')
              .progress
                .progress-bar.bg-warning(
                  :aria-valuenow='(totalOrganizationUsers / usersPlanLimit) * 100'
                  :style='`width: ${(totalOrganizationUsers / usersPlanLimit) * 100}%`'
                  aria-label='Warning example'
                  aria-valuemax='100'
                  aria-valuemin='0'
                  role='progressbar'
                )
            span.text {{ totalOrganizationUsers }} usuários de {{ usersPlanLimit }}
        .col-12.col-md-4.col-lg-3.text-end.col--action
          .btn-invite-user
            .text-end
              button.btn.btn-primary.btn--invite-user(
                :disabled='totalOrganizationUsers >= usersPlanLimit'
                @click='openInviteUserModal'
              )
                | Convidar usuário
    .col-12
    pending-invites-card-table(
      :data='usersInvitations.data'
      @item-deleted='fetchPendingInvites'
    )
    simple-paginator(
      :payload='usersInvitations'
      @navigate='fetchPendingInvites'
    )
  .text-center.mt-5(v-else)
    img.img-fluid.mt-5(src='/img/ilustrations/no-guest-users.svg')
    p.font-color-blue.mt-4
      | Você ainda não convidou nenhum usuário!
      br
      | Vamos começar agora?
    button.btn.btn-primary.btn--new-envelope(
      :disabled='usersInvitations.data.length >= usersPlanLimit'
      @click='openInviteUserModal'
    )
      | Convidar Usuário
  invite-user-modal(
    @invites-sent='fetchPendingInvites(); fetchOrganizationStats()'
    ref='inviteUserModal'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import InvitationService from '@/services/InvitationService';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import debounce from 'debounce';
import InviteUserModal from '@/modules/account/components/organisms/modals/InviteUserModal.vue';
import alert from '@/modules/account/common/alert';
import PendingInvitesCardTable from '@/modules/account/components/organisms/card-table/PendingInvitesCardTable.vue';
import OrganizationsHttp from '@/modules/business/services/http/organizations/OrganizationsService';

export default {
  name: 'PendingInvites',
  components: {
    Icon,
    InvitationService,
    SimplePaginator,
    PendingInvitesCardTable,
    InviteUserModal,
  },
  data() {
    return {
      usersInvitations: {
        data: [],
        links: {},
        meta: {},
      },
      filters: {
        search: '',
      },
      selectedInvitedEmail: '',
      totalOrganizationUsers: 0,
    };
  },
  computed: {
    usersPlanLimit() {
      if (!this.$store.state.subscription.plan.plan) {
        return false;
      }
      const limit = this.$store.state.subscription.plan.plan.limits
        .filter((i) => i.limitation === 'users')
        .shift();
      if (!limit) {
        return false;
      }
      return limit.quantity;
    },
  },
  async created() {
    this.$store.dispatch('page/setTitle', 'Convites Pendentes');
    this.$store.dispatch('page/setHead', 'Usuários');
    this.$store.dispatch('page/setClass', 'accounts users pending-invites');
    this.fetchPendingInvites();
  },
  methods: {
    fetchPendingInvites(page = 1) {
      this.$loading(true);
      this.fetchOrganizationStats();
      const params = new URLSearchParams({
        ...(this.filters.search && { q: this.filters.search }),
        ...{ page },
      });
      InvitationService.list(params.toString())
        .then((response) => {
          this.usersInvitations = response.data;
        })
        .catch((error) => {
          alert.fireAlert(
            `Não foi possível listar convites pendentes na organização. ${error.response.data.message}`,
            {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
              icon: 'triangle-exclamation',
            }
          );
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    searchUsersOrganization: debounce(function searchUsersOrganization() {
      this.fetchPendingInvites();
    }, 300),
    openInviteUserModal() {
      this.$refs.inviteUserModal.$refs.modal.open();
    },
    fetchOrganizationStats() {
      const selected = this.$store.state['organizations'].selected;
      OrganizationsHttp.getStats(selected.id).then((res) => {
        this.totalOrganizationUsers = res.data.invitations + res.data.users;
      });
    },
  },
};
</script>

<style scoped lang="stylus">
.users-pending-screen
  .btn--invite-user
    margin-bottom: 2rem
    width: 100%
  .users-limit
    min-width: 165px
    height: fit-content
    margin-top: .5rem
    position: relative
    font-size: .9em
    .progress, .text
      margin-left: 1.5rem
    .progress
      height: .85em
      border-radius: 1rem
    .text
      font-size: .9em
    .icon
      top: -2px
      position: absolute

@media (min-width: 992px)
  .users-pending-screen
    .btn--invite-user
      max-width: 180px
    .col--action
      max-width: 200px
    .col--bar
      max-width: 200px
      margin-left: auto
    .form-group--search
      max-width: 320px
@media (min-width: 1200px)
  .manage-applications-card-table__name
    max-width: 470px
</style>
