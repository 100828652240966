function formatPhoneNumber(phoneNumber) {
  if (phoneNumber === null) return '-';

  const regExp = /^\+(\d{2})(\d{2})(\d{8,9})/;
  let splitNumber = phoneNumber.match(regExp);

  if (!splitNumber) return '-';

  if (splitNumber[3].length === 9) {
    // eslint-disable-next-line prettier/prettier
    return `(${splitNumber[2]}) ${splitNumber[3].slice(0, 5)}-${splitNumber[3].slice(5, 9)}`;
  }
  // eslint-disable-next-line prettier/prettier
  return `(${splitNumber[2]}) ${splitNumber[3].slice(0, 4)}-${splitNumber[3].slice(4, 8)}`;
}

export default { formatPhoneNumber };
