const storage = window.localStorage;

export default {
  set: (key, data) => {
    if (!key) {
      const error = {
        cod: 1,
        message: 'Invalid key to set in localstorage',
      };
      throw error;
    }
    const key64 = window.btoa(key);
    if (typeof data === 'object') {
      return storage.setItem(key64, window.btoa(JSON.stringify(data)));
    }
    return storage.setItem(key64, window.btoa(data));
  },
  get: (key) => {
    if (!key) {
      const error = {
        cond: 1,
        message: 'Invalid key to get from localstorage',
      };
      throw error;
    }
    const key64 = window.btoa(key);
    const data = storage.getItem(key64);
    if (!data) {
      return false;
    }
    try {
      const result = JSON.parse(window.atob(data));
      return Object.keys(result).length ? result : false;
    } catch (e) {
      return window.atob(data);
    }
  },
  delete: (key) => {
    storage.removeItem(window.btoa(key));
  },
};
