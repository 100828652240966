<template lang="pug">
modal.checkbox-settings-modal(
  :show-cancel-button='false'
  @process='process'
  process-button-label='Ok'
  ref='modal'
  size='sm'
  title='QR Code'
)
  .col-12(v-if='element')
    p O QR Code autentica o documento e é gerado automaticamente
      |
      | após a conclusão de todas as assinaturas.
    .text-center
      img(src='/images/qrcode-solid.svg' style='width: 70px')
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      element: {},
    };
  },
  methods: {
    process() {
      this.$emit('settings', this.element);
      this.$refs.modal.close();
    },
  },
};
</script>
<style lang="stylus"></style>
