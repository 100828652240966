<template lang="pug">
modal#delete-developer-client-modal(
  :noFooter='true'
  @process='closeModal'
  ref='modal'
  title='Atenção'
)
  .text-center
    p.text-start Quando em Sandbox, o aplicativo deve ser utilizado
      | &nbsp;para desenvolvimento, testes e validação de integração.
    p.text-start Todas as ações realizadas nesse ambiente serão deletadas após 7 dias.

    img.mt-4.mb-3(
      alt='developer-delete-warning'
      src='/img/ilustrations/application-warning.svg'
    )

  .modal-footer.p-0.d-flex.justify-content-end
    button#buttonColor.btn.btn-primary(@click='closeModal' type='button')
      | Ok
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'CreatingAppInfoModal',
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.$refs.modal.close();
    },
  },
};
</script>
