var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('modal',{ref:"modal",attrs:{"id":"edit-contact-modal","disableProcessButton":invalid,"close-button-label":"Cancelar","process-button-label":"Salvar","size":"sm","title":"Editar contato"},on:{"closed":_vm.onCloseModal,"opened":function($event){_vm.key += 1},"process":_vm.submit}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}}),_c('ValidationProvider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nome")]),_c('div',{staticClass:"form-control-icon"},[_c('icon',{attrs:{"name":"user"}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.editableContact.name),expression:"editableContact.name",modifiers:{"trim":true}}],staticClass:"form-control",class:{ "border border-danger": errors[0] },attrs:{"autocomplete":"off","placeholder":"Digite um nome completo","type":"text"},domProps:{"value":(_vm.editableContact.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editableContact, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})],1),_c('small',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Endereço de e-mail")]),_c('div',{staticClass:"form-control-icon"},[_c('icon',{attrs:{"name":"at"}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.editableContact.email),expression:"editableContact.email",modifiers:{"trim":true}}],staticClass:"form-control",class:{ "border border-danger": errors[0] },attrs:{"autocomplete":"off","placeholder":"Digite um e-mail","type":"text"},domProps:{"value":(_vm.editableContact.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editableContact, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})],1),_c('small',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"phone","rules":"intBrazilianPhone","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{key:_vm.key,staticClass:"form-group"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Telefone")]),_c('div',{staticClass:"form-control-icon"},[_c('input-tel',{directives:[{name:"mask",rawName:"v-mask",value:("## #####-####"),expression:"\"## #####-####\""}],staticClass:"form-control",attrs:{"id":"phone","autocomplete":"off","type":"text"},model:{value:(_vm.editableContact.phone),callback:function ($$v) {_vm.$set(_vm.editableContact, "phone", $$v)},expression:"editableContact.phone"}}),(errors.length > 0)?_c('small',{staticClass:"text text-danger d-flex mt-1"},[_c('icon',{attrs:{"name":"xmark"}}),_c('span',{staticClass:"text ms-2"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e()],1)])]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }