<template lang="pug">
modal#edit-recipient-modal.text-center(
  :buttonColor='false'
  :disableProcessButton='errors.length > 0'
  :noFooter='true'
  :title='modalTitle'
  @closed='deselect'
  close-button-label='Cancelar'
  process-button-label='Alterar'
  ref='modal'
  size='sm'
)
  #edit-recipient(ref='edit' v-show='processStep === 1')
    ValidationObserver(ref='form' v-slot='{ valid, passes }')
      form(@submit.prevent='passes(edit)')
        ValidationProvider(
          name='nome'
          rules='required'
          v-slot='{ errors }'
        )
          label.text-start.ps-1.mb-2(for='name') Nome
          .form-control-icon
            icon(name='user')
            input#name.form-control(
              :class='{ "border border-danger": errors[0] }'
              autocomplete='off'
              placeholder='Digite o nome do destinatário'
              type='text'
              v-model='newRecipient.name'
            )
          small.align-items-center.text.text-danger.d-flex(
            v-if='errors.length > 0'
          )
            icon(name='xmark')
            span.text.ms-2 {{ errors[0] }}
        ValidationProvider(
          name='e-mail'
          rules='required|email'
          v-slot='{ errors }'
        )
          label.text-start.ps-1.mt-4.mb-2(for='email') E-mail
          .form-control-icon
            icon(name='at')
            input#name.form-control(
              :class='{ "border border-danger": errors[0] }'
              autocomplete='off'
              placeholder='Digite o e-mail do destinatário'
              type='text'
              v-model='newRecipient.email'
            )
          small.align-items-center.text.text-danger.d-flex(
            v-if='errors.length > 0'
          )
            icon(name='xmark')
            span.text.ms-2 {{ errors[0] }}
        .text-end
          button.btn.btn-secondary.mt-5(@click='closeAction' type='button') Cancelar
          button.btn.btn-primary.ms-3.mt-5(
            :disabled='!valid'
            @click='next'
            type='button'
          )
            | Alterar
  #confirm-recipient(ref='confirm' v-show='processStep === 2')
    p.text-start Confira os dados para concluir a alteração e notificar o destinatário.
    .current.d-flex.flex-column.text-start.mb-3
      label.fw-bold Atual:
      span Nome: {{ recipient.addressees[0].name }}
      span Email: {{ recipient.addressees[0].value }}
    .modified.d-flex.flex-column.text-start
      label.fw-bold Alteração:
      span Nome: {{ newRecipient.name }}
      span Email: {{ newRecipient.email }}
    .text-end
      button.btn.btn-secondary.mt-5(@click='closeAction' type='button') Cancelar
      button.btn.btn-primary.ms-3.mt-5(@click='edit' type='button') Enviar
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'EditRecipientModal',

  props: {
    recipient: {
      type: Object,
      required: true,
    },
  },
  components: {
    Icon,
    Modal,
  },
  data() {
    return {
      modalTitle: 'Editar destinatário',
      processStep: 1,
      newRecipient: {
        name: this.recipient.addressees[0].name,
        email: this.recipient.addressees[0].value,
      },
      errors: [],
    };
  },
  watch: {
    recipient(newValue) {
      this.newRecipient.name = newValue.addressees[0].name;
      this.newRecipient.email = newValue.addressees[0].value;
    },
  },
  methods: {
    closeAction() {
      this.deselect();
      this.$refs.modal.close();
    },
    deselect() {
      this.$emit('deselect');
    },
    edit() {
      this.$emit('edit', this.newRecipient);
    },
    next() {
      this.modalTitle = 'Enviar notificação';
      this.processStep += 1;
    },
  },
};
</script>

<style scoped lang="stylus">
#edit-recipient-modal
  label
    width: 100%
</style>
