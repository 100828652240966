<template lang="pug">
.draw-area(:class='{ expanded: expanded }')
  .draw-area__content
    button.btn-clear(@click='clearDraw()' v-if='draw')
      icon(name='trash-can')
    button.btn-expand(@click='expand()')
      icon(:name='this.getExpandIcon()')
    .line Desenhe sua <span class="text-lowercase"> {{ label }}</span>
    canvas.canvas(
      :height='`${height}`'
      :width='`${width}`'
      ref='canvas'
      v-on:mousedown='this.clickToDraw'
      v-on:mousemove='this.drawing'
      v-on:mouseup='this.stopDrawing'
    )
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      default: 'Assinatura',
    },
  },
  data() {
    return {
      ready: false,
      width: 200,
      height: 100,
      isDrawing: false,
      drawCordX: '',
      drawCordY: '',
      context: '',
      draw: '',
      expanded: false,
    };
  },
  mounted() {
    this.setCanvasSize();
    this.context = this.$refs.canvas.getContext('2d');
  },
  methods: {
    clickToDraw(e) {
      this.setCanvasSize();
      this.drawCordX = e.layerX;
      this.drawCordY = e.layerY;
      this.isDrawing = true;
    },
    clearDraw() {
      this.context.clearRect(0, 0, this.width, this.height);
      this.draw = '';
      this.$emit('change', '');
    },
    drawing(e) {
      if (!this.isDrawing) {
        return true;
      }
      this.context.beginPath();
      this.context.lineWidth = Math.round(this.width * 0.008);
      this.context.lineCap = 'round';
      this.context.strokeStyle = 'black';
      this.context.moveTo(this.drawCordX, this.drawCordY);

      this.drawCordX = e.layerX;
      this.drawCordY = e.layerY;

      this.context.lineTo(e.layerX, e.layerY);
      this.context.stroke();
      return true;
    },
    stopDrawing() {
      this.isDrawing = false;
      this.context.canvas.toBlob((blob) => {
        this.blobToBase64(blob).then((draw) => {
          this.draw = draw;
          this.$emit('change', {
            blob,
            image: draw,
          });
        });
      });
    },
    setCanvasSize(width) {
      if ((this.$el.clientWidth === this.width || !this.context) && !width) {
        return;
      }
      const copy = this.$refs.canvas.cloneNode();
      copy
        .getContext('2d')
        .drawImage(this.$refs.canvas, 0, 0, this.width, this.height);

      this.width = width || this.$el.clientWidth;
      this.height = this.width / 2;

      setTimeout(() => {
        this.context.drawImage(copy, 0, 0, this.width, this.height);
      }, 10);
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    expand() {
      if (this.expanded) {
        this.expanded = false;
        return;
      }
      this.expanded = true;
      setTimeout(() => {
        this.setCanvasSize();
      }, 100);
    },
    getExpandIcon() {
      return !this.expanded
        ? 'up-right-and-down-left-from-center'
        : 'down-left-and-up-right-to-center';
    },
  },
};
</script>
<style lang="stylus">
.draw-area
  border: 1px solid #E5E5E5
  border-radius: 5px
  min-width: 100%
  min-height: 100px
  position: relative
  &.expanded
    position: fixed
    top: 1rem
    bottom: 1rem
    left: 1rem
    right: 1rem
    background-color: #fff
    margin: auto
    min-width: auto
    min-height: calc(100% - 30px)
    z-index: 2
    .draw-area__content
      width: inherit
      height: fit-content
      margin: auto
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
    .btn-clear
      top: 2rem
    .btn-expand
      bottom: 2rem
  .draw-area__content
    width: inherit
    height: inherit
  .btn-clear, .btn-expand
    position: absolute
    right: 1rem
    top: 1rem
    color: #949494
    z-index: 3
    font-size: 1.3rem
    background-color: #fff
  .btn-expand
    top: auto
    bottom: 1rem
  .canvas
    width: 100%
    height: 100%
    position: relative
    z-index: 2
  .line
    position: absolute
    border-top: 2px solid #aaaaaa
    top: auto
    bottom: 15%
    left: 0
    right: 0
    margin: auto 10%
    text-align: center
    padding-top: 1em
    z-index: 1
</style>
