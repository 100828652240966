<template lang="pug">
.file-list-mini
  .row
    .col-12.d-flex.flex-column-reverse.flex-md-row(
      v-if='enableSearch || enableFolderCreation'
    )
      .form-group.input-search-recipient(v-if='enableSearch')
        .form-control-icon--end-line
          input#name2.form-control(
            @keyup='onSearchItems()'
            autocomplete='off'
            placeholder='Buscar pasta'
            type='name'
            v-model.trim='queryItem'
          )
          icon(name='magnifying-glass')
      .text-center.mb-4.mb-md-0.text-md-end.create-folder--top(
        v-if='(items.data.length || parent) && enableFolderCreation'
      )
        CreateFolderButton.mt-4(
          @createFolder='createFolder'
          placeHolder='Digite o nome da pasta'
          textAfterClick='Criar'
          textBeforeClick='Nova pasta'
        )

  .row(v-if='showRoot')
    nav(aria-label='breadcrumb' style='--bs-breadcrumb-divider: ">"')
      .breadcrumb.font-size-small
        li.breadcrumb-item
          a(@click='getItems(1)') Armazenamento
        li.breadcrumb-item(v-if='parent && parent.parent')
          a(@click='getItems(1, parent.parent)') {{ parent.parent.name }}
        li.breadcrumb-item.active(style='cursor: default' v-if='parent')
          span {{ parent.name }}

  .row.files-list
    .col-6.col-sm-4.col-md-3(:key='item.id' v-for='item in rootItemsCleaner')
      .folders-list__item.mb-3(
        :title='item.name'
        @click='getItems(1, item)'
        data-placement='top'
        data-toggle='tooltip'
        v-if='item.is_directory'
      )
        icon.font-color-gray(name='folder')
        span.font-size-small.ms-2 {{ item.name }}
      .files-list__item.mb-3(
        :title='item.name'
        data-placement='top'
        data-toggle='tooltip'
        v-else
      )
        label.file-preview(
          :for='`input-${item.id}`'
          :style='`background-image: url(${item.links.preview})`'
        )
        .file-name {{ item.name }}
        input.checkbox.form-check-input(
          :checked='fileIsSelected(item)'
          :id='`input-${item.id}`'
          @click='triggerItem(item)'
          type='checkbox'
        )
    simple-paginator.mt-3(:payload='items' @navigate='getItems')

  .text-center(
    v-if='!items.data.length && enableSearch && queryItem && !parent && !isSearching'
  )
    img.img(src='/img/ilustrations/no-folder.svg')
    p.font-color-blue.mt-3 Você ainda não possui {{ textSearchWithoutResult }}.

  .text-center(v-if='!items.data.length && enableSearch && isSearching')
    img.img(
      src='/img/ilustrations/no-contacts-found.svg'
      style='max-height: 260px'
    )
    p.mt-4 Não encontramos resultados para sua pesquisa.

  .text-center(
    v-if='!parent && !items.data.length && enableFolderCreation && !queryItem'
  )
    img.img(src='/img/ilustrations/no-folder.svg')
    p.font-color-blue.mt-3 Você ainda não criou pastas.
    .creat-folder-button--center
      CreateFolderButton.mt-4(
        @createFolder='createFolder'
        placeHolder='Digite o nome da pasta'
        textAfterClick='Criar'
        textBeforeClick='Nova pasta'
      )

  .text-center(
    v-if='!parent && !items.data.length && !enableFolderCreation && !queryItem'
  )
    img.img(src='/img/ilustrations/no-files.svg')
    p.font-color-blue.mt-4 Você ainda não tem documentos ou pastas armazenados.

  .text-center(v-if='parent && !items.data.length && !isSearching')
    img.img(src='/img/ilustrations/no-files.svg')
    p.font-color-blue.mt-4 Essa pasta está vazia.
</template>
<script>
import StorageHttp from '@/services/StorageHttp';
import Icon from '@/ui/atoms/Icon.vue';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import CreateFolderButton from '@/ui/molecules/CreateFolderButton.vue';
import debounce from 'debounce';

export default {
  components: {
    Icon,
    SimplePaginator,
    CreateFolderButton,
  },
  props: {
    filterFolder: {
      type: Boolean,
      default: false,
    },
    enableFolderCreation: {
      type: Boolean,
      default: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    enableRootDirectory: {
      type: Boolean,
      default: false,
    },
    ignoreSignedItems: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: {
        data: [],
        links: {},
        meta: {},
      },
      parent: null,
      selectedFiles: [],
      queryItem: '',
      hasLoadedItens: false,
    };
  },
  watch: {
    queryItem() {
      this.hasLoadedItens = false;
    },
  },
  computed: {
    textSearchWithoutResult() {
      return this.filterFolder ? 'pasta criada' : 'pasta ou arquivo criado';
    },
    isSearching() {
      return this.queryItem.length > 0;
    },
    showRoot() {
      if (!this.enableRootDirectory) {
        return this.parent !== null;
      }
      return true;
    },
    rootItemsCleaner() {
      if (
        (this.parent === null && !this.isSearching) ||
        (this.parent === null && !this.hasLoadedItens)
      ) {
        return this.items.data.filter((item) => item.parent === null);
      }
      return this.items.data;
    },
  },
  created() {
    this.getItems(1);
  },
  methods: {
    async getItems(page, parent = null) {
      this.$loading(true);
      this.parent = parent;
      const params = {
        parent: this.parent ? this.parent.id : '',
        page,
        perPage: 16,
        q: this.queryItem,
      };
      if (this.filterFolder) {
        params['filter[mimetype]'] = 'inode/directory';
      }
      if (this.ignoreSignedItems) {
        params['filter[is_signed]'] = false;
      }
      await StorageHttp.list(params)
        .then((response) => {
          this.items = response.data;
        })
        .finally(() => {
          document.querySelector('.row.files-list').scrollTop = 0;
          this.$loading(false);
        });
      this.$emit('select-folder', {
        parent: this.parent,
        items: this.items.data,
      });
    },
    async getBreadCrumbs(parentId) {
      await StorageHttp.getItem(parentId).then((response) => {
        this.parent = response.data;
      });
    },
    triggerItem(file) {
      const hasFile = this.selectedFiles
        .filter((item) => item.id === file.id)
        .shift();
      if (hasFile) {
        this.selectedFiles = this.selectedFiles.filter(
          (item) => item.id !== file.id
        );
      } else {
        this.selectedFiles.push(file);
      }
      this.$emit('change', this.selectedFiles);
    },
    fileIsSelected(file) {
      return (
        this.selectedFiles.filter((item) => item.id === file.id).length > 0
      );
    },
    async createFolder(name) {
      await StorageHttp.createDirectory(
        name,
        this.parent ? this.parent.id : ''
      );
      this.$emit('newFoderCreated');
      this.getItems(1, this.parent);
    },
    onSearchItems: debounce(function search() {
      this.getItems(1, this.parent);
      this.hasLoadedItens = true;
    }, 300),
    cleanVariables() {
      this.parent = null;
      this.queryItem = '';
    },
  },
};
</script>
<style lang="stylus" scoped>
  .file-list-mini
    .breadcrumb-item
      cursor: pointer
    .folders-list__item
      cursor: pointer
      padding: 0.5rem 0.8rem
      border: 1px solid #CCCCCC;
      border-radius: 3px
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover
        box-shadow: 0px 3px 5px #00000012
    .files-list__item
      position: relative
      border: 1px solid #CCCCCC;
      border-radius: 3px;
      .checkbox
        cursor: pointer
        left: .25rem
        top: 0
        position: absolute
        &:focus
          border-color: rgba(0,0,0,.25)
      &:hover
        box-shadow: 0px 3px 5px #00000012
    .file-preview
      cursor: pointer
      width: 100%
      height: 90px
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background-size: cover
      background-position: initial
      background-repeat: no-repeat
    .file-name
      font-size: 0.9rem
      margin-top: -6px
      padding: 10px 7px
      overflow: hidden
      text-overflow: ellipsis
      background: #fff
      white-space: nowrap;
    .files-list
      max-height: 365px
      overflow: auto
      padding-top: 3px
    .creat-folder-button--center
      .folder-button--element-button
          left: 0rem;
        .collapsed
          left: -1.7rem;
    .create-folder--top
      width: 100%
    .input-search-recipient
      input
        width: 16.188rem
@media screen and (max-width: 768px)
  .file-list-mini
    .input-search-recipient
      width: 100%
      input
        width: 100%
    .creat-folder-button--center
      .folder-button--element-button
          left: 0rem;
        .collapsed
          left: 0rem;
</style>
