<template lang="pug">
modal#change-type-signer-modal.text-center(
  :buttonColor='buttonColor'
  :loading='loading'
  @process='$emit("process"); $refs.modal.close()'
  close-button-label='Cancelar'
  process-button-label='Sim'
  processButtonClass='btn-danger'
  ref='modal'
  size='sm'
  title='Alterar tipo de assinante'
)
  .text-start Ao alterar o tipo de assinante, a posição da
    |
    |
    | assinatura no documento será perdida.
    | Essa ação não poderá ser desfeita.
  img.mt-4.mb-3(
    alt='draft-delete-warning'
    src='/img/ilustrations/signature-failure.svg'
  )
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';

export default {
  name: 'DeleteRecipientModal',
  components: {
    Modal,
    ImageLoader,
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
    };
  },
};
</script>
