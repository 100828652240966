const config = {
  cdnPath: process.env.VUE_APP_CDN,
  authorizationServer: process.env.VUE_APP_AUTH,
  loginPath: `${process.env.VUE_APP_AUTH}/login`,
  registerPath: `${process.env.VUE_APP_AUTH}/register`,
  logoutPath: `${process.env.VUE_APP_AUTH}/logout`,
  docsUrl: process.env.VUE_APP_DOCS,
  verifyEmailPath: `${process.env.VUE_APP_AUTH}/email/verify`,
  api: {
    baseURL: `${process.env.VUE_APP_API}/v1`,
    withCredentials: true,
  },
  assine: {
    baseURL: `${process.env.VUE_APP_API}`,
    withCredentials: true,
  },
  bff: {
    baseURL: `${process.env.VUE_APP_BFF}`,
    withCredentials: true,
  },
  signPlugin: {
    pluginUrl: `${process.env.VUE_APP_SIGN_PLUGIN_URL}`,
    pluginUrlMac: `${process.env.VUE_APP_SIGN_PLUGIN_URL_MAC}`,
    api: {
      baseURL: '',
      withCredentials: false,
    },
  },
  isGTMEnabled: process.env.VUE_APP_IS_GTM_ENABLED === 'true' ? true : false,
  GTMCode: process.env.VUE_APP_GTM_CODE || 'GTM-XXXXXX',
  env: process.env.VUE_APP_ENV,
};

config.signPlugin.api.baseURL = config.signPlugin.pluginUrl;
if (window.navigator.platform.includes('Mac')) {
  config.signPlugin.api.baseURL = config.signPlugin.pluginUrlMac;
}

export default config;
