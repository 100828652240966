import BillingHttp from '@/services/BillingHttp';

export default {
  namespaced: true,
  state: {
    plan: [],
  },
  getters: {
    hasCredits: (state) => {
      const { quota } = state.plan;
      return quota.envelopes.consumed < quota.envelopes.available;
    },
  },
  actions: {
    getSubscription: ({ commit }) =>
      BillingHttp.getSubscription().then((result) => {
        commit('setSubscription', result.data);
      }),
  },
  mutations: {
    setSubscription: (state, plan) => {
      state.plan = plan;
    },
  },
};
