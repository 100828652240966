<template lang="pug">
div
  .row
  .col-12.col-sm-6.col-md-3
    .card.mb-4
      .card-body
        .row
          .col-12
            h6(style='color: #013d52; font-weight: 600') {{ plan.name }}
            .price(style='color: #01afec; margin-top: 1rem')
              //- span(style='font-weight: 600;') R$
              span(style='font-weight: 700; font-size: 1.6rem')
                | {{ plan.price ? plan.price.formatted : '' }}
              span /mês
            //- p.mb-0.text-end
              a(href='#') Trocar Plano
  .col-12
    .card
      .card-body
        .vertical-wizzard.p-5
          .form
            .step(:class='{ active: step === 1 }')
              .step-number(@click='changeStep(1)') 1
              h6.step-name Dados do usuário
              .step-content
                p Informações necessárias para a emissão da nota fiscal
                ValidationObserver(v-slot='{ handleSubmit }')
                  form(@submit.prevent='handleSubmit(checkUserData)')
                    .row
                      .col-12.col-sm-6
                        ValidationProvider(
                          name='nome'
                          rules='required'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='name') Nome
                            .form-control-icon
                              icon(name='user')
                              input#name.form-control(
                                autocomplete='off'
                                placeholder='Digite seu nome'
                                type='name'
                                v-model='userData.name'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-12.col-sm-6
                        ValidationProvider(
                          mode='passive'
                          name='email'
                          rules='required|email'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='email') Endereço de e-mail
                            .form-control-icon
                              icon(name='at')
                              input#email.form-control(
                                autocomplete='off'
                                placeholder='Digite seu e-mail'
                                type='email'
                                v-model='userData.email'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-12.col-sm-6
                        ValidationProvider(
                          mode='passive'
                          name='cpf/cnpj'
                          rules='required|cpf'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='document') CPF/CNPJ
                            .form-control-icon
                              icon(name='id-card')
                              input#document.form-control(
                                autocomplete='off'
                                placeholder='Digite seu cpf/cnpj'
                                type='document'
                                v-mask='["###.###.###-##", "##.###.###/####-##"]'
                                v-model='userData.document'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-12.col-sm-6
                        .form-group
                          label(for='phone') Telefone (opcional)
                          .form-control-icon
                            icon(name='phone')
                            input#phone.form-control(
                              autocomplete='off'
                              placeholder='Digite seu telefone'
                              type='phone'
                              v-mask='["(##) ####-####", "(##) #####-####"]'
                              v-model='userData.phone'
                            )
                      .col-12.text-end
                        button.btn.btn-primary.shadow(type='submit')
                          span.text Próximo
            .step(:class='{ active: step === 2 }')
              .step-number(@click='changeStep(2)') 2
              h6.step-name Endereço
              .step-content
                p Informações necessárias para a emissão da nota fiscal
                ValidationObserver(v-slot='{ handleSubmit }')
                  form(@submit.prevent='handleSubmit(checkAddress)')
                    .row
                      .col-12.col-sm-6
                        ValidationProvider(
                          mode='passive'
                          name='CEP'
                          rules='required|min:9'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='cep') CEP
                            .form-control-icon
                              icon(name='map-marker-alt')
                              input#cep.form-control(
                                autocomplete='off'
                                placeholder='Digite seu CEP'
                                type='cep'
                                v-mask='"#####-###"'
                                v-model='addressData.cep'
                              )
                            small.text.text-danger {{ errors[0] ? 'CEP inválido' : '' }}
                      .col-12.col-sm-6
                        ValidationProvider(
                          name='endereço'
                          rules='required'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='address') Endereço
                            .form-control-icon
                              icon(name='map-marker-alt')
                              input#address.form-control(
                                autocomplete='off'
                                placeholder='Digite seu endereço'
                                type='address'
                                v-model='addressData.endereco'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-12.col-sm-6
                        ValidationProvider(
                          name='numero'
                          rules='required'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='number') Número
                            .form-control-icon
                              icon(name='map-marker-alt')
                              input#number.form-control(
                                autocomplete='off'
                                placeholder='Digite o número'
                                type='number'
                                v-model='addressData.numero'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-12.col-sm-6
                        .form-group
                          label(for='plus') Complemento (opcional)
                          .form-control-icon
                            icon(name='map-marker-alt')
                            input#plus.form-control(
                              autocomplete='off'
                              placeholder='Digite o complemento'
                              type='plus'
                              v-model='addressData.complemento'
                            )
                      .col-12.col-sm-6
                        ValidationProvider(
                          name='bairro'
                          rules='required'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='block') Bairro
                            .form-control-icon
                              icon(name='map-marker-alt')
                              input#block.form-control(
                                autocomplete='off'
                                placeholder='Digite o bairro'
                                type='block'
                                v-model='addressData.bairro'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-12.col-sm-6
                        ValidationProvider(
                          name='cidade'
                          rules='required'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='city') Cidade
                            .form-control-icon
                              icon(name='map-marker-alt')
                              input#city.form-control(
                                autocomplete='off'
                                placeholder='Digite a cidade'
                                type='city'
                                v-model='addressData.cidade'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-12.col-sm-6
                        ValidationProvider(
                          name='estado'
                          rules='required'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='state') Estado
                            .form-control-icon
                              icon(name='map-marker-alt')
                              input#state.form-control(
                                autocomplete='off'
                                placeholder='Digite o estado'
                                type='state'
                                v-model='addressData.estado'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-12.text-end
                        button.btn.btn-primary.shadow(type='submit')
                          span.text Próximo
            .step(:class='{ active: step === 3 }')
              .step-number(@click='changeStep(3)') 3
              h6.step-name Forma de pagamento
              .step-content
                p Selecione uma opção abaixo
                ValidationObserver(v-slot='{ handleSubmit }')
                  form(@submit.prevent='handleSubmit(checkPayment)')
                    .row(style='max-width: 460px; margin: auto')
                      .col-12.mb-5
                        button.payment-method__button.shadow
                          icon(name='credit-card')
                          span.text(style='color: #777777') Cartão de crédito
                      //- .col-6
                        button.payment-method__button.shadow
                          icon(name='barcode')
                          span.text(style='color: #777777;') Boleto
                      .col-12
                        p.text-center Total {{ plan.price ? plan.price.formatted : '' }}
                      .col-12
                        ValidationProvider(
                          name='nome'
                          rules='required'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='name') Nome
                            .form-control-icon
                              icon(name='user')
                              input#name.form-control(
                                autocomplete='off'
                                placeholder='Digite seu nome'
                                type='name'
                                v-model='paymentData.name'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-7
                        ValidationProvider(
                          mode='passive'
                          name='número do cartão'
                          rules='required|credit_card'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='card-number') Número do Cartão
                            .form-control-icon
                              icon(name='credit-card')
                              input#card-number.form-control(
                                autocomplete='off'
                                placeholder='Digite o número do cartão'
                                type='card-number'
                                v-mask='"####-####-####-####"'
                                v-model='paymentData.cardNumber'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-5
                        ValidationProvider(
                          name='cvv'
                          rules='required'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='cvv') CVV
                            .form-control-icon
                              icon(name='lock')
                              input#cvv.form-control(
                                autocomplete='off'
                                placeholder='Digite o CVV'
                                type='text'
                                v-mask='"###"'
                                v-model='paymentData.cardCvv'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-6
                        ValidationProvider(
                          mode='passive'
                          name='validade mês'
                          rules='required|max_value:12'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='date-month') Validade Mês
                            .form-control-icon
                              icon(name='calendar-days')
                              input#date-month.form-control(
                                autocomplete='off'
                                placeholder='08'
                                type='text'
                                v-mask='"##"'
                                v-model='paymentData.cardValidateMonth'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-6
                        ValidationProvider(
                          name='validade ano'
                          rules='required'
                          v-slot='{ errors }'
                        )
                          .form-group
                            label(for='date-year') Validade Ano
                            .form-control-icon
                              icon(name='calendar-days')
                              input#date-year.form-control(
                                autocomplete='off'
                                placeholder='2028'
                                type='text'
                                v-mask='"####"'
                                v-model='paymentData.cardValidateYear'
                              )
                            small.text.text-danger {{ errors[0] }}
                      .col-12.text-center
                        button.btn.btn-primary.shadow(type='submit')
                          span.text(v-if='loading') Processando...
                          span.text(v-else) Finalizar compra
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import BillingHttp from '@/services/BillingHttp';
import ProductsHttp from '@/services/ProductsHttp';

export default {
  name: 'PlanPage',
  components: {
    Icon,
  },
  data() {
    return {
      plan: [],
      step: 1,
      userData: {},
      addressData: {},
      paymentData: {},
      loading: false,
      subscription: [],
      organization: '',
      step1: false,
      step2: false,
    };
  },
  methods: {
    changeStep(step) {
      switch (step) {
        case 1:
          this.step = 1;
          break;
        case 2:
          if (this.step1) {
            this.step = 2;
          }
          break;
        case 3:
          if (this.step2) {
            this.step = 3;
          }
          break;
        default:
          this.step = 1;
      }
    },
    getRequestBody() {
      const data = {
        plan: this.$route.params.planId,
        payment_method: 12,
        customer: {
          document: this.userData.document,
          name: this.userData.name,
          email: this.userData.email,
          cellphone: `+55${this.userData.phone.replace(/\D/gim, '')}`,
          zip_code: this.addressData.cep,
          address: this.addressData.endereco,
          number: this.addressData.numero,
          neighborhood: this.addressData.bairro,
          city: this.addressData.cidade,
          state: this.addressData.estado,
        },
        card: {
          holder_name: this.paymentData.name,
          expiration_month: this.paymentData.cardValidateMonth,
          expiration_year: this.paymentData.cardValidateYear,
          security_code: this.paymentData.cardCvv,
          card_number: this.paymentData.cardNumber.replace(/\D/gim, ''),
        },
      };
      return data;
    },
    checkUserData() {
      this.step1 = true;
      this.step = 2;
    },
    checkAddress() {
      this.step2 = true;
      this.step = 3;
    },
    checkPayment() {
      this.$loading(true);
      BillingHttp.updateSubscription(this.getRequestBody())
        .then((ret) => {
          if (ret.status === 200) {
            this.$router.push('/account/billing/plan');
          }
          this.step += 1;
        })
        .finally(() => {
          this.$loading(false);
        });
    },
  },
  created() {
    this.$store.dispatch('page/setTitle', 'Planos');
    this.$store.dispatch('page/setHead', { title: 'Planos' });
    this.$store.dispatch('page/setClass', 'account subscribe');
    ProductsHttp.getProductPlans(
      this.$route.params.planId,
      this.$route.params.organizationId
    ).then((ret) => {
      this.plan = ret.data;
    });
  },
};
</script>
