<template lang="pug">
.dev-doc--screen
  .row(v-if='clientList.length > 0')
    .col-12
      .text-end
        button.btn.btn-primary(
          data-bs-target='#new-developer-client-modal'
          data-bs-toggle='modal'
        ) Nova aplicação
    .col-12.mt-3
      manage-applications-card-table(
        :data='clientList'
        @listClients='listClients'
        @showClientSecret='showClientSecret'
      )
  .row(v-else)
    .col-12
      .text-center.mt-5
        img.img(
          src='/img/ilustrations/empty-applications.svg'
          style='max-height: 260px'
        )
        p.m-auto.mt-4.mb-3(style='max-width: 450px') Você ainda não possui aplicações. Comece adicionando uma agora!
        button.btn.btn-primary(
          data-bs-target='#new-developer-client-modal'
          data-bs-toggle='modal'
        ) Nova aplicação

  new-developer-client-modal(
    @refresh='listClients'
    @showClientSecret='showClientSecret'
    @showInfoModal='openCreatingAppInfoModal'
    ref='newDeveloperClientModal'
  )
  show-developer-client-secret-modal(
    :client-secret='this.clientSecret'
    :isRefreshingClientSecret='this.isRefreshingClientSecret'
    @showInfoModal='openCreatingAppInfoModal'
    ref='showDeveloperClientSecretModal'
  )
  creating-app-info-modal(ref='creatingAppInfoModal')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import OauthService from '@/services/Oauth';
import NewDeveloperClientModal from '@/modules/account/components/organisms/modals/NewDeveloperClientModal.vue';
// eslint-disable-next-line max-len
import ShowDeveloperClientSecretModal from '@/modules/account/components/organisms/modals/ShowDeveloperClientSecretModal.vue';
import TableComponent from '@/ui/organisms/Table.vue';
import IconEnviromentClient from '@/ui/atoms/IconEnviromentClient.vue';
import CreatingAppInfoModal from '@/modules/account/components/organisms/modals/CreatingAppInfoModal.vue';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
// eslint-disable-next-line max-len
import ManageApplicationsCardTable from '@/modules/account/components/organisms/card-table/ManageApplicationsCardTable.vue';

export default {
  name: 'DeveloperPage',
  components: {
    Icon,
    NewDeveloperClientModal,
    ShowDeveloperClientSecretModal,
    IconEnviromentClient,
    TableComponent,
    CreatingAppInfoModal,
    SimplePaginator,
    ManageApplicationsCardTable,
  },

  data() {
    return {
      clientList: [],
      clientSecret: '',
      isRefreshingClientSecret: false,
    };
  },
  created() {
    this.$store.dispatch('page/setTitle', 'Desenvolvedor');
    this.$store.dispatch('page/setHead', 'Desenvolvedor');
    this.$store.dispatch('page/setClass', 'account developer');
    this.listClients();
  },
  methods: {
    async listClients() {
      this.$loading(true);
      const result = await OauthService.getClients();
      this.clientList = result.data;
      this.$loading(false);
    },
    showClientSecret(clientSecret, refreshing) {
      this.clientSecret = clientSecret;
      this.isRefreshingClientSecret = refreshing;
      this.$refs.showDeveloperClientSecretModal.$refs.modal.open();
    },
    openCreatingAppInfoModal() {
      this.$refs.creatingAppInfoModal.$refs.modal.open();
    },
  },
};
</script>
<style scoped lang="stylus">
@media screen and (max-width: 768px)
  .table
    tbody
      tr
        position: relative
        display: flex
        flex-direction: row
        flex-wrap: wrap
        padding-left: 0
        td
          padding-left: 13px
          flex: 0 0 80%
          &.action
            position: absolute
            top: 35px
            right: -55px
            padding: 0
</style>
