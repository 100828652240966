<template lang="pug">
.row
  .col-12.mb-3
    ul#my-tab.nav.nav-tabs(role='tablist')
      li.nav-item(role='presentation')
        router-link.nav-link(
          :class='{ active: isTabActive("usuarios") }'
          :to='{ name: "account.users.usersguest" }'
        ) Usuários
      li.nav-item(role='presentation')
        router-link.nav-link(
          :class='{ active: isTabActive("pending-invites") }'
          :to='{ name: "account.users.pendinginvites" }'
        ) Convites pendentes
    #myTabContent.tab-content.pt-3
      router-view
</template>

<script>
export default {
  name: 'UsersPage',
  methods: {
    isTabActive(type) {
      return this.$route.name.split('.').pop() === type;
    },
  },
};
</script>
