<template lang="pug">
footer.footer
  ul.nav.justify-content-center.py-3
    li.nav-item
      a.nav-link(:href='`${linkBase}/terms`' target='_blank') Termos de uso
    li.nav-item
      a.nav-link(:href='`${linkBase}/privacy`' target='_blank') Política de privacidade
    li.nav-item
      a.nav-link(:href='`${linkBase}/cookie`' target='_blank') Política de cookies
</template>
<script>
import Config from '../../config.js';

export default {
  name: 'FooterOrganisms',
  computed: {
    linkBase() {
      if (Config.env !== 'prod') {
        return `https://auth.${Config.env}.assine.online`;
      }
      return `https://auth.assine.online`;
    },
  },
};
</script>
<style lang="stylus">
.footer
  background-color: rgba(240, 240, 240, 1)
  font-size: 0.9rem
  .nav-link
    color: rgba(1, 61, 82, 1)
    border-right: 1px solid rgba(1, 61, 82, 1)
    padding: 0 0.3rem
    font-size: 0.8rem
  .nav-item
    &:last-child
      .nav-link
        border-right: none
@media (min-width: 576px)
  .footer .nav-link
    padding: 0 1rem
    font-size: 1rem
</style>
