<template lang="pug">
modal#follow-up-envelope-modal(
  :no-footer='isPublic'
  :show-cancel-button='false'
  @process='$emit("confirm")'
  process-button-label='Ok'
  ref='modal'
  size='sm'
  title='Acompanhamento de envelope'
)
  p.justify-center Você será notificado quando novas assinaturas forem concluídas.
  .text-center.mt-4
    img.img-fluid(src='/img/ilustrations/followup-document.svg')
  buttons-login-and-create-account.mt-4(v-if='isPublic')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ButtonsLoginAndCreateAccount from '@/modules/business/components/molecules/ButtonsLoginAndCreateAccount.vue';

export default {
  name: 'FollowUpEnvelopeModal',
  components: {
    Modal,
    ButtonsLoginAndCreateAccount,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
