<template lang="pug">
modal#signature-created-modal(
  @process='$emit("process")'
  close-button-label='Criar depois'
  process-button-label='Sim'
  ref='modal'
  title='Parabéns!'
)
  p.m-0 Sua assinatura foi criada com sucesso!
  p.m-0 Agora falta criar sua <strong>Rubrica</strong>.
  p.m-0 Deseja fazer isso agora?
  .mt-2.text-center
    img.img-fluid(src='/img/ilustrations/signature-feedback.svg')
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  components: {
    Modal,
  },
};
</script>
