<template lang="pug">
.download-page
  .text-center
    img(src='/img/ilustrations/mailbox-download.svg')
  .text-center.mt-4.mb-3
    template(v-if='documents.length > 1')
      button.btn.btn-thin.mb-3.me-md-2(
        @click='downloadDocumentsBackgroundSheet'
      ) Baixar páginas de assinaturas
      button.btn.btn-primary.mb-3.ms-md-2(@click='downloadDocuments') Baixar documentos assinados
    template(v-else)
      a(
        :href='documents[0].links.background_sheet'
        download
        target='_blank'
      )
        button.btn.btn-thin.mb-3.me-md-2 Baixar página de assinaturas
      a(
        :href='documents[0].links.download'
        download
        target='_blank'
      )
        button.btn.btn-primary.mb-3.ms-md-2 Baixar documento assinado

  h6.h-title Documentos
  template(v-for='doc in documents')
    .card.card--file.mb-2(:key='doc.id')
      .card-body.d-flex
        icon.icon.me-2(name='file')
        .name {{ doc.name }}
        .dropdown.ms-auto
          button#dropdownMenuButton1.dropdown-toggle.btn-actions(
            aria-expanded='false'
            data-bs-toggle='dropdown'
            type='button'
          )
            icon(name='ellipsis-vertical')
          ul.dropdown-menu(aria-labelledby='dropdownMenuButton1')
            li
              a.dropdown-item(
                :href='doc.links.download'
                download
                target='_blank'
              ) Baixar documento assinado
            li
              a.dropdown-item(
                :href='doc.links.background_sheet'
                download
                target='_blank'
              ) Baixar página de assinaturas

  h6.h-title.mt-4 Detalhes do envelope
  .card
    .card-body
      ul.list-unstyled.m-0
        li.mb-1 Enviado em: {{ this.envelope.created_at | date }}
        li.mb-1 Prazo de assinatura até:
          span.ms-1
            template(v-if='this.envelope.expire_at') {{ this.envelope.expire_at | date }}
            template(v-else) Sem prazo
        li.mb-1 Vencimento do envelope em:
          span.ms-1
            template(v-if='this.envelope.effective_date_reminder_at')
              | {{ this.envelope.effective_date_reminder_at | date }}
            template(v-else) Sem Vencimento
        li Status do envelope:
          |
          IconStatus.ms-2.me-1(:iconStatus='this.envelope.state')
          span.text {{ $t(this.envelope.state) }}

  h6.h-title.mt-4 Destinatários
  .card
    .card-body.p-0
      template(v-for='recipient in recipients')
        ul.m-0.p-3.recipient-item.list-unstyled(:key='recipient.id')
          li
            span(style='font-weight: 600') {{ recipient.addressees[0].name }}
          li(v-if='recipient.addressees[0].via !== "face-to-face"') {{ recipient.addressees[0].value }}
          template(v-for='(identifier, index) in recipient.identifiers')
            li(v-if='identifier.value') {{ identifier.title }}: {{ identifier.value }}
          li
            IconStatus.me-1(:iconStatus='recipient.state')
            span.text {{ $t(recipient.state) }}
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import RecipientsService from '@/modules/business/services/http/envelopes/RecipientsService';
import EnvelopeDownloadsFactory from '@/modules/business/common/EnvelopeDownloads/EnvelopeDownloadsFactory';

export default {
  name: 'DownloadEnvelopePage',
  components: {
    Icon,
    IconStatus,
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    isPublic() {
      return this.$route.name.split('.').shift() === 'public';
    },
  },
  data() {
    return {
      envelope: {},
      documents: [],
      recipients: [],
    };
  },
  async created() {
    const pageTitle = 'Envelope concluído';
    this.$loading(true);
    this.$store.dispatch('page/setClass', 'envelope-download');
    this.$store.dispatch('page/setTitle', pageTitle);
    const head = {
      title: pageTitle,
    };
    if (!this.isPublic) {
      head.action = () => {
        this.$router.push({ name: 'business.documents.inbox' });
      };
    }
    this.$store.dispatch('page/setHead', head);
    await this.getEnvelopeData(this.token);
    this.$loading(false);
  },
  methods: {
    async getEnvelopeData(token) {
      const res = await RecipientsService.listEspecificRecipientEnvelope(token);
      this.envelope = res.data.envelope;
      this.documents = res.data.envelope.documents;
      this.recipients = res.data.envelope.recipients;
    },
    async downloadDocuments() {
      const envelopeDownloads =
        EnvelopeDownloadsFactory.createEnvelopeDownloads(this.token, 'public');

      await envelopeDownloads.downloadDocuments(this.envelope.title);
    },
    async downloadDocumentsBackgroundSheet() {
      const envelopeDownloads =
        EnvelopeDownloadsFactory.createEnvelopeDownloads(this.token, 'public');

      await envelopeDownloads.downloadSignaturePage(this.envelope.title);
    },
  },
};
</script>
<style lang="stylus">
.download-page
  padding-bottom: 5rem
  .card--file
    .icon
      color: #CCCCCC
      margin-top: 4px
    .btn-actions
      width: 1.2em
      height: 1.5em
      .icon
        color: rgb(85, 85, 85)
        font-size: 1.2rem
        display: contents
  .recipient-item
    border-bottom: 1px solid #CCCCCC
    &:last-child
      border-bottom: none
</style>
