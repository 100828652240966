<template lang="pug">
.organization-menu-block
  .select-organization__block
    .dropdown
      button#selectOrganization.dropdown-toggle(data-bs-toggle='dropdown')
        .select-organization-title
          .simple-sidebar-organization
            icon(name='sitemap')
            span.text {{ selectedOrganizationName }}
            icon(name='angle-down')
      .dropdown-menu.shadow(aria-labelledby='productsDropdown')
        services-select(
          :items='organizations'
          :user='$root.user'
          v-on:change='changeOrganizaton'
        )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import ServicesSelect from '@/ui/molecules/ServicesSelect.vue';

export default {
  components: {
    Icon,
    ServicesSelect,
  },
  props: {
    organizations: Array,
    currentOrganization: [Object, Boolean],
  },
  data() {
    return {
      showBallon: false,
    };
  },
  computed: {
    selectedOrganizationName() {
      return this.currentOrganization.name
        ? this.currentOrganization.name
        : this.$root.user.name;
    },
  },
  methods: {
    changeOrganizaton(event) {
      this.$store.dispatch('organizations/setSelected', event);
    },
  },
};
</script>
<style lang="stylus" scoped>
.simple-sidebar__header
  .organization-menu-block
    .dropdown
      button
        height: 30px
    .simple-sidebar-organization
      color: #013D52
      span
        display: inline-block
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        margin-right: 0.625rem
        margin-left: 0.625rem
      .icon
        font-size: 1rem
</style>
