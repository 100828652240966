import Vue from 'vue/dist/vue';
import AlertSnackBar from '@/ui/molecules/AlertSnackBar.vue';

Vue.component('alert-snack-bar', AlertSnackBar);

function fireAlert(message, options = {}) {
  const margin = 15;
  const approximateContainerSize = 100;
  const prefix = 'application_alert_';
  const elementContainer =
    options.elementContainer ?? document.querySelector('body');
  const tag = options.tag ?? 'default_';
  const classes = options.classes ?? 'alert-info';
  const zIndex = options.zIndex ?? '10';
  const icon = options.icon ?? '';
  const styles =
    options.styles ??
    'color: #004085; background-color: #cce5ff; border-color: #b8daff;';

  const alertsElements = document.querySelectorAll(`[id^='${prefix}']`);
  const alertNumber = alertsElements.length;
  const id = prefix + tag + alertNumber;
  const positionY =
    150 +
    (alertNumber * (alertsElements[0]?.offsetHeight ?? 0) + margin) -
    approximateContainerSize;

  elementContainer.insertAdjacentHTML(
    'beforeend',
    `
  <div
    id="${id}"
    style="position: fixed; z-index: ${zIndex};
    right: ${margin}px;
    top: ${positionY}px;"
  >
  <alert-snack-bar
    icon="${icon}"
    class="${classes} alert-dismissible fade show"
    styles="z-index: 9999; ${styles};"
    text="${message}"
  >
  </alert>
  </div>`
  );
  // eslint-disable-next-line no-new
  new Vue({
    el: `#${id}`,
    data: {
      iconsCache: {},
    },
  });

  window.jQuery(`#${id} div`).on('closed.bs.alert', () => {
    document.querySelector(`#${id}`).remove();
  });

  window.setTimeout(() => {
    window.jQuery(`#${id} div`).alert('close');
  }, 1000 * 10);
}

export default { fireAlert };
