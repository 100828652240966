import { isCreditCard } from 'validator';

const rules = {
  credit_card: {
    validate: (val) => isCreditCard(val),
    message: 'Cartão de crédito inválido.',
  },
  cpf: {
    validate: (val) => {
      if (val.replace(/\D/g, '').length >= 11) {
        return true;
      }
      return false;
    },
    message: 'Cpf/cnpj inválido',
  },
  invalidDate: {
    validate: (val) => {
      if (val !== 'invalidDate') {
        return true;
      }
      return false;
    },
    message: 'A data inserida deve ser maior que o dia atual',
  },
  intBrazilianPhone: {
    validate: (val) => {
      if (val.replace(/\D/g, '').length >= 13) {
        return true;
      }
      return false;
    },
    message: 'Inserir telefone no formato (00) 0000-0000',
  },
  url: {
    validate: (val) => {
      const urlRegexp =
        // eslint-disable-next-line no-useless-escape
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,63}\.[a-zA-Z0-9()]{1,63}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

      return urlRegexp.test(val);
    },
    message: 'URL inválida',
  },
};

export default rules;
