<template lang="pug">
modal#no-subscriber-modal(
  :showCancelButton='false'
  @process='closeModal'
  process-button-label='Ok'
  ref='modal'
  size='sm'
  title='Atenção'
)
  .text É necessário ter ao menos um assinante para configurar um envelope.
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/mailbox-no-subscriber.svg')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'NoSubscriberModal',
  components: {
    Modal,
  },
  methods: {
    async closeModal() {
      this.$refs.modal.close();
    },
  },
};
</script>
