import Http from '@/services/Http';

const path = 'p/envelopes';
const envelopes = new Http(path);

envelopes.download = (envelopeId) => {
  const config = {
    responseType: 'blob',
  };
  return envelopes.get('', '', `/${envelopeId}/download`, config);
};

envelopes.downloadBackgroundSheet = (envelopeToken) => {
  const config = {
    responseType: 'blob',
  };
  return envelopes.get(
    '',
    '',
    `/${envelopeToken}/background-sheet/download`,
    config
  );
};

envelopes.getCessSettings = (token) => {
  return envelopes.get('', '', `/recipients/${token}/cess/license`);
};

envelopes.getSignableHashes = (
  token,
  x509,
  latitude = null,
  longitude = null
) => {
  return envelopes.post(
    {
      certificates: [x509],
      latitude,
      longitude,
    },
    `${path}/recipients/${token}/generate-signable-hashes`
  );
};

export default envelopes;
