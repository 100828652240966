import ProfilePage from '@/modules/account/pages/Profile.vue';
import ContactsPage from '@/modules/account/pages/Contacts.vue';
import BillingPage from '@/modules/account/pages/Billing.vue';
import PlanPage from '@/modules/account/pages/billing/Plan.vue';
import SubscriptionPage from '@/modules/account/pages/Subscription.vue';
import DeveloperPage from '@/modules/account/pages/developer/DeveloperPage.vue';
import ManageApplications from '@/modules/account/pages/developer/ManageApplications.vue';
import DeveloperDocumentation from '@/modules/account/pages/developer/DeveloperDocumentation.vue';
import ConfigurationPage from '@/modules/account/pages/Configuration.vue';
import AccountLayout from '@/ui/layouts/Account.vue';
import UsersPage from '@/modules/account/pages/users/UsersPage.vue';
import PendingInvites from '@/modules/account/pages/users/PendingInvites.vue';
import UsersGuest from '@/modules/account/pages/users/UsersGuest.vue';

export default {
  name: 'people',
  routes: [
    {
      path: '/account',
      component: AccountLayout,
      redirect: {
        name: 'account.profile',
      },
      children: [
        {
          path: '/account/profile',
          name: 'account.profile',
          component: ProfilePage,
        },
        {
          path: '/account/contacts',
          name: 'account.contacts',
          component: ContactsPage,
        },
        {
          path: '/account/billing',
          name: 'account.billing',
          component: BillingPage,
        },
        {
          path: '/account/billing/plan',
          name: 'account.billing.plan',
          component: PlanPage,
        },
        {
          path: '/account/billing/subscription/:planId/:organizationId',
          name: 'account.billing.subscription',
          component: SubscriptionPage,
        },
        {
          path: '/account/developer',
          name: 'account.developer',
          component: DeveloperPage,
          redirect: { name: 'account.developer.manageapplications' },
          children: [
            {
              path: 'manageapplications',
              name: 'account.developer.manageapplications',
              component: ManageApplications,
            },
            {
              path: 'documentation',
              name: 'account.developer.documentation',
              component: DeveloperDocumentation,
            },
          ],
        },
        {
          path: '/account/configuration',
          name: 'account.configuration',
          component: ConfigurationPage,
        },
        {
          path: '/account/users/',
          name: 'account.users',
          component: UsersPage,
          redirect: { name: 'account.users.usersguest' },
          children: [
            {
              path: 'usersguest',
              name: 'account.users.usersguest',
              component: UsersGuest,
            },
            {
              path: 'pendinginvites',
              name: 'account.users.pendinginvites',
              component: PendingInvites,
            },
          ],
        },
      ],
    },
  ],
};
