function treatFormErrors(vueElement, error, dictFieldNamesByResponseKeys) {
  const responseKeys = Object.keys(dictFieldNamesByResponseKeys);
  for (let i = 0; i < responseKeys.length; i += 1) {
    const responseKey = responseKeys[i];
    const fieldName = dictFieldNamesByResponseKeys[responseKey];
    if (error.response.data.errors[responseKey] !== undefined) {
      if (vueElement.$refs[fieldName] !== undefined) {
        vueElement.$refs[fieldName].applyResult({
          errors: error.response.data.errors[responseKey],
          valid: false,
          failedRules: {},
        });
      } else if (vueElement.$refs.form !== undefined) {
        const errorsToSet = {};
        errorsToSet[fieldName] = error.response.data.errors[responseKey];
        vueElement.$refs.form.setErrors(errorsToSet);
      } else {
        // eslint-disable-next-line no-param-reassign
        vueElement.errors[fieldName] = error.response.data.errors[responseKey];
      }
    }
  }
}

export default { treatFormErrors };
