import Http from '@/services/Http';

const path = 'organizations';
const client = new Http(path);

client.list = () => client.get();

client.updateLogo = (organizationId, blob) => {
  const formData = new FormData();
  formData.append('logo', blob);
  return client.post(formData, `/organizations/${organizationId}/logo`);
};

client.deleteLogo = (organizationId) =>
  client.delete('', '', `/${organizationId}/logo`);

client.getOrganization = (id) => client.get(id);

client.deleteOrganization = (organizationId, params) =>
  client.delete(organizationId, params, path);

export default client;
