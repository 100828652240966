<template lang="pug">
modal#delete-developer-client-modal(
  :loading='loading'
  @process='submit'
  close-button-label='Cancelar'
  process-button-label='Excluir'
  processButtonClass='btn-danger'
  ref='modal'
  title='Excluir aplicação'
)
  .text-center
    div(v-if='isSandbox')
      p.text-start Ao excluir a aplicação no ambiente Sandbox, os usuários do sistema perderão
        | &nbsp;suas permissões de acesso. Os documentos e envelopes não serão excluídos de imediato.
      p.text-start Nesse ambiente eles expiram após 7 dias de sua criação e então serão deletados.
      p.text-start Essa ação não poderá ser desfeita.
    div(v-else)
      p.text-start Ao excluir a aplicação no ambiente de produção,
        | &nbsp;os usuários do sistema perderão suas permissões de acesso. Os
        | &nbsp;documentos e os demais dados salvos no sistema não serão excluídos.
      p.text-start Essa ação não poderá ser desfeita.

    img.mt-4.mb-3(
      alt='developer-delete-warning'
      src='/img/ilustrations/developer-delete-warning.svg'
    )
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import Icon from '@/ui/atoms/Icon.vue';
import OauthService from '@/services/Oauth';

export default {
  name: 'DeleteDeveloperClientModal',
  props: {
    clientId: [Number, String],
    isSandbox: Boolean,
  },
  components: {
    Modal,
    Icon,
    ImageLoader,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    modalTitle() {
      return this.title;
    },
  },
  methods: {
    submit() {
      this.loading = true;
      OauthService.deleteClient(this.clientId).then(() => {
        this.loading = false;
        this.$refs.modal.close();
        this.$emit('refresh');
      });
    },
  },
};
</script>
