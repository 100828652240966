<template lang="pug">
modal#remove-invite-modal(
  :buttonColor='buttonColor'
  :close='close'
  :loading='loading'
  :title='modalTitle || "Excluir convite"'
  @closed='onCloseModal'
  @opened='onOpenModal'
  @process='removeInvite'
  close-button-label='Cancelar'
  process-button-label='Excluir'
)
  .span.text Ao excluir um convite, a ação não poderá ser desfeita.
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import InvitationsService from '@/modules/business/services/http/organizations/invitations/InvitationsService';
import formHandler from '@/modules/account/common/formHandler';
import alert from '@/modules/account/common/alert';

export default {
  name: 'RemoveInviteUserModal',
  props: {
    organizationId: [Number, String],
    userEmail: String,
  },
  components: {
    Modal,
    InvitationsService,
  },
  data() {
    return {
      loading: false,
      close: false,
      buttonColor: true,
      name: '',
      organization: {},
    };
  },

  computed: {
    modalTitle() {
      return this.title;
    },
  },

  methods: {
    onCloseModal() {
      this.organization = {};
      this.name = '';
      this.logo = '';
      this.close = false;
      this.loading = true;
    },

    onOpenModal() {
      this.loading = false;
    },

    async removeInvite() {
      formHandler.submit(
        this,
        () => InvitationsService.remove(this.userEmail),
        {
          successHandler: (response) => {
            if (response.status === 204) {
              this.close = true;
              this.loading = false;
              alert.fireAlert('Convite removido com sucesso', {
                classes: 'alert-success',
                styles:
                  'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
                icon: 'circle-check',
              });
              this.$emit('refresh');
            }
          },
        }
      );
    },
  },
};
</script>
