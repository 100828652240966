<template lang="pug">
.nav.top-bar.my-3.font-size-title(v-if='pageHead')
  .d-flex.align-items-center(v-if='actionIsValid')
    a.me-1(@click='pageHead.action' v-if='isFunction(pageHead.action)')
      icon(name='chevron-left')
    router-link.pe-2(:to='pageHead.action' v-else)
      icon(name='chevron-left')
  span {{ pageHead.title }}
  span.help.px-2(
    @click='pageHead.help'
    v-if='helpIsValid'
    v-tooltip:right='`Precisa de ajuda? Clique para visualizar o nosso tutorial`'
  )
    icon.fs-6(name='circle-question')
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'TopPageBar',
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    pageHead() {
      return this.$store.state.page.head;
    },
    sideBar() {
      return this.$store.state.page.sideBar;
    },
    actionIsValid() {
      return (
        this.pageHead.action &&
        (this.isRoute(this.pageHead.action) ||
          this.isFunction(this.pageHead.action))
      );
    },
    helpIsValid() {
      return (
        this.pageHead.help &&
        (this.isRoute(this.pageHead.help) ||
          this.isFunction(this.pageHead.help))
      );
    },
  },
  methods: {
    isRoute(action) {
      return action instanceof Object;
    },
    isFunction(action) {
      return action instanceof Function;
    },
  },
};
</script>
<style scoped lang="stylus">
.nav.top-bar
  display: flex
  align-items: center
  color: #5C7A84
  font-weight: 600
  a
    cursor: pointer
    color: #013D52
    line-height: 1em
    &:hover
      text-decoration: none
  span.help
    margin-top: -2px
    cursor: pointer
</style>
