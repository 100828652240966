<template lang="pug">
#edit-user-modal.modal(
  :close='close'
  :loading='loading'
  @closed='onCloseModal'
  @opened='onOpenModal'
)
  .modal-dialog
    .modal-content
      form
      .modal-header
        h5.modal-title Editar usuário
        button.close(
          aria-label='Close'
          data-bs-dismiss='modal'
          type='button'
        )
          span(aria-hidden='true') &times;
      .modal-body
        .form-group
          label(for='exampleInputEmail') Endereço de e-mail
          .form-control-icon
            icon(name='at')
            input#exampleInputEmail.form-control(
              disabled
              placeholder='E-mail do usuário'
              type='text'
              v-model='user.email'
            )
        .form-group
          label.label--dark-blue(for='roles') Funções
            .row(v-if='user.roles')
              .col-12.col-sm-6(:key='role.id' v-for='role in user.roles')
                a(:href='`business/roles/edit/${role.id}`')
                  .span.text-decoration-underline.text--light-blue.p-2 {{ role.name }}
        .form-group
          label.label--dark-blue(for='teams') Times
            .row(v-if='user.teams')
              .col-12.col-sm-6(:key='team.id' v-for='team in user.teams')
                a(:href='`business/teams/edit/${team.id}`')
                  .span.text-decoration-underline.text--light-blue.p-2 {{ team.name }}
        .form-group
          label.label--dark-blue(for='access') Personalizar acesso
            .row(v-if='user.permissions')
              .col-12.col-sm-6.pt-2
                .form-check
                  input#check-1.form-check-input(
                    type='checkbox'
                    v-model='user.permissions.edit_organization'
                    value='1'
                  )
                  label.form-check-label(for='check-1') Editar Organizações
              .col-12.col-sm-6.pt-2
                .form-check
                  input#check-2.form-check-input(
                    type='checkbox'
                    v-model='user.permissions.manage_users'
                    value='2'
                  )
                  label.form-check-label(for='check-2') Gerenciar Usuários
              .col-12.col-sm-6.pt-2
                .form-check
                  input#check-3.form-check-input(
                    type='checkbox'
                    v-model='user.permissions.manage_teams'
                    value='3'
                  )
                  label.form-check-label(for='check-3') Gerenciar Times
              .col-12.col-sm-6.pt-2
                .form-check
                  input#check-4.form-check-input(
                    type='checkbox'
                    v-model='user.permissions.send_envelopes'
                    value='4'
                  )
                  label.form-check-label(for='check-4') Pode enviar envelopes
              .col-12.col-sm-6.pt-2
                .form-check
                  input#check-5.form-check-input(
                    type='checkbox'
                    v-model='user.permissions.manage_billing'
                    value='5'
                  )
                  label.form-check-label(for='check-5') Gerenciar faturamento

      .modal-footer
        button.btn.btn-danger(
          @click='removeUser'
          data-bs-dismiss='modal'
          type='button'
        )
          span.text Excluir usuário
        button.btn.btn-secondary(
          @click='editUser'
          data-bs-dismiss='modal'
          type='button'
        )
          span.text Salvar
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import Icon from '@/ui/atoms/Icon.vue';
import TeamsService from '@/modules/business/services/http/organizations/teams/TeamsService';
import UsersService from '@/modules/business/services/http/organizations/users/UsersService';
import formHandler from '@/modules/account/common/formHandler';

export default {
  name: 'EditUserModal',
  components: {
    Modal,
    Icon,
  },
  props: {
    teamId: [Number, String],
    userId: [Number, String],
    operation: Boolean,
  },
  data() {
    return {
      user: '',
      loading: false,
      close: false,
    };
  },

  methods: {
    onCloseModal() {
      this.close = false;
      this.loading = true;
    },
    onOpenModal() {
      this.loading = false;
    },
    async removeUser() {
      this.loading = true;
      if (this.operation) {
        await UsersService.remove(this.userId).then(() => {
          this.$emit('refresh');
          this.loading = false;
        });
      } else {
        await TeamsService.deleteUser(this.teamId, this.userId).then(() => {
          this.$emit('refresh');
          this.loading = false;
        });
      }
    },

    async editUser() {
      const payload = {
        send_envelopes: this.user.permissions.send_envelopes,
        attach_organizations: this.user.permissions.attach_organizations,
        edit_organization: this.user.permissions.edit_organization,
        manage_teams: this.user.permissions.manage_teams,
        manage_users: this.user.permissions.manage_users,
        manage_billing: this.user.permissions.manage_billing,
      };

      formHandler.submit(
        this,
        () => UsersService.changePermissions(this.userId, payload),
        {
          successHandler: (response) => {
            formHandler.defaultSuccess(response);
            this.close = true;
            this.loading = false;
            window.jQuery('#invite-user-modal').modal('hide');
          },
        }
      );
    },

    async setUser() {
      const user = await UsersService.listExtended(this.userId);
      this.user = user.data;
    },
  },
  watch: {
    userId() {
      this.setUser();
    },
  },
};
</script>
