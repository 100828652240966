<template lang="pug">
.draw-area-mobile(:class='{ expanded: expanded }')
  .draw-area-mobile__content
    button.btn-back(@click='close()')
      icon(name='chevron-left')
    button.btn-clear(@click='clearDraw()' v-if='draw')
      icon(name='trash-can')
    p.intro Desenhe a {{ label }} com os dedos ou utilize uma caneta especial.
    .line Desenhe sua <span class="text-lowercase"> {{ label }}</span>
    .canvas-block
      canvas.canvas(
        :height='`${height}`'
        :style='{ width: `${width}px`, height: `${height}px` }'
        :width='`${width}`'
        ref='canvas'
        v-on:mousedown='clickToDraw($event.layerX, $event.layerY)'
        v-on:mousemove='drawing($event.layerX, $event.layerY)'
        v-on:mouseup='stopDrawing()'
        v-on:touchend='onTouchEnd'
        v-on:touchmove='onTouchMove'
        v-on:touchstart='onTouchStart'
      )
    button.btn-apply.btn.btn-primary(@click='apply') Aplicar
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      default: 'Assinatura',
    },
  },
  data() {
    return {
      ready: false,
      width: 200,
      height: 100,
      isDrawing: false,
      drawCordX: '',
      drawCordY: '',
      context: '',
      draw: '',
      blob: '',
      expanded: false,
    };
  },
  mounted() {
    this.setCanvasSize();
    this.context = this.$refs.canvas.getContext('2d');
  },
  methods: {
    onTouchMove(e) {
      const coord = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY,
      };
      this.drawing(coord.x, coord.y);
    },
    onTouchStart(e) {
      const coord = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY,
      };
      this.clickToDraw(coord.x, coord.y);
    },
    onTouchEnd() {
      this.drawing(this.drawCordX, this.drawCordY);
      this.stopDrawing();
    },
    clickToDraw(x, y) {
      this.setCanvasSize();
      this.drawCordX = x;
      this.drawCordY = y;
      this.isDrawing = true;
    },
    clearDraw() {
      this.context.clearRect(0, 0, this.width, this.height);
      this.draw = '';
    },
    drawing(x, y) {
      if (!this.isDrawing) {
        return true;
      }
      this.context.beginPath();
      this.context.lineWidth = Math.round(this.width * 0.008);
      this.context.lineCap = 'round';
      this.context.strokeStyle = 'black';
      this.context.moveTo(this.drawCordX, this.drawCordY);

      this.drawCordX = x;
      this.drawCordY = y;

      this.context.lineTo(x, y);
      this.context.stroke();
      return true;
    },
    stopDrawing() {
      this.isDrawing = false;
      this.context.canvas.toBlob((blob) => {
        this.blob = blob;
        this.blobToBase64(blob).then((draw) => {
          this.draw = draw;
        });
      });
    },
    setCanvasSize(width) {
      if ((this.$el.clientWidth === this.width || !this.context) && !width) {
        return;
      }
      this.width = width || this.$el.clientWidth;
      this.height = this.width / 2;
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    expand() {
      if (this.expanded) {
        this.expanded = false;
        window.screen.orientation.lock('portrait');
        window.document.webkitExitFullscreen();
        return;
      }
      window.document.querySelector('.draw-area-mobile').requestFullscreen();
      window.screen.orientation.lock('landscape').then(() => {
        this.expanded = true;
        this.setCanvasSize();
        setTimeout(() => {
          this.setCanvasSize();
        }, 1500);
      });
    },
    apply() {
      if (!this.draw) {
        return;
      }
      this.$emit('change', {
        blob: this.blob,
        image: this.draw,
      });
      this.expand();
    },
    close() {
      this.expand();
    },
  },
};
</script>
<style lang="stylus">
.draw-area-mobile
  border: 1px solid #E5E5E5
  background-color: #fff
  border-radius: 5px
  min-width: 100%
  min-height: 100px
  position: relative
  &.expanded
    position: fixed
    top: 1rem
    bottom: 1rem
    left: 1rem
    right: 1rem
    background-color: #E5E5E5
    margin: auto
  .btn-clear
    top: 15%
    right: 9%
  .draw-area-mobile__content
    text-align: center
  .intro
    text-align: center
    margin: 1em
    position: absolute
    z-index: 2
    width: 100%

  .canvas-block
    width: 100%
  .canvas
    width: 100%
    height: 100%
    position: relative
    background-color: #fff
    margin: auto
  .line
    position: absolute
    border-top: 2px solid #aaaaaa
    top: auto
    bottom: 20%
    left: 0
    right: 0
    margin: auto 10%
    text-align: center
    padding-top: 1em
    z-index: 3
  .btn-apply
    width: 40%
    display: block
    margin: auto
    position: absolute
    left: 0
    right: 0
    z-index: 3
    bottom: 1em
  .btn-back
    position: absolute
    left: 1em
    top: 1em
    color: #013D52
    z-index: 3
    width: 2em
    height: 2em
  .btn-clear
    position: absolute
    right: 1rem
    top: 1rem
    color: #949494
    z-index: 3
    font-size: 1.3rem
    background-color: #fff
</style>
