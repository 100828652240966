<template lang="pug">
modal#new-team-modal(
  :loading='loading'
  @closed='onCloseModal'
  @process='addTeam'
  close-button-label='Cancelar'
  process-button-label='Criar'
  ref='modal'
  title='Novo time'
)
  .form-group.mb-0
    label(for='exampleInputTeamName') Nome do time
    ValidationProvider(
      mode='passive'
      name='nome do time'
      ref='time'
      rules='required'
      v-slot='{ errors }'
    )
      .form-control-icon
        icon(name='users')
        input#teamName.form-control(
          :class='{ "border border-danger": errors[0] }'
          placeholder='Digite um nome para o time'
          type='text'
          v-model='teamName'
        )
      small.text.text-danger {{ errors[0] }}
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import Icon from '@/ui/atoms/Icon.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import '@/services/Validator';
import TeamsService from '@/modules/business/services/http/organizations/teams/TeamsService';
import formHandler from '@/modules/account/common/formHandler';

export default {
  name: 'NewTeamModal',
  components: {
    Modal,
    Icon,
    ImageLoader,
  },
  data() {
    return {
      loading: false,
      teamName: '',
    };
  },
  methods: {
    async addTeam() {
      this.loading = true;
      formHandler.submit(this, () => TeamsService.create(this.teamName), {
        fieldsByPropertyName: {
          name: 'time',
        },
        successHandler: (response) => {
          formHandler.defaultSuccess(response);
          this.$emit('refresh');
          this.teamName = '';
          this.$refs.modal.close();
        },
        elementToValidate: this.$refs.time,
      });
      this.loading = false;
    },
    onCloseModal() {
      this.$refs.time.reset();
    },
  },
};
</script>
