<template lang="pug">
modal#invite-user-modal(
  :loading='loading'
  :title='modalTitle || "Convidar usuário"'
  @closed='onCloseModal'
  @opened='onOpenModal'
  @process='submit'
  close-button-label='Cancelar'
  process-button-label='Convidar'
  ref='modal'
)
  ValidationObserver(ref='form' v-slot='{ handleSubmit }')
    form(@submit.prevent='handleSubmit(submit)')
      ValidationProvider(
        name='e-mail'
        rules='required|email'
        v-slot='{ errors }'
      )
        .form-group
          label(for='exampleInputEmail') Endereço de e-mail *
          .form-control-icon
            icon(name='at')
            input#email.form-control(
              :class='{ "border border-danger": errors[0] }'
              placeholder='Digite um e-mail ou mais'
              type='text'
              v-model.trim='email'
            )
          small.text.text-danger {{ errors[0] }}

      ValidationProvider(
        name='função'
        rules='required'
        v-slot='{ errors }'
      )
        .form-group
          label(for='role') Funções
          select#role.form-select.form-select-lg.mb-8(
            :class='{ "border border-danger": errors[0] }'
            v-model='rolesUser'
          )
            option(
              :key='role.id'
              :value='role.id'
              v-for='role in roles'
            ) {{ role.name === 'Usuário' ? 'Usuário padrão' : role.name }}
          small.text.text-danger {{ errors[0] }}

      ValidationProvider(name='time' v-slot='{ errors }')
        .form-group
          label(for='team') Time
          select#team.form-select.form-select-lg.mb-8(
            :class='{ "border border-danger": errors[0] }'
            v-model='team'
          )
            option(
              :key='team.id'
              v-bind:value='team.id'
              v-for='team in teams'
            ) {{ team.name }}
          small.text.text-danger {{ errors[0] }}
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import Icon from '@/ui/atoms/Icon.vue';
import '@/services/Validator';
import InvitationsService from '@/modules/business/services/http/organizations/invitations/InvitationsService';
import RolesService from '@/modules/business/services/http/organizations/roles/RolesService';
import TeamsService from '@/modules/business/services/http/organizations/teams/TeamsService';
import formHandler from '@/modules/account/common/formHandler';

export default {
  name: 'InviteUserModal',
  props: {
    title: String,
  },
  components: {
    Modal,
    Icon,
    InvitationsService,
    TeamsService,
    RolesService,
  },
  data() {
    return {
      loading: false,
      name: '',
      logo: '',
      organization: {},
      teams: '',
      email: [],
      team: [],
      rolesUser: [],
      roles: null,
    };
  },
  mounted() {
    this.listTeams();
    this.listRoles();
  },

  computed: {
    modalTitle() {
      return this.title;
    },
  },

  watch: {
    roles() {
      this.roles.forEach((role) => {
        if (role.name === 'Usuário') {
          this.rolesUser = role.id;
        }
      });
    },
  },

  methods: {
    onCloseModal() {
      this.organization = {};
      this.name = '';
      this.logo = '';
      this.loading = true;
      this.cleanInvitation();
    },

    onOpenModal() {
      this.loading = false;
    },

    cleanInvitation() {
      this.email = null;
      this.team = null;

      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },

    async listTeams() {
      await TeamsService.list().then((response) => {
        this.teams = response.data.data;
      });
    },

    async listRoles() {
      await RolesService.list().then((response) => {
        this.roles = response.data.data;
      });
    },

    async submit() {
      const payload = {
        emails: [this.email],
        role: [this.rolesUser],
        team: [this.team],
      };

      formHandler.submit(
        this,
        () => InvitationsService.inviteUsersToOrganization(payload),
        {
          fieldsByPropertyName: {
            'emails.0': 'e-mail',
            roles: 'permissão',
            teams: 'time',
          },
          successHandler: (response) => {
            formHandler.defaultSuccess(response);
            this.cleanInvitation();
            this.$refs.modal.close();
            this.$emit('refresh');
          },
        }
      );
    },
  },
};
</script>
