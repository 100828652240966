<template lang="pug">
modal#show-signature-failure-modal(
  :show-cancel-button='false'
  @process='closeModal'
  process-button-label='Tente novamente'
  ref='modal'
  title='Falha na assinatura'
)
  .text Ops! Tivemos um problema no processamento da sua assinatura.
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/signature-failure.svg')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'ShowSignatureFailureModal',
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.$refs.modal.close();
    },
  },
};
</script>
