import { render, staticRenderFns } from "./FilesListMini.vue?vue&type=template&id=12fcce0e&scoped=true&lang=pug"
import script from "./FilesListMini.vue?vue&type=script&lang=js"
export * from "./FilesListMini.vue?vue&type=script&lang=js"
import style0 from "./FilesListMini.vue?vue&type=style&index=0&id=12fcce0e&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fcce0e",
  null
  
)

export default component.exports