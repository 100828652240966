<template lang="pug">
modal.file-loader-modal(
  :aux-button-show='hasLoadedFiles'
  :disableProcessButton='!hasSelectedFiles'
  @auxButtonAction='selectFile'
  @closed='onCloseModal'
  @opened='onOpenModal'
  @process='submit'
  aux-button-label='Selecionar arquivos'
  close-button-label='Cancelar'
  process-button-label='Enviar'
  ref='modal'
  size='lg'
  title='Buscar'
)
  ul.nav.nav-tabs(role='tablist')
    li.nav-item(role='presentation')
      button.nav-link.active(
        aria-controls='settings'
        aria-selected='true'
        data-bs-target='#computer'
        data-bs-toggle='tab'
        role='tab'
        type='button'
      )
        icon(name='laptop')
        span.text.ms-2 Computador
    li.nav-item(role='presentation' v-if='sourceStorage')
      button.nav-link(
        aria-controls='storage'
        aria-selected='true'
        data-bs-target='#storage'
        data-bs-toggle='tab'
        role='tab'
        type='button'
      )
        icon(name='box-open')
        span.text.ms-2 Armazenamento
  .tab-content
    #computer.tab-pane.fade.show.active(
      aria-labelledby='computer-tab'
      role='tab-computer'
    )
      .text-center.pt-3
        input(
          :accept='allowedFileType.join()'
          @change='loadFile'
          multiple='multiple'
          ref='inputFile'
          style='display: none'
          type='file'
        )
        .loaded-files-list(v-if='hasLoadedFiles || hasInvalidFiles')
          .row
            .col-12.col-md-6(
              :key='payload.index'
              v-for='(payload, index) in [].concat(computerFiles, invalidFiles)'
            )
              .loaded-files-list__item
                .content
                  .name {{ payload.file.name }}
                  button.d-flex.p-0.content-center(
                    @click='removeFile(payload)'
                    title='Remover'
                  )
                    icon.icon.m-0(name='trash-can' style='color: #949494')
                .file-error-message(
                  v-if='payload.invalidFile === "INVALID_FILE_TYPE"'
                )
                  span.text Formato inválido. Tipo de arquivo aceito: {{ allowedFileExtensions | fileExtensions }}.
                .file-error-message(
                  v-if='payload.invalidFile === "INVALID_FILE_SIZE"'
                )
                  span.text Tamanho máximo excedido. Insira arquivos com até {{ maxFileSizeMb }}MB.

        .card--upload-file(v-else)
          div(
            :class='["dropzone-area", dragging ? "dragenterClass" : ""]'
            @dragend='dragging = false'
            @dragenter='dragging = true'
            @dragleave='dragging = false'
            @dragover.prevent='dragover'
            @drop.prevent='drop'
          )
            label.notclickable.block.cursor-pointer(for='assetsFieldHandle')
              h3.card-title Arraste aqui seus documentos
              p.font-size-subtitle.mb-2 ou
              button.btn.btn-thin(@click='selectFile') Selecionar arquivos
              p.font-size-small.mb-0 Arquivos {{ allowedFileExtensions | fileExtensions }},
                |
                | com no máximo {{ maxFileSizeMb }}MB
    #storage.tab-pane.fade.show(
      aria-labelledby='storage-tab'
      role='tab-storage'
    )
      files-list-mini.mt-3(
        :ignore-signed-items='true'
        :key='filesListKey'
        @change='selectedFiles'
      )
</template>
<script>
import FileValidator from '@/services/FileValidator';
import Icon from '@/ui/atoms/Icon.vue';
import Alert from '@/ui/molecules/Alert.vue';
import Modal from '@/ui/molecules/Modal.vue';
import FilesListMini from '@/ui/organisms/FilesListMini.vue';

export default {
  components: {
    Icon,
    Alert,
    Modal,
    FilesListMini,
  },
  props: {
    folderId: String,
    sourceStorage: {
      type: Boolean,
      default: true,
    },
    maxFileSizeMb: {
      type: Number,
      default: 5,
    },
    allowedFileType: {
      type: Array,
      default: () => ['application/pdf'],
    },
    allowedFileExtensions: {
      type: Array,
      default: () => ['pdf'],
    },
    dragOnDrop: Boolean,
  },
  data() {
    return {
      filesListKey: 0,
      computerFiles: [],
      invalidFiles: [],
      storageFiles: [],
      dragAndDropModal: false,
      dragging: false,
    };
  },
  computed: {
    sanitizedComputerFiles() {
      if (this.computerFiles.length === 0) {
        return [];
      }
      return this.computerFiles
        .filter((payload) => !(payload.file instanceof File))
        .map((payload) => payload.file);
    },
    hasLoadedFiles() {
      return this.computerFiles.length > 0;
    },
    hasInvalidFiles() {
      return this.invalidFiles.length > 0;
    },
    hasStorageFiles() {
      return this.storageFiles.length > 0;
    },
    hasUploadedFiles() {
      if (!Array.isArray(this.sanitizedComputerFiles)) {
        return false;
      }
      return this.sanitizedComputerFiles.length > 0;
    },
    hasSelectedFiles() {
      return this.hasLoadedFiles || this.hasStorageFiles;
    },
    getSelectedFiles() {
      return this.storageFiles.concat(this.computerFiles);
    },
  },
  methods: {
    submit() {
      this.$emit('selected-files', this.getSelectedFiles);
      this.$refs.modal.close();
    },
    onCloseModal() {
      this.computerFiles = [];
      this.storageFiles = [];
      this.filesListKey += 1;
    },
    onOpenModal() {
      this.$refs.inputFile.value = '';
      this.computerFiles = [];
      this.storageFiles = [];
    },
    async loadFile(e, dragOnDrop = false) {
      let recivedFiles;
      if (dragOnDrop) {
        recivedFiles = e.dataTransfer.files;
      } else {
        recivedFiles = e.target.files;
      }
      recivedFiles.forEach((file) => {
        const invalidFile = FileValidator.isInvalidFile(
          file,
          this.maxFileSizeMb,
          this.allowedFileType,
          this.allowedFileExtensions
        );
        if (invalidFile) {
          this.invalidFiles.push({
            index: new Date().getTime() + Math.random(),
            file,
            invalidFile,
          });
          return;
        }
        this.computerFiles.push({
          index: new Date().getTime() + Math.random(),
          file,
          invalidFile,
        });
      });
    },
    selectFile() {
      this.$refs.inputFile.click();
    },
    selectedFiles(files) {
      this.storageFiles = files;
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.dragAndDropModal = true;
      this.loadFile(event, true);
      this.dragging = false;
    },
    removeFile(payload) {
      if (payload.invalidFile) {
        this.invalidFiles = this.invalidFiles.filter(
          (f) => f.index !== payload.index
        );
        return;
      }
      this.computerFiles = this.computerFiles.filter(
        (f) => f.index !== payload.index
      );
    },
  },
};
</script>
<style lang="stylus">
.file-loader-modal
  .nav-tabs
    .nav-item
      .text
        margin-left: .5em
  .loaded-files-list__item
    border: 1px solid #ffeae9
    border-radius: 3px
    margin: 10px 0
    &:hover
      box-shadow: 0px 3px 5px rgba(0, 0, 0, .07)
    .content
      display: flex
      justify-content: space-between
      align-items: center
      padding: 1rem
      .name
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        font-size: .875rem
    .file-error-message
      background-color: #ffeae9
      padding: 0.2rem 1rem
      font-size: 0.7em
      color: #d00a00
      text-align: left
  .card--upload-file
    button
      margin-bottom: .5rem
    .dragenterClass
      cursor: grabbing
      cursor: -webkit-grabbing
      background:rgba(0,0,0,0.1)
      .notclickable
        pointer-events: none;
        h3
          pointer-events: none;
        p
          pointer-events: none;
</style>
