import enumBase from './EnumBase';

export default class EnvelopeState extends enumBase {
  static DRAFT = 'draft';

  static IN_TRANSIT = 'in-transit';

  static EXPIRED = 'expired';

  static CANCELLED = 'cancelled';

  static PROCESSING = 'processing';

  static COMPLETED = 'completed';

  static HALTED = 'halted';

  static values = [
    {
      value: this.DRAFT,
      label: 'Rascunho',
      description: 'Ainda não enviado, salvo como rascunho.',
    },
    {
      value: this.IN_TRANSIT,
      label: 'Em andamento',
      description: 'Aguardando assinatura de terceiros.',
    },
    {
      value: this.EXPIRED,
      label: 'Expirado',
      description: 'Prazo para assinatura expirou.',
    },
    {
      value: this.CANCELLED,
      label: 'Cancelado',
      description: 'Cancelado pelo remetente.',
    },
    {
      value: this.PROCESSING,
      label: 'Processando',
      description: 'Processando envio.',
    },
    {
      value: this.COMPLETED,
      label: 'Finalizado',
      description: 'Nenhuma alteração poderá ser realizada no documento.',
    },
    {
      value: this.HALTED,
      label: 'Falhou',
      description: 'Não foi possível enviar o envelope.',
    },
  ];

  /**
   * Envelope is opened for be signed only the state is in-transit
   * @param string value
   * @returns boolean
   */
  static isOpened(value) {
    return value === this.IN_TRANSIT;
  }

  static isClosed(value) {
    return !this.isOpened(value);
  }

  static getAllSentStates() {
    return this.values.filter((item) => item.value !== this.DRAFT);
  }
}
