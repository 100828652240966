import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/pt_BR.json';

function isNullOrUndefined(value) {
  return value === null || value === undefined;
}
function isEmptyArray(arr) {
  return Array.isArray(arr) && arr.length === 0;
}

extend('password', {
  validate: (value) => {
    const result = {
      valid: false,
      required: true,
    };
    if (isNullOrUndefined(value) || isEmptyArray(value)) {
      return result;
    }
    result.valid = !!String(value).trim().length;
    return result && value.length >= 8;
  },
  computesRequired: true,
  message: 'O campo senha deve conter mínimo de 8 caracteres',
});

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule],
  });
});
