<template lang="pug">
.row.pt-4
  .col-6
    .form-group(style='max-width: 520px')
      label(for='exampleInputTeam') Nome do time *
      .form-control-icon
        icon(name='users')
        input#exampleInputTeam.form-control(
          @input='updateTeamName'
          placeholder='Digite um nome para o time'
          type='text'
          v-model='teamName'
        )
  .col-6.text-end
    button.btn.btn--cancel-red-text.me-3.align-middle.shadow(
      data-bs-target='#remove-team-modal'
      data-bs-toggle='modal'
      type='button'
    )
      span.text Excluir time
  .col-12.pt-2
    label.label--dark-blue(for='users') Usuários
    .row
      .col-12.text-end
        button.btn.btn-secondary(
          data-bs-target='#new-user-modal'
          data-bs-toggle='modal'
          type='button'
        ) Adicionar usuário
    .row.g-3(v-if='users.length > 0')
      .col-12.col-sm-6.col-md-4.col-xl-3.mb-1(
        :key='user.id'
        v-for='user in users'
      )
        .card.card--mini
          .card-body
            button.action-remove(
              @click='setUserIdModal(user.id)'
              data-bs-target='#remove-user-modal'
              data-bs-toggle='modal'
              type='button'
            )
              icon(name='trash-can')
            img.img.rounded-circle.mb-2.mt-2(:src='user.avatar' width='38px')
            h5.card-title.mb-0 {{ user.name }}
            small {{ user.email }}
    .row(v-else)
      .col-12
        img(
          src='/images/empty-users.svg'
          style='display: block; margin-left: auto; margin-right: auto'
          vertical-align='middle'
        )
      .col-12.mt-4
        .text-center Você ainda não tem usuários nesse time.
  new-user-modal(:team-id='team.id' @refresh='fetchTeam')
  remove-user-modal(
    :team-id='team.id'
    :user-id='userId'
    @refresh='fetchTeam'
  )
  remove-team-modal(:team-id='team.id')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import NewUserModal from '@/modules/business/components/organisms/modals/NewUserModal.vue';
import RemoveUserModal from '@/modules/business/components/organisms/modals/RemoveUserModal.vue';
import RemoveTeamModal from '@/modules/business/components/organisms/modals/RemoveTeamModal.vue';
import TeamsService from '@/modules/business/services/http/organizations/teams/TeamsService';
import debounce from 'debounce';

export default {
  name: 'TeamsPage',
  components: {
    Icon,
    NewUserModal,
    RemoveUserModal,
    RemoveTeamModal,
    TeamsService,
  },
  data() {
    return {
      team: '',
      users: [],
      userId: '',
      teamName: '',
    };
  },
  async created() {
    if (this.$store.state.organizations.personalWorkspace) {
      this.$router.push('/business/dashboard');
    }
    this.$store.dispatch('page/setTitle', 'Editar Time');
    this.$store.dispatch('page/setClass', 'business teams edit');
    this.$store.dispatch('page/setHead', { title: 'Editar Time' });
    try {
      this.$loading(true);
      await this.fetchTeam();
      this.$loading(false);
    } catch {
      this.$loading(false);
    }
  },
  methods: {
    setUserIdModal(value) {
      this.userId = value;
    },
    updateTeamName: debounce(function updateTeamName() {
      TeamsService.update(this.$route.params.id, this.teamName).then(() => {
        this.fetchTeam();
      });
    }, 1000),
    async fetchTeam() {
      this.$loading(true);
      const team = await TeamsService.listEspecificTeam(this.$route.params.id, {
        extended: true,
      });
      this.team = team.data;
      this.teamName = team.data.name;
      this.users = team.data.users;
      this.$loading(false);
    },
  },
};
</script>
