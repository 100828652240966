export default {
  extratElementsFromDocument(
    document,
    recipients = [],
    signatures = {},
    recipientEmail = '',
    envelopeState = ''
  ) {
    const elements = [];
    document.fields.forEach((field) => {
      const element = {
        id: field.id,
        document: document.id,
        type: field.type,
        category: 'field',
        recipient: recipients.filter((r) => r.id === field.recipient).shift(),
        inApi: true,
        title: field.title,
        value: field.value,
        options: field.options,
        required: field.is_required,
        page: field.widgets[0].page,
        apiX: field.widgets[0].x_axis,
        apiY: field.widgets[0].y_axis,
        apiWidth: field.widgets[0].width || 'auto',
        apiHeight: field.widgets[0].height || 'auto',
        apiFontSize: field.widgets[0].font_size,
        updated: false,
      };
      const recipientIsElementOwner =
        recipientEmail === element.recipient.addressees[0].value;
      switch (field.type) {
        case 'signature':
          element.value = '';
          element.name = 'Assinatura';
          element.resizable = true;
          if (signatures.signature && recipientIsElementOwner) {
            element.value = signatures.signature;
          }
          break;
        case 'initial':
          element.value = '';
          element.name = 'Rubrica';
          element.resizable = true;
          if (signatures.initial && recipientIsElementOwner) {
            element.defaultInitial = signatures.initial;
          }
          if (
            field.filled_at &&
            signatures.initial &&
            recipientIsElementOwner
          ) {
            element.value = signatures.initial;
          }
          break;
        case 'checkbox':
          element.name = field.name;
          element.value = field.value;
          element.settings = true;
          element.version = 1;
          element.resizable = false;
          break;
        case 'dropdown':
          element.name = field.name;
          element.settings = true;
          element.version = 1;
          element.resizable = false;
          break;
        case 'text':
          element.resizable = false;
          break;
        default:
          break;
      }
      if (
        element.recipient.state === 'done' ||
        ['failed', 'suspended', 'refused'].includes(envelopeState)
      ) {
        element.disabled = true;
      }
      elements.push(element);
    });
    document.contents.forEach((content) => {
      const element = {
        id: content.id,
        document: document.id,
        type: content.type,
        category: 'content',
        inApi: true,
        value: content.value,
        page: content.page,
        apiX: content.x_axis,
        apiY: content.y_axis,
        apiWidth: content.width || 'auto',
        apiHeight: content.height || 'auto',
        apiFontSize: content.font_size,
        updated: false,
      };
      if (element.value) {
        if (element.value.includes('\n') || content.opacity) {
          element.type = 'comment';
          element.textLines = element.value.split('\n').length;
          element.settings = true;
          element.version = 1;
        }
      }
      if (content.type === 'image') {
        element.resizable = true;
        this.getFileObjectFromBase64(content.value).then((file) => {
          element.value = file;
        });
        element.settings = true;
        element.version = 1;
        const result = new Promise((resolve) => {
          const image = new Image();
          image.src = content.value;
          image.onload = () => resolve(image);
        });
        result.then((image) => {
          element.image = image;
        });
      }

      if (content.type === 'validation-qrcode') {
        element.resizable = true;
      }

      if (content.type === 'text') {
        element.resizable = false;
      }
      elements.push(element);
    });
    return elements;
  },

  generateApiFieldObject(element) {
    const apiWidth = Math.round(
      element.sizePercentage.width * element.pageSize.width
    );
    const apiHeight = Math.round(
      element.sizePercentage.height * element.pageSize.height
    );
    const data = {
      recipient: element.recipient.id,
      type: element.type,
      is_required: element.required || false,
      title: element.title,
      value: element.value || null,
      widgets: [
        {
          page: element.page,
          x_axis: Math.round(
            element.positionPercentage.x * element.pageSize.width
          ),
          y_axis: Math.round(
            element.positionPercentage.y * element.pageSize.height
          ),
          font_size: Math.round(element.fontSizePercentage * apiWidth),
          width: apiWidth,
          height: apiHeight,
        },
      ],
    };
    if (element.type === 'signature') {
      data.name = `signature-${element.id}`;
    }
    if (element.type === 'initial') {
      data.type = 'initial';
      data.name = `initial-${element.id}`;
    }
    if (element.type === 'checkbox') {
      data.name = `checkbox-${element.id}`;
      data.widgets[0].background_color = '#ffffff';
      data.widgets[0].border_color = '#cccccc';
      data.widgets[0].text_color = '#000000';
      data.widgets[0].border_width = 1;
      data.widgets[0].font_family = 'Helvetica';
    }
    if (element.type === 'dropdown') {
      data.name = `select-${element.id}`;
      data.options =
        typeof element.options === 'string'
          ? element.options.split(',')
          : element.options;
      data.value = null;
      data.widgets[0].background_color = '#ffffff';
      data.widgets[0].border_color = '#cccccc';
      data.widgets[0].text_color = '#000000';
      data.widgets[0].border_width = 1;
      data.widgets[0].font_family = 'Helvetica';
    }
    if (element.type === 'text') {
      data.name = `input-text-${element.id}`;
      data.value = null;
      data.widgets[0].background_color = '#ffffff';
      data.widgets[0].border_color = '#cccccc';
      data.widgets[0].text_color = '#000000';
      data.widgets[0].border_width = 1;
      data.widgets[0].font_family = 'Helvetica';
    }
    return data;
  },

  generateApiContentObject(element) {
    const apiWidth = Math.round(
      element.sizePercentage.width * element.pageSize.width
    );
    const apiHeight = Math.round(
      element.sizePercentage.height * element.pageSize.height
    );
    const data = {
      type: element.type,
      value: element.value,
      page: element.page,
      x_axis: Math.round(element.positionPercentage.x * element.pageSize.width),
      y_axis: Math.round(
        element.positionPercentage.y * element.pageSize.height
      ),
      width: apiWidth,
      height: apiHeight,
      font_size: Math.round(element.fontSizePercentage * apiWidth),
      font_family: 'Helvetica',
      text_color: '#000000',
      opacity: 1,
    };
    if (data.type === 'date') {
      data.type = 'text';
    }
    if (data.type === 'comment') {
      data.type = 'text';
    }
    return data;
  },

  async getFileObjectFromBase64(base64) {
    const response = await fetch(base64);
    const blob = await response.blob();
    return new File([blob], 'image', { type: blob.type });
  },
};
