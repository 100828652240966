<template lang="pug">
.documents-pdf-viewer
  //- Select
  select.document-select.form-select(v-if='loaded' v-model='selectedDocument')
    option(:value='document' v-for='(document, index) in documents') {{ document.name }}
  button.field-focus.btn.start(
    @click='goToNextField'
    v-if='this.getEmptyFields().length > 0 && loaded'
  )
    span.text(v-if='this.getEmptyFields().length === fields.length') Iniciar
    span.text(v-else) Avançar
  pdf(
    :key='selectedDocument.id'
    :show-envelope-options='true'
    :src='documentSrc'
    @document-loaded='loaded = $event; $emit("loaded", $event)'
    @download-envelope='downloadEnvelope'
    @download-signatures='downloadSignatures'
    @fullscreen='showFullScreen'
    @proxy-pages='onLoadPages($event)'
    v-if='documentSrc'
  )
    //- Slot de cada página <3
    template(
      v-for='(page, index) in pages'
      v-slot:[`page-${page.number}`]='{}'
    )
      .elements-list(:key='`${index}-${selectedDocument.id}`')
        document-element-viewer(
          :element='element'
          :global-initial='globalInitial'
          :global-signature='globalSignature'
          :key='element.id'
          :page='page'
          :recipient-id='recipientId'
          @change='onElementChange'
          @edit-initial='editInitial(element)'
          @edit-signature='editSignature(element)'
          @initial-click='initialClick(element)'
          @signature-click='signatureClick(element)'
          v-for='(element, index) in page.elements'
        )
  //- Modal de assinaturas
  signature-creator-modal#signature-creator-modal(
    :default-text='signatureModalDefaultText'
    :label='signatureModalLabel'
    :signature-support='signatureSupport'
    :signature-text-max-length='signatureModalTextLength'
    @change-signature='onSignatureUpdate'
    ref='signatureCreatorModal'
  )
</template>
<script>
import Pdf from '@/ui/molecules/Pdf.vue';
import SignatureCreatorModal from '@/ui/organisms/modals/SignatureCreatorModal.vue';
import DocumentElementViewer from '@/ui/molecules/DocumentElementViewer.vue';
import Icon from '@/ui/atoms/Icon.vue';
import EnvelopeState from '@/enums/EnvelopeState';
import alert from '@/modules/account/common/alert';
import Abbreviator from '@/services/Abbreviator';
import EnvelopeDownloadsFactory from '@/modules/business/common/EnvelopeDownloads/EnvelopeDownloadsFactory';

export default {
  name: 'DocumentsPDFViewer',
  components: {
    Pdf,
    SignatureCreatorModal,
    DocumentElementViewer,
    Icon,
  },
  props: {
    documents: Array,
    document: {
      type: Object,
      default: () => {},
    },
    recipientId: String,
    envelope: {
      type: [Object, String],
      default: null,
    },
    signatureSupport: {
      type: Array,
    },
  },
  data() {
    return {
      loaded: false,
      pages: [],
      clickedElement: '',
      documentSrc: null,
      selectedDocument: {},
      signatureModalLabel: 'Assinatura',
      signatureModalTextLength: 30,
      signatureModalDefaultText: null,
      globalSignature: '',
      globalInitial: '',
      documentAutoSelect: false,
    };
  },
  mounted() {
    if (this.document) {
      this.selectedDocument = this.document;
      this.documentSrc = this.selectedDocument.src;
    }
    this.$emit('empty-fields', this.getEmptyFields());
  },
  watch: {
    document(document) {
      this.selectedDocument = document;
    },
    selectedDocument(document) {
      this.documentSrc = null;
      this.page = 1;
      this.pages = [];
      this.loaded = false;
      setTimeout(() => {
        this.documentSrc = document.src;
        this.resetTopOnFocusButton();
      }, 100);
    },
    loaded(value) {
      if (value && this.documentAutoSelect) {
        setTimeout(() => {
          this.documentAutoSelect = false;
          this.goToNextField();
        }, 3000);
      }
    },
  },
  computed: {
    labelFieldFocus() {
      if (
        this.getOnlyFieldElements.length > this.getOnlyFieldElementsEmpty.length
      ) {
        return 'Avançar';
      }
      return 'Iniciar';
    },
    fields() {
      // eslint-disable-next-line max-len
      const elements = this.documents.reduce(
        (ret, document) => [].concat(ret, document.elements || []),
        []
      );
      return (
        elements
          .filter(
            (e) => e.category === 'field' && e.recipient.id === this.recipientId
          )
          .sort((a, b) => a.apiY * 100 ** a.page - b.apiY * 100 ** b.page) || []
      );
    },
    hasToken() {
      return this.$route.params.token;
    },
  },
  methods: {
    changeDocumentById(documentId) {
      const document = this.documents
        .filter((d) => d.id === documentId)
        .shift();
      if (!document) {
        return;
      }
      this.selectedDocument = document;
    },
    invalidRecipient(element) {
      if (!element.recipient) {
        return true;
      }
      if (element.recipient.id !== this.recipientId) {
        return true;
      }
      return false;
    },
    editInitial(element) {
      if (this.invalidRecipient(element)) {
        return;
      }
      if (this.$root.user.name) {
        this.signatureModalDefaultText = Abbreviator.get(this.$root.user.name);
      } else {
        this.signatureModalDefaultText = Abbreviator.get(
          element.recipient.addressees[0].name
        );
      }
      this.signatureModalLabel = 'Rubrica';
      this.signatureModalTextLength = 5;
      if (element.defaultInitial && !element.value) {
        // eslint-disable-next-line no-param-reassign
        element.value = element.defaultInitial;
        // eslint-disable-next-line no-param-reassign
        element.defaultInitial = '';
        this.$emit('element-updated', element);
        this.$emit('empty-fields', this.getEmptyFields());
      } else {
        this.$refs.signatureCreatorModal.$refs.modal.open();
      }
      this.clickedElement = element;
    },
    editSignature(element) {
      if (this.invalidRecipient(element)) {
        return;
      }
      if (this.$root.user.name) {
        this.signatureModalDefaultText = this.$root.user.name;
      } else {
        this.signatureModalDefaultText = element.recipient.addressees[0].name;
      }
      this.signatureModalLabel = 'Assinatura';
      this.signatureModalTextLength = 30;
      this.$refs.signatureCreatorModal.$refs.modal.open();
      this.clickedElement = element;
    },
    initialClick(element) {
      if (this.invalidRecipient(element)) {
        return;
      }
      if (this.globalInitial) {
        this.clickedElement = element;
        element.value = this.globalInitial;
        this.$emit('element-updated', element);
        this.$emit('empty-fields', this.getEmptyFields());
        return;
      }
      this.editInitial(element);
    },
    signatureClick(element) {
      if (this.invalidRecipient(element)) {
        return;
      }
      if (this.globalSignature) {
        this.clickedElement = element;
        element.value = this.globalSignature;
        this.$emit('element-updated', element);
        this.$emit('empty-fields', this.getEmptyFields());
        return;
      }
      this.editSignature(element);
    },
    getEmptyFields() {
      return (
        this.fields.filter((e) => !e.value && e.required && !e.disabled) || []
      );
    },
    goToNextField() {
      const nextEmptyField = this.getEmptyFields()[0];
      if (!nextEmptyField) {
        return;
      }
      if (nextEmptyField.document !== this.selectedDocument.id) {
        this.documentAutoSelect = true;
        this.changeDocumentById(nextEmptyField.document);
        return;
      }
      const currentFieldElement = document.querySelector(
        `#element-${nextEmptyField.id}`
      );
      const pdfPagesElement = document.querySelector(
        '.pdf-render__content__pages'
      );
      let incrementPagesSize = 0;
      for (let $c = 1; $c < nextEmptyField.page; $c += 1) {
        incrementPagesSize +=
          pdfPagesElement.querySelector(`#page-${$c}`).offsetHeight + 7.5;
      }
      pdfPagesElement.scroll(
        0,
        currentFieldElement.offsetTop +
          incrementPagesSize -
          currentFieldElement.offsetHeight / 2
      );
      // Set focus on field
      currentFieldElement.classList.add('focus');
      // Remove focus from field some time after
      setTimeout(() => {
        currentFieldElement.classList.remove('focus');
      }, 1700);
    },
    getFocusButtonElement() {
      return document.querySelector('a.field-focus');
    },
    setCustomBottomOnFocusButton(bottom) {
      const element = this.getFocusButtonElement();
      element.style.bottom = `${bottom}px`;
      element.style.top = 'auto';
    },
    resetTopOnFocusButton() {
      const element = this.getFocusButtonElement();
      if (element) {
        element.removeAttribute('style');
      }
    },
    async downloadEnvelope() {
      let id = this.$route.params.token;
      let type = 'public';
      if (!this.hasToken) {
        id = this.envelope.id;
        type = 'private';
      }
      this.$loading(true);
      try {
        const envelopeDownloads =
          EnvelopeDownloadsFactory.createEnvelopeDownloads(id, type);

        await envelopeDownloads.downloadDocuments(this.envelope.title);
      } finally {
        this.$loading(false);
      }
    },
    showFullScreen() {
      window.open(this.selectedDocument.links.display, '_blank');
    },
    downloadSignatures() {
      if (this.envelope.state === EnvelopeState.COMPLETED) {
        window.open(this.selectedDocument.links.background_sheet, '_blank');
        return;
      }
      alert.fireAlert(
        'A página de assinaturas só pode ser visualizada quando o envelope estiver finalizado.',
        {
          classes: 'alert-warning',
          styles:
            'background-color: #FFFCE2; border-color: #EBE5A7; color: #635B00;',
          tag: 'httpAlert',
          icon: 'circle-exclamation',
        }
      );
    },
    onLoadPages(pages) {
      this.pages = pages.map((p) => {
        let elements = [];
        if (this.selectedDocument.elements) {
          elements = this.selectedDocument.elements.filter(
            (e) => e.page === p.pageNumber
          );
        }
        return {
          size: {
            width: p.view[2],
            height: p.view[3],
          },
          number: p.pageNumber,
          elements,
        };
      });
    },
    onSignatureUpdate(signature) {
      if (this.clickedElement.type === 'initial') {
        this.globalInitial = signature.image;
      }
      if (this.clickedElement.type === 'signature') {
        this.globalSignature = signature.image;
      }
      this.clickedElement.value = signature.image;
      this.$emit('signature-updated', signature, this.clickedElement);
      this.$emit('empty-fields', this.getEmptyFields());
    },
    onElementChange(e) {
      this.$emit('element-updated', e);
      this.$emit('empty-fields', this.getEmptyFields());
    },
  },
};
</script>
<style lang="stylus">
.documents-pdf-viewer
  position: relative
  .pdf-thumbs
    height: calc(100vh - 255px)
  .document-select
    margin: auto
    width: 100%
    max-width: 73%
    position: absolute
    z-index: 2
    right: auto
    left: 0.4rem
    top: 0.4rem
    padding: .3em 2.5em .3em .6em
    border-color: #CCCCCC !important
    background-color: #fff
    border-radius: 5px
  .elements-list
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    margin: auto
    z-index: 1
.field-focus
  cursor: pointer
  position: absolute
  top: 10%
  z-index: 2
  background-color: #013D52
  color: #fff
  font-size: .8rem
  text-align: center
  padding: 1em 1em 1.15em 1em
  line-height: 1em
  padding-right: 2.5em
  border-radius: 5px
  min-width: 85px
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  &:hover
    text-decoration: none
    color: #fff
    opacity: 1
  > *
    font-size: 1rem
    font-weight: 400
@media screen and (min-width: 468px)
  .documents-pdf-viewer
    .document-select
      max-width: 78%
@media screen and (min-width: 576px)
  .documents-pdf-viewer
    .document-select
      max-width: 40%
      left: 13.5rem
@media screen and (min-width: 768px)
  .documents-pdf-viewer
    .document-select
      max-width: 35%
      left: 14rem
@media screen and (min-width: 992px)
  .documents-pdf-viewer
    .document-select
      max-width: 50%
      left: 14.5rem
@media screen and (min-width: 1400px)
  .documents-pdf-viewer
    .document-select
      max-width: 60%
      left: 14.5rem
</style>
