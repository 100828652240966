<template lang="pug">
modal#sign-envelope-modal(
  @process='sign'
  aria-hidden='true'
  close-button-label='Cancelar'
  process-button-label='Assinar'
  ref='modal'
  title='Assinar com Bird ID'
)
  .form-group
    label CPF
    .form-control-icon
      icon(name='id-card')
      input#exampleInputRole.form-control(
        placeholder='Digite seu CPF'
        type='text'
        v-model='cpf_cnpj'
      )
  .form-group
    label Código OTP
    .form-control-icon
      icon(name='user-shield')
      input#exampleInputRole.form-control(
        placeholder='Digite o código OTP'
        type='text'
        v-model='otp'
      )
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import Icon from '@/ui/atoms/Icon.vue';
import BirdIdService from '@/modules/business/services/http/envelopes/BirdIdService';

export default {
  name: 'SignEnvelopeWithoutSignatureModal',
  components: {
    Modal,
    Icon,
  },
  data() {
    return {
      cpf_cnpj: '',
      otp: '',
    };
  },
  methods: {
    async sign() {
      this.$loading(true);
      try {
        await BirdIdService.getToken({ cpf_cnpj: this.cpf_cnpj, otp: this.otp })
          .then(async (response) => {
            const certificate = response.data.certificates.filter(
              this.getValidCertificate
            );
            const lastCertificate = certificate.pop();
            if (lastCertificate) {
              this.$emit('acquiredToken', {
                access_token: response.data.access_token,
                certificate_alias: lastCertificate.alias,
              });
            }
          })
          .catch(() => {
            this.$loading(false);
            this.$emit('callSignatureFailureModal');
          });
      } finally {
        this.$refs.modal.close();
      }
    },
    getValidCertificate(certicate) {
      if (certicate.was_revoked === false) {
        const initial = new Date(certicate.validity.not_before);
        const final = new Date(certicate.validity.not_after);
        const current = new Date();
        if (current > initial && current < final) {
          return certicate;
        }
      }
      return false;
    },
  },
};
</script>
