<template lang="pug">
.max-text-counter(:class='{ "text-danger": remaining === 0 }')
  template(v-if='remaining === limit')
    span.text {{ remaining }} caracteres
  template(v-else)
    span.text {{ remaining }} caracteres restantes
</template>
<script>
export default {
  name: 'MaxTextCounter',
  props: {
    limit: {
      required: true,
      type: Number,
    },
    value: {
      required: true,
    },
  },
  computed: {
    remaining() {
      if (this.value === null) {
        return this.limit;
      }
      return this.limit - this.value.length;
    },
  },
  watch: {
    remaining(value) {
      this.$emit('remaining', value);
    },
  },
};
</script>
<style lang="stylus">
.form-control-icon, .form-control
  .max-text-counter
    position: absolute
    right: 0
    bottom: -1.75em
.form-group
  .max-text-counter
    margin-right: 0
    margin-left: auto
    width: fit-content
</style>
