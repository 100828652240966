<template lang="pug">
div
  #drafts.tab-pane.fade.show(aria-labelledby='settings-tab' role='tabpanel')
    .row(v-if='!showFeedbackNoEnvelopes')
      .mb-3.col-12.col-lg-6.col-xl-5
        .form-control-icon--end-line.flex-grow-1
          input.form-control.rounded-3(
            @input='searchEnvelopesArchived()'
            autocomplete='off'
            placeholder='Buscar por nome ou ID'
            v-model='search'
          )
          icon.icon--blue.font-size-subtitle(name='magnifying-glass')
    .row(v-if='!showFeedbackNoEnvelopes')
      .col-12
        draft-envelopes.mb-5(
          :data='envelopesArchived.data'
          @item-deleted='fetchEnvelopeDraft'
          @sort='setSortColumn'
        )
        simple-paginator(
          :payload='envelopesArchived'
          @navigate='fetchEnvelopeDraft'
        )
    .text-center.mt-5(v-if='showFeedbackNoEnvelopes')
      img.img(
        src='/img/ilustrations/no-drafts-found.svg'
        style='max-height: 260px'
      )
      .mt-4.text-center Você ainda não tem rascunhos para editar.

    .text-center.mt-5(v-if='showFeedbackNoResults')
      img.img-fluid(src='/img/ilustrations/no-contacts-found.svg')
      p.font-color-blue.text-center.mt-4
        | Não encontramos resultados para sua pesquisa.
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import debounce from 'debounce';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import TableComponent from '@/ui/organisms/Table.vue';
import DraftEnvelopes from '@/modules/business/components/organisms/card-table/DraftEnvelopesCardTable.vue';

export default {
  name: 'DocumentsPage',
  components: {
    Icon,
    SimplePaginator,
    TableComponent,
    DraftEnvelopes,
  },
  data() {
    return {
      envelopesArchived: {
        data: [],
        links: {},
        meta: {},
      },
      filters: {
        sort: '-created_at',
      },
      search: '',
    };
  },
  async created() {
    this.$store.dispatch('page/setTitle', 'Rascunhos de Envelopes');
    this.$store.dispatch('page/setHead', { title: 'Envelopes' });
    this.$store.dispatch('page/setClass', 'business documents');
    this.fetchEnvelopeDraft();
  },
  computed: {
    isEmptyList() {
      return this.envelopesArchived.data.length === 0;
    },
    isSearching() {
      return this.search.length > 0;
    },
    showFeedbackNoEnvelopes() {
      return this.isEmptyList && !this.isSearching;
    },
    showFeedbackNoResults() {
      return this.isEmptyList && this.isSearching;
    },
  },
  methods: {
    fetchEnvelopeDraft(page = 1) {
      this.$loading(true);
      const params = new URLSearchParams({
        ...(this.search && { q: this.search }),
        ...{ page },
        ...{ 'filter[state]': 'draft' },
        ...{ sort: this.filters.sort },
      });
      EnvelopesService.listSent(params.toString())
        .then((response) => {
          this.envelopesArchived = response.data;
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    searchEnvelopesArchived: debounce(function searchEnvelopesArchived() {
      this.fetchEnvelopeDraft();
    }, 300),
    setSortColumn(column) {
      this.filters.sort = `${column.desc ? '-' : ''}${column.column}`;
      this.fetchEnvelopeDraft();
    },
  },
};
</script>

<style scoped lang="stylus">
.form-group
  margin-bottom: 0.5rem
  .form-control-icon--end-line
    max-width: 260px
</style>
