<template lang="pug">
.page.business.dashboard
  .file-upload.file-upload--hide
    .mb-4
      .col-12.mb-3
        .component-title
          span.text Novo envelope
      .card.card--file-loader
        .card-body(
          :class='["dropzone-area", dragging ? "dragenterClass" : ""]'
          @dragend='dragging = false'
          @dragenter='dragging = true'
          @dragleave='dragging = false'
          @dragover.prevent='dragover'
          @drop.prevent='drop'
        )
          input(
            :accept='allowedFileType.join()'
            multiple='multiple'
            ref='inputFile'
            style='display: none'
            type='file'
          )
          label.notclickable.block.cursor-pointer(for='assetsFieldHandle')
            h3.card-title Arraste aqui seus documentos
            p.font-size-subtitle.mb-2 ou
            button.btn.btn-thin(
              data-bs-target='#file-local-loader-modal'
              data-bs-toggle='modal'
            ) Buscar em
            p.font-size-small.mb-0.mt-2 Arquivos {{ allowedFileExtensions | fileExtensions }}, com no máximo 25MB
  .my-activity(v-if='Object.keys(sentEnvelopeStatistics).length > 0')
    .row.cards
      .col-12.mb-2
        .component-title
          span.text Minhas atividades
      .col-6.col-sm-6.col-md-6.col-xl-3.mb-4
        router-link(
          :to='{ name: "business.documents.sent", params: { state: "in-transit" } }'
        )
          .card.card-activity.card-activity--pending
            .card-body.info-block
              .icon-block.ms-2
                icon-status(icon-status='in-transit')
              .data-block
                span.text.d-block.data-block__title
                  strong {{ sentEnvelopeStatistics['in-transit'] }}
                .text.font-size-1 Pendentes
      .col-6.col-sm-6.col-md-6.col-xl-3.mb-4
        router-link(
          :to='{ name: "business.documents.sent", params: { state: "rejected" } }'
        )
          .card.card-activity.card-activity--rejected
            .card-body.info-block
              .icon-block.ms-2
                icon-status(icon-status='rejected')
              .data-block
                span.text.d-block.data-block__title
                  strong {{ sentEnvelopeStatistics.rejected }}
                .text.font-size-1 Rejeitados
      .col-6.col-sm-6.col-md-6.col-xl-3.mb-4
        router-link(
          :to='{ name: "business.documents.sent", params: { state: "completed" } }'
        )
          .card.card-activity.card-activity--finished
            .card-body.info-block
              .icon-block.ms-2
                icon-status(icon-status='completed')
              .data-block
                span.text.d-block.data-block__title
                  strong {{ sentEnvelopeStatistics.completed }}
                .text.font-size-1 Finalizados
      .col-6.col-sm-6.col-md-6.col-xl-3.mb-4
        router-link(
          :to='{ name: "business.documents.sent", params: { state: "cancelled" } }'
        )
          .card.card-activity.card-activity--canceled
            .card-body.info-block
              .icon-block.ms-2
                icon-status(icon-status='cancelled')
              .data-block
                span.text.d-block.data-block__title
                  strong {{ sentEnvelopeStatistics.cancelled }}
                .text.font-size-1 Cancelados
  .documents
    .row.mt-4
      .col-12(v-if='!isEmptyList')
        .component-title
          .text Envelopes recebidos
        received-envelopes.mb-5(
          :data='envelopesReceived.data'
          @sort='setSortColumn'
        )
      .col-12(v-else)
        .card.no-envelope
          .text-center.mb-5
            img.img-fluid.mt-5.img.mb-3(
              src='/img/ilustrations/no-envelope.svg'
              style='max-height: 260px'
            )
            .text.font-color-blue Você ainda não recebeu envelopes para assinar.
            .text.font-color-blue Quando receber, eles aparecerão aqui!
  file-local-loader-modal#file-local-loader-modal(
    :allowed-file-extensions='allowedFileExtensions'
    :allowed-file-type='allowedFileType'
    :max-file-size-mb='maxFileSizeMb'
    :only-load-files='true'
    @selected-files='onSelectFiles'
    ref='fileUpload'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import DashboardService from '@/modules/business/services/http/organizations/DashboardService';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import FileLocalLoaderModal from '@/ui/organisms/modals/FileLocalLoaderModal.vue';
import Alert from '@/ui/molecules/Alert.vue';
import Oauth from '@/services/Oauth';
import EnvelopeState from '@/enums/EnvelopeState';
import EnvelopeAcceptedFileTypes from '@/enums/EnvelopeAcceptedFileTypes';
import ReceivedEnvelopes from '@/modules/business/components/organisms/card-table/ReceivedEnvelopesCardTable.vue';

export default {
  name: 'DashboardPage',
  components: {
    Icon,
    IconStatus,
    FileLocalLoaderModal,
    Alert,
    Oauth,
    ReceivedEnvelopes,
  },
  props: {
    maxFileSizeMb: {
      type: Number,
      default: 25,
    },
  },
  data() {
    return {
      filters: {
        sort: '-envelope.created_at',
      },
      sentEnvelopeStatistics: {},
      envelopesReceived: {
        data: [],
        links: {},
        meta: {},
      },
      limit: 5,
      dragging: false,
      allowedFileType: [].concat(
        EnvelopeAcceptedFileTypes.mimeTypes,
        EnvelopeAcceptedFileTypes.extensions
      ),
      allowedFileExtensions: EnvelopeAcceptedFileTypes.extensions,
    };
  },
  async created() {
    this.$store.dispatch('page/setTitle', 'Início');
    this.$store.dispatch('page/setHead', { title: 'Início' });
    try {
      this.$loading(true);
      await this.fetchSelfActivities();
      await this.fetchReceivedEnvelopes();
      this.$loading(false);
    } catch {
      this.$loading(false);
    }
  },
  methods: {
    setSortColumn(column) {
      this.filters.sort = `${column.desc ? '-' : ''}${column.column}`;
      this.fetchReceivedEnvelopes();
    },
    async fetchSelfActivities() {
      const result = await DashboardService.selfActivities();
      this.sentEnvelopeStatistics = result.data;
    },
    async fetchReceivedEnvelopes() {
      this.$loading(true);
      EnvelopesService.listReceived(
        `include=envelope.recipients_stats&sort=${this.filters.sort}&limit=5`
      )
        .then((response) => {
          this.envelopesReceived = response.data;
        })
        .catch((error) => {
          alert.fireAlert(
            `Erro ao consultar envelopes recebidos. ${error.response.data.message}`,
            {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
              icon: 'triangle-exclamation',
            }
          );
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    onSelectFiles(files) {
      this.$store.dispatch('envelope/addDocuments', files);
      this.$router.push('/business/envelopes/new');
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileUpload.$refs.modal.open();
      this.$refs.fileUpload.loadFile(event, true);
      this.dragging = false;
    },
    selectReceivedDocument(item) {
      if (item.envelope.status === 'pending' && item.state !== 'done') {
        return `/business/envelopes/viewer/${item.action_token}`;
      }
      return `/business/documents/info/${item.envelope.id}/${item.action_token}`;
    },
    showEnvelopeChartProgress(envelope) {
      return envelope.state === EnvelopeState.IN_TRANSIT;
    },
  },
  computed: {
    computedEnvelopes() {
      return this.limit
        ? this.envelopesReceived.slice(0, this.limit)
        : this.envelopes;
    },
    isEmptyList() {
      return this.envelopesReceived.data.length === 0;
    },
  },
};
</script>
<style lang="stylus" scoped>
.container-padding
  padding-right: 86px;
.no-envelope
  font-size: 1rem
  border-radius: 0
  border-color: 1px solid #D7D7D7;
  &:hover
    box-shadow: none
.card--file-loader
  text-align: center
  border: 2px dashed #3ECB90
  color: #013D52
  padding: 1rem
  &:hover
    background-color: #eaeaea
  .card-title
    font-size: 1.7rem
  .dragenterClass
    cursor: grabbing
    cursor: -webkit-grabbing
    background:rgba(0,0,0,0.1);
    .notclickable
      pointer-events: none;
      h3
        pointer-events: none;
      p
        pointer-events: none;
.card-activity
  height: 120px !important;
  .data-block
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  .icon-block
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  .tab-pane
    margin-top: 0
</style>
