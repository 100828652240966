import EnvelopePrivateDownloads from './EnvelopePrivateDownloads';
import EnvelopePublicDownloads from './EnvelopePublicDownloads';

export default class EnvelopeDownloadsFactory {
  static createEnvelopeDownloads(id, type) {
    switch (type) {
      case 'public':
        return new EnvelopePublicDownloads(id);
      case 'private':
        return new EnvelopePrivateDownloads(id);
    }
  }
}
