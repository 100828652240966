import enumBase from './EnumBase';

export default class RecipientState extends enumBase {
  static WAITING = 'waiting';

  static DONE = 'done';

  static REFUSED = 'refused';

  static SIGNING = 'signing';

  static QUEUED = 'queued';

  static SUSPENDED = 'suspended';

  static FAILED = 'failed';

  static FOLLOWING = 'following';

  static values = [
    { value: this.WAITING, label: 'Pendente' },
    { value: this.DONE, label: 'Assinado' },
    { value: this.REFUSED, label: 'Rejeitado' },
    { value: this.SIGNING, label: 'Processando' },
    { value: this.QUEUED, label: 'Na fila' },
    { value: this.SUSPENDED, label: 'Suspenso' },
    { value: this.FAILED, label: 'Falha' },
    { value: this.FOLLOWING, label: 'Visualizador' },
  ];

  static canSign(state) {
    return state === this.WAITING;
  }
}
