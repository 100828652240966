<template lang="pug">
modal#envelope-sent-modal(
  :showCancelButton='false'
  @closed='redirectDocuments'
  @process='closeModal'
  process-button-label='Ok'
  ref='modal'
  size='sm'
  title='Envelope enviado'
)
  .text Quando todas as partes assinarem, você receberá um e-mail com acesso ao download
    |
    | do documento assinado.
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/envelope-sent.svg')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'EnvelopeSentModal',
  components: {
    Modal,
  },
  methods: {
    redirectDocuments() {
      this.$router.push('/business/documents/sent');
    },
    closeModal() {
      this.$refs.modal.close();
    },
  },
};
</script>
