<template lang="pug">
modal#show-envelope-info-modal(
  :disable-process-button='!form.checkTermsAndConditions && !hideFormAcceptTerms'
  :loading='loading'
  :removeCloseButton='true'
  :show-cancel-button='false'
  @closed='onCloseModal'
  @process='acceptTerms'
  process-button-label='Ver envelope'
  ref='modal'
  size='sm'
  title='Envelope recebido'
)
  .inner-content.font-size-small(v-if='envelope')
    p Enviado por:
      |
      | {{ envelope.sender.name }}
    p {{ dateTitle }}:
      |
      | {{ formatDate(envelope.expire_at) }}
    signatures-list.mt-4(:envelope='envelope')
    form.form.mt-3(v-if='!hideFormAcceptTerms')
      label(for='terms-checkbox')
        input#terms-checkbox.form-check-input(
          type='checkbox'
          v-model='form.checkTermsAndConditions'
        )
        | &nbsp; Li e concordo com os
        a(:href='`${baseUrlAuth}/terms`' target='_blank') &nbsp; Termos de uso
        | &nbsp; e
        a(:href='`${baseUrlAuth}/privacy`' target='_blank') &nbsp; Política de privacidade
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import EnvelopeState from '@/enums/EnvelopeState';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import SignaturesList from '@/modules/business/components/molecules/SignaturesList.vue';
import AppConfig from '@/config';

export default {
  name: 'showEnvelopeInfoModal',
  components: {
    Modal,
    IconStatus,
    SignaturesList,
  },
  props: {
    envelope: {
      type: Object,
      required: true,
    },
    hideFormAcceptTerms: {
      type: Boolean,
      default: false,
    },
    recipientType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        checkTermsAndConditions: false,
      },
    };
  },
  methods: {
    acceptTerms() {
      this.$emit('process');
      this.$refs.modal.close();
    },
    onCloseModal() {
      this.$refs.modal.close();
      this.$emit('closed');
    },
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      }
      return 'Sem prazo definido';
    },
  },
  computed: {
    envelopeStateText() {
      return EnvelopeState.getText(this.envelope.state);
    },
    baseUrlAuth() {
      return AppConfig.authorizationServer;
    },
    dateTitle() {
      if (this.envelope.state === 'expired') return 'Expirado em';
      if (this.recipientType === 'approver') return 'Prazo para aprovar';
      if (this.recipientType === 'signer') return 'Prazo para assinar';
      return 'Prazo para conclusão das assinaturas';
    },
  },
};
</script>
<style lang="stylus" scoped>
.inner-content
  a
    color: #01AFEC
  p
    margin-bottom: 0;

.modal-footer
  justify-content: center !important;
</style>
