<template lang="pug">
modal#cancel-envelope-modal(
  :showCancelButton='false'
  @process='close'
  processButtonLabel='Ok'
  ref='modal'
  size='sm'
  title='Envelope cancelado'
)
  p.justify-center O envelope foi cancelado com sucesso! Todos os destinatários
    = ' '
    | serão notificados sobre esse cancelamento.
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/canceled-rejected-envelope.svg')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  components: {
    Modal,
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
