<template lang="pug">
modal#approve-envelope-modal(
  :no-footer='isPublic'
  :show-cancel-button='false'
  @process='goToInboxPage'
  process-button-label='Ok'
  ref='modal'
  title='Envelope aprovado'
)
  .text O envelope {{ getSubject }} foi aprovado.
  .text-center.mt-4
    img.img-fluid.mb-4(src='/img/ilustrations/approve-document.svg')
    buttons-login-and-create-account.mt-4(v-if='isPublic')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ButtonsLoginAndCreateAccount from '@/modules/business/components/molecules/ButtonsLoginAndCreateAccount.vue';

export default {
  name: 'ApproveEnvelopeModal',
  components: {
    Modal,
    ButtonsLoginAndCreateAccount,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
    subject: {
      type: String,
      default: '',
    },
  },
  computed: {
    getSubject() {
      return this.subject || '(Sem assunto)';
    },
  },
  methods: {
    goToInboxPage() {
      this.$refs.modal.close();
      this.$router.push({ name: 'business.documents.inbox' });
    },
  },
};
</script>
