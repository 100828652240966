<template lang="pug">
modal#new-envelope-mobile-modal(
  :showCancelButton='false'
  @closed='clodedModal'
  @opened='onOpenModal'
  @process='closeModal'
  process-button-label='Ok'
  ref='modal'
  size='sm'
  title='Acesse pelo computador'
)
  .text Acesse o Assine.Online pelo computador para aproveitar todas as suas funcionalidades!
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/new-envelope-mobile.svg')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'NewEnvolepeMobileModal',
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.$refs.modal.close();
    },
    onOpenModal() {
      window.document.getElementById('sidebar').classList.remove('show');
    },
    clodedModal() {
      this.$root.closeSidebar();
    },
  },
};
</script>
