import Http from '@/services/Http';

const path = 'invitations';

const client = new Http(path);

client.inviteUsersToOrganization = (data) =>
  client.post(data, '/invitations', '');

client.listPendingInvitations = (page) =>
  client.get('', page ? { page } : '', '');

client.inviteUsersViaSpreadsheet = (organizationId, data) =>
  client.post(`${organizationId}/invitations/import`, data);

client.remove = (email) => client.delete(email, '', '', '');

client.listEspecificInvitation = (organizationId, email) =>
  client.get(`${organizationId}/invitations/${email}`);

client.acceptInvitation = (organizationId, data) =>
  client.post(`${organizationId}/invitations/accept`, data);

export default client;
