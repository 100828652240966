<template lang="pug">
div
  .row.pt-4
    .col-12.col-sm-6
      .form-group(v-if='showFormSearch')
        .form-control-icon--end-line
          input#name.form-control(
            @input='onSearch'
            aria-describedby='buscarTime'
            autocomplete='off'
            placeholder='Buscar time'
            type='name'
            v-model.trim='queryField'
          )
          icon(name='magnifying-glass')
    .col.mb-3.text-end
      button.btn.btn-secondary(
        data-bs-target='#new-team-modal'
        data-bs-toggle='modal'
        type='button'
      ) Novo time

  .row.mt-3.g-3(v-if='showFormSearch')
    .col-12.col-sm-6.col-md-4.mb-1(
      :key='team.id'
      v-for='team in teams.data'
      v-if='hasTeams'
    )
      .card.card--mini.card--mini--teams
        router-link(
          :to='{ name: "business.teams.edit", params: { id: team.id } }'
        )
          .card-body
            h5.card-title.mb-0 {{ team.name }}
            small Criado em {{ team.created_at | dateTime }}
            ul.mt-2.list-unstyled.horizontal-list--avatar
              li.list-item(
                :key='user.id'
                v-for='(user, index) in team.users'
                v-if='index <= 3'
              )
                img.img.rounded-circle(
                  :alt='user.name'
                  :src='user.avatar'
                  :title='user.name'
                )
              li.list-item.more(v-if='team.amount_users > 3')
                span.img.rounded-circle {{ team.amount_users - 3 }} +
  simple-paginator(:payload='teams' @navigate='listTeams')

  .row
    .col-12.mt-5(v-if='showCallToCreateNewTeam')
      .text-center.mt-5
        img.img-fluid.img(
          src='/img/ilustrations/no-teams.svg'
          style='max-height: 260px'
        )
        p.mt-4 Você ainda não tem times em sua organização. Comece criando um agora.

    .col-12.mt-5(v-else-if='!hasTeams')
      .text-center.mt-5
        p.mt-4 Nenhum item recente corresponde à sua pesquisa.

  new-team-modal(@refresh='listTeams')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import NewTeamModal from '@/modules/business/components/organisms/modals/NewTeamModal.vue';
import TeamsService from '@/modules/business/services/http/organizations/teams/TeamsService';
import debounce from 'debounce';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';

export default {
  name: 'TeamsPage',
  components: {
    Icon,
    NewTeamModal,
    TeamsService,
    SimplePaginator,
  },
  data() {
    return {
      loading: false,
      teams: {
        data: [],
        links: {},
        meta: {},
      },
      queryField: '',
    };
  },
  async created() {
    if (this.$store.state.organizations.personalWorkspace) {
      this.$router.push('/business/dashboard');
    }
    this.$store.dispatch('page/setTitle', 'Times');
    this.$store.dispatch('page/setHead', { title: 'Times' });
    this.$store.dispatch('page/setClass', 'business teams');

    this.listTeams();
  },
  computed: {
    hasTeams() {
      return this.teams.data.length > 0;
    },
    showCallToCreateNewTeam() {
      return !this.hasTeams && this.queryField === '';
    },
    showFormSearch() {
      return !this.showCallToCreateNewTeam;
    },
  },
  methods: {
    async listTeams(page = 1) {
      this.$loading(true);
      const params = new URLSearchParams({
        include: 'users-preview',
        q: this.queryField,
        page,
      });
      const result = await TeamsService.list(params);
      this.teams = result.data;
      this.$loading(false);
    },
    onSearch: debounce(function searchTeams() {
      this.listTeams();
    }, 500),
  },
};
</script>
