<template lang="pug">
#received.tab-pane.fade.show.active(
  aria-labelledby='received-tab'
  role='tabpanel'
)
  div
    .row(v-if='!showFeedbackNoEnvelopes')
      .mb-3.col-12.col-lg-6.col-xl-5
        .form-group.mb-0.d-flex.align-items-center.justify-content-between.flex-wrap.justify-content-md-start
          .form-control-icon--end-line.flex-grow-1
            input.form-control.rounded-3(
              @input='searchEnvelopesReceived()'
              autocomplete='off'
              placeholder='Buscar por nome ou ID'
              v-model='filters.search'
            )
            icon.icon--blue.font-size-subtitle(name='magnifying-glass')
          filter-selector.me-auto(
            :filters='mountFilterConfig'
            @update='updateFilter'
          )
    .row(v-if='!isEmptyList')
      .col-12
        received-envelopes.mb-5(
          :data='envelopesReceived.data'
          @sort='setSortColumn'
        )
        simple-paginator.mt-3(
          :payload='envelopesReceived'
          @navigate='fetchEnvelopeReceived'
        )
  .text-center.mt-5(v-if='showFeedbackNoEnvelopes')
    img.img-fluid(src='/img/ilustrations/no-document-received.svg')
    p.font-color-blue.mt-4
      | Você ainda não recebeu envelopes para assinar.
      br
      | Quando receber, eles aparecerão aqui!

  .text-center.mt-5(v-if='showFeedbackNoResults')
    img.img-fluid(src='/img/ilustrations/no-contacts-found.svg')
    p.font-color-blue.mt-4
      | Não encontramos resultados para sua pesquisa.
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import EnvelopeState from '@/enums/EnvelopeState';
import RecipientState from '@/enums/RecipientState';
import debounce from 'debounce';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import alert from '@/modules/account/common/alert';
import FilterSelector from '@/ui/organisms/FilterSelector.vue';
import TableComponent from '@/ui/organisms/Table.vue';
import ReceivedEnvelopes from '@/modules/business/components/organisms/card-table/ReceivedEnvelopesCardTable.vue';

export default {
  name: 'DocumentsInboxPage',
  components: {
    Icon,
    IconStatus,
    SimplePaginator,
    FilterSelector,
    TableComponent,
    ReceivedEnvelopes,
  },
  data() {
    return {
      isLocalLoading: false,
      envelopesReceived: {
        data: [],
        links: {},
        meta: {},
      },
      filters: {
        your_state: '',
        envelope_state: '',
        search: '',
        sort: '-envelope.created_at',
      },
    };
  },
  async created() {
    this.$store.dispatch('page/setTitle', 'Envelopes Recebidos');
    this.$store.dispatch('page/setHead', { title: 'Envelopes' });
    this.$store.dispatch('page/setClass', 'business documents inbox');
    this.fetchEnvelopeReceived();
  },
  computed: {
    isEmptyList() {
      return this.envelopesReceived.data.length === 0;
    },
    isSearching() {
      return (
        this.filters.search.length > 0 ||
        this.filters.your_state.length > 0 ||
        this.filters.envelope_state.length > 0
      );
    },
    showFeedbackNoEnvelopes() {
      return this.isEmptyList && !this.isSearching;
    },
    showFeedbackNoResults() {
      return this.isEmptyList && this.isSearching;
    },
    mountFilterConfig() {
      return [
        {
          name: 'your_state',
          label: 'Seu status',
          selected: null,
          options: [
            {
              label: 'Todos',
              value: '',
            },
          ].concat(RecipientState.getAllValues()),
        },
        {
          name: 'envelope_state',
          label: 'Status do envelope',
          selected: null,
          options: [
            {
              label: 'Todos',
              value: '',
            },
          ].concat(EnvelopeState.getAllSentStates()),
        },
      ];
    },
    isRecipientsHaveOrder() {
      if (this.envelopeRecipients.data.length === 0) return null;
      return this.envelopeRecipients.data[0].routing_order;
    },
  },
  methods: {
    fetchEnvelopeReceived(page = 1) {
      this.$loading(true);
      const params = new URLSearchParams({
        ...{ include: 'envelope.recipients_stats' },
        ...(this.filters.your_state && {
          'filter[state]': this.filters.your_state,
        }),
        ...(this.filters.envelope_state && {
          'filter[envelope.state]': this.filters.envelope_state,
        }),
        ...{ sort: this.filters.sort },
        ...(this.filters.search && { q: this.filters.search }),
        ...{ page },
      });
      EnvelopesService.listReceived(params.toString())
        .then((response) => {
          this.envelopesReceived = response.data;
        })
        .catch((error) => {
          alert.fireAlert(
            `Erro ao consultar envelopes recebidos. ${error.response.data.message}`,
            {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
              icon: 'triangle-exclamation',
            }
          );
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    searchEnvelopesReceived: debounce(function searchEnvelopesReceived() {
      this.fetchEnvelopeReceived();
    }, 300),
    updateFilter(selectedFilters) {
      Object.keys(selectedFilters).forEach((key) => {
        this.filters[key] = selectedFilters[key].value;
      });
      this.fetchEnvelopeReceived();
    },
    setSortColumn(column) {
      this.filters.sort = `${column.desc ? '-' : ''}${column.column}`;
      this.fetchEnvelopeReceived();
    },
  },
};
</script>
<style scoped lang="stylus">
.form-control-icon--end-line
  >.icon
    top: .9em
</style>
