<template lang="pug">
modal#onboarding-recipient-modal(
  :cancelAction='backAction'
  :process-button-label='processButtonLabel'
  :show-cancel-button='showCancelButton'
  @process='process'
  closeButtonLabel='Voltar'
  ref='modal'
  size='sm'
  title='Adicionar destinatários'
)
  .col-12
    template(v-if='step === 1')
      p Para começar, adicione um destinatário.
      .text-center
        img.img-fluid(src='/img/ilustrations/onboarding-novo-destinatario.gif')
    template(v-if='step === 2')
      p Configure ao menos um destinatário assinante para avançar.
      .text-center
        img.img-fluid(
          src='/img/ilustrations/onboarding-select-option-destinatario.gif'
        )
    template(v-if='step === 3')
      p Clique na seta para voltar etapas.
      .text-center
        img.img-fluid(
          src='/img/ilustrations/onboarding-use-stepbar-destinatario.gif'
        )
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      step: 1,
      finalStep: 3,
    };
  },
  computed: {
    processButtonLabel() {
      return this.step === this.finalStep ? 'Ok' : 'Próximo';
    },
    showCancelButton() {
      return this.step > 1;
    },
  },
  methods: {
    process() {
      if (this.step === this.finalStep) {
        this.$refs.modal.close();
        this.$emit('finished');
        return;
      }
      this.step += 1;
    },
    backAction() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
  },
};
</script>
