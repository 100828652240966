import Http from '@/services/Http';

const path = 'roles';
const client = new Http(path);

client.create = (data) => client.post(data, '/roles', '');

client.list = (pathParams) =>
  client.get('', '', pathParams === undefined ? '' : `?${pathParams}`);

client.search = (q) => client.get('', { q }, '');

client.update = (roleId, data) => client.patch(roleId, data, '/roles', '');

client.remove = (roleId) => client.delete(roleId, '', '/roles', '');

client.addUser = (roleId, users) =>
  client.post(users, `/roles/${roleId}/users`, '');

client.listEspecificRole = (roleId, params) =>
  client.get(roleId, params, '', '');

client.listUsers = (roleId) => client.get('', '', `/${roleId}/users`, '');

client.associateUser = (roleId, users) =>
  client.put(roleId, users, `/roles/${roleId}/users`, '');

client.removeUser = (roleId, userId) =>
  client.delete(userId, '', `roles/${roleId}/users`, '');

export default client;
