import Http from './Http';

const client = new Http('');

client.list = (pathParams) => client.get('', pathParams, '/items');

client.listPublishedItem = (tokenId = '') =>
  client.get(tokenId, '', '/published-items');

client.removeItem = (itemId) => client.delete(itemId, '', '/items');

client.createDirectory = (name, parent = '') => {
  const data = { name };
  if (parent) {
    data.parent = parent;
  }
  return client.post(data, '/items');
};

client.getItem = (itemId) => client.get(itemId, '', '/items');

client.uploadFile = (blob, parent = null, onUploadProgress = null) => {
  const formData = new FormData();
  formData.append('file', blob);
  if (parent) {
    formData.append('parent', parent);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    onUploadProgress,
  };
  return client.post(formData, '/items/upload', config);
};

client.renameItem = (itemId, name) => client.patch(itemId, { name }, '/items');

client.shareItem = (itemId, users) =>
  client.put(`${itemId}/shares`, { users }, '/items');

client.unshareItem = (itemId) =>
  client.delete(`${itemId}/shares`, '', '/items');

client.publishItem = (itemId) => client.post('', `/items/${itemId}/publish`);

client.moveItem = (itemId, parent) =>
  client.put(`${itemId}/move`, { parent }, '/items');

client.unpublishItem = (itemId) =>
  client.delete(`${itemId}/publish`, '', '/items');

client.download = (itemId) => {
  const config = {
    responseType: 'blob',
  };
  return client.get(`${itemId}/download`, '', '/items', config);
};

export default client;
