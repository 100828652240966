<template lang="pug">
.file-upload-item(
  :class='getClass'
  :title='errorMessage'
  data-placement='center'
  data-toggle='tooltip'
)
  .file-content
    .file-name(:title='payload.file.name') {{ payload.file.name }}
    button.btn.btn-link.btn-sm(
      :disabled='isDeleting'
      @click='removeFile'
      title='Remover'
      v-if='isUploaded || isDeleting'
    )
      icon.uploaded(
        :blink='isDeleting'
        name='trash-can'
        style='color: #949494'
      )
    button.btn.btn-link.btn-sm(
      @click='removeFileNotUploaded'
      title='Remover'
      v-if='isError'
    )
      icon.icon-state(name='circle-xmark' style='color: #d00a00')
      icon.icon-action(name='trash-can' style='color: #d00a00')
    icon.uploading(
      :blink='true'
      name='cloud-arrow-up'
      style='color: #949494'
      v-if='isUploading'
    )
  .file-error-message(v-if='isError') {{ errorMessage }}
  .file-progress(:style='`width: ${progress}%`' v-show='isUploading')
</template>
<script>
import StorageHttp from '@/services/StorageHttp';
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'FileUploadItem',
  components: {
    Icon,
  },
  props: {
    payload: {
      type: [File, Object],
      required: true,
    },
    folderId: {
      type: String,
      default: null,
    },
    maxFileSizeMb: {
      type: Number,
      default: 5,
    },
    allowedFileType: {
      type: Array,
      default: () => ['application/pdf'],
    },
  },
  data() {
    return {
      progress: 0,
      status: 'uploading',
      errorMessage: '',
    };
  },
  mounted() {
    if (this.payload.file instanceof File) {
      this.uploadFile();
    } else {
      this.status = 'uploaded';
    }
  },
  computed: {
    isUploading() {
      return this.status === 'uploading';
    },
    isError() {
      return this.status === 'error';
    },
    isUploaded() {
      return this.status === 'uploaded';
    },
    isDeleting() {
      return this.status === 'deleting';
    },
    getClass() {
      if (this.isUploading) {
        return 'is-uploading';
      }
      if (this.isError) {
        return 'is-error';
      }
      if (this.isUploaded) {
        return 'is-uploaded';
      }
      return '';
    },
  },
  methods: {
    checkFile() {
      const maxFileSizeBytes = this.maxFileSizeMb * 1024 * 1024;

      if (!this.allowedFileType.includes(this.payload.file.type)) {
        this.errorMessage = `Formato inválido. Tipo de arquivo aceito: ${this.allowedFileType
          .join(', ')
          .replace(/.*\//, '')
          .toUpperCase()}.`;
        this.status = 'error';
        return false;
      }

      if (this.payload.file.size > maxFileSizeBytes) {
        this.errorMessage = `Tamanho máximo excedido. Insira arquivos com até ${this.maxFileSizeMb}MB.`;
        this.status = 'error';
        return false;
      }

      return true;
    },
    uploadFile() {
      if (!this.checkFile()) {
        return;
      }

      StorageHttp.uploadFile(
        this.payload.file,
        this.folderId,
        this.onUploadProgress
      )
        .then((response) => {
          this.uploadFinished(response.data);
        })
        .catch((error) => {
          this.status = 'error';
          if (error.response) {
            const [errorMessage] = error.response.data.errors.file;
            this.errorMessage = errorMessage;
            return;
          }
          this.errorMessage =
            'Erro ao fazer upload do arquivo, verifique sua conexão com a internet.';
        });
    },
    onUploadProgress(progressEvent) {
      this.progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
    uploadFinished(file) {
      this.status = 'uploaded';
      this.$emit('uploadFinished', this.payload, file);
    },
    removeFile() {
      this.status = 'deleting';
      StorageHttp.removeItem(this.payload.file.id)
        .then(() => {
          this.$emit('removeFile', this.payload);
        })
        .catch((error) => {
          this.status = 'error';
          if (error.response) {
            this.errorMessage = error.response.data.message;
            return;
          }
          this.errorMessage =
            'Erro ao remover arquivo, verifique sua conexão com a internet.';
        });
    },
    removeFileNotUploaded() {
      this.$emit('removeFile', this.payload);
    },
  },
};
</script>
<style scoped lang="stylus">
.file-upload-item
  position: relative
  width: 100%
  margin: 10px 0
  border-radius: 3px
  background-color: #FFFFFF
  border: 1px solid #CCCCCC
  &:hover
    box-shadow: 0px 3px 5px #00000012
  &.is-error
    background-color: #FFF9F8
    border: 1px solid #FFEAE9
    &:hover
      button i.icon-state
        display: none
      button i.icon-action
        display: block
  .file-content
    display: flex
    justify-content: space-between
    align-items: center;
    padding: 1rem
    .file-name
      text-align: left
      font-size: .875rem
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    button
      border: 0
      padding: 0
      display: flex
      margin-left: 10px
      &:focus
        box-shadow: none;
      i
        margin-right: 0
        &.icon-action
          display: none
  .file-error-message
    font-size: .688rem
    text-align: left
    color: #D00A00
    background-color: #FFEAE9
    padding: .1rem 1rem
  .file-progress
    position: absolute
    bottom: 0
    background: rgb(0,74,98)
    background: linear-gradient(90deg, rgba(0,74,98,1) 0%, rgba(62,203,144,1) 100%)
    height: 3px
    border-radius: 2px
</style>
