<template lang="pug">
#received.tab-pane.fade.show.active(
  aria-labelledby='received-tab'
  role='tabpanel'
)
  div
    .row(v-if='!showFeedbackNoEnvelopes')
      .mb-3.col-12.col-lg-6.col-xl-5
        .form-group.mb-0.d-flex.align-items-center.justify-content-between.flex-wrap.justify-content-md-start
          .form-control-icon--end-line.flex-grow-1
            input.form-control.rounded-3(
              @input='searchEnvelopesSent()'
              autocomplete='off'
              placeholder='Buscar por nome ou ID'
              v-model='filters.search'
            )
            icon.icon--blue.font-size-subtitle(name='magnifying-glass')
          filter-selector.me-auto(
            :filters='mountFilterConfig'
            @update='updateFilter'
          )
    .row(v-if='!showFeedbackNoEnvelopes')
      .col-12
        sent-envelopes.mb-5(:data='envelopesSent.data' @sort='setSortColumn')
        simple-paginator(
          :payload='envelopesSent'
          @navigate='fetchEnvelopeSent'
        )
  .text-center.mt-5(v-if='showFeedbackNoEnvelopes')
    img.img-fluid.mt-5(src='/img/ilustrations/no-document-sent.svg')
    p.font-color-blue.mt-4
      | Você ainda não criou envelopes para acompanhar.
      br
      | Vamos começar?

  .text-center.mt-5(v-if='showFeedbackNoResults')
    img.img-fluid(src='/img/ilustrations/no-contacts-found.svg')
    p.font-color-blue.mt-4
      | Não encontramos resultados para sua pesquisa.
</template>

<script>
import EnvelopeState from '@/enums/EnvelopeState';
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import debounce from 'debounce';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import NewEnvelopeMobileModal from '@/modules/business/components/organisms/modals/NewEnvelopeMobileModal.vue';
import TableComponent from '@/ui/organisms/Table.vue';
import FilterSelector from '@/ui/organisms/FilterSelector.vue';
import RecipientsLoader from '@/ui/molecules/RecipientsLoader.vue';
import SentEnvelopes from '@/modules/business/components/organisms/card-table/SentEnvelopesCardTable.vue';

export default {
  name: 'DocumentsPage',
  components: {
    Icon,
    IconStatus,
    SimplePaginator,
    EnvelopeState,
    NewEnvelopeMobileModal,
    TableComponent,
    FilterSelector,
    RecipientsLoader,
    SentEnvelopes,
  },
  data() {
    return {
      isLocalLoading: false,
      envelopesSent: {
        data: [],
        links: {},
        meta: {},
      },
      envelopeRecipients: {
        data: [],
        envelopeId: null,
      },
      filters: {
        state: '',
        search: '',
        sort: '-created_at',
      },
    };
  },
  async created() {
    this.$store.dispatch('page/setTitle', 'Envelopes Enviados');
    this.$store.dispatch('page/setHead', { title: 'Envelopes' });
    this.$store.dispatch('page/setClass', 'business documents sent');
    if (
      this.$route.params.state &&
      EnvelopeState.getAllSentStates().find(
        (item) => item.value === this.$route.params.state
      )
    ) {
      this.filters.state = this.$route.params.state;
    }
    this.fetchEnvelopeSent();
  },
  computed: {
    tableHead() {
      return [
        { label: 'ID' },
        { label: 'Nome' },
        { label: 'Data de envio', name: 'created_at', sort: true },
        { label: 'Status do envelope' },
      ];
    },
    getAllSentStates() {
      return EnvelopeState.getAllSentStates();
    },
    isEmptyList() {
      return this.envelopesSent.data.length === 0;
    },
    isSearching() {
      return this.filters.search.length > 0 || this.filters.state.length > 0;
    },
    showFeedbackNoEnvelopes() {
      return this.isEmptyList && !this.isSearching;
    },
    showFeedbackNoResults() {
      return this.isEmptyList && this.isSearching;
    },
    mountFilterConfig() {
      return [
        {
          name: 'state',
          label: 'Status do envelope',
          selected: this.$route.params.state,
          options: [
            {
              label: 'Todos',
              value: '',
            },
          ].concat(EnvelopeState.getAllSentStates()),
        },
      ];
    },
  },
  methods: {
    fetchEnvelopeSent(page = 1) {
      this.$loading(true);
      this.envelopeRecipients.data = [];
      const params = new URLSearchParams({
        ...{ include: 'recipients_stats' },
        ...(this.filters.state && { 'filter[state]': this.filters.state }),
        ...{ sort: this.filters.sort },
        ...(this.filters.search && { q: this.filters.search }),
        ...{ page },
      });
      EnvelopesService.listSent(params.toString())
        .then((response) => {
          this.envelopesSent = response.data;
        })
        .catch((error) => {
          alert.fireAlert(
            `Erro ao consultar envelopes enviados. ${error.response.data.message}`,
            {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
              icon: 'triangle-exclamation',
            }
          );
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    fetchRecipientsState(id) {
      const isOpen = this.envelopeRecipients.envelopeId === id;
      if (isOpen) {
        this.envelopeRecipients = { envelopeId: null, data: [] };
        return;
      }
      this.isLocalLoading = true;
      this.envelopeRecipients.envelopeId = id;
      EnvelopesService.getRecipientsState(id)
        .then((res) => {
          this.envelopeRecipients.data = res.data;
        })
        .finally(() => {
          this.isLocalLoading = false;
        });
    },
    searchEnvelopesSent: debounce(function searchEnvelopesSent() {
      this.fetchEnvelopeSent();
    }, 300),
    updateFilter(selectedFilters) {
      Object.keys(selectedFilters).forEach((key) => {
        this.filters[key] = selectedFilters[key].value;
      });
      this.fetchEnvelopeSent();
    },
    setSortColumn(column) {
      this.filters.sort = `${column.desc ? '-' : ''}${column.column}`;
      this.fetchEnvelopeSent();
    },
  },
};
</script>

<style lang="stylus" scoped>
.recipient-loading
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  width: 100%;
.wrapper
  justify-content: center;
  animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940)
.recipient
  height: 10px;
  width: 60%;
  background: #777;
  margin-top: 10px;
@keyframes fullView
  100%
    width 100%
.animate
  animation: shimmer 2s infinite linear
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)
  background-size: 1000px 100%
@keyframes shimmer
  0%
    background-position -1000px 0
  100%
    background-position 1000px 0

@media screen and (max-width: 768px)
  .btn--new-envelope
    margin-bottom: 2rem
    width: 100%
.form-control-icon--end-line
  >.icon
    top: .9em
.form-group
  margin-bottom: 0.5rem
</style>
