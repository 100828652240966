<template lang="pug">
modal#delete-contact-modal(
  :buttonColor='buttonColor'
  :loading='loading'
  @process='deleteContact'
  close-button-label='Cancelar'
  process-button-label='Excluir'
  processButtonClass='btn-danger'
  ref='modal'
  size='sm'
  title='Excluir Contato'
)
  p Tem certeza que deseja excluir o contato <strong> {{ contact.email }} </strong>?
  img.d-flex.m-auto.mt-5.text-center(
    src='/img/ilustrations/contact-delete-warning.svg'
  )
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import Icon from '@/ui/atoms/Icon.vue';
import ContactService from '@/modules/account/services/http/ContactsService';
import alert from '@/modules/account/common/alert';

export default {
  props: {
    contact: Object,
  },
  components: {
    Modal,
    Icon,
    ImageLoader,
  },
  data() {
    return {
      loading: true,
      buttonColor: true,
      errors: {},
    };
  },
  methods: {
    deleteContact() {
      this.$loading(true);
      ContactService.remove(this.contact.id)
        .then((res) => {
          if (res.status === 204) {
            alert.fireAlert('Contato excluído com sucesso', {
              classes: 'alert-success',
              styles:
                'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
              icon: 'circle-check',
            });
            this.$refs.modal.close();
            this.$emit('refresh');
          }
        })
        .finally(() => {
          this.$loading(false);
        });
    },
  },
};
</script>
