import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import FileSaver from 'file-saver';
import alert from '@/modules/account/common/alert';
import EnvelopeDownloads from './EnvelopeDownloads';
import axios from 'axios';

export default class EnvelopePrivateDownloads extends EnvelopeDownloads {
  constructor(envelopeId) {
    super();
    this.envelopeId = envelopeId;
    this.envelopeData = null;
  }

  async _getEnvelopeData() {
    this.envelopeData = await EnvelopesService.listEspecificEnvelope(
      this.envelopeId,
      { extended: true }
    );
  }

  async downloadDocuments(subject) {
    //Fetch dos dados do envelope
    await this._getEnvelopeData();

    //Extração do nome do arquivo
    var [subjectWithoutExtension] = subject.split('.');

    try {
      if (this.envelopeData.data.documents.length > 1) {
        /**
         * Download do arquivo .zip no caso do
         * envelope ter mais de um documento
         */
        const response = await EnvelopesService.download(this.envelopeId);
        FileSaver.saveAs(response.data, `${subjectWithoutExtension}.zip`);
        return;
      }

      /**
       * Download do arquivo .pdf no caso do
       * envelope ter apenas um documento
       */
      const response = await axios.get(
        this.envelopeData.data.documents[0].links.download,
        { responseType: 'blob' }
      );
      var file = new Blob([response.data], {
        type: 'application/pdf;charset=utf-8',
      });
      FileSaver.saveAs(file, `${subjectWithoutExtension}.pdf`);
    } catch (error) {
      if (error.response.status === 404) {
        alert.fireAlert('Envelope não encontrado.', {
          classes: 'alert-danger',
          styles:
            'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
          tag: 'httpAlert',
          icon: 'triangle-exclamation',
        });
      }
    }
  }

  async downloadSignaturePage(subject) {
    //Extração do nome do arquivo
    var [subjectWithoutExtension] = subject.split('.');

    //Download do arquivo .zip
    try {
      const response = await EnvelopesService.downloadBackgroundSheet(
        this.envelopeId
      );
      FileSaver.saveAs(response.data, `${subjectWithoutExtension}.zip`);
    } catch (error) {
      if (error.response.status === 404) {
        alert.fireAlert('Envelope não encontrado.', {
          classes: 'alert-danger',
          styles:
            'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
          tag: 'httpAlert',
          icon: 'triangle-exclamation',
        });
      }
    }
  }
}
