<template lang="pug">
.envelope-viewer--screen(v-if='isReady')
  .row(:class='{ blured: blured }')
    .col-12.col-lg-9.viewer
      documents-viewer(
        :document='payload.envelope.documents[0]'
        :documents='payload.envelope.documents'
        :envelope='payload.envelope'
        :recipient-id='payload.id'
        @element-updated='onElementUpdate'
        @empty-fields='emptyFields = $event'
        @loaded='documentLoaded = $event'
        @signature-updated='onSignatureUpdate'
      )
    .col-12.col-lg-3.recipients-list
      signatures-list-dropdown(:envelope='payload.envelope')
    .col-12.actions-bar.my-3(:class='{ loaded: documentLoaded }')
      bottom-action(
        :disableOptions='!isAllowed || emptyFields.length > 0'
        :payload='payload'
        @canceled='canceled'
        @rejected='rejected'
        @signatureFailure='signatureFailure'
        @signed='signed'
      )
  show-envelope-info-modal(
    :envelope='payload.envelope'
    :hideFormAcceptTerms='hideFormAcceptTerms'
    :recipientType='payload.type'
    @closed='checkIfIsAllowed'
    @process='markAsAllowed'
    ref='showEnvelopeInfoModal'
  )
  show-envelope-done-modal(
    :envelopeId='payload.envelope.id'
    :is-public='isPublic'
    ref='showEnvelopeDoneModal'
  )
  show-signature-failure-modal(ref='showSignatureFailureModal')
  approve-envelope-modal(
    :is-public='isPublic'
    :subject='payload.envelope.subject'
    ref='approveEnvelopeModal'
  )
  show-request-signature-sent-modal(
    :is-public='isPublic'
    ref='showRequestSignatureSentModal'
  )
</template>
<script>
import RecipientsService from '@/modules/business/services/http/envelopes/RecipientsService';
import SignatureHttp from '@/services/SignatureHttp';
import DocumentsViewer from '@/ui/organisms/DocumentsPDFViewer.vue';
import EnvelopeManager from '@/modules/business/services/EnvelopeManager';
import ShowEnvelopeInfoModal from '@/modules/business/components/organisms/modals/ShowEnvelopeInfoModal.vue';
import ShowEnvelopeDoneModal from '@/modules/business/components/organisms/modals/ShowEnvelopeDoneModal.vue';
import BottomAction from '@/ui/organisms/DocumentsBottom.vue';
import Icon from '@/ui/atoms/Icon.vue';
import EnvelopeState from '@/enums/EnvelopeState';
import RecipientState from '@/enums/RecipientState';
import RecipientType from '@/enums/RecipientType';
import ShowSignatureFailureModal from '@/modules/business/components/organisms/modals/ShowSignatureFailureModal.vue';
// eslint-disable-next-line max-len
import ShowRequestSignatureSentModal from '@/modules/business/components/organisms/modals/RequestSignatureSentModal.vue';
import ApproveEnvelopeModal from '@/modules/business/components/organisms/modals/ApproveEnvelopeModal.vue';
import SignaturesListDropdown from '@/modules/business/components/organisms/SignatureListAccordion.vue';
import alert from '@/modules/account/common/alert';

export default {
  name: 'EnvelopeViewerPage',
  components: {
    DocumentsViewer,
    ShowEnvelopeInfoModal,
    ShowEnvelopeDoneModal,
    Icon,
    BottomAction,
    ShowSignatureFailureModal,
    ShowRequestSignatureSentModal,
    ApproveEnvelopeModal,
    SignaturesListDropdown,
  },
  props: {
    noTitleBackButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      documentLoaded: false,
      payload: null,
      isReady: false,
      isAllowed: false,
      signatures: {
        initial: '',
        signature: '',
      },
      emptyFields: [],
      blured: true,
    };
  },
  async created() {
    this.$loading(true);
    const self = this;
    const pageTitle = 'Envelope recebido';
    this.$store.dispatch('page/setClass', 'envelope-viewer');
    this.$store.dispatch('page/setTitle', pageTitle);
    const head = {
      title: pageTitle,
    };
    if (!this.noTitleBackButton) {
      head.action = function h() {
        let routeName = 'business.documents';
        if (self.isPublic) {
          routeName = 'public.checker';
        }
        self.$router.push({ name: routeName });
      };
    }
    this.$store.dispatch('page/setHead', head);
    await this.getSignatures();
    await this.fetchEnvelope();

    this.isReady = true;

    this.$nextTick(() => {
      this.$refs.showEnvelopeInfoModal.$refs.modal.open();
    });

    if (!this.isPublic) {
      this.blured = false;
    }

    this.$loading(false);
  },
  computed: {
    isPublic() {
      return this.$route.name.split('.').shift() === 'public';
    },
    envelopeCanBeSigned() {
      return this.payload.envelope.state === EnvelopeState.IN_TRANSIT;
    },
    hideFormAcceptTerms() {
      return (
        !this.isPublic ||
        !this.envelopeCanBeSigned ||
        this.payload.state !== RecipientState.WAITING
      );
    },
    userCanSing() {
      return (
        this.payload.type === RecipientType.SIGNER &&
        this.payload.state === RecipientState.WAITING
      );
    },
    isAllowToDownloadSignaturePage() {
      return this.payload.envelope.state === EnvelopeState.COMPLETED;
    },
    // hasFieldsToFill() {
    //   return this.getOnlyFieldElementsEmpty.some((e) => e.required);
    // }
  },
  methods: {
    async fetchEnvelope() {
      try {
        RecipientsService.supressDefault = true;
        const res = await RecipientsService.listEspecificRecipientEnvelope(
          this.$route.params.token
        );
        this.processEnvelope(res);
      } catch (error) {
        if (error.response.status === 400) {
          alert.fireAlert('Token invalido', {
            classes: 'alert-danger',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
            icon: 'triangle-exclamation',
          });
          this.$router.replace({ path: '/not-found' });
        }
      } finally {
        RecipientsService.supressDefault = false;
      }
    },
    processEnvelope(res) {
      this.payload = res.data;
      this.payload.envelope.documents = res.data.envelope.documents.map(
        (doc) => {
          const document = doc;
          document.src = document.links.display;
          // eslint-disable-next-line max-len
          document.elements = EnvelopeManager.extratElementsFromDocument(
            document,
            this.payload.envelope.recipients,
            this.signatures,
            this.payload.value,
            this.payload.state
          );
          return document;
        }
      );
    },
    async signed(response) {
      if (response.status === 202) {
        this.fetchEnvelope();
        this.$refs.showRequestSignatureSentModal.$refs.modal.open();
        return;
      }
      await this.getSignatures();
      this.processEnvelope(response);
      if (this.payload.type === RecipientType.APPROVER) {
        this.$refs.approveEnvelopeModal.$refs.modal.open();
        return;
      }
      this.$refs.showEnvelopeDoneModal.$refs.modal.open();
    },
    signatureFailure() {
      this.$refs.showSignatureFailureModal.$refs.modal.open();
    },
    rejected(response) {
      this.processEnvelope(response);
    },
    canceled(response) {
      this.processEnvelope(response);
    },
    markAsAllowed() {
      this.blured = false;
      this.isAllowed = true;
    },
    checkIfIsAllowed() {
      if (!this.isAllowed) {
        this.$refs.showEnvelopeInfoModal.$refs.modal.open();
      }
    },
    onElementUpdate(element) {
      RecipientsService.fillField(
        this.$route.params.token,
        element.id,
        element.value
      );
    },
    async onSignatureUpdate(signature, element) {
      this.$loading(true);
      await SignatureHttp.savePublicSignature(
        this.$route.params.token,
        element.type,
        signature.blob
      ).then(async () => {
        await RecipientsService.fillField(
          this.$route.params.token,
          element.id,
          signature.image
        );
        this.$loading(false);
      });
    },
    async getSignatures() {
      this.signatures.initial = await SignatureHttp.getPublicSignature(
        this.$route.params.token,
        'initial'
      );
      this.signatures.signature = await SignatureHttp.getPublicSignature(
        this.$route.params.token,
        'signature'
      );
    },
  },
};
</script>
<style lang="stylus">
.envelope-viewer--screen
  .blured
    .pdf-page
      filter: blur(6px)
  .pdf-render.loading
    top: 1.7rem
  .view__actions
    margin-bottom: 1rem
    text-align: right
  .viewer
    order: 2
  .pdf-pages, .pdf-thumbs
    max-height: calc(100vh - 240px)
    scroll-behavior: smooth
  .recipients-list
    order: 1
  .actions-bar
    order: 3
    display: none
    &.loaded
      display: block
@media(min-width: 992px)
  .envelope-viewer--screen
    .viewer
      order: 1
    .recipients-list
      order: 2
</style>
