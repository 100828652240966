import NewOrganizationPage from '@/modules/business/pages/organizations/NewOrganization.vue';
import OrganizationSettingsPage from '@/modules/business/pages/organizations/OrganizationSettings.vue';
import RolesEdit from '@/modules/business/pages/roles/Edit.vue';
import DashboardPage from '@/modules/business/pages/dashboard/Index.vue';
import DocumentsPage from '@/modules/business/pages/documents/Index.vue';
import DocumentsInboxPage from '@/modules/business/pages/documents/Inbox.vue';
import DocumentsSentPage from '@/modules/business/pages/documents/Sent.vue';
import DocumentsDraftPage from '@/modules/business/pages/documents/Draft.vue';
import DocumentsTemplatePage from '@/modules/business/pages/documents/Template.vue';
import DocumentsInfoPage from '@/modules/business/pages/documents/Info.vue';
import Teams from '@/modules/business/pages/teams/Index.vue';
import TeamsEdit from '@/modules/business/pages/teams/Edit.vue';
import UsersPage from '@/modules/business/pages/users/Index.vue';
import StoragePage from '@/modules/business/pages/storage/Storage.vue';
import EnvelopeSelectDocumentsPage from '@/modules/business/pages/envelopes/SelectDocuments.vue';
import EnvelopeRecipientsPage from '@/modules/business/pages/envelopes/Recipients.vue';
import EnvelopeFieldsPage from '@/modules/business/pages/envelopes/Fields.vue';
import EnvelopeReviewPage from '@/modules/business/pages/envelopes/Review.vue';
import EnvelopeViewerPage from '@/modules/business/pages/envelopes/Viewer.vue';
import EnvelopeDownloadPage from '@/modules/business/pages/envelopes/Download.vue';

import FaceToFaceSigningList from '@/modules/business/pages/envelopes/face-to-face-signing/List.vue';
import FaceToFaceSigningDocument from '@/modules/business/pages/envelopes/face-to-face-signing/Document.vue';
import BusinessLayout from '@/ui/layouts/Business.vue';

import store from './store/index';

export default {
  name: 'business',
  routes: [
    {
      path: '/business/layout',
      component: BusinessLayout,
      redirect: {
        name: 'business',
      },
      children: [
        {
          path: '/business',
          name: 'business',
          redirect: '/business/dashboard',
        },
        {
          path: '/business/dashboard',
          name: 'business.dashboard',
          component: DashboardPage,
        },
        {
          path: '/business/organizations',
          name: 'business.organizations',
          redirect: '/business/organizations/new',
        },
        {
          path: '/business/organizations/settings',
          name: 'business.organizations.settings',
          component: OrganizationSettingsPage,
        },
        {
          path: '/business/organizations/new',
          name: 'business.organizations.new',
          component: NewOrganizationPage,
        },
        {
          path: '/business/roles/edit/:id',
          name: 'business.roles.edit',
          component: RolesEdit,
        },
        {
          path: '/business/documents/',
          name: 'business.documents',
          component: DocumentsPage,
          redirect: { name: 'business.documents.inbox' },
          children: [
            {
              path: 'inbox',
              name: 'business.documents.inbox',
              component: DocumentsInboxPage,
            },
            {
              path: 'sent/:state?',
              name: 'business.documents.sent',
              component: DocumentsSentPage,
            },
            {
              path: 'draft',
              name: 'business.documents.draft',
              component: DocumentsDraftPage,
            },
            {
              path: 'template',
              name: 'business.documents.template',
              component: DocumentsTemplatePage,
            },
          ],
        },
        {
          path: '/business/documents/:status',
          name: 'business.documents.status',
          component: DocumentsPage,
        },
        {
          path: '/business/documents/info/:id/:token?',
          name: 'business.documents.info',
          component: DocumentsInfoPage,
        },
        {
          path: '/business/checker/',
          name: 'business.checker',
          beforeEnter() {
            window.open('https://verificador.iti.gov.br/', '_blank');
          },
        },
        {
          path: '/business/users/',
          name: 'business.users',
          component: UsersPage,
        },
        {
          path: '/business/teams/',
          name: 'business.teams',
          component: Teams,
        },
        {
          path: '/business/teams/edit/:id',
          name: 'business.teams.edit',
          component: TeamsEdit,
        },

        {
          path: '/business/envelopes/new',
          name: 'business.envelopes.new',
          component: EnvelopeSelectDocumentsPage,
        },
        {
          path: '/business/envelopes/:id/documents',
          name: 'business.envelope.documents',
          component: EnvelopeSelectDocumentsPage,
        },
        {
          path: '/business/envelopes/:id/recipients',
          name: 'business.envelope.recipients',
          component: EnvelopeRecipientsPage,
        },
        {
          path: '/business/envelopes/:id/fields',
          name: 'business.envelope.fields',
          component: EnvelopeFieldsPage,
        },
        {
          path: '/business/envelopes/:id/review',
          name: 'business.envelope.review',
          component: EnvelopeReviewPage,
        },
        {
          path: '/business/envelopes/viewer/:token',
          name: 'business.envelopes.viewer',
          component: EnvelopeViewerPage,
        },
        {
          path: '/business/envelopes/download/:token',
          name: 'business.envelopes.download',
          component: EnvelopeDownloadPage,
        },
        {
          path: '/business/envelopes/face-to-face',
          name: 'business.envelopes.faceToFaceList',
          component: FaceToFaceSigningList,
        },
        {
          path: '/business/envelopes/face-to-face/:token',
          name: 'business.envelopes.faceToFaceEnvelope',
          component: FaceToFaceSigningDocument,
        },
        {
          path: '/business/storage',
          name: 'business.storage',
          component: StoragePage,
        },
        {
          path: '/business/storage/:itemId',
          name: 'business.storage.item',
          component: StoragePage,
        },
      ],
    },
  ],
  store,
};
