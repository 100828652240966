<template lang="pug">
modal#remove-organization-modal(
  :buttonColor='buttonColor'
  :loading='loading'
  @process='removeOrganization'
  close-button-label='Cancelar'
  process-button-label='Excluir'
  ref='modal'
  title='Excluir Organização'
)
  p Ao excluir uma organização, os usuários vinculados a ela serão excluídos desta,
    |
    | bem como os envelopes. A ação não poderá ser desfeita.
  p Para continuar, insira sua senha.
  .form-control-icon
    icon(:fix-width='true' name='key')
    input#password.form-control(
      aria-describedby='emailHelp'
      placeholder='Digite sua senha'
      type='password'
      v-model='password'
    )
    button.show-password(
      @click='showPassword'
      data-password-input-id='password'
      type='button'
    )
      icon(:fix-width='true' name='eye')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import Icon from '@/ui/atoms/Icon.vue';
import OrganizationsHttp from '@/services/OrganizationsHttp';

export default {
  name: 'RemoveOrganizationModal',
  props: {
    organizationId: [Number, String],
  },
  components: {
    Modal,
    Icon,
    ImageLoader,
    OrganizationsHttp,
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
      errors: {},
      name: '',
      logo: '',
      organization: {},
      type: 'password',
      password: '',
    };
  },
  methods: {
    removeOrganization() {
      this.loading = true;
      OrganizationsHttp.deleteOrganization(this.organizationId, {
        password: this.password,
      })
        .then(() => {
          this.loading = false;
          this.$refs.modal.close();
          this.$emit('change-organization');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showPassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
  },
};
</script>
