<template lang="pug">
#recipientsAcordion.accordion-flush.accordion.mb-3
  .accordion-item
    h2#flush-heading.accordion-header
      button.accordion-button.collapsed(
        aria-controls='flush-collapse'
        aria-expanded='false'
        data-bs-target='#flush-collapse'
        data-bs-toggle='collapse'
        type='button'
      ) Destinatários
    #flush-collapse.accordion-collapse.collapse(
      abelledby='flush-heading'
      data-bs-parent='#recipientsAcordion'
    )
      signatures-list.accordion-body(:envelope='envelope')
</template>

<script>
import SignaturesList from '@/modules/business/components/molecules/SignaturesList.vue';
import Icon from '@/ui/atoms/Icon.vue';

export default {
  components: {
    SignaturesList,
    Icon,
  },
  props: {
    envelope: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="stylus">
.accordion-item
  .accordion-body
    padding: 0
    border: 1px solid #C4C4C4
    border-radius: 0 0 5px 5px
    ul
      border: none
      width: 100%
  .accordion-button
    background: #E5EBED
    border: 1px solid #C4C4C4
    font-weight: 600
    font-size: 16px
    color: #013D52
    &:focus
      outline: none
      box-shadow: none
      color: #013D52
    &::after
      background-image: url('../../../../../public/images/accordion-arrow.svg')
</style>
