<template lang="pug">
modal#remove-team-modal(
  :buttonColor='buttonColor'
  :loading='loading'
  @process='removeTeam'
  close-button-label='Cancelar'
  process-button-label='Excluir'
  ref='modal'
  title='Excluir time'
)
  .text Ao excluir um time, a ação não poderá ser desfeita
    |
    | e seus usuários poderão ficar sem vinculo a um time.
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import TeamsService from '@/modules/business/services/http/organizations/teams/TeamsService';
import formHandler from '@/modules/account/common/formHandler';
import alert from '@/modules/account/common/alert';

export default {
  name: 'RemoveTeamModal',
  components: {
    Modal,
    TeamsService,
    ImageLoader,
  },
  props: {
    teamId: [Number, String],
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
    };
  },
  methods: {
    async removeTeam() {
      formHandler.submit(this, () => TeamsService.remove(this.teamId), {
        successHandler: (response) => {
          if (response.status === 204) {
            alert.fireAlert('Time removido com sucesso', {
              classes: 'alert-success',
              styles:
                'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
              icon: 'circle-check',
            });
            this.loading = false;
            this.$refs.modal.close();
            this.$router.push('/business/teams');
          }
        },
      });
    },
  },
};
</script>
