export default class EnvelopeDownloads {
  async _getEnvelopeData() {
    throw new Error('This method needs to be implemented');
  }

  async downloadDocuments() {
    throw new Error('This method needs to be implemented');
  }

  async downloadSignaturePage() {
    throw new Error('This method needs to be implemented');
  }
}
