export default {
  namespaced: true,
  state: {
    documents: [],
  },
  actions: {
    reset: ({ commit }) => {
      commit('reset');
    },
    addDocuments: ({ commit }, documents) => {
      commit('addDocuments', documents);
    },
  },
  mutations: {
    reset: (state) => {
      state.documents = [];
    },
    addDocuments: (state, documents) => {
      state.documents = documents;
    },
  },
};
