<template lang="pug">
modal#remove-user-modal(
  :buttonColor='true'
  :loading='loading'
  @process='removeUser'
  close-button-label='Cancelar'
  process-button-label='Excluir'
  ref='modal'
  title='Excluir usuário'
)
  .span.text Ao excluir um usuário, a ação não poderá ser desfeita.
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import TeamsService from '@/modules/business/services/http/organizations/teams/TeamsService';
import UsersService from '@/modules/business/services/http/organizations/users/UsersService';
import RolesService from '@/modules/business/services/http/organizations/roles/RolesService';

export default {
  name: 'RemoveUserModal',
  props: {
    teamId: [Number, String],
    userId: [Number, String],
    roleId: [Number, String],
    operation: Boolean,
  },
  components: {
    Modal,
    ImageLoader,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async removeUser() {
      this.loading = true;
      if (this.operation) {
        await UsersService.remove(this.userId).then(() => {
          this.closeModal();
        });
      } else if (this.teamId != null) {
        await TeamsService.deleteUser(this.teamId, this.userId).then(() => {
          this.closeModal();
        });
      } else {
        await RolesService.removeUser(this.roleId, this.userId).then(() => {
          this.closeModal();
        });
      }
    },
    closeModal() {
      this.$emit('refresh');
      this.loading = false;
      this.$refs.modal.close();
    },
  },
};
</script>
