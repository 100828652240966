const getAbreviation = (text) => {
  if (typeof text !== 'string' || !text) {
    return '';
  }
  if (!/\s/.test(text)) {
    return (text.charAt(0) + text.charAt(1)).toUpperCase();
  }
  const abbrev = text
    .match(/[\p{Alpha}\p{Nd}]+/gu)
    .reduce((previous, next) => previous + next[0], '')
    .toUpperCase();
  return abbrev;
};

export default {
  get: getAbreviation,
};
