<template lang="pug">
.manage-applications-card-table
  card-table(:total-items='data.length' breakpoint-size='lg')
    template(v-for='(client, index) in data' v-slot:[`card-${index}`]='{}')
      .mb-1.text-overflow-ellipse(style='width: 90%')
      .mb-1 
        span.text.me-2 Nome da aplicação: {{ client.name }}
        icon.me-2(
          :key='client.id'
          name='user-secret'
          v-if='client.is_confidential'
          v-tooltip:right='`Aplicação confidencial`'
        )
        icon.me-2(
          :key='client.id'
          name='globe'
          v-else
          v-tooltip:right='`Aplicação pública`'
        )
      .mb-1 
        span.text.me-2 URL de redirecionamento: {{ client.redirect }}
      .mb-1
        span.text.me-2 Client ID: {{ client.id }}
      .mb-0
        span.text.me-2 Ambiente:
        icon-enviroment-client(:isSandbox='client.is_sandbox')
      .dropdown.text-end.mt-2(
        style='position: absolute; top: 0; right: 0; font-size: 1.5em'
      )
        button#clientActionsDropdown.dropdown-toggle(
          @click.stop=''
          data-bs-toggle='dropdown'
        )
          icon.icon--blue(name='ellipsis-vertical')
        .dropdown-menu(aria-labelledby='clientActionsDropdown')
          button.dropdown-item(
            @click='openUpToProductionModal(client)'
            v-if='client.is_sandbox'
          )
            | Publicar
          button.dropdown-item(
            @click='refreshClientSecret(client)'
            v-if='client.is_confidential'
          )
            | Recriar client secret
          button.dropdown-item(@click='openDeleteClientModal(client)')
            | Excluir
    template(v-slot:table-head)
      th(style='width: 25%') 
        span.text Nome da Aplicação
      th(style='width: 20%') 
        span.text Url de redirecionamento
      th(style='width: 35%') 
        span.text Client ID
      th(style='width: 19%')
        span.text Ambiente
      th(style='width: 1%')
        // Coluna de ícones 
    template(
      v-for='(client, index) in data'
      v-slot:[`table-row-${index}`]='{}'
    )
      td
        icon.me-2(
          :key='client.id'
          name='user-secret'
          v-if='client.is_confidential'
          v-tooltip:right='`Aplicação confidencial`'
        )
        icon.me-2(
          :key='client.id'
          name='globe'
          v-else
          v-tooltip:right='`Aplicação pública`'
        )
        span.text {{ client.name }}
      td
        span.text {{ client.redirect }}
      td
        span.text {{ client.id }}
        span.ms-2
          a(
            style='cursor: pointer'
            v-clipboard:copy='client.id'
            v-clipboard:success='onCopy'
          )
            icon(name='copy' v-tooltip:bottom='`Copiar`')
      td
        icon-enviroment-client(:isSandbox='client.is_sandbox')
      td.action.pb-1.text-end
        .dropdown.text-end
          button#clientActionsDropdown.dropdown-toggle(
            @click.stop=''
            data-bs-toggle='dropdown'
          )
            icon.icon--blue(name='ellipsis-vertical')
          .dropdown-menu(aria-labelledby='clientActionsDropdown')
            button.dropdown-item(
              @click='openUpToProductionModal(client)'
              v-if='client.is_sandbox'
            )
              | Publicar
            button.dropdown-item(
              @click='refreshClientSecret(client)'
              v-if='client.is_confidential'
            )
              | Recriar client secret
            button.dropdown-item(@click='openDeleteClientModal(client)')
              | Excluir

  publish-application-modal(
    :client-id='this.selectedClient.id'
    @refresh='listClients'
    ref='publishApplicationModal'
  )
  delete-developer-client-modal(
    :client-id='this.selectedClient.id'
    :isSandbox='this.selectedClient.is_sandbox'
    @refresh='listClients'
    ref='deleteDeveloperClientModal'
  )
  refresh-developer-client-secret-modal(
    :client-id='this.selectedClient.id'
    @showClientSecret='showClientSecret'
    ref='refreshDeveloperClientSecretModal'
  )
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import CardTable from '@/ui/organisms/CardTable.vue';
import IconEnviromentClient from '@/ui/atoms/IconEnviromentClient.vue';
import PublishApplicationModal from '@/modules/account/components/organisms/modals/PublishApplicationModal.vue';
import DeleteDeveloperClientModal from '@/modules/account/components/organisms/modals/DeleteDeveloperClientModal.vue';
// eslint-disable-next-line max-len
import RefreshDeveloperClientSecretModal from '@/modules/account/components/organisms/modals/RefreshDeveloperClientSecretModal.vue';
import alert from '@/modules/account/common/alert';

export default {
  name: 'ReceivedEnvelopesCardTable',
  components: {
    Icon,
    IconStatus,
    CardTable,
    IconEnviromentClient,
    PublishApplicationModal,
    RefreshDeveloperClientSecretModal,
    DeleteDeveloperClientModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedClient: {},
    };
  },
  methods: {
    openUpToProductionModal(client) {
      this.selectedClient = client;
      this.$refs.publishApplicationModal.$refs.modal.open();
    },
    refreshClientSecret(client) {
      this.selectedClient = client;
      this.$refs.refreshDeveloperClientSecretModal.$refs.modal.open();
    },
    openDeleteClientModal(client) {
      this.selectedClient = client;
      this.$refs.deleteDeveloperClientModal.$refs.modal.open();
    },
    listClients() {
      this.$emit('listClients');
    },
    showClientSecret(clientSecret) {
      this.$emit('showClientSecret', clientSecret, true);
    },
    onCopy() {
      alert.fireAlert('Link Copiado', {
        classes: 'alert-success',
        styles:
          'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
        icon: 'circle-check',
      });
    },
  },
};
</script>
<style lang="stylus">
.manage-applications-card-table__name
  max-width: 180px;
@media (min-width: 576px)
  .manage-applications-card-table__name
    max-width: 180px
@media (min-width: 768px)
  .manage-applications-card-table__name
    max-width: 180px
@media (min-width: 992px)
  .manage-applications-card-table__name
    max-width: 500px
@media (min-width: 1200px)
  .manage-applications-card-table__name
    max-width: 470px
@media (min-width: 1400px)
  .manage-applications-card-table__name
    max-width: 640px
</style>
