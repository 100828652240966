<template lang="pug">
modal#request-signature-sent-modal(
  :showCancelButton='false'
  @process='closeModal'
  process-button-label='Ok'
  ref='modal'
  title='Pedido de assinatura enviado'
)
  .text Estamos processando sua assinatura! Acompanhe o andamento na
    |
    | lista de documentos recebidos.
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/request-signature-sent.svg')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'RequestSignatureSentModal',
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.$refs.modal.close();
    },
  },
};
</script>
