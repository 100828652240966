import enumBase from './EnumBase';

export default class EnvelopeStatus extends enumBase {
  static DRAFT = 'draft';

  static PENDING = 'pending';

  static COMPLETED = 'completed';

  static CANCELLED = 'cancelled';

  static EXPIRED = 'expired';

  static REJECTED = 'rejected';

  static values = [
    { value: this.DRAFT, label: 'Rascunho' },
    { value: this.PENDING, label: 'Pendente' },
    { value: this.COMPLETED, label: 'Finalizado' },
    { value: this.CANCELLED, label: 'Cancelado' },
    { value: this.EXPIRED, label: 'Expirado' },
    { value: this.REJECTED, label: 'Rejeitado' },
  ];

  static getAllSentStatus() {
    return this.values.filter((item) => item.value !== this.DRAFT);
  }
}
