import Http from '@/services/Http';

const path = 'teams';

const teams = new Http(path);

teams.create = (teamName) => teams.post({ name: teamName }, path);

teams.list = (pathParams) =>
  teams.get('', '', pathParams === undefined ? '' : `?${pathParams}`);

teams.update = (teamId, teamName) =>
  teams.patch(teamId, { name: teamName }, path);

teams.remove = (teamId) => teams.delete(`${teamId}`);

teams.searchTeams = (q) => teams.get('', { q }, '');

teams.addUser = (teamId, users) => teams.post(users, `teams/${teamId}/users`);

teams.listEspecificTeam = (teamId, params) => teams.get(teamId, params, '');

teams.listUsers = (teamId) => teams.get(`${teamId}/users`);

teams.associateUser = (teamId, users) =>
  teams.put(`teams/${teamId}/users`, users);

teams.deleteUser = (teamId, userId) =>
  teams.delete(`${teamId}/users/${userId}`);

export default teams;
