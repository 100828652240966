import Http from '@/services/Http';

const path = 'validate';

const validate = new Http(path);

validate.validate = (code) =>
  validate.post({ code }, `p/envelopes/documents/${path}`);

export default validate;
