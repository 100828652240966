<template lang="pug">
.filter-element.d-flex.align-middle.ms-2
  .dropdown
    button#filterElementDropdown.dropdown-toggle.rounded-circle(
      aria-expanded='false'
      data-bs-auto-close='outside'
      data-bs-toggle='dropdown'
    )
      icon.font-color-blue.font-size-subtitle(name='filter')
    ul.dropdown-menu.p-4(
      @click.prevent=''
      aria-labelledby='filterElementDropdown'
    )
      .filters(v-if='hasFilters')
        .filter.mb-3(v-for='filter in filters')
          p.mb-2 {{ filter.label }}
          aol-select(
            :name='filter.name'
            :options='filter.options'
            :searchable='false'
            v-model='model[filter.name]'
          )
            template(v-slot:no-options='{}') Nenhum item encontrado
      .no-filters.font-color-blue(v-if='!hasFilters') Não há filtros definidos
      .d-flex.justify-content-between.mt-3
        .btn.btn-secondary.btn-sm(@click='reset') Limpar
        .btn.btn-primary.btn-sm(@click='apply') Aplicar
  .filters-selected.d-flex.align-center(v-if='loaded && hasFiltersSelected')
    .filter-selected.px-2.font-color-blue.font-size-small(
      v-for='filter in filters'
      v-if='modelAplied[filter.name].value'
    )
      | {{ filter.label }}:
      span {{ modelAplied[filter.name].label }}
        button.font-color-blue(@click='removeFilter(filter.name)')
          icon(name='xmark')
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'FilterSelector',
  components: {
    Icon,
  },
  props: {
    filters: {
      type: Array,
      default: () => [
        {
          name: 'filter_1',
          label: 'Filtro 1',
          selected: null,
          options: [
            {
              label: 'Opção 1',
              value: 'option_1',
            },
            {
              label: 'Opção 2',
              value: 'option_2',
            },
          ],
        },
      ],
    },
  },
  data() {
    return {
      dropdownObject: null,
      model: {},
      modelAplied: {},
      loaded: false,
    };
  },
  mounted() {
    if (this.hasFilters) {
      this.model = this.getStartDefaultOptions();
      this.modelAplied = { ...this.model };
    }
    const dropdownElement = document.getElementById('filterElementDropdown');
    this.dropdownObject = new window.bootstrap.Dropdown(dropdownElement);
    this.loaded = true;
  },
  computed: {
    hasFilters() {
      return this.filters.length > 0;
    },
    hasFiltersSelected() {
      let result = false;
      Object.keys(this.modelAplied).forEach((key) => {
        if (this.modelAplied[key].value.length) {
          result = true;
        }
      });
      return result;
    },
  },
  methods: {
    close() {
      this.dropdownObject.toggle();
    },
    apply() {
      this.modelAplied = { ...this.model };
      this.$emit('update', this.modelAplied);
      this.close();
    },
    reset() {
      Object.assign(this.model, this.getDefatulOptions());
      this.apply();
    },
    removeFilter(name) {
      this.model[name] = this.getDefatulOptionByName(name);
      this.apply();
    },
    getDefatulOptions() {
      const model = {};
      this.filters.forEach((filter) => {
        const [firstOption] = filter.options;
        model[filter.name] = firstOption;
      });
      return model;
    },
    getDefatulOptionByName(name) {
      const [firstOption] = this.filters.find(
        (filter) => filter.name === name
      ).options;
      return firstOption;
    },
    getSelectedOptionByFilter(filter) {
      const [selectedOption] = filter.options.filter(
        (option) => option.value === filter.selected
      );
      return selectedOption;
    },
    getStartDefaultOptions() {
      const model = {};
      this.filters.forEach((filter) => {
        model[filter.name] = this.getDefatulOptionByName(filter.name);
        if (filter.selected) {
          const selected = this.getSelectedOptionByFilter(filter);
          if (selected) {
            model[filter.name] = selected;
          }
        }
      });
      return model;
    },
  },
};
</script>
<style scoped lang="stylus">
.filter-element
  .dropdown-menu
    overflow: visible
    width: 270px
  button#filterElementDropdown
    padding: 6px 11px
    border: none
    &:hover
      background-color: #F0F0F0
    &.dropdown-toggle::after
      display: none
  .filters-selected
    overflow: auto
    margin: 7px 0
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none
    .filter-selected
      display: flex
      align-items: center
      white-space: nowrap
      &:first-child
        border-left: 1px solid #ccc
    span
      position: relative
      padding: 0 10px
      padding-right: 25px
      background-color: #E5EBED
      border: 1px solid #C3D1D6
      margin-left: 5px
      border-radius: 5px
      white-space: nowrap
      &:hover
        border-color: #013D52
      button
        position: absolute
        top: 50%
        right: -1px
        margin-top: -13px
@media screen and (max-width: 576px)
  .filter-element
    max-width: 100%
</style>
