<template lang="pug">
.row(v-if='role')
  .col-6.mt-3
    form(@submit.prevent='save' style='max-width: 520px')
      .form-group
        label(for='exampleInputRole') Nome da função
        .form-control-icon
          icon(:fix-width='true' name='user-tie')
          input#exampleInputRole.form-control(
            disabled
            placeholder='Digite um nome'
            type='text'
            v-model='role.name'
          )
      .form-group
        .row
          .col-12.col-sm-6
            .form-check
              input#check-1.form-check-input(
                @input='onUpdateRoles()'
                type='checkbox'
                v-model='role.edit_organization'
                value='1'
              )
              label.form-check-label(for='check-1') Editar Organizações
          .col-12.col-sm-6
            .form-check
              input#check-2.form-check-input(
                @input='onUpdateRoles()'
                type='checkbox'
                v-model='role.manage_users'
                value='2'
              )
              label.form-check-label(for='check-2') Gerenciar Usuários
          .col-12.col-sm-6
            .form-check
              input#check-3.form-check-input(
                @input='onUpdateRoles()'
                type='checkbox'
                v-model='role.manage_teams'
                value='3'
              )
              label.form-check-label(for='check-3') Gerenciar Times
          .col-12.col-sm-6.pt-2
            .form-check
              input#check-4.form-check-input(
                @input='onUpdateRoles()'
                type='checkbox'
                v-model='role.send_envelopes'
                value='4'
              )
              label.form-check-label(for='check-4') Enviar envelopes
          .col-12.col-sm-6
            .form-check
              input#check-5.form-check-input(
                @input='onUpdateRoles()'
                type='checkbox'
                v-model='role.manage_billing'
                value='5'
              )
              label.form-check-label(for='check-5') Gerenciar faturamento

  .col-6.mt-3.text-end
    button.btn.btn--cancel-red-text.me-3.align-middle.shadow(
      data-bs-target='#remove-role-modal'
      data-bs-toggle='modal'
      type='button'
    )
      span.text Excluir Função

  .col-6.mt-3
    h5 Usuários
  .col-6.mt-3.text-end
    button.btn.btn-secondary(
      data-bs-target='#new-user-modal'
      data-bs-toggle='modal'
      type='button'
    ) Adicionar
  .col-12
    .row.mt-3.g-3(v-if='users.length > 0')
      .col-12.col-sm-6.col-md-4.col-xl-3.mb-1(
        :key='user.id'
        v-for='user in users'
      )
        .card.card--mini
          .card-body
            button.action-remove(
              @click='setUserIdModal(user.id)'
              data-bs-target='#remove-user-modal'
              data-bs-toggle='modal'
              type='button'
            )
              icon(name='trash-can')
            img.img.rounded-circle.mb-2.mt-2(:src='user.avatar' width='38px')
            h5.card-title.mb-0 {{ user.name }}
            small {{ user.email }}

    .row(v-else)
      .col-12
        img(
          src='/images/empty-users.svg'
          style='display: block; margin-left: auto; margin-right: auto'
          vertical-align='middle'
        )
      .col-12.mt-4
        .text-center Você ainda não tem usuários nessa função.

  new-user-modal(
    :operation='operation'
    :role-id='role.id'
    @refresh='listEspecificRole'
  )
  remove-user-modal(
    :role-id='role.id'
    :user-id='userId'
    @refresh='listEspecificRole'
  )
  remove-role-modal(:role-id='role.id')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import NewUserModal from '@/modules/business/components/organisms/modals/NewUserModal.vue';
import RemoveUserModal from '@/modules/business/components/organisms/modals/RemoveUserModal.vue';
import RemoveRoleModal from '@/modules/business/components/organisms/modals/RemoveRoleModal.vue';
import RolesService from '@/modules/business/services/http/organizations/roles/RolesService';
import debounce from 'debounce';
import formHandler from '@/modules/account/common/formHandler';

export default {
  name: 'RolesPage',
  components: {
    Icon,
    NewUserModal,
    RemoveUserModal,
    RemoveRoleModal,
    RolesService,
  },
  data() {
    return {
      role: '',
      users: [],
      allUsers: null,
      userId: '',
      roleName: '',
      operation: true,
    };
  },

  created() {
    this.$store.dispatch('page/setTitle', 'Editar Função');
    this.$store.dispatch('page/setHead', { title: 'Editar Função' });
    this.listEspecificRole();
  },

  methods: {
    setUserIdModal(value) {
      this.userId = value;
    },

    onUpdateRoles: debounce(function update() {
      this.updateRoles();
    }, 10),

    async updateRoles() {
      const payload = {
        name: this.role.name,
        send_envelopes: this.role.send_envelopes,
        edit_organization: this.role.edit_organization,
        manage_teams: this.role.manage_teams,
        manage_users: this.role.manage_users,
        manage_billing: this.role.manage_billing,
      };

      formHandler.submit(
        this,
        () => RolesService.update(this.role.id, payload),
        {
          fieldsByPropertyName: {
            name: 'name',
            send_envelopes: 'envelopes',
            edit_organization: 'editar organização',
            manage_teams: 'gerenciar times',
            manage_users: 'gerenciar usuários',
            manage_billing: 'gerenciar faturamento',
          },
          successHandler: (response) => {
            formHandler.defaultSuccess(response);
          },
        }
      );
    },

    async listEspecificRole() {
      const role = await RolesService.listEspecificRole(this.$route.params.id, {
        extended: true,
      });
      this.role = role.data;
      this.roleName = role.data.name;
      this.users = role.data.users;
    },
  },
};
</script>
