<template lang="pug">
modal#new-storage-folder-modal(
  :no-footer='true'
  @process='submit'
  ref='modal'
  title='Nova Pasta'
)
  ValidationObserver(ref='form' v-slot='{ handleSubmit, passes, invalid }')
    form.form(@submit.prevent='handleSubmit(submit)')
      .row
        .col-12
          ValidationProvider(
            name='name'
            rules='required'
            v-slot='{ errors }'
          )
            .form-group
              label(for='name') Nome da pasta
              .form-control-icon
                icon(name='folder')
                input#name.form-control(
                  :class='{ "is-invalid": errors.length }'
                  autocomplete='off'
                  placeholder='Digite o nome da pasta'
                  type='text'
                  v-model='form.name'
                )
              small.text.text-danger {{ errors[0] }}
          .d-flex.justify-content-end
            button.btn.btn-secondary.me-2(@click='cancel' type='button') Cancelar
            button.btn.btn-primary(:disabled='invalid' type='submit') Criar
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import Icon from '@/ui/atoms/Icon.vue';
import StorageHttp from '@/services/StorageHttp';

export default {
  components: {
    Modal,
    Icon,
  },
  data() {
    return {
      form: {
        name: '',
      },
    };
  },
  methods: {
    submit() {
      this.$loading(true);
      StorageHttp.supressDefault = true;
      StorageHttp.createDirectory(this.form.name, this.$route.params.itemId)
        .then(() => {
          this.$emit('created');
          this.resetForm();
          this.$refs.modal.close();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$refs.form.setErrors(error.response.data.errors);
          }
        })
        .finally(() => {
          StorageHttp.supressDefault = false;
          this.$loading(false);
        });
    },
    cancel() {
      this.$refs.modal.close();
    },
    resetForm() {
      this.form.name = '';
      this.$refs.form.reset();
    },
  },
};
</script>
