<template lang="pug">
modal#collect-recipient-modal(
  :no-footer='true'
  :no-header='true'
  data-bs-backdrop='static'
  data-bs-keyboard='false'
  ref='modal'
  size='sm'
)
  .text-center
    .mb-4
      img.img-fluid(src='/img/assine-online-loading.gif' style='width: 50%')
    p {{ text }}
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'QualifiedSignatureLoadingModal',
  components: {
    Icon,
    Modal,
  },
  data() {
    return {
      text: '',
    };
  },
  methods: {
    selectType(type) {
      this.$emit('update-type', type);
      this.$refs.modal.close();
    },
  },
};
</script>
