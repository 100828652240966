<template lang="pug">
ValidationObserver(ref='form' v-slot='{ handleSubmit, invalid }')
  envelope-step-bar(:step='4')
  form(@submit.prevent='handleSubmit(submit)')
    .col-12.mt-4
      .card.card--rounded
        .card-body.p-4
          form.form
            .row
              .col-12.col-sm-6
                ValidationProvider(
                  name='nome do envelope'
                  rules='required'
                  v-slot='{ errors }'
                )
                  .form-group
                    label.review-label(for='subject') Nome do envelope
                    .form-control-icon
                      icon(name='file')
                      input#subject.form-control(
                        :class='{ "border-danger": remainingCaracters.title === 0 }'
                        autocomplete='off'
                        maxlength='60'
                        placeholder='Digite o nome do envelope'
                        type='text'
                        v-model='title'
                      )
                      max-text-counter(
                        :limit='60'
                        :value='title'
                        @remaining='remainingCaracters.title = $event'
                      )
                    small.text.text-danger {{ errors[0] }}
              .col-12.col-sm-6
                .form-group
                  label.review-label(for='subject') Salvar documento na pasta
                  .form-control-icon.form-control-icon-select-folder(
                    @click='openModalSelectFolder'
                  )
                    icon(name='folder')
                    .text.form-control {{ textSelectedFolder }}
                      icon.text-end(name='chevron-right')
              .col-12.col-sm-6
                ValidationProvider(
                  name='envelope-limit-date'
                  rules='invalidDate'
                  v-slot='{ errors }'
                )
                  .form-group
                    label.review-label(for='signature-date-limit') Prazo para assinatura
                    .form-control-icon
                      icon(name='calendar')
                      date-picker-input#signature-date-limit.form-control(
                        :dateValue='expire_at'
                        :min-date='datePickMinDate'
                        @change='validateDate($event, "signature-date-limit")'
                        placeholder='Digite uma data limite'
                        type='text'
                        v-model='expire_at'
                      )
                    small.text.text-danger {{ errors[0] }}
              .col-12.col-sm-6(v-if='enableSignatureReminder')
                .form-group
                  label.review-label(for='signature-reminder')
                    | Lembrete para o prazo de assinatura
                  select#signature-reminder.form-control(
                    v-model='action_reminder_frequency'
                  )
                    option(:value='null') Selecione uma opção
                    option(value='24') Diariamente
                    option(value='72') A cada 3 dias
                    option(value='120') A cada 5 dias
    .col-12.mt-4
      .card.card--rounded
        .card-body.p-4
          .row
            .col-12.col-sm-6
              ValidationProvider(
                name='signature-date-limit'
                rules='invalidDate'
                v-slot='{ errors }'
              )
                .form-group
                  label.review-label(for='subject') Assunto
                  .form-control-icon
                    icon(name='paper-plane')
                    input#subject.form-control(
                      :class='{ "border-danger": remainingCaracters.subject === 0 }'
                      autocomplete='off'
                      maxlength='60'
                      placeholder='Digite um assunto'
                      type='text'
                      v-model='subject'
                    )
                    max-text-counter(
                      :limit='60'
                      :value='subject'
                      @remaining='remainingCaracters.subject = $event'
                    )
            .col-12.mt-1
              .form-group
                label.review-label(for='message') Mensagem
                textarea#message.form-control(
                  :class='{ "border-danger": remainingCaracters.message === 0 }'
                  maxlength='500'
                  placeholder='Digite uma mensagem para o destinatário'
                  style='min-height: 120px'
                  v-model='message'
                )
                max-text-counter(
                  :limit='500'
                  :value='message'
                  @remaining='remainingCaracters.message = $event'
                )
  envelope-bottom-bar(
    :disableButtonNextStep='invalid'
    :enableSaveTemplate='$route.name === "business.envelope.review"'
    @nextStep='nextStep'
    @save-template='saveAsTemplate = $event'
    @saveAndClose='saveAndClose'
  )
  envelope-sent-modal(ref='envelopeSentModal')
  select-folder-modal(
    :enableRootDirectory='true'
    @selected-folder='selectedFolder'
    ref='selectFolderModal'
  )
  warning-save-and-close-envelope-modal(
    @discardChanges='discardChanges'
    @saveAndClose='saveAndClose'
    ref='warningSaveAndCloseEnvelopeModal'
  )
    p.mb-0 Você deseja sair sem salvar os detalhes inseridos ou alterados?
  no-credit-modal(@saveAndClose='saveAndClose' ref='noCreditModal')
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import DatePickerInput from '@/ui/molecules/DatePickerInput.vue';
import EnvelopeState from '@/enums/EnvelopeState';
import EnvelopeSentModal from '@/modules/business/components/organisms/modals/EnvelopeSentModal.vue';
import NoCreditModal from '@/modules/business/components/organisms/modals/NoCreditModal.vue';
import EnvelopeStepBar from '@/ui/organisms/EnvelopeStepBar.vue';
import SelectFolderModal from '@/ui/organisms/modals/SelectFolderModal.vue';
import EnvelopeTriggerEvent from '@/enums/EnvelopeTriggerEvent';
import EnvelopeTrigger from '@/enums/EnvelopeTrigger';
// eslint-disable-next-line max-len
import WarningSaveAndCloseEnvelopeModal from '@/modules/business/components/organisms/modals/WarningSaveAndCloseEnvelope.vue';
import EnvelopeBottomBar from '@/ui/organisms/EnvelopeBottom.vue';
import StorageHttp from '@/services/StorageHttp';
import MaxTextCounter from '@/ui/atoms/MaxTextCounter.vue';

export default {
  name: 'ReviewEnvelopePage',
  components: {
    Icon,
    DatePickerInput,
    EnvelopeSentModal,
    EnvelopeStepBar,
    SelectFolderModal,
    WarningSaveAndCloseEnvelopeModal,
    EnvelopeBottomBar,
    NoCreditModal,
    MaxTextCounter,
  },
  data() {
    return {
      envelope: '',
      loading: false,
      subject: '',
      title: '',
      expire_at: '',
      action_reminder_frequency: '',
      message: '',
      selectFolder: null,
      nextRoute: null,
      saveAsTemplate: false,
      remainingCaracters: {
        subject: 60,
        message: 60,
        title: 500,
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === 'business.envelope.fields' ||
      to.name === 'business.documents.sent' ||
      to.name === 'business.documents.draft'
    ) {
      next();
      return;
    }
    this.$refs.warningSaveAndCloseEnvelopeModal.$refs.modal.open();
    this.nextRoute = next;
  },
  computed: {
    selectWhetherToSaveOrSend() {
      return (
        this.envelope.state !== EnvelopeState.IN_TRANSIT &&
        this.envelope.state !== EnvelopeState.EXPIRED
      );
    },
    enableSignatureReminder() {
      return this.expire_at && this.expire_at !== 'invalidDate';
    },
    textSelectedFolder() {
      return this.selectFolder ? this.selectFolder.name : 'Armazenamento';
    },
    datePickMinDate() {
      return new Date().fp_incr(1);
    },
  },
  created() {
    let pageTitle = 'Editar envelope';
    const self = this;
    if (this.$route.params.isNew) {
      pageTitle = 'Novo envelope';
    }
    this.$store.dispatch('page/setTitle', pageTitle);
    this.$store.dispatch('page/setHead', {
      title: pageTitle,
      action() {
        self.$router.replace({
          name: 'business.envelope.fields',
          params: {
            id: self.$route.params.id,
            isNew: self.$route.params.isNew,
          },
        });
      },
    });
  },
  async beforeMount() {
    const res = await EnvelopesService.getExtendedEnvelope(
      this.$route.params.id
    );
    const fileDirectory = res.data.triggers.find(
      (x) => x.trigger === EnvelopeTrigger.SAVE_FILES_ON_DIRECTORY
    );
    if (fileDirectory) {
      StorageHttp.getItem(fileDirectory.value).then((result) => {
        this.selectedFolder(result.data);
      });
    }
    this.envelope = res.data;
    this.title = String(this.envelope.title.split('.', 1));
    this.subject = this.envelope.subject;
    this.expire_at = this.envelope.expire_at
      ? new Date(this.envelope.expire_at).fp_incr(1)
      : '';
    this.action_reminder_frequency = this.envelope.action_reminder_frequency;
    this.message = this.envelope.message;
  },
  methods: {
    async saveDetails() {
      this.$loading(true);
      const data = {};
      data.title = this.title;
      data.subject = this.subject;
      data.triggers = [
        {
          event: EnvelopeTriggerEvent.ENVELOPE_COMPLETED,
          trigger: EnvelopeTrigger.SAVE_FILES_ON_DIRECTORY,
          value: this.selectFolder ? this.selectFolder.id : null,
        },
      ];
      data.action_reminder_frequency = this.action_reminder_frequency;
      if (this.expire_at) {
        data.expire_at = this.expire_at;
      }
      if (this.message) {
        data.message = this.message;
      }
      await EnvelopesService.update(this.$route.params.id, data);
      if (this.saveAsTemplate) {
        await EnvelopesService.copyTo('template', this.$route.params.id);
      }
      this.$loading(false);
    },
    validateDate(event, inputId) {
      if (event === 'invalidDate') {
        document
          .querySelector(`#${inputId} + .date-input`)
          .classList.add('border-danger');
      } else {
        document
          .querySelector(`#${inputId} + .date-input`)
          .classList.remove('border-danger');
      }
    },
    discardChanges() {
      if (this.nextRoute) {
        this.nextRoute();
        return;
      }
      this.$router.go(-1);
    },
    async saveAndClose() {
      await this.saveDetails();
      if (this.nextRoute) {
        this.nextRoute();
        return;
      }
      this.$router.push({ name: 'business.documents.draft' });
    },
    async nextStep() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) {
          return;
        }
        if (!this.$store.getters['subscription/hasCredits']) {
          this.$refs.noCreditModal.$refs.modal.open();
          return;
        }

        await this.saveDetails();
        try {
          if (this.selectWhetherToSaveOrSend) {
            await EnvelopesService.sendEnvelope(this.$route.params.id);
            this.$store.dispatch('subscription/getSubscription');
          }
          this.$refs.envelopeSentModal.$refs.modal.open();
        } catch (error) {
          if (error.response.status === 402) {
            this.$refs.noCreditModal.$refs.modal.open();
          }
        }
      });
    },
    openModalSelectFolder() {
      this.$refs.selectFolderModal.$refs.modal.open();
    },
    selectedFolder(folder) {
      if (!folder) {
        this.selectFolder = null;
        return;
      }

      this.selectFolder = {
        name: folder.name,
        id: folder.id,
      };
    },
  },
};
</script>
<style lang="stylus">
.w-center
  margin: auto
  max-width: 960px
.form-control-icon-select-folder
  cursor: pointer;
  .icon
    color: #013d52
  .form-control
    padding-top: 0.7rem
    display: flex;
    justify-content: space-between;
.review-label
  font-size: 1rem
.review-radius
  border-radius: .125rem
</style>
