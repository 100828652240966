import Http from '@/services/Http';

const path = 'organizations';
const client = new Http(path);

client.list = () => client.get();

client.create = async (data) => {
  const formData = new FormData();
  const list = Object.entries(data);
  list.forEach((item) => {
    formData.append(item[0], item[1]);
  });
  return client.post(formData, '', {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

client.logoUpload = async (data) => {
  const formData = new FormData();
  const list = Object.entries(data);
  list.forEach((item) => {
    formData.append(item[0], item[1]);
  });
  return client.post(formData, '', {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

client.getStats = (organizationId) => client.get(organizationId + '/stats', {});

export default client;
