<template lang="pug">
modal#new-user-modal(
  :loading='loading'
  @closed='eraseErrorForm'
  @process='addUsers'
  close-button-label='Cancelar'
  process-button-label='Adicionar'
  ref='modal'
  title='Novo usuário'
)
  .form-group.mb-0
    label(for='exampleInputUserEmail') Endereço de e-mail
    ValidationProvider(
      mode='passive'
      name='endereço de e-mail'
      ref='user'
      rules='required'
      v-slot='{ errors }'
    )
      .form-control-icon
        icon(name='at')
        VueTagsInput#teamUser.input.form-control(
          :add-only-from-autocomplete='true'
          :autocomplete-items='autocompleteItems'
          :class='{ "border border-danger": errors[0] }'
          :tags='tags'
          @tags-changed='update'
          autocomplete='off'
          placeholder='Digite um email ou mais'
          v-model='tag'
        )
      small.text.text-danger {{ errors[0] }}
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import Icon from '@/ui/atoms/Icon.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import VueTagsInput from '@johmun/vue-tags-input';
import TeamsService from '@/modules/business/services/http/organizations/teams/TeamsService';
import debounce from 'debounce';
import UsersService from '@/modules/business/services/http/organizations/users/UsersService';
import RolesService from '@/modules/business/services/http/organizations/roles/RolesService';
import formHandler from '@/modules/account/common/formHandler';

export default {
  name: 'NewUserModal',
  components: {
    Modal,
    Icon,
    VueTagsInput,
    ImageLoader,
    RolesService,
  },
  props: {
    teamId: [Number, String],
    roleId: [Number, String],
    operation: Boolean,
  },
  data() {
    return {
      loading: false,
      tag: '',
      tags: [],
      autocompleteItems: [],
    };
  },
  watch: {
    tag: 'initItems',
  },
  methods: {
    update(newTags) {
      this.autocompleteItems = [];
      this.tags = newTags;
    },
    initItems: debounce(function initItems() {
      if (this.tag.length < 2) return;
      const params = new URLSearchParams({ q: this.tag });
      UsersService.list(params).then((response) => {
        this.autocompleteItems = response.data.data.map((a) => ({
          id: a.id,
          text: `${a.name}:${a.email}`,
        }));
      });
    }, 1000),
    async addUsers() {
      const users = [];
      this.tags.forEach((user) => {
        users.push(user.id);
      });

      if (this.operation) {
        formHandler.submit(
          this,
          () => RolesService.addUser(this.roleId, { users }),
          {
            successHandler: (response) => {
              formHandler.defaultSuccess(response);
              this.tags = [];
              this.tag = '';
              this.$emit('refresh');
              this.loading = false;
              this.$refs.modal.close();
            },
          }
        );
      } else {
        formHandler.submit(
          this,
          () => TeamsService.addUser(this.teamId, { users }),
          {
            successHandler: (response) => {
              formHandler.defaultSuccess(response);
              this.tags = [];
              this.tag = '';
              this.$emit('refresh');
              this.loading = false;
              this.$refs.modal.close();
            },
          }
        );
      }
    },

    eraseErrorForm() {
      this.$refs.user.reset();
    },
  },
};
</script>
