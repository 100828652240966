<template lang="pug">
.camera-photo
  //- input(
  //-   accept='image/*'
  //-   capture='user'
  //-   type='file'
  //- )
  video(
    autoplay
    playsinline='true'
    ref='video'
    v-show='streaming'
    width='100%'
  )
  canvas(
    :height='height'
    :width='width'
    ref='canvas'
    v-show='!streaming && photo'
  )
</template>
<script>
export default {
  name: 'CameraPhoto',
  data() {
    return {
      stream: '',
      streaming: false,
      photo: '',
      width: 0,
      height: 0,
    };
  },
  methods: {
    async start() {
      if (this.streaming) {
        return;
      }
      this.stream = await window.navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      this.$refs.video.srcObject = this.stream;
      this.streaming = true;
      this.$refs.video.addEventListener('play', () => {
        this.height = this.$refs.video.clientHeight;
        this.width = this.$refs.video.clientWidth;
      });
    },
    capture() {
      if (!this.streaming) {
        return;
      }
      this.$refs.canvas
        .getContext('2d')
        .drawImage(
          this.$refs.video,
          0,
          0,
          this.$refs.canvas.width,
          this.$refs.canvas.height
        );
      this.photo = this.$refs.canvas.toDataURL('image/jpeg');
      this.$emit('photo', this.photo);
      this.stopStream();
    },
    stopStream() {
      if (!this.streaming) {
        return;
      }
      this.photo = '';
      this.stream.getTracks()[0].stop();
      this.streaming = false;
    },
  },
};
</script>
<style lang="stylus">
.camera-photo
  width: 100%
</style>
