<template lang="pug">
ul.select-organization-menu-list.list-unstyled
  li.list-item(
    :class='{ disabled: item.id === getSelected.id }'
    :key='index'
    @click='selectItem(item)'
    v-for='(item, index) in items'
  )
    a.list-item-link.list-img-organization.text-overflow-ellipse(
      :href='redirectTo'
      :title='item.name'
    )
      span.text {{ item.name }}
  //- <hr>
  //-   li.list-config(v-if='showConfigurations')
  //-     a.list-item-link(href='/business/organizations/settings')
  //-       icon(name='gear' style='vertical-align: 0')
  //-       span.text(style='vertical-align: bottom') Configurações
  //-   li.list-config
  //-     a.list-item-link(href='/business/organizations/new')
  //-       icon(name='sitemap' style='vertical-align: 0')
  //-       span.text Nova Organização
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'ServicesSelect',
  components: {
    Icon,
  },
  props: {
    items: Array,
    user: Object,
  },
  computed: {
    showConfigurations() {
      const { permissions } = this.$store.state.organizations.selected;

      if (
        permissions &&
        !this.$store.state.organizations.selected.is_owner &&
        !permissions.edit_organization &&
        !permissions.manage_billing
      ) {
        return false;
      }

      return true;
    },
    redirectTo() {
      return this.$route.path;
    },
    getSelected() {
      return this.$store.state.organizations.selected;
    },
  },
  data() {
    return {
      showBallon: false,
    };
  },
  methods: {
    selectItem(item) {
      this.showBallon = false;
      this.$emit('change', item);
    },
    blur() {
      this.showBallon = !this.showBallon;
    },
    avatarUrl(organizationName) {
      return `https://ui-avatars.com/api/?font-size=0.33&background=18d16b&color=fff&name=${organizationName}`;
    },
  },
};
</script>
<style scoped lang="stylus">
ul.select-organization-menu-list
  margin-bottom: 0
  width: 180px
  min-width: 180px
  li.disabled, li.disabled a
    cursor: default
    pointer-events: none
    color: #555555
    background-color: #ccc
  .list-item-link
    line-height: 2.3em
</style>
