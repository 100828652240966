import enumBase from './EnumBase';

export default class RecipientType extends enumBase {
  static SIGNER = 'signer';

  static APPROVER = 'approver';

  static CARBON_COPY = 'carbon-copy';

  static IN_PERSON_SIGNER = 'in-person-signer';

  static values = [
    { value: this.SIGNER, label: 'Assinante' },
    { value: this.APPROVER, label: 'Aprovador' },
    { value: this.CARBON_COPY, label: 'Visualizador' },
    { value: this.IN_PERSON_SIGNER, label: 'Assinante presencial' },
  ];
}
