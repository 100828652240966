<template lang="pug">
.profile-screen
  ul#myTab.nav.nav-tabs
    li.nav-item.active
      button.nav-link.active(
        data-bs-target='#tab__signatures'
        data-bs-toggle='tab'
      ) Assinaturas
    li.nav-item
      button.nav-link(data-bs-target='#tab__myaccount' data-bs-toggle='tab') Minha conta
  .tab-content.profile-page
    #tab__signatures.tab-pane.fade.show.active(
      aria-labelledby='home-tab'
      role='tabpanel'
    )
      .row.mb-5(v-if='!signature && !initial')
        .no-envelope
          .text-center.mb-5
            img.img-fluid.mt-5.img.mb-3.without-img(
              src='/img/ilustrations/no-signature.svg'
              style='max-height: 260px'
            )
            .text.font-color-blue Você ainda não criou sua assinatura e nem a sua rubrica.
            .text.font-color-blue Vamos começar pela assinatura!
            .mt-3
              button.btn.btn-primary.signature-create(
                @click='editSignature("signature")'
                type='button'
              ) Criar assinatura
      .row.mt-4(v-else)
        .col-12.col-sm-6.col-lg-4.col-xl-3.mb-3
          h5.title-dark.mb-3 Assinatura
          .card.signature--card(
            :class='{ "signature--card--loading": this.loadingSignature }'
            v-if='signature'
          )
            .card-body
              button.action-remove(
                @click='editSignature("signature")'
                title='Editar Assinatura'
                type='button'
              )
                icon(name='pen-to-square')
              .signature-img
                .loading-text Carregando...
                img.img(:src='signature')
          .mt-2(v-else)
            button.btn.btn-primary(
              @click='editSignature("signature")'
              type='button'
            ) Criar assinatura
        .col-12.col-sm-6.col-lg-4.col-xl-3
          h5.title-dark.mb-3 Rubrica
          .card.signature--card(
            :class='{ "signature--card--loading": this.loadingInitial }'
            v-if='initial'
          )
            .card-body
              button.action-remove(
                @click='editSignature("initial")'
                title='Editar Rubrica'
                type='button'
              )
                icon(name='pen-to-square')
              .signature-img
                .loading-text Carregando...
                img.img(:src='initial')
          .mt-2(v-else)
            button.btn.btn-primary.rubrica-create(
              @click='editSignature("initial")'
              type='button'
            ) Criar rubrica
    #tab__myaccount.tab-pane.fade
      span.my-3.d-flex.flex-column.mt-3.title-dark Dados do acesso
      .profile-page.mt-3
        .row(:style='"cursor: " + cursorStyle')
          .col-12
            .card.card--rounded.flex-row.mb-5(name='profile')
              .card-body.card-body--user-info
                ValidationObserver(ref='form' v-slot='{ valid }')
                  .row.profile-inputs
                    .col-12.col-md-6.form-group
                      label(for='name') Nome
                      .form-control-icon
                        icon(name='user')
                        input.form-control(
                          :placeholder='$root.user.name'
                          autocomplete='off'
                          type='name'
                          v-model='user.name'
                        )
                    .col-12.col-md-6.form-group
                      ValidationProvider(
                        name='phone'
                        rules='intBrazilianPhone'
                        v-slot='{ errors }'
                      )
                        label(for='phone') Telefone
                        .form-control-icon
                          input-tel#phone.form-control(
                            :placeholder='$root.user.phone'
                            autocomplete='off'
                            type='text'
                            v-mask='"## #####-####"'
                            v-model='user.phone'
                          )
                        small.align-items-center.text.text-danger.d-flex.mt-1(
                          v-if='errors.length > 0'
                        )
                          icon(name='xmark')
                          span.text.ms-2 {{ errors[0] }}
                    .col-12.col-md-6.form-group
                      label(for='email') Endereço de e-mail
                      .form-control-icon
                        icon(name='at')
                        input#email.form-control(
                          autocomplete='off'
                          disabled
                          placeholder='Digite um e-mail'
                          type='email'
                          v-model='$root.user.email'
                        )
                    .col-12.d-flex.align-items-center.action-buttons
                      a.text--change-password(
                        data-bs-target='#change-password-modal'
                        data-bs-toggle='modal'
                        href='#'
                      ) Alterar senha
                      .ms-auto.button__save-changes
                        button.btn.btn-primary(
                          :disabled='!valid || canSubmit'
                          @click='submit'
                          style='margin-left: 2rem'
                          type='button'
                        ) Salvar alterações

  change-password-modal(ref='changePasswordModal')

  //- Modal de assinaturas
  signature-creator-modal#signature-creator-modal(
    :default-text='signatureModalDefaultText'
    :label='signatureModalLabel'
    :signature-text-max-length='signatureModalTextLength'
    @change-signature='onSignatureUpdate'
    ref='signatureCreatorModal'
  )
  signature-created-modal(
    @process='editSignature("initial")'
    ref='signatureCreatedModal'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import ChangePasswordModal from '@/modules/account/components/organisms/modals/ChangePasswordModal.vue';
import SignatureCreatedModal from '@/modules/account/components/organisms/modals/SignatureCreatedModal.vue';
import SignatureCreatorModal from '@/ui/organisms/modals/SignatureCreatorModal.vue';
import AccountService from '@/services/AccountHttp';
import SignatureService from '@/services/SignatureHttp';
import alert from '@/modules/account/common/alert';
import Abbreviator from '@/services/Abbreviator';
import InputTel from '@/ui/molecules/InputTel.vue';

export default {
  name: 'ProfilePage',
  data() {
    return {
      signature: '',
      initial: '',
      loading: false,
      idToDelete: null,
      user: {
        name: this.$root.user.name,
        phone: this.$root.user.phone,
      },
      inEditionSignatureType: null,
      signatureModalLabel: 'Assinatura',
      signatureModalTextLength: 30,
      signatureModalDefaultText: null,
      loadingSignature: false,
      loadingInitial: false,
    };
  },
  components: {
    Icon,
    InputTel,
    ChangePasswordModal,
    SignatureCreatorModal,
    SignatureCreatedModal,
  },
  computed: {
    cursorStyle() {
      return this.loading ? 'wait' : '';
    },
    canSubmit() {
      if (
        this.user.name !== this.$root.user.name ||
        this.user.phone !== this.$root.user.phone
      ) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.$store.dispatch('page/setTitle', 'Perfil');
    this.$store.dispatch('page/setHead', 'Perfil');
    this.$store.dispatch('page/setClass', 'account profile');
    this.loadInitial();
    this.loadSignature();
    this.avatar = this.$root.user.avatar;
  },

  methods: {
    async submit() {
      const userData = {
        name:
          this.user.name !== this.$root.user.name
            ? this.user.name
            : this.$root.user.name,
        phone:
          this.user.phone !== this.$root.user.phone
            ? '+' + this.user.phone
            : this.$root.user.phone,
      };
      await AccountService.setUser(userData)
        .then(() => {
          this.$root.user.name = this.user.name;
          this.$root.user.phone = this.user.phone;
          alert.fireAlert('Usuário alterado com sucesso', {
            classes: 'alert-success',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
            icon: 'circle-check',
          });
        })
        .catch((error) => {
          const errorMessage = error.response.data.errors.phone
            ? 'Ops! Este número de telefone já existe em nossa base de dados. Tente cadastrar outro número'
            : 'Erro ao tentar processar atualização';
          alert.fireAlert(errorMessage, {
            classes: 'alert-danger',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
            icon: 'triangle-exclamation',
          });
          this.user = {};
        });
    },
    loadInitial() {
      this.loadingInitial = true;
      SignatureService.getSignature('initial').then((res) => {
        this.initial = res;
        this.loadingInitial = false;
      });
    },
    loadSignature() {
      this.loadingSignature = true;
      SignatureService.getSignature('signature').then((res) => {
        this.signature = res;
        this.loadingSignature = false;
      });
    },
    editSignature(type) {
      this.$refs.signatureCreatedModal.$refs.modal.close();
      if (type === 'signature') {
        this.inEditionSignatureType = 'signature';
        this.signatureModalDefaultText = this.$root.user.name;
        this.signatureModalLabel = 'Assinatura';
        this.signatureModalTextLength = 30;
        this.$refs.signatureCreatorModal.$refs.modal.open();
        return;
      }
      this.inEditionSignatureType = 'initial';
      this.signatureModalDefaultText = Abbreviator.get(this.$root.user.name);
      this.signatureModalLabel = 'Rubrica';
      this.signatureModalTextLength = 5;
      this.$refs.signatureCreatorModal.$refs.modal.open();
    },
    onSignatureUpdate(data) {
      if (!this.signature) {
        this.$refs.signatureCreatedModal.$refs.modal.open();
      }
      if (this.inEditionSignatureType === 'signature') {
        this.loadingSignature = true;
      } else {
        this.loadingInitial = true;
      }
      SignatureService.saveSignature(
        this.inEditionSignatureType,
        data.blob
      ).then(() => {
        if (this.inEditionSignatureType === 'signature') {
          this.loadSignature();
        } else {
          this.loadInitial();
        }
      });
    },
  },
};
</script>
<style lang="stylus">
.profile-page
  .signature--card
    border-left: 5px solid #3ECB90
  .signature-img
    .img
      max-width: 100%
      // width: 100%
      max-height: 6rem
  .title-dark
    color: #013D52
  .text--change-password
    font-size: 1rem
    color: #01AFEC

  .file
    input[type="file"]
      display: none;

    color: #0d6efd;
    cursor: pointer;
    margin-top: 3px;
    margin-right: 60px;

    &:hover
      opacity: 0.5;
      transition: opacity .1s;

  .loading-text
    display: none
    position: absolute
    margin: auto
    left: 0
    right: 0
    top: 0
    bottom: 0
    height: 1.6em
    padding-left: 1em
  .signature--card--loading
    .img
      opacity: 0
    .loading-text
      display: block

  .card-body--user-info
    padding: 1.438rem 2rem
  .card-body
    [class^="action"]
      cursor: pointer;
      position: absolute;
      color: #949494;
      right: 10px;

      &:hover
        opacity: 0.6;
        transition: opacity .3s;

    .action-remove
      top: 10px;
      color: #013D52

    .action-default
      top: 10px;

      &.active
        color: #54AA5A !important;
  #tab__myaccount
    span
      font-size: 0.875rem
@media (max-width: 991px)
  .without-img
    width: 100%
  .signature-create
    width: 100%
  .rubrica-create
    width: 100%
  .action-buttons
    display: flex
    flex-direction: column
    .text--change-password
      align-self: start
    .button__save-changes
      margin-top: 1rem
      width: 100%
      margin-left: 0 !important
      button
        width: 100%
        margin-left: 0 !important
  .profile-inputs
    display: flex
    flex-direction: column
    .form-group
      width: 100%
</style>
