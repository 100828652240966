<template lang="pug">
nav(aria-label='Paginator' v-if='isReady && showPaginator')
  ul.pagination.justify-content-end
    li.page-item(:class='{ disabled: !hasPreviousPage }')
      button.page-link.arrow(
        :class='{ active: hasPreviousPage }'
        :disabled='!hasPreviousPage'
        @click='prev()'
        title='Página anterior'
      )
        icon(name='chevrons-left')
    li.page-item.disabled.current-page(title='Página atual')
      a.page-link {{ currentPage }}
    li.page-item(:class='{ disabled: !hasNextPage }')
      button.page-link.arrow(
        :class='{ active: hasNextPage }'
        :disabled='!hasNextPage'
        @click='next()'
        title='Próxima página'
      )
        icon(name='chevrons-right')
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'SimplePaginator',
  components: {
    Icon,
  },
  data() {
    return {
      isReady: false,
    };
  },
  props: {
    payload: Object,
  },
  created() {
    this.isReady = true;
  },
  computed: {
    currentPage() {
      return this.payload.meta.current_page;
    },
    hasNextPage() {
      return Boolean(this.payload.links.next);
    },
    hasPreviousPage() {
      return Boolean(this.payload.links.prev);
    },
    showPaginator() {
      return this.hasNextPage || this.hasPreviousPage;
    },
  },
  methods: {
    first() {
      if (this.pagination.links.prev) {
        this.$emit('navigate', 1);
      }
    },
    prev() {
      if (this.hasPreviousPage) {
        this.$emit('navigate', this.currentPage - 1);
      }
    },
    next() {
      if (this.hasNextPage) {
        this.$emit('navigate', this.currentPage + 1);
      }
    },
  },
};
</script>
<style scoped lang="stylus">
button:active,
button:focus,
button.active
  outline: 0px !important
  -webkit-appearance: none
  box-shadow: none !important
.icon {
  font-size: 0.65rem;
}
.page-item
  &.current-page .page-link
    border: 1px solid #013d52
  .page-link.arrow.active
    i
      color: #013d52
</style>
