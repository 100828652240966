export default {
  namespaced: true,
  state: {
    title: '',
    class: '',
    head: {
      title: '',
      action: null, // route or function
    },
    sideBar: true,
  },
  actions: {
    setTitle: ({ commit }, title) => {
      commit('setTitle', title);
    },
    setClass: ({ commit }, pageClass) => {
      commit('setClass', pageClass);
    },
    setHead: ({ commit }, head) => {
      commit('setHead', head);
    },
    showSideBar: ({ commit }, value) => {
      commit('setSideBar', value);
    },
  },
  mutations: {
    setTitle: (state, title) => {
      state.title = title;
    },
    setClass: (state, pageClass) => {
      state.class = pageClass;
    },
    setHead: (state, head) => {
      state.head = head;
    },
    setSideBar: (state, value) => {
      state.sideBar = value;
    },
  },
};
