<template lang="pug">
flat-pickr.date-input(
  :config='config'
  @blur='$emit("blur", $event)'
  v-bind='$attrs'
  v-model='input'
)
</template>
<script>
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

export default {
  components: {
    FlatPickr,
  },
  props: {
    minDate: Date,
    dateValue: [String, Date],
  },
  data() {
    return {
      input: '',
      config: {
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        time_24hr: true,
        altInput: true,
        defaultHour: 0,
        defaultMinute: 0,
        locale: Portuguese,
        minDate: this.minDate,
        allowInput: true,
        allowInvalidPreload: true,
      },
    };
  },
  watch: {
    input(value) {
      this.$emit('input', this.checkDate(value));
      this.$emit('change', this.checkDate(value));
    },
    dateValue(value) {
      this.input = value;
    },
  },
  methods: {
    checkDate(date) {
      if (this.minDate) {
        return date !== null &&
          new Date(date).fp_incr(1) < new Date(this.minDate)
          ? 'invalidDate'
          : date;
      }
      return date;
    },
  },
};
</script>
<style lang="stylus">
.date-input
  &.form-control:disabled, &.form-control[readonly]
    background-color: #fff
</style>
