<template lang="pug">
modal#cancel-envelope-modal(
  :no-footer='true'
  ref='modal'
  size='sm'
  title='Cancelar envelope'
)
  p.justify-center Tem certeza que deseja cancelar o envelope?
    br
    | Essa ação não poderá ser desfeita.
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/envelope-cancel.svg')
  .text-end
    button.btn.btn-secondary(@click='close' type='button')
      | Manter
    button.btn.btn-danger.ms-2(
      @click='cancelEnvelope'
      style='border-width: 1px'
      type='button'
    )
      | Cancelar
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import RecipientsService from '@/modules/business/services/http/envelopes/RecipientsService';
import alert from '@/modules/account/common/alert';

export default {
  name: 'CancelEnvelopeModal',
  props: {
    envelopeId: [Number, String],
  },
  components: {
    Modal,
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    cancelEnvelope() {
      this.$loading(true);
      if (this.$route.params.token) {
        RecipientsService.rejectEnvelope(this.$route.params.token)
          .then((response) => {
            this.$emit('refresh', response);
          })
          .catch((error) => {
            alert.fireAlert(
              `Erro ao cancelar envelope. ${error.response.data.message}`,
              {
                classes: 'alert-danger',
                styles:
                  'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
                tag: 'httpAlert',
                icon: 'triangle-exclamation',
              }
            );
          })
          .finally(() => {
            this.$loading(false);
            this.close();
          });
      } else {
        EnvelopesService.cancelEnvelope(this.envelopeId)
          .then(() => {
            this.$emit('refresh');
          })
          .catch((error) => {
            alert.fireAlert(
              `Erro ao cancelar envelope. ${error.response.data.message}`,
              {
                classes: 'alert-danger',
                styles:
                  'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
                tag: 'httpAlert',
                icon: 'triangle-exclamation',
              }
            );
          })
          .finally(() => {
            this.$loading(false);
            this.close();
          });
      }
    },
  },
};
</script>
