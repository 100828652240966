<template lang="pug">
#app
  router-view(v-if='$root.loaded')
</template>
<script>
export default {
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (this.$root.isMobile && to.name === 'business.envelopes.new') {
        return;
      }
      this.$store.dispatch('page/setTitle', '');
      this.$store.dispatch('page/setClass', '');
      this.$store.dispatch('page/setHead', { title: '' });
      this.$store.dispatch('page/showSideBar', true);
      next();
    });
  },
  computed: {
    pageTitle() {
      return this.$store.state.page.title;
    },
  },
  watch: {
    pageTitle(value) {
      window.document.title = `${value} - Assine Online`;
    },
  },
};
</script>
<style lang="stylus">
#app
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
body
  min-width: 370px
  &.modal-open
    .page
      filter: none
  .page
    filter: none
    min-height: calc(100vh - 170px)
  select.form-control
    background-position-x: calc(100% - 1em)
  .form-control-icon .icon
    height: 1em
  .form-control.is-valid, .was-validated .form-control:valid
    border-color: #e0e6ed
.input-group-icon
  .input-group-text
    border-color: #e0e6ed
  .form-control
    border-left: none
.input-group-text__icon
  background-color: #fff
  color: rgba(0,0,0,0.1)
  padding: .5rem .2rem .5rem 1rem
.select-organization__block
  .select-organization__block__action
    position: relative
    cursor: pointer
    border-right: 1px solid #d2d2d2
    padding-right: 2rem
    .avatar
      img
        width: 1.5rem
        border-radius: 50%
        border: 1px solid #eaeaea
      margin-right: .5rem
      margin-top: -.1rem
    .text
      color: #013D52
      font-size: 1.1rem
    .icon
      color: #013D52
      margin: auto
      font-size: 1.1rem
      margin-left: 1.5rem
.font-size-1
  font-size: 1rem
.font-size-title
  font-size: 1.5rem
.font-size-subtitle
  font-size: 1.125rem
.font-size-small
  font-size: .875rem
.font-color-gray
  color: #CCCCCC
.font-color-blue
  color: #013D52
.form-control.is-valid:focus, .was-validated .form-control:valid:focus
  border-color: #e0e6ed
  box-shadow: none
.btn--cancel-red-text
  color: #D00A00
.btn-sm
  padding: 0.4rem 1rem
.h-title
  color: #013D52
.max-width-sidebar-xl-new
  position: relative;
  top: 0;
  z-index: 4;
  max-width: 235px;
  top: 0;
  bottom: 0;
  left: 0
  transition: left 0.3s;
.max-width-sidebar-xl-new.show
  left: 0;
.header
  position: fixed
  top: 0
  width: 100%
  z-index: 1001
.simple-sidebar__menu-list .menu-list__item .icon,
.header-avatar-menu-list .list-item .icon
  width: initial
  height: initial
.form-control-icon .form-control
  padding-left: 2.4em
.text-overflow-ellipse
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
.icon--blue
  color: #013D52
.link-color
  color: #01AFEC
.link-color .text
  color: #01AFEC !important
.btn-secondary
  background-color: #e6e6e6
  border-color: #e6e6e6
  &:hover
    background-color: #e6e6e6
    border-color: #e6e6e6
.btn-disabled,
.btn-primary:disabled,
.btn-primary:hover:disabled,
.btn-primary:active:disabled,
.btn-primary:focus:disabled
  border-color: #ccc
  background-color: #ccc
  color: #555555
  opacity: 1
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus
  font-weight: 600
.dropdown-stylized .dropdown-menu li
  line-height: 2em
.page
  .content
    max-width: 1280px
    margin: auto
    padding: 0 1rem
    min-height: calc(100vh - 132px)
.page.business.dashboard .component-title
  margin-top: 0
.form-group.is-invalid
  .invalid-feedback
    display: block
  .form-control-icon .icon
    bottom: auto
    top: 1.1em
@media screen and (max-width: 1200px)
  .max-width-sidebar-xl-new
    position: fixed
    left: -244px
    top: 0
</style>
