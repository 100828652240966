<template lang="pug">
.documents-pdf-render
  //- Select
  select.document-select.form-select(v-if='loaded' v-model='selectedDocument')
    option(:value='document' v-for='(document, index) in documents') {{ document.name }}
  pdf(
    :key='selectedDocument.id'
    :show-expand-options='true'
    :src='documentSrc'
    @document-loaded='loaded = $event'
    @fullscreen='showFullScreen'
    @proxy-pages='pages = $event'
    @selected-page='page = $event'
    @version='documentVersion = $event'
    v-if='documentSrc'
  )
    //- Slot de cada página <3
    template(
      v-for='(page, index) in pages'
      v-slot:[`page-${page.pageNumber}`]='{}'
    )
      //- Área com drop disponível
      draggable.dropzone(@add='onDropElement($event, page)' group='elements')
      .dragging-zone(
        :key='`${index}-${selectedDocument.id}-${documentVersion}`'
      )
        template(v-for='(element, index) in getPageElements(page)')
          document-element(
            :element='element'
            :key='`${element.id}-${element.version}`'
            @change-position='onElementChangePosition'
            @delete='$emit("deleteElement", $event, selectedDocument)'
            @element-click='onElementClick'
            @ready='onElementReady($event, element, page)'
            @resize='onElementResize'
            @updated='onElementUpdate'
          )
  rubrica-settings-modal(
    @settings='onApplyElementSettings'
    ref='rubricaSettingsModal'
  )
  date-settings-modal(
    @settings='onApplyElementSettings'
    ref='dateSettingsModal'
  )
  checkbox-settings-modal(
    @settings='onApplyElementSettings'
    ref='checkboxSettingsModal'
  )
  select-settings-modal(
    @settings='onApplyElementSettings'
    ref='selectSettingsModal'
  )
  input-text-settings-modal(
    @settings='onApplyElementSettings'
    ref='inputTextSettingsModal'
  )
  comment-settings-modal(
    @settings='onApplyElementSettings'
    ref='commentSettingsModal'
  )
  image-settings-modal(
    @settings='onApplyElementSettings'
    ref='imageSettingsModal'
  )
  qr-code-settings-modal(
    @settings='onApplyElementSettings'
    ref='qrCodeSettingsModal'
  )
</template>
<script>
/* eslint-disable vue/no-mutating-props */
import Pdf from '@/ui/molecules/Pdf.vue';
import Draggable from 'vuedraggable';
import DocumentElement from '@/ui/molecules/DocumentElement.vue';
import RubricaSettingsModal from '@/ui/organisms/modals/fields/RubricaSettingsModal.vue';
import DateSettingsModal from '@/ui/organisms/modals/fields/DateSettingsModal.vue';
import SelectSettingsModal from '@/ui/organisms/modals/fields/SelectSettingsModal.vue';
import CheckboxSettingsModal from '@/ui/organisms/modals/fields/CheckboxSettingsModal.vue';
import InputTextSettingsModal from '@/ui/organisms/modals/fields/InputTextSettingsModal.vue';
import CommentSettingsModal from '@/ui/organisms/modals/fields/CommentSettingsModal.vue';
import ImageSettingsModal from '@/ui/organisms/modals/fields/ImageSettingsModal.vue';
import QrCodeSettingsModal from '@/ui/organisms/modals/fields/QRCodeSettingsModal.vue';

export default {
  components: {
    Pdf,
    Draggable,
    DocumentElement,
    RubricaSettingsModal,
    DateSettingsModal,
    CheckboxSettingsModal,
    SelectSettingsModal,
    InputTextSettingsModal,
    CommentSettingsModal,
    ImageSettingsModal,
    QrCodeSettingsModal,
  },
  props: {
    documents: Array,
    document: {
      type: Object,
      default: () => {},
    },
    elements: Array,
  },
  data() {
    return {
      loaded: false,
      pages: [],
      page: 1,
      documentSrc: null,
      selectedElement: {},
      selectedDocument: {},
      documentVersion: 1,
    };
  },
  mounted() {
    if (this.document) {
      this.selectedDocument = this.document;
      this.documentSrc = this.selectedDocument.src;
    }
  },
  watch: {
    document(document) {
      this.selectedDocument = document;
    },
    selectedDocument(document) {
      this.documentSrc = null;
      this.page = 1;
      this.pages = [];
      this.loaded = false;
      setTimeout(() => {
        this.documentSrc = document.src;
      }, 50);
    },
  },
  methods: {
    getPageElements(page) {
      const { pageNumber } = page;
      const docId = this.selectedDocument.id;
      return this.elements.filter(
        (i) => pageNumber === i.page && i.document === docId
      );
    },
    onElementClick(element) {
      this.selectedElement = {};
      this.selectedElement = element;
      if (this.selectedElement.type === 'initial') {
        this.$refs.rubricaSettingsModal.element = this.selectedElement;
        this.$refs.rubricaSettingsModal.$refs.modal.open();
      }
      if (this.selectedElement.type === 'date') {
        this.$refs.dateSettingsModal.element = this.selectedElement;
        this.$refs.dateSettingsModal.$refs.modal.open();
      }
      if (this.selectedElement.type === 'checkbox') {
        this.$refs.checkboxSettingsModal.element = this.selectedElement;
        this.$refs.checkboxSettingsModal.$refs.modal.open();
      }
      if (this.selectedElement.type === 'dropdown') {
        this.$refs.selectSettingsModal.element = this.selectedElement;
        this.$refs.selectSettingsModal.$refs.modal.open();
      }
      if (this.selectedElement.type === 'text') {
        this.$refs.inputTextSettingsModal.element = this.selectedElement;
        this.$refs.inputTextSettingsModal.$refs.modal.open();
      }
      if (this.selectedElement.type === 'comment') {
        this.$refs.commentSettingsModal.element = this.selectedElement;
        this.$refs.commentSettingsModal.$refs.modal.open();
      }
      if (this.selectedElement.type === 'image') {
        this.$refs.imageSettingsModal.element = this.selectedElement;
        this.$refs.imageSettingsModal.$refs.modal.open();
      }
      if (this.selectedElement.type === 'validation-qrcode') {
        this.$refs.qrCodeSettingsModal.element = this.selectedElement;
        this.$refs.qrCodeSettingsModal.$refs.modal.open();
      }
    },
    onApplyElementSettings(element) {
      // eslint-disable-next-line no-param-reassign
      element.version = element.version ? element.version + 1 : 1;
      if (element.spread && !element.spreaded) {
        this.pages.forEach((page) => {
          if (page.pageNumber !== this.page) {
            const spreadElement = { ...element };
            spreadElement.id = Math.floor(Math.random() * 10000);
            spreadElement.parent = element.id;
            spreadElement.page = page.pageNumber;
            this.elements.push(spreadElement);
          }
        });
        // eslint-disable-next-line no-param-reassign
        element.spreaded = true;
        return;
      }
      if (element.version === 1) {
        this.$emit('dropElement', element);
      } else {
        this.$emit('updateElement', element);
      }
    },
    showFullScreen() {
      window.open(this.selectedDocument.links.display, '_blank');
    },
    onDropElement(event, page) {
      let { element, recipient } = event.item.dataset;
      element = JSON.parse(element);
      recipient = recipient !== undefined ? JSON.parse(recipient) : '';
      const dropzone = event.target.getBoundingClientRect();
      element.id = Math.floor(Math.random() * 10000);
      element.document = this.selectedDocument.id;
      element.page = page.pageNumber;
      element.x = event.originalEvent.clientX - dropzone.x;
      element.y = event.originalEvent.clientY - dropzone.y;
      element.parentSize = {
        width: 0,
        height: 0,
      };
      const pagesEl = document.querySelector('.pdf-render__content__pages');
      element.fontSize = parseInt(((pagesEl.clientWidth * 0.18) / 200) * 16);
      if (recipient) {
        element.recipient = recipient;
      }
      if (element.openOnDrop === true) {
        this.onElementClick(element);
      } else {
        this.$emit('dropElement', element);
      }
    },
    onElementReady({ parentSize }, element, page) {
      element.pageSize = {
        width: page.view[2],
        height: page.view[3],
      };
      element.parentSize = parentSize;
    },
    onElementUpdate(element) {
      element.updated = true;
    },
    onElementResize({ size, sizePercentage, fontSizePercentage, element }) {
      element.width = size.width;
      element.height = size.height;
      element.fontSize = size.fontSize;
      if (sizePercentage) {
        element.sizePercentage = sizePercentage;
      }
      if (fontSizePercentage) {
        element.fontSizePercentage = fontSizePercentage;
      }
    },
    onElementChangePosition({ position, positionPercentage, element }) {
      element.x = position.x;
      element.y = position.y;
      if (positionPercentage) {
        element.positionPercentage = positionPercentage;
      }
    },
  },
};
</script>
<style lang="stylus">
.documents-pdf-render
  position: relative
  .pdf-thumbs
    height: calc(100vh - 255px)
  .document-select
    width: 100%
    max-width: 68%
    position: absolute
    z-index: 2
    right: 0
    left: 0.4rem
    top: 0.4rem
    padding: .3em 2.5em .3em .6em
    border-color: #CCCCCC !important
    background-color: #fff
    border-radius: 5px
  .dragging-zone
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    margin: auto
  .dropzone
    top: 0
    bottom: 0
    margin: auto
    position: absolute
    left: 0
    right: 0
    z-index: 3
@media screen and (min-width: 468px)
  .documents-pdf-render
    .document-select
      max-width: 73%
@media screen and (min-width: 576px)
  .documents-pdf-render
    .document-select
      max-width: 33%
      left: 13.5rem
@media screen and (min-width: 768px)
  .documents-pdf-render
    .document-select
      max-width: 50%
      left: 13.5rem
@media screen and (min-width: 992px)
  .documents-pdf-render
    .document-select
      max-width: 47%
      left: 13.5rem
@media screen and (min-width: 1400px)
  .documents-pdf-render
    .document-select
      max-width: 60%
      left: 13.5rem
</style>
