<template lang="pug">
modal.checkbox-settings-modal(
  :disable-process-button='invalidForm'
  @opened='onOpenModal'
  @process='process'
  close-button-label='Cancelar'
  process-button-label='Salvar'
  ref='modal'
  size='sm'
  title='Anotações'
)
  .col-12(v-if='element')
    form.needs-validation(novalidate)
      .form-group.mb-0
        label(for='field-comment-value') Insira uma anotação
        textarea#field-comment-value.mt-0.form-control(
          @blur='validate'
          @input='validate'
          maxlength='100'
          placeholder='Digite um texto'
          required
          rows='3'
          style='min-height: 80px'
          v-model='element.value'
        )
        .invalid-feedback Por favor, preencha o campo com uma anotação
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';

export default {
  components: {
    Modal,
    Icon,
  },
  data() {
    return {
      element: {},
      invalidForm: true,
    };
  },
  methods: {
    onOpenModal() {
      this.validate();
      if (!this.element.value) {
        window
          .jQuery('#field-comment-value')
          .removeClass('is-valid')
          .removeClass('is-invalid');
      }
    },
    process() {
      this.element.textLines = this.element.value.split('\n').length;
      this.$emit('settings', this.element);
      this.$refs.modal.close();
    },
    validate() {
      if (this.element.value) {
        this.invalidForm = false;
      } else {
        this.invalidForm = true;
      }
    },
  },
};
</script>
<style lang="stylus">
.checkbox-settings-modal
  .form-check.form-switch
    .form-check-label
      margin: .2rem 0 0 .5rem
</style>
