<template lang="pug">
#planinfo__molecule
  .d-flex.flex-column(v-if='hasPlanContract')
    a.btn.btn__contract-plan.w-100(
      href='https://wa.me/556299828935'
      role='button'
      target='_blank'
    )
      icon(name='crown')
      span.text Consultor Online
    .envelopes-consumed.mt-3.d-xl-none.text-overflow-ellipse
      span.text Créditos restantes:
      span.text.ms-1(style='color: #01afec') {{ remainingSignatures }}
  .envelopes-consumed.mt-2.w-100(v-else)
    a.btn.btn__contract-plan.w-100(
      href='https://wa.me/556299828935'
      role='button'
      target='_blank'
    )
      icon(name='whatsapp' type='brands')
      span.text Contrate agora
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'PlanInfo',
  components: {
    Icon,
  },
  data() {
    return {
      subscriptionPlan: null,
    };
  },
  created() {
    this.subscriptionPlan = this.$store.state.subscription.plan;
  },
  computed: {
    hasPlanContract() {
      return (
        this.subscriptionPlan.plan === null ||
        this.subscriptionPlan.plan.name != 'Free'
      );
    },
    showSubscriptionPlan() {
      return this.subscriptionPlan.plan && this.subscriptionPlan.quota;
    },
    remainingSignatures() {
      if (!this.$store.state.subscription.plan.quota) {
        return 0;
      }
      return (
        this.$store.state.subscription.plan.quota.envelopes.available -
        this.$store.state.subscription.plan.quota.envelopes.consumed
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
.btn__contract-plan
  border-color: #013D52
  color: #013D52
.envelopes-consumed
  font-weight: 600
  color: #013D52
  font-size: .9rem
</style>
