import BffHttp from '@/services/BffHttp';

export default {
  namespaced: true,
  state: {
    settings: {},
  },
  getters: {
    getOnboardings: (state) => state.settings,
    getOnboarding: (state) => (onboardingName) => {
      if (state.settings[onboardingName]) {
        return state.settings[onboardingName];
      }
      return null;
    },
  },
  actions: {
    getOnboardings: ({ commit }) =>
      BffHttp.get()
        .then((result) => {
          commit('setOnboardings', result.data.settings);
        })
        .catch(() => {
          return;
        }),
    updateOnboarding: ({ dispatch, commit }, onboarding) => {
      commit('updateOnboarding', onboarding);
      dispatch('saveOnboardings');
    },
    removeOnboarding: ({ dispatch, commit }, onboardingName) => {
      commit('removeOnboarding', onboardingName);
      dispatch('saveOnboardings');
    },
    saveOnboardings: ({ state, commit }) => {
      return BffHttp.put(null, state, '')
        .then((result) => {
          commit('setOnboardings', result.data.settings);
        })
        .catch(() => {});
    },
  },
  mutations: {
    setOnboardings: (state, onboardings) => {
      state.settings = onboardings;
    },
    updateOnboarding: (state, { onboardingName, value }) => {
      state.settings[onboardingName] = value;
    },
    removeOnboarding: (state, onboardingName) => {
      delete state.settings[onboardingName];
    },
  },
};
