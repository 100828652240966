<template lang="pug">
.alert(
  :class='classes'
  :style='styles'
  onclick='$(this).alert("close");'
  role='alert'
)
  icon(:name='icon')
  span.text {{ text }}
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'AlertSnackBar',
  components: {
    Icon,
  },
  props: {
    text: String,
    icon: String,
    classes: String,
    styles: String,
  },
};
</script>
<style lang="stylus" scoped>
.alert
  font-weight: 400
  border-color: inherit
  border-radius: 4px
  background-color: transparent
.alert-danger:before
  background-color: transparent
</style>
