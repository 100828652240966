import Http from '@/services/Http';

const path = 'p/envelopes/recipients';

const client = new Http('');

client.listEspecificRecipientEnvelope = (token) => client.get(token, '', path);
client.execAction = (token, data) => {
  if (!data.documents && !data.tnc) {
    return client.post(data, `${path}/${token}`);
  }
  return client.post(data, `${path}/${token}/sign-with-plugin`);
};
client.fillField = (token, id, value) =>
  client.patch(id, { value }, `${path}/${token}/fields`);
client.editRecipient = (recipientToken, envelopeId, payload) =>
  client.patch(
    null,
    payload,
    `/envelopes/${envelopeId}/recipients/${recipientToken}`
  );
client.rejectEnvelope = (token, reason = null) =>
  client.delete('', '', `${path}/${token}`, { data: { reason } });

export default client;
