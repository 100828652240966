<template lang="pug">
modal#no-credit-modal(
  :showCancelButton='false'
  @process='closeModal'
  process-button-label='Salvar e fechar'
  ref='modal'
  size='sm'
  title='Créditos insuficientes'
)
  .text Seu envelope será salvo como rascunho.
  .text
    a(href='https://wa.me/556299828935' target='_blank') Clique aqui
    span
      |
      | e fale com um consultor para inserir novos créditos.
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/mailbox-no-credits.svg')
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'NoCreditModal',
  components: {
    Modal,
  },
  methods: {
    async closeModal() {
      this.$emit('saveAndClose');
      this.$refs.modal.close();
    },
  },
};
</script>
