<template lang="pug">
.users-guest-card-table
  card-table(:total-items='data.length' breakpoint-size='lg')
    template(v-for='(item, index) in data' v-slot:[`card-${index}`]='{}')
      .mb-1 Data: {{ formattedDate(item.created_at) }}
      .mb-1 Valor: {{ item.value.formatted }}
      .mb-1 Status: {{ formattedStatus(item.status).text }}
      .mb-0
        span.owner__organization Ver Detalhes
    template(v-slot:table-head)
      th(style='width: 30%') Data
      th(style='width: 30%') Valor
      th(style='width: 20%') Status
      th(style='width: 20%')
    template(v-for='(item, index) in data' v-slot:[`table-row-${index}`]='{}')
      td {{ formattedDate(item.created_at) }}
      td {{ item.value.formatted }}
      td
        .alert-simple(:class='formattedStatus(item.status).class') {{ formattedStatus(item.status).text }}
      td
        .text-end
          span.owner__organization Ver detalhes
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import CardTable from '@/ui/organisms/CardTable.vue';
import moment from 'moment';

export default {
  name: 'PlanDetailsCardTable',
  components: {
    Icon,
    CardTable,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formattedDate(item) {
      return moment(item).format('DD/MM/YYYY') || null;
    },
    formattedStatus(item) {
      if (item === 'created') {
        return {
          text: 'em aberto',
          class: 'alert-simple-warning',
        };
      } else if (item === 'pending') {
        return {
          text: 'aguardando',
          class: 'alert-simple-warning',
        };
      } else if (item === 'paid') {
        return {
          text: 'pago',
          class: 'alert-simple-success',
        };
      } else if (item === 'rejected') {
        return {
          text: 'recusado',
          class: 'alert-simple-danger',
        };
      }
    },
  },
};
</script>

<style lang="stylus">
.users-guest-card-table
  .btn-reinvite-user
    i
      color: #013D52;
  .btn--delete-user
    position: absolute
    top: 0.5rem
    right: 0.5rem
  .owner__organization
    font-weight: 600
    color: #0080A3
</style>
