<template lang="pug">
.image-holder.d-flex.align-items-center.justify-content-center
  span.text {{ abr }}
</template>
<script>
import Abbreviator from '@/services/Abbreviator';

export default {
  name: 'ImageHolder',
  props: {
    text: String,
    width: {
      type: String,
    },
    height: String,
  },
  computed: {
    abr() {
      return Abbreviator.get(this.text);
    },
  },
};
</script>
<style lang="stylus">
.image-holder
  display: inline-block
  background-color: #ccc
  width: 3em
  height: 3em
</style>
