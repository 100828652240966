<template lang="pug">
modal#warning-save-and-close-envelope-modal(
  :no-footer='true'
  ref='modal'
  size='sm'
  title='Atenção!'
)
  .text-start Você deseja sair sem salvar o envelope?
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/warning-envelope.svg')
  .text-end.mt-5
    button.btn.btn-secondary.me-2(
      @click='saveAndClose'
      data-bs-dismiss='modal'
      type='button'
    )
      span.text Salvar e fechar
    button.btn.btn-danger(
      @click='discardChanges'
      data-bs-dismiss='modal'
      type='button'
    )
      span.text Descartar
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'WarningSaveAndCloseEnvelopeModal',
  components: {
    Modal,
  },
  methods: {
    saveAndClose() {
      this.$emit('saveAndClose');
    },
    discardChanges() {
      this.$emit('discardChanges');
    },
  },
};
</script>
