<template lang="pug">
modal#show-developer-client-secret-modal(
  :showCancelButton='false'
  @closed='onClose'
  @process='submit'
  process-button-label='Ok'
  ref='modal'
  title='Client Secret'
)
  p Client Secret gerado com sucesso!
  p Por motivos de segurança esse código será mostrado apenas uma única vez.
    | &nbsp;Copie o código abaixo e guarde-o em um lugar seguro.
  .col-12.mt-4
    .form-group.form-group--span-right
      label(for='name') Client secret
      span(ref='clipboard')
        a(@click='doCopy' style='cursor: pointer')
          icon(
            :style='isCopied'
            name='copy'
            v-tooltip:bottom='`Copiar`'
          )
      .form-control-icon
        icon(name='user-secret')
        input#name.form-control(
          :value='clientSecret'
          autocomplete='off'
          disabled
          type='text'
        )
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import Icon from '@/ui/atoms/Icon.vue';
import alert from '@/modules/account/common/alert';

export default {
  name: 'ShowDeveloperClientSecretModal',
  props: {
    clientSecret: [Number, String],
    isRefreshingClientSecret: Boolean,
  },
  components: {
    Modal,
    Icon,
    ImageLoader,
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    submit() {
      this.$refs.modal.close();
      this.$emit('refresh');
      if (this.isRefreshingClientSecret) return;
      this.$emit('showInfoModal');
    },
    doCopy() {
      this.$copyText(this.clientSecret, this.$refs.clipboard);
      this.copied = true;
      alert.fireAlert('Link Copiado', {
        classes: 'alert-success',
        styles:
          'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
        icon: 'circle-check',
        zIndex: 1061,
      });
    },
    onClose() {
      this.copied = false;
    },
  },
  computed: {
    isCopied() {
      if (this.copied) return 'color: #01AFEC';
      return '';
    },
  },
};
</script>

<style scoped lang="stylus">
#show-developer-client-secret-modal
  z-index: 1060;
</style>
