var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modal",attrs:{"id":"create-contact-modal","loading":_vm.loading,"close-button-label":"Cancelar","process-button-label":"Salvar","size":"sm","title":"Novo contato"},on:{"closed":_vm.onCloseModal,"process":_vm.submit}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}}),_c('ValidationProvider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nome")]),_c('div',{staticClass:"form-control-icon"},[_c('icon',{attrs:{"name":"user"}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.contact.name),expression:"contact.name",modifiers:{"trim":true}}],staticClass:"form-control",class:{ "border border-danger": errors[0] },attrs:{"autocomplete":"off","placeholder":"Digite um nome completo","type":"text"},domProps:{"value":(_vm.contact.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})],1),_c('small',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Endereço de e-mail")]),_c('div',{staticClass:"form-control-icon"},[_c('icon',{attrs:{"name":"at"}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.contact.email),expression:"contact.email",modifiers:{"trim":true}}],staticClass:"form-control",class:{ "border border-danger": errors[0] },attrs:{"autocomplete":"off","placeholder":"Digite um e-mail","type":"text"},domProps:{"value":(_vm.contact.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})],1),_c('small',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"telefone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Telefone (opcional)")]),_c('div',{staticClass:"form-control-icon"},[_c('icon',{attrs:{"name":"phone"}}),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:("+## ## # ####-####"),expression:"\"+## ## # ####-####\""},{name:"model",rawName:"v-model.trim",value:(_vm.contact.phone),expression:"contact.phone",modifiers:{"trim":true}}],staticClass:"form-control",class:{ "border border-danger": errors[0] },attrs:{"autocomplete":"off","placeholder":"Digite um telefone","type":"text"},domProps:{"value":(_vm.contact.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "phone", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})],1),_c('small',{staticClass:"text text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }