import Http from '@/services/Http';

const path = 'envelopes';
const dashboard = new Http(path);

dashboard.selfActivities = () => dashboard.get('stats?source=self');

dashboard.organizationActivities = () =>
  dashboard.get('stats?source=workspace');

export default dashboard;
