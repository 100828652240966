<template lang="pug">
modal#canceled-envelope-modal(
  :show-cancel-button='false'
  @process='closeModal'
  process-button-label='Ok'
  ref='modal'
  size='sm'
  title='Envelope cancelado'
)
  p.justify-center O envelope foi cancelado com sucesso! Todos os destinatários serão
    |
    | notificados sobre esse cancelamento.
  .text-center.my-4
    img.img-fluid(src='/img/ilustrations/canceled-rejected-envelope.svg')
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'CancelEnvelopeModal',
  components: {
    Modal,
  },
  methods: {
    closeModal() {
      this.$refs.modal.close();
    },
  },
};
</script>
