<template lang="pug">
modal#refresh-developer-client-secret-modal(
  @process='submit'
  close-button-label='Cancelar'
  process-button-label='Recriar'
  ref='modal'
  title='Atenção'
)
  .text-center
    p.text-start Ao recriar o Client Secret da aplicação, o anterior será revogado. Essa ação não poderá ser desfeita.

    img.mt-4.mb-3(
      alt='developer-delete-warning'
      src='/img/ilustrations/application-warning.svg'
    )
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import Icon from '@/ui/atoms/Icon.vue';
import OauthService from '@/services/Oauth';

export default {
  name: 'RefreshDeveloperClientSecretModal',
  props: {
    clientId: [Number, String],
  },
  components: {
    Modal,
    Icon,
    ImageLoader,
  },
  methods: {
    submit() {
      OauthService.refreshClientSecret(this.clientId)
        .then((response) => {
          this.$refs.modal.close();
          this.$emit('showClientSecret', response.data.plain_secret);
        })
        .catch((response) => {
          console.log(response.data.error);
        });
    },
  },
};
</script>
