<template lang="pug">
modal#error-modal(close-button-label='Ok' title='Erro ao processar operação')
  .text {{ message }}
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'ErrorModal',
  props: {
    message: String,
  },
  components: {
    Modal,
  },
};
</script>
