<template lang="pug">
modal.checkbox-settings-modal(
  :no-footer='true'
  @closed='onClose'
  close-button-label='Cancelar'
  ref='modal'
  size='sm'
  title='Imagem'
)
  .col-12(v-if='element')
    upload-area(:key='key' @change='loadFile')
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Modal from '@/ui/molecules/Modal.vue';
import UploadArea from '@/ui/molecules/ImageUploadArea.vue';

export default {
  components: {
    Modal,
    Icon,
    UploadArea,
  },
  data() {
    return {
      element: {},
      key: 1,
    };
  },
  methods: {
    async loadFile(file) {
      const imageBase64 = file.image;
      const imageData = await this.getImageData(imageBase64);
      this.$set(this.element, 'key', this.element.key + 1);
      this.$set(this.element, 'value', file.blob);
      // this.$set(this.element, 'image', '');
      this.$set(this.element, 'image', imageData);
      // this.$set(this.element, 'width', imageData.width);
      // this.$set(this.element, 'height', imageData.height);
      this.$emit('settings', this.element);
      this.$refs.modal.close();
    },
    async getImageData(base64) {
      const result = await new Promise((resolve) => {
        const image = new Image();
        image.src = base64;
        image.onload = () => resolve(image);
      });
      return result;
    },
    onClose() {
      this.key += 1;
    },
  },
};
</script>
<style lang="stylus">
.checkbox-settings-modal
  .form-check.form-switch
    .form-check-label
      margin: .2rem 0 0 .5rem
</style>
