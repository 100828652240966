<template lang="pug">
modal.signature-creator-modal(
  :loading='loading'
  :no-footer='true'
  :title='label'
  @closed='onCloseModal'
  @opened='onOpenModal'
  @process='process'
  ref='modal'
  size='lg'
)
  .d-flex.flex-column(style='height: inherit')
    ul#signature-options.nav.nav-tabs(
      role='tablist'
      v-if='signatureSupport.length > 1'
    )
      li.nav-item(role='presentation' v-if='signatureSupport.includes("text")')
        button#signature-tab.nav-link.active(
          @click='tab = 1; $refs.textToImage.render()'
          aria-controls='signature'
          aria-selected='true'
          data-bs-target='#signature'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) Padrão
      li.nav-item(role='presentation' v-if='signatureSupport.includes("draw")')
        button#draw-tab.nav-link(
          @click='tab = 2; signature = ""'
          aria-controls='draw'
          aria-selected='true'
          data-bs-target='#draw'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) Desenho
      li.nav-item(
        role='presentation'
        v-if='signatureSupport.includes("image")'
      )
        button#upload-tab.nav-link(
          @click='tab = 3; signature = ""'
          aria-controls='upload'
          aria-selected='false'
          data-bs-target='#upload'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) Imagem
    #signature-options-content.tab-content.pt-3(v-if='isOpen')
      #signature.tab-pane.fade(
        :class='{ "show active": tab === 1 }'
        aria-labelledby='settings-tab'
        role='tabpanel'
      )
        form.form
          .row
            .col-12.col-md-6
              .form-group.mb-0
                label(for='signature-name') Nome
                .form-control-icon
                  icon(name='user')
                  input#signature-name.form-control(
                    :maxlength='signatureTextMaxLength'
                    @input='checkText(text)'
                    autocomplete='off'
                    placeholder='Ex: Roberto A. T. Soares'
                    type='text'
                    v-model='text'
                  )
              .mb-2
                icon.me-2(
                  name='circle'
                  style='font-size: 0.6em; color: #949494'
                )
                small.text Máximo de {{ signatureTextMaxLength }} caracteres
            .col-12.col-md-6
              .form-group
                label(for='signature-typography') Fonte
                select#signature-typography.form-control.form-select(
                  v-model='font'
                )
                  option(
                    :key='index'
                    :value='item'
                    v-for='(item, index) in fonts'
                  ) {{ item }}
            .col-12.col-sm-6
              .form-group.mt-4(style='position: relative')
                label(style='position: absolute; top: -12px; left: 1rem') {{ label }}:
                .draw-example
                  text-to-image(
                    :font='font'
                    :text='text'
                    @change='signature = tab === 1 ? $event : ""'
                    @fonts='onEmitFonts'
                    ref='textToImage'
                    v-if='text'
                  )
      #draw.tab-pane.fade(
        :class='{ "show active": tab === 2 }'
        aria-labelledby='draw-tab'
        role='tabpanel'
      )
        template(v-if='isMobile')
          .draw-image-preview(v-if='signature')
            img.img-fluid(:src='signature.image')
            button.btn-clear(@click='signature = ""')
              icon(name='trash-can')
          template(v-else)
            img.signature-type-image.mx-auto.img(
              src='/img/ilustrations/mobile-draw-area.svg'
            )
            p.text-center.py-3 Desenhe a <span class="text-lowercase"> {{ label }}</span>
              |
              | com os dedos ou utilize uma caneta especial.
            draw-area-mobile(
              :label='label'
              @change='signature = $event'
              ref='drawArea'
              v-if='tab === 2'
            )
        template(v-else)
          p.text-center.py-3 Desenhe a <span class="text-lowercase"> {{ label }}</span>
            |
            | com o mouse ou utilize uma caneta especial.
          draw-area(
            :label='label'
            @change='signature = $event'
            ref='drawArea'
            v-if='tab === 2'
          )
      #upload.tab-pane.fade(
        :class='{ "show active": tab === 3 }'
        aria-labelledby='upload-tab'
        role='tabpanel'
      )
        template(v-if='!isMobile')
          upload-area(@change='signature = $event' ref='uploadArea')
        template(v-else)
          upload-area(
            :style='!signature ? "height: 0; overflow: hidden;" : ""'
            @change='signature = $event'
            ref='uploadArea'
          )
          template(v-if='!signature')
            img.signature-type-image.mx-auto.img(
              src='/img/ilustrations/mobile-upload-area.svg'
            )
            .d-flex.flex-column
              p.text-center.py-3 Ao selecionar sua imagem, verifique se:
              ul.mx-auto.list-unstyled(
                style='list-style: disc; list-style-position: inside'
              )
                li Não contém objetos ao redor do papel.
                li Esteja visível e com qualidade.
                li Esteja desenhada em um papel branco.
    .signature-creator-modal__actions.mt-auto
      template(v-if='!isMobile')
        button.btn.btn-primary(@click='process' v-if='signature') Salvar
        button.btn.btn-primary(disabled v-else) Salvar
      template(v-else)
        button.btn.btn-primary(@click='process' v-if='signature') Salvar
        button.btn.btn-thin(
          @click='openDrawAreaExtended'
          v-if='!signature && tab === 2'
        ) Começar
        button.btn.btn-thin(@click='onLoadFile' v-if='!signature && tab === 3') Selecionar
      button.btn.btn-secondary(@click='close') Cancelar
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import TextToImage from '@/ui/molecules/TextToImage.vue';
import DrawArea from '@/ui/molecules/DrawArea.vue';
import DrawAreaMobile from '@/ui/molecules/DrawAreaMobile.vue';
import Icon from '@/ui/atoms/Icon.vue';
import UploadArea from '@/ui/molecules/ImageUploadArea.vue';

export default {
  components: {
    Modal,
    Icon,
    TextToImage,
    DrawArea,
    DrawAreaMobile,
    UploadArea,
  },
  props: {
    defaultText: String,
    label: {
      type: String,
      default: () => 'Assinatura',
    },
    signatureTextMaxLength: {
      type: Number,
      default: 30,
    },
    signatureSupport: {
      type: Array,
      default: () => ['text', 'draw', 'image'],
      required: false,
    },
  },
  data() {
    return {
      text: '',
      tab: 1,
      loading: false,
      font: '',
      fonts: [],
      signature: '',
      isOpen: false,
    };
  },
  computed: {
    isMobile() {
      return this.$root.isMobile && !this.$root.isIos;
    },
  },
  watch: {
    defaultText(value) {
      this.text = value;
    },
  },
  methods: {
    process() {
      this.$emit('change-signature', this.signature);
      this.$refs.modal.close();
    },
    onCloseModal() {
      this.isOpen = false;
      this.signature = '';
      this.tab = 1;
    },
    close() {
      this.$refs.modal.close();
    },
    onOpenModal() {
      this.isOpen = true;
      if (this.signatureSupport.length > 1) {
        window.jQuery('#signature-tab').tab('show');
        return;
      }
      if (this.signatureSupport.includes('text')) {
        this.tab = 1;
      }
      if (this.signatureSupport.includes('draw')) {
        this.tab = 2;
      }
      if (this.signatureSupport.includes('image')) {
        this.tab = 3;
      }
    },
    onEmitFonts(fonts) {
      this.fonts = fonts;
      [this.font] = this.fonts;
    },
    checkText(value) {
      if (!value) {
        this.signature = '';
      }
    },
    openDrawAreaExtended() {
      this.$refs.drawArea.expand();
    },
    onLoadFile() {
      this.$refs.uploadArea.selectFile();
    },
  },
};
</script>
<style lang="stylus">
.signature-creator-modal
  .modal-body
    height: inherit
  .modal-dialog
    top: 0
    bottom: 0
    position: absolute
    // min-height: 100vh
    min-width: 100vw
    margin: 0
    height: inherit
  .modal-content
    height: inherit
  .signature-designer .line
    margin-top: -1rem
  .draw-example
    min-height: 100px
    border: 1px solid #e0e6ed
    padding: 0
    border-color: #b7b7b7
    border-style: solid
    border-radius: 5px
    border-top-width: 0
    border-left-width: 1px
    border-right-width: 0
    border-bottom-width: 1px
  .signature-type-image
    width: 75%
    max-width: 520px
    display: block
    max-height: 260px
  .draw-area-mobile
    position: absolute
    left: 100%
  .draw-image-preview
    border: 1px solid #ccc
    border-radius: .4em
    position: relative
    .btn-clear
      color: #949494
      position: absolute
      top: 0.5em
      right: 0.5em
      font-size: 1.5rem
.signature-creator-modal__actions
  .btn
    width: 100%
    margin-bottom: 2em
    &:last-child
      margin-bottom: 0
@media (min-width: 992px)
  .signature-creator-modal
    .modal-dialog
      max-width: 55rem
      height: auto
      position: initial
      min-width: auto
      margin: 5rem auto
      height: auto
    .signature-creator-modal__actions
      margin-top: 2em !important
      text-align: right
      direction: rtl
      .btn
        width: auto
        margin-bottom: 0
        margin-left: 1.5em
</style>
