<template lang="pug">
.row
  .col-12.mb-3
    ul#my-tab.nav.nav-tabs(role='tablist')
      li.nav-item(role='presentation')
        button#detail-tab.nav-link.active(
          aria-controls='detail'
          aria-selected='true'
          data-bs-target='#detail'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) Detalhes
      li.nav-item(role='presentation' v-if='showLogs')
        button#history-tab.nav-link(
          aria-controls='history'
          aria-selected='true'
          data-bs-target='#history'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) Histórico
    #myTabContent.tab-content.pt-3
      #detail.tab-pane.fade.show.active(
        aria-labelledby='settings-tab'
        role='tabpanel'
      )
        form.form.row
          .col-12.col-xl-6
            .row
              .top-content.col-6.w-100.d-flex.justify-content-between.mb-3.mt-3
                .dropdown-stylized
                  button(
                    aria-expanded='false'
                    data-bs-toggle='dropdown'
                    type='button'
                  )
                    icon.font-color-blue.me-2(name='arrow-down-to-bracket')
                    span.text Baixar Documentos
                    icon.d-inline-block.arrow(name='angle-down')
                  .dropdown-menu(aria-labelledby='productsDropdown')
                    ul
                      template(v-if='documents.length > 1')
                        li
                          button.dropdown-item(
                            @click='downloadEnvelope(envelope)'
                            type='button'
                          )
                            | {{ downloadTitleRestriction(this.envelope.state) }}
                        li
                          span(
                            data-placement='top'
                            data-toggle='tooltip'
                            tabindex='0'
                            title='Disponível apenas quando o envelope estiver finalizado'
                          )
                            button.dropdown-item(
                              :disabled='!envelopeIsCompleted'
                              @click='downloadSignaturePage(envelope)'
                              type='button'
                            ) Página de assinaturas
                      template(v-else-if='documents.length === 1')
                        li
                          a(
                            :href='documents[0].links.download'
                            download
                            target='_blank'
                          )
                            button.dropdown-item(type='button')
                              | {{ downloadTitleRestriction(this.envelope.state) }}
                        li
                          a(
                            :href='documents[0].links.background_sheet'
                            data-placement='top'
                            data-toggle='tooltip'
                            download
                            tabindex='0'
                            target='_blank'
                            title='Disponível apenas quando o envelope estiver finalizado'
                          )
                            button.dropdown-item(
                              :disabled='!envelopeIsCompleted'
                              type='button'
                            ) Página de assinaturas
                button.cancel-button.btn.btn-danger(
                  @click='cancelEnvelope'
                  type='button'
                  v-if='envelopeIsPending && userView === "sender"'
                ) Cancelar Envelope
              .col-12
                .component-title
                  label.space-between(for='name')
                    .text Detalhe do envelope
                div
                  .card
                    .card-body.data-section.p-2.border-adjustment
                      ul.nav.flex-column
                        li(v-if='this.envelope.title')
                          small.text Nome do envelope: {{ this.envelope.title }}
                        li(v-if='this.envelope.subject')
                          small.text Assunto: {{ this.envelope.subject }}
                        li(v-if='this.envelope.created_at')
                          small.text Enviado em: {{ this.envelope.created_at | date }}
                        li(v-if='this.envelope.expire_at')
                          small.text Prazo de assinatura até:
                            |
                            | {{ this.envelope.expire_at | date }}
                        li(v-if='this.envelope.effective_date_reminder_at')
                          small.text Vencimento do envelope em:
                            |
                            | {{ this.envelope.effective_date_reminder_at | date }}
                        li(v-if='this.envelope.effective_date_reminder_at')
                          small.text Notificação de vencimento: {{ daysForNotification }}
                            | dias antes
                        li
                          small.text.me-1 Status do envelope:
                          = ' '
                          IconStatus(:iconStatus='this.envelope.state')
                          span.text {{ $t(this.envelope.state) }}
              .col-12
                .component-title
                  label.space-between(for='name')
                  .text Mensagem
                div
                  .card
                    .card-body.data-section.p-2
                      ul.nav.flex-column.ps-1
                        li
                          small.text
                            | {{ this.message ? this.message : 'Nenhuma mensagem encaminhada' }}
              .col-12
                .component-title.d-flex
                  .me-auto
                    label.space-between(for='name')
                    .text Destinatários
                  button.link-color(
                    @click='reNotify'
                    type='button'
                    v-if='envelope.state === "in-transit" && !onlyFaceToFace'
                    v-tooltip:top='resendTooltipMessage'
                  )
                    icon.me-2(name='share-from-square')
                    span.text Reenviar notificação
                div
                  .card.data-section(
                    :key='recipient.id'
                    v-for='(recipient, index) in recipients'
                  )
                    .card-body.recipients-detail.d-flex.align-items-start.p-2
                      ul.nav.flex-column.ps-1
                        li
                          small.text.recipient-name {{ recipient.addressees[0].name }}
                        template(
                          v-:key='index'
                          v-for='(identifier, index) in recipient.addressees[0].identifiers'
                        )
                          template(v-if='identifier.type === "image"')
                            li.d-none
                              small.text {{ identifier.title }}:
                              |
                              img(:src='identifier.value' style='width: 30px')
                          template(v-else)
                            li
                              small.text {{ identifier.title }}: {{ identifier.value }}
                        li(
                          v-if='recipient.addressees[0].via !== "face-to-face"'
                        )
                          small.text {{ recipient.addressees[0].value }}
                        li
                          small.text Tipo de destinatário:
                            |
                            | {{ recipientLabel(recipient.type) }}
                        li
                          IconStatus(:iconStatus='recipient.state')
                          span.text {{ $t(recipient.state) }}
                      button.pt-2(
                        @click='editRecipient(index)'
                        type='button'
                        v-if='recipientIsPending(recipient.state) && userView === "sender" && !onlyFaceToFace'
                      )
                        icon.font-size-subtitle.font-color-blue(
                          name='pen-to-square'
                        )
              .col-12(v-if='userView === "sender"')
                .component-title
                  label.space-between(for='name')
                  .text Notas
                div
                  .card.data-section(v-if='notes.data.length === 0')
                    .card-body.recipients-detail.d-flex.align-items-start.p-2
                      .text.ps-1 Nenhuma nota adicionada
                  .card.data-section(v-else v-for='note in notes.data')
                    .card-body.recipients-detail.d-flex.flex-column.align-items-start.p-2
                      ul.nav.flex-column.ps-1.pe-1
                        li
                          .recipient-name {{ note.recipient.name }}
                        li
                          span {{ note.recipient.value }}
                        li
                          IconStatus(:iconStatus='note.recipient.state')
                          span.text {{ $t(note.recipient.state) }}
                        li.mt-4.mb-2
                          .text.w-100 {{ note.message }}
                            span.fw-light {{ note.created_at | date }}
                              |
                              | às {{ note.created_at | time }}
          .col-12.col-xl-6.mt-4
            template(v-if='documents')
              documents-viewer(
                :document='documents[0]'
                :documents='documents'
                :envelope='envelope'
              )
      #history.tab-pane.fade.show(
        aria-labelledby='settings-tab'
        role='tabpanel'
        v-if='showLogs'
      )
        .row
          .col-12
            .card-body.shadow(:key='index' v-for='(log, index) in logs.data')
              icon.icon--finished(name='circle')
              span.text {{ log.created_at | date }} às {{ log.created_at | time }}
                |
                | {{ log.message }}
          simple-paginator(:payload='logs' @navigate='fetchEnvelopeLogs')
  reject-envelope-modal(
    :token='this.$route.params.token'
    @refresh='fetchEnvelope'
  )
  edit-recipient-modal(
    :recipient='recipients[selectedRecipientIndex]'
    @deselect='deselectRecipient'
    @edit='sendNewRecipient'
    ref='editRecipientModal'
    v-if='selectedRecipientIndex || selectedRecipientIndex === 0'
  )
  error-modal(:message='this.errorMessage')
  cancel-envelope-modal(
    :envelopeId='this.$route.params.id'
    @refresh='cancelFeedback'
    ref='cancelEnvelopeModal'
  )
  canceled-envelope-feedback-modal(ref='canceledEnvelopeFeedbackModal')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';
import RejectEnvelopeModal from '@/modules/business/components/organisms/modals/RejectEnvelopeModal.vue';
import CancelEnvelopeModal from '@/modules/business/components/organisms/modals/CancelEnvelopeModal.vue';
import ErrorModal from '@/modules/business/components/organisms/modals/ErrorModal.vue';
import EditRecipientModal from '@/modules/business/components/organisms/modals/EditRecipientModal.vue';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import RecipientsService from '@/modules/business/services/http/envelopes/RecipientsService';
import DocumentsViewer from '@/ui/organisms/DocumentsPDFViewer.vue';
import alert from '@/modules/account/common/alert';
import RecipientType from '@/enums/RecipientType';
import RecipientState from '@/enums/RecipientState';
import EnvelopeState from '@/enums/EnvelopeState';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import StorageHttp from '@/services/StorageHttp';
// eslint-disable-next-line max-len
import CanceledEnvelopeFeedbackModal from '@/modules/business/components/organisms/modals/CanceledEnvelopeFeedbackModal.vue';
import EnvelopeDownloadsFactory from '@/modules/business/common/EnvelopeDownloads/EnvelopeDownloadsFactory';

export default {
  name: 'DashboardPage',
  components: {
    Icon,
    IconStatus,
    RejectEnvelopeModal,
    CancelEnvelopeModal,
    ErrorModal,
    Document,
    DocumentsViewer,
    SimplePaginator,
    EditRecipientModal,
    CanceledEnvelopeFeedbackModal,
  },
  data() {
    return {
      envelope: '',
      logs: {
        data: [],
        links: {},
        meta: {},
      },
      notes: {
        data: [],
        links: {},
        meta: {},
      },
      documents: [],
      recipients: [],
      selectedRecipientIndex: null,
      message: '',
      errorMessage: '',
    };
  },
  async created() {
    this.$store.dispatch('page/setTitle', 'Envelopes');
    this.$store.dispatch('page/setClass', 'business documents');
    try {
      this.$loading(true);
      await this.fetchEnvelope();
      if (!this.$route.params.token) {
        await this.fetchEnvelopeLogs();
        await this.fetchEnvelopeNotes();
        this.$store.dispatch('page/setHead', {
          title: 'Envelope enviado',
          action: { name: 'business.documents.sent' },
        });
      } else {
        this.$store.dispatch('page/setHead', {
          title: 'Envelope recebido',
          action: { name: 'business.documents.inbox' },
        });
      }
      this.$loading(false);
    } catch {
      this.$loading(false);
    }
  },
  computed: {
    showLogs() {
      if (this.$route.params.token) {
        return false;
      }
      return true;
    },
    isAllowToDownloadSignaturePage() {
      return this.envelope.state === EnvelopeState.COMPLETED;
    },
    getToken() {
      return this.$route.params.token;
    },
    daysForNotification() {
      return this.convertDateToDaysForReminderAt(
        this.envelope.effective_date_reminder_at
      );
    },
    userView() {
      if (this.$route.params.token) {
        return 'recipient';
      }
      return 'sender';
    },
    envelopeIsPending() {
      return this.envelope.state === EnvelopeState.IN_TRANSIT;
    },
    envelopeIsCompleted() {
      return this.envelope.state === EnvelopeState.COMPLETED;
    },
    resendTooltipMessage() {
      if (this.envelope.recipients[0].routing_order > 0) {
        return 'Notifica o próximo destinatário com assinatura pendente.';
      }
      return 'Notifica todos os destinatários com assinaturas pendentes.';
    },
    onlyFaceToFace() {
      const res = this.recipients.filter(
        (r) => r.addressees[0].via !== 'face-to-face'
      );
      return res.length ? false : true;
    },
  },
  methods: {
    async fetchEnvelope() {
      StorageHttp.supressDefault = true;
      let envelope;
      if (this.getToken) {
        await RecipientsService.listEspecificRecipientEnvelope(
          this.$route.params.token
        ).then((result) => {
          envelope = result.data.envelope;
          envelope.recipientState = result.data.state;
        });
      } else {
        await EnvelopesService.listEspecificEnvelope(this.$route.params.id, {
          extended: true,
        })
          .then((result) => {
            envelope = result.data;
          })
          .catch(() => {
            alert
              .fireAlert('O Envelope não foi encontrado na sua conta.', {
                classes: 'alert-danger',
                styles:
                  'background-color: #f8d7da; border-color: #f5c6cb; color: #721c24;',
                icon: 'triangle-exclamation',
              })
              .finally(() => {
                StorageHttp.supressDefault = false;
              });
          });
      }

      this.envelope = envelope;
      this.recipients = envelope.recipients.sort(
        (a, b) => a.routing_order - b.routing_order
      );
      this.message = envelope.message;

      this.documents = envelope.documents.map((doc) => {
        const document = doc;
        document.src = document.links.display;
        return document;
      });
    },
    async reNotify() {
      await EnvelopesService.reNotify(this.envelope.id).then(() => {
        alert.fireAlert('Notificação reenviada!', {
          classes: 'alert-success',
          styles:
            'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
          icon: 'circle-check',
        });
      });
    },
    fetchEnvelopeLogs(page = 1) {
      EnvelopesService.listEvents(this.envelope.id, {
        page,
      }).then((result) => {
        this.logs = result.data;
      });
    },
    fetchEnvelopeNotes() {
      EnvelopesService.listEnvelopeNotes(this.envelope.id).then((res) => {
        this.notes = res.data;
      });
    },
    async downloadEnvelope(envelope) {
      this.$loading(true);
      try {
        const envelopeDownloads =
          EnvelopeDownloadsFactory.createEnvelopeDownloads(
            this.getToken ? this.getToken : envelope.id,
            this.getToken ? 'public' : 'private'
          );

        await envelopeDownloads.downloadDocuments(envelope.title);
      } finally {
        this.$loading(false);
      }
    },
    async downloadSignaturePage(envelope) {
      this.$loading(true);
      try {
        const envelopeDownloads =
          EnvelopeDownloadsFactory.createEnvelopeDownloads(
            this.getToken ? this.getToken : envelope.id,
            this.getToken ? 'public' : 'private'
          );

        await envelopeDownloads.downloadSignaturePage(envelope.title);
      } finally {
        this.$loading(false);
      }
    },
    getHowManyDaysBetweenDates(dateOne, dateTwo) {
      const convertedDate = dateOne ? new Date(dateOne) : new Date();
      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = convertedDate;
      const secondDate = new Date(dateTwo);

      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      return diffDays;
    },
    convertDateToDaysForReminderAt(dateReminderAt) {
      const daysBetween = this.getHowManyDaysBetweenDates(
        dateReminderAt,
        this.envelope.effective_until
          ? new Date(this.envelope.effective_until).fp_incr(1)
          : ''
      );
      const days = dateReminderAt ? daysBetween : null;
      if (days >= 1 && days < 15) {
        return 1;
      }
      if (days >= 15 && days < 30) {
        return 15;
      }
      if (days >= 30) {
        return 30;
      }
      return null;
    },
    recipientLabel(recipientType) {
      const typeValue = RecipientType.values.filter(
        (type) => type.value === recipientType
      );
      return typeValue[0].label;
    },
    recipientIsPending(state) {
      return state === RecipientState.WAITING;
    },
    downloadTitleRestriction(envelopeState) {
      if (envelopeState === EnvelopeState.COMPLETED) {
        return 'Documentos assinados';
      }
      return 'Documentos originais';
    },
    editRecipient(index) {
      this.selectedRecipientIndex = index;

      this.$nextTick(() => {
        this.$refs.editRecipientModal.$refs.modal.open();
      });
    },
    deselectRecipient() {
      this.selectedRecipientIndex = null;
    },
    async sendNewRecipient(newRecipientdata) {
      // Building Request payload
      const payload = {
        name: newRecipientdata.name,
        addressees: [
          {
            identifiers:
              this.recipients[this.selectedRecipientIndex].addressees[0]
                .identifiers,
            via: 'email',
            value: newRecipientdata.email,
            name: newRecipientdata.name,
          },
        ],
      };

      this.$loading(true);
      try {
        await RecipientsService.editRecipient(
          this.recipients[this.selectedRecipientIndex].id,
          this.envelope.id,
          payload
        );
        await this.fetchEnvelope();
      } catch (err) {
        console.log(err);
      } finally {
        this.$refs.editRecipientModal.$refs.modal.close();
        this.deselectRecipient();
        this.$loading(false);
      }
    },
    cancelEnvelope() {
      this.$refs.cancelEnvelopeModal.$refs.modal.open();
    },
    cancelFeedback() {
      this.fetchEnvelope();
      this.$refs.canceledEnvelopeFeedbackModal.$refs.modal.open();
    },
  },
};
</script>

<style scoped lang="stylus">
@media only screen and (max-width: 768px)
  .top-content
    flex-direction: column
    margin-top: 0 !important
    margin-bottom: 0 !important
    .dropdown-stylized
      order: 2
      margin-bottom: 10px
    button.cancel-button
      order: 1
      margin-top: 20px
      margin-bottom: 20px
.card
  &:hover
    box-shadow: none
.data-section
  border-radius: 0rem
  &:first-child
    border-radius: 5px 5px 0px 0px
  &:last-child
    border-radius: 0px 0px 5px 5px
  &:only-child
    border-radius: 5px
.dropdown-menu
  ul
    li
      button:disabled
        background-color: #F7F7F7
        color: #707070
.recipient-name
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
</style>
