<template lang="pug">
.upload-area
  template
    .files-list--modal(v-if='files')
      .row
        .col-6.col-lg-7(v-if='files')
          .file-name.shadow(
            :title='files.name'
            style='background-color: #f7f7f7; padding: 0.5rem'
          ) {{ files.name }}
            button(@click='removeFile')
              icon.icon--dark-blue(name='xmark' style='margin-left: 1.2rem')
    .card--upload-file(v-else)
      div(
        :class='["dropzone-area", dragging ? "dragenterClass" : ""]'
        @dragend='dragging = false'
        @dragenter='dragging = true'
        @dragleave='dragging = false'
        @dragover.prevent='dragover'
        @drop.prevent='drop'
      )
        template(v-if='this.errorExtension')
          alert(
            text='Ocorreu um erro de envio. Verifique se o arquivo é PDF e tente novamente.'
          )
        input(
          :accept='allowedFileType.join()'
          @change='loadFile'
          ref='inputFile'
          style='display: none'
          type='file'
        )
        label.notclickable.block.cursor-pointer(for='assetsFieldHandle')
          h6.upload-area__title Arraste e solte aqui seu arquivo
          p.mb-2 ou
          button.btn.btn-thin(@click='selectFile') Selecione um Arquivo
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Alert from '@/ui/molecules/Alert.vue';

export default {
  components: {
    Icon,
    Alert,
  },
  props: {
    clear: Boolean,
    maxFileSizeMb: {
      type: Number,
      default: 5,
    },
    allowedFileType: {
      type: Array,
      default: () => ['application/pdf'],
    },
  },
  data() {
    return {
      files: '',
      dragOnDrop: false,
      errorExtension: false,
      dragging: false,
    };
  },
  methods: {
    removeFile() {
      this.files = '';
      this.$emit('change', '');
    },
    loadFile(e) {
      let file = '';
      if (!this.dragOnDrop) {
        file = e.target.files;
      } else {
        file = e.dataTransfer.files;
      }
      if (!this.allowedFileType.includes(file[0].type)) {
        this.errorExtension = true;
        return;
      }
      this.files = file['0'];
      this.$emit('change', file['0']);
    },
    selectFile() {
      this.dragOnDrop = false;
      this.$refs.inputFile.click();
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.dragOnDrop = true;
      this.loadFile(event);
      this.dragging = false;
    },
  },
  watch: {
    clear(value) {
      if (value) {
        this.src = '';
        this.file = '';
      }
    },
  },
};
</script>
<style lang="stylus">
.upload-area
  min-height: 160px
  padding: 1rem 1rem
  border: 2px dashed #c3c3c3
  text-align: center
  position: relative
.upload-area__title
  color: #013D52
  font-weight: 700
.upload-area-img
  max-width: 100%
  max-height: 110px
.remove-button
  position: absolute
  top: 0
  right: 0
  opacity: .2
.card--upload-file
  label
     margin: 1rem 1rem
  .dragenterClass
    cursor: grabbing
    cursor: -webkit-grabbing
    background:rgba(0,0,0,0.1)
    .notclickable
      &:hover
        background:rgba(0,0,0,0.1)
      pointer-events: none;
      h3
        pointer-events: none;
      p
        pointer-events: none;
</style>
