<template lang="pug">
modal#success-checker-modal(close-button-label='Ok' title='Histório')
  .text(v-if='subject') Assunto: {{ subject }}
  .text(v-if='sender') Enviado por: {{ sender }}
  .text(v-if='createdAt') Criado em: {{ createdAt | dateTime }}
  .text(v-if='status') Status : {{ $t(status) }}
  .text(v-if='expireAt') Prazo para assinatura até {{ expireAt | date }}
  .card.card-activity.shadow.mt-5
    .card-body.recipients-detail
      ul.nav.flex-column
        div
          div(:key='recipient.id' v-for='recipient in recipients')
            div
              li
                small.text {{ recipient.addressees[0].value }}
              li
                IconStatus(:iconStatus='recipient.state')
                small.text {{ $t(recipient.state) }}
            hr
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import IconStatus from '@/ui/atoms/IconStatus.vue';

export default {
  name: 'SuccessCheckerModal',
  props: {
    createdAt: String,
    subject: String,
    status: String,
    sender: String,
    expireAt: String,
    recipients: Array,
  },
  components: {
    Modal,
    IconStatus,
  },
};
</script>
