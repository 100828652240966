<template lang="pug">
modal#delete-user-modal.text-center(
  :buttonColor='buttonColor'
  :loading='loading'
  @process='deleteUser'
  close-button-label='Cancelar'
  process-button-label='Excluir'
  processButtonClass='btn-danger'
  ref='modal'
  size='sm'
  title='Excluir usuário'
)
  .text-start Deseja realmente excluir este usuário?<br>
    | Esta ação não poderá ser desfeita.
  img.mt-4.mb-3(
    alt='user-delete-warning'
    src='/img/ilustrations/delete-invite-user.svg'
  )
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';
import ImageLoader from '@/ui/molecules/ImageLoader.vue';
import UsersService from '@/services/UsersService';
import alert from '@/modules/account/common/alert';

export default {
  name: 'DeleteUserModal',
  components: {
    Modal,
    ImageLoader,
    UsersService,
  },
  props: {
    userId: String,
  },
  data() {
    return {
      loading: false,
      buttonColor: true,
    };
  },
  methods: {
    deleteUser() {
      this.$loading(true);
      UsersService.deleteUser(this.userId)
        .then(() => {
          this.$emit('refresh');
          this.loading = false;
          this.$refs.modal.close();
          alert.fireAlert('Usuário excluído com sucesso!', {
            styles:
              'background-color: #D4EDDA; border: solid 1px #AACFB3; color: #0F6642;',
            icon: 'circle-check',
          });
        })
        .catch((error) => {
          alert.fireAlert(
            `Não foi possível excluir este usuário! ${error.response.data.message}`,
            {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
              tag: 'httpAlert',
              icon: 'circle-exclamation',
            }
          );
        })
        .finally(() => {
          this.$loading(false);
        });
    },
  },
};
</script>
