<template lang="pug">
.row
  .col-12.mb-3
    ul#my-tab.nav.nav-tabs(role='tablist')
      li.nav-item(role='presentation')
        button#users-tab.nav-link.active(
          aria-controls='users'
          aria-selected='true'
          data-bs-target='#users'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) Usuários
      li.nav-item(role='presentation')
        button#invitation-tab.nav-link(
          aria-controls='invitation'
          aria-selected='true'
          data-bs-target='#invitation'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) Convite pendente
      li.nav-item(role='presentation')
        button#roles-tab.nav-link(
          aria-controls='roles'
          aria-selected='false'
          data-bs-target='#roles'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) Funções

    #myTabContent.tab-content.pt-3
      #users.tab-pane.fade.show.active(
        aria-labelledby='users-tab'
        role='tabpanel'
      )
        div
          .row
            .col-12.col-sm-7.col-md-4
              .form-group(v-if='showUsersFormSearch')
                .form-control-icon--end-line
                  input#name2.form-control(
                    @keyup='onSearchUser()'
                    autocomplete='off'
                    placeholder='Buscar usuário'
                    type='name'
                    v-model.trim='queryUser'
                  )
                  icon(name='magnifying-glass')

            .col-12.col-sm-5.col-md-8.text-end.mb-2
              button.btn.btn-secondary(
                data-bs-target='#invite-user-modal'
                data-bs-toggle='modal'
                type='button'
              ) Convidar usuário

            .col-12(v-if='showUsersFormSearch')
              .card(:key='user.id' v-for='user in users.data')
                .row.p-2
                  .col-12.col-sm-5.col-md-4.col-lg-4.col-xl-4
                    span.text {{ user.name }}

                  .col-12.col-sm-5.col-md-4.col-lg-4.col-xl-4
                    span.text {{ user.email }}

                  .col-12.col-sm-3.col-md-2.col-lg-2.col-xl-2
                    span.text {{ user.joined_at | dateTime }}

                  .col-12.col-sm-8.col-md-2.col-lg-2.col-xl-2.text-end
                    button(
                      @click='setUserId(user.id)'
                      data-bs-target='#edit-user-modal'
                      data-bs-toggle='modal'
                      title='Editar usuário'
                      type='button'
                    )
                      icon.icon--dark-blue(name='edit')

                    button(
                      @click='setUserId(user.id)'
                      data-bs-target='#remove-user-modal'
                      data-bs-toggle='modal'
                      title='Excluir usuário'
                      type='button'
                    )
                      icon.icon--canceled(name='trash-can')
              simple-paginator(:payload='users' @navigate='listUsers')

          .row
            .col-12.mt-5.text-end(v-if='showCallToCreateNewUser')
              img(
                src='/images/empty-users.svg'
                style='display: block; margin-left: auto; margin-right: auto'
                vertical-align='middle'
              )

              .text-center.mt-5 Você ainda não tem usuários em sua organização.
                |
                | Comece convidando um agora!

            .col-12.mt-5(v-else-if='!hasUsers')
              .text-center.mt-5
                p.mt-4 Nenhum item recente corresponde à sua pesquisa.

      #invitation.tab-pane.fade.show(
        aria-labelledby='themes-tab'
        role='tabpanel'
      )
        div
        .row(v-if='invitations.data.length > 0')
          .col-12.mt-5
            .card(:key='invitation.id' v-for='invitation in invitations.data')
              .row.p-2
                .col-12.col-sm-6.col-md-7.col-lg-6.col-xl-6
                  span.text {{ invitation.email }}

                .col-12.col-sm-3.col-md-3.col-lg-4.col-xl-3
                  span.text {{ invitation.created_at | dateTime }}

                .col-12.col-sm-3.col-md-2.col-lg-2.col-xl-3.text-end
                  //- button(type='button' title='Reenviar convite')
                  //-   icon.icon--dark-blue(name='edit')

                  button(
                    @click='setUserEmail(invitation.email)'
                    data-bs-target='#remove-invite-modal'
                    data-bs-toggle='modal'
                    title='Excluir convite'
                    type='button'
                  )
                    icon.icon--canceled(name='trash-can')
          simple-paginator(:payload='invitations' @navigate='listInvitations')

        .row(v-else)
          .col-12.text-end
            button.btn.btn-secondary(
              data-bs-target='#invite-user-modal'
              data-bs-toggle='modal'
              type='button'
            ) Convidar usuário
          .col-12.mt-5
            img(
              src='/images/empty-invite.svg'
              style='display: block; margin-left: auto; margin-right: auto'
              vertical-align='middle'
            )
          .col-12.mt-4
            .text-center Nenhum convite pendente em sua organização!

      #roles.tab-pane.fade(aria-labelledby='roles-tab' role='tabbusiness')
        div
          .row
            .col-12.col-sm-7.col-md-4
              .form-group(v-if='showRolesFormSearch')
                .form-control-icon--end-line
                  input#name2.form-control(
                    @input='onSearchRoles()'
                    autocomplete='off'
                    placeholder='Buscar função'
                    type='name'
                    v-model.trim='queryRoles'
                  )
                  icon(name='magnifying-glass')

            .col-12.col-sm-5.col-md-8.text-end
              button.btn.btn-secondary(
                data-bs-target='#new-role-modal'
                data-bs-toggle='modal'
                type='button'
              ) Nova função

          .row.mt-1.g-3(v-if='showRolesFormSearch')
            .col-12.col-sm-6.col-md-4(
              :key='role.id'
              v-for='role in roles.data'
            )
              .card.card--mini.card--mini--teams
                router-link(
                  :to='{ name: "business.roles.edit", params: { id: role.id } }'
                )
                  .card-body
                    h5.card-title.mb-0 {{ role.name === 'Usuário' ? 'Usuário padrão' : role.name }}
                    small Criado em {{ role.created_at | dateTime }}
                    ul.mt-2.list-unstyled.horizontal-list--avatar
                      li.list-item(
                        v-for='(user, index) in role.users'
                        v-if='index <= 5'
                      )
                        img.img.rounded-circle(
                          :src='user.avatar'
                          :title='user.name'
                        )
                      li.list-item.more(v-if='role.amount_users > 5')
                        span.img.rounded-circle {{ role.amount_users - 6 }} +
            simple-paginator(:payload='roles' @navigate='listRoles')

          .row
            .col-12.mt-5(v-if='showCallToCreateNewRole')
              .text-center.mt-5
                img.img-fluid.img(
                  src='/img/ilustrations/no-teams.svg'
                  style='max-height: 260px'
                )
                p.mt-4 Você ainda não tem funções em sua organização. Comece criando uma agora.
            .col-12.mt-5(v-else-if='!hasRoles')
              .text-center.mt-5
                p.mt-4 Nenhum item corresponde à sua pesquisa.

  edit-user-modal(
    :operation='operation'
    :user-id='userId'
    @refresh='listUsers'
  )
  remove-user-modal(
    :operation='operation'
    :userId='userId'
    @refresh='listUsers'
  )
  invite-user-modal(@refresh='listInvitations')
  remove-invite-modal(:userEmail='userEmail' @refresh='listInvitations')
  new-role-modal(@refresh='listRoles')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import EditUserModal from '@/modules/business/components/organisms/modals/EditUserModal.vue';
import RemoveUserModal from '@/modules/business/components/organisms/modals/RemoveUserModal.vue';
import InviteUserModal from '@/modules/business/components/organisms/modals/InviteUserModal.vue';
import RemoveInviteModal from '@/modules/business/components/organisms/modals/RemoveInviteModal.vue';
import NewRoleModal from '@/modules/business/components/organisms/modals/NewRoleModal.vue';
import RolesService from '@/modules/business/services/http/organizations/roles/RolesService';
import InvitationsService from '@/modules/business/services/http/organizations/invitations/InvitationsService';
import UsersService from '@/modules/business/services/http/organizations/users/UsersService';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import debounce from 'debounce';

export default {
  name: 'UsersPage',
  props: {
    organizationId: [Number, String],
  },
  components: {
    Icon,
    EditUserModal,
    RemoveUserModal,
    InviteUserModal,
    RemoveInviteModal,
    NewRoleModal,
    RolesService,
    InvitationsService,
    UsersService,
    SimplePaginator,
  },
  data() {
    return {
      users: {
        data: [],
        links: {},
        meta: {},
      },
      queryUser: '',
      invitations: {
        data: [],
        links: {},
        meta: {},
      },
      roles: {
        data: [],
        links: {},
        meta: {},
      },
      queryRoles: '',
      userEmail: '',
      userId: '',
      operation: false,
    };
  },
  created() {
    if (this.$store.state.organizations.personalWorkspace) {
      this.$router.push('/business/dashboard');
    }
    this.$store.dispatch('page/setTitle', 'Usuários');
    this.$store.dispatch('page/setHead', { title: 'Usuários' });
    this.$store.dispatch('page/setClass', 'users');
  },
  async mounted() {
    this.listUsers();
    this.listInvitations();
    this.listRoles();
  },
  computed: {
    hasUsers() {
      return this.users.data.length > 0;
    },
    isSearchingUsers() {
      return this.queryUsers !== '';
    },
    showCallToCreateNewUser() {
      return !this.hasUsers && !this.isSearchingUsers;
    },
    showUsersFormSearch() {
      return this.isSearchingUsers || this.hasUsers;
    },
    hasRoles() {
      return this.roles.data.length > 0;
    },
    isSearchingRoles() {
      return this.queryRoles !== '';
    },
    showCallToCreateNewRole() {
      return !this.hasRoles && !this.isSearchingRoles;
    },
    showRolesFormSearch() {
      return this.isSearchingRoles || this.hasRoles;
    },
  },
  methods: {
    setUserEmail(value) {
      this.userEmail = value;
    },
    setUserId(value) {
      this.userId = value;
      this.operation = true;
    },
    async listEspecificRole(rolesData) {
      const especificRoles = [];
      await Promise.all(
        rolesData.data.data.map(async (role) => {
          const roles = await RolesService.listEspecificRole(role.id, {
            extended: true,
          });
          const users = [];
          if (roles.data.users.length > 0) {
            roles.data.users.forEach(async (userData) => {
              const user = {
                id: userData.id,
                name: userData.name,
                email: userData.email,
                avatar: userData.avatar,
              };
              users.push(user);
            });
          }
          const roleWithUsers = {
            id: roles.data.id,
            name: roles.data.name,
            created_at: roles.data.created_at,
            users,
            amount_users: roles.data.users.length,
          };
          especificRoles.push(roleWithUsers);
        })
      );
      return especificRoles;
    },
    async listRoles(page = 1) {
      this.$loading(true);
      const params = new URLSearchParams({
        q: this.queryRoles,
        page,
      });
      const roles = await RolesService.list(params);
      this.roles = roles.data;
      this.$loading(false);
    },
    async listInvitations(page = 1) {
      this.$loading(true);
      const invitations = await InvitationsService.listPendingInvitations(page);
      this.invitations = invitations.data;
      this.$loading(false);
    },
    async listUsers(page = 1) {
      this.$loading(true);
      const params = new URLSearchParams({
        q: this.queryUser,
        page,
      });
      const users = await UsersService.list(params);
      this.users = users.data;
      this.$loading(false);
    },
    onSearchUser: debounce(function search() {
      this.listUsers();
    }, 500),
    onSearchRoles: debounce(function search() {
      this.listRoles();
    }, 500),
  },
};
</script>
