<template lang="pug">
.recipient-loading
  .wrapper
    .animate
      schimmer-rectangle.mb-2(height='10px' width='60%')
      schimmer-rectangle.mb-2(height='10px' width='60%')
      schimmer-rectangle.mb-2(height='10px' width='60%')
  .wrapper
    .animate
      schimmer-rectangle.mb-2(height='10px' width='60%')
      schimmer-rectangle.mb-2(height='10px' width='60%')
      schimmer-rectangle.mb-2(height='10px' width='60%')
  .wrapper
    .animate
      schimmer-rectangle.mb-2(height='10px' width='60%')
      schimmer-rectangle.mb-2(height='10px' width='60%')
      schimmer-rectangle.mb-2(height='10px' width='60%')
</template>

<script>
import SchimmerRectangle from '@/ui/atoms/SchimmerRectangle.vue';

export default {
  name: 'RecipientsLoader',
  components: {
    SchimmerRectangle,
  },
};
</script>

<style lang="stylus" scoped>
.recipient-loading
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  width: 100%;
.wrapper
	margin-left: 5px
	margin-right: 5px
	justify-content: center;
	animation: fullView 0.5s forwards cubic-bezier(0.250, 0.460, 0.450, 0.940)
@keyframes fullView
  100%
    width 100%
.animate
  animation: shimmer 2s infinite linear
@keyframes shimmer
  0%
    background-position -1000px 0
  100%
    background-position 1000px 0
</style>
