<template lang="pug">
modal#invitation-error-modal(
  :removeCloseButton='true'
  :show-cancel-button='false'
  @closed='onCloseModal'
  @process='goToDashboard'
  process-button-label='Ok'
  ref='modal'
  size='md'
  title='Atenção!'
)
  .inner-content
    .text-center.my-4
      img.img-fluid(src='/img/ilustrations/organizations-hierarchy.svg')
    p.justify-content Esse convite pertence a outra pessoa. Você não tem autorização para
      | &nbsp;acessar essa organização.
</template>

<script>
import Modal from '@/ui/molecules/Modal.vue';

export default {
  name: 'InvitationErrorModal',
  components: {
    Modal,
  },
  data() {
    return {
      confirmOk: false,
    };
  },
  methods: {
    goToDashboard() {
      this.confirmOk = true;
      this.$refs.modal.close();
      this.$router.replace({ name: 'business.dashboard' });
    },
    onCloseModal() {
      if (!this.confirmOk) {
        this.$refs.modal.open();
      }
    },
  },
};
</script>
