<template lang="pug">
.pending-invites-card-table
  card-table(:total-items='data.length' breakpoint-size='lg')
    template(v-for='(item, index) in data' v-slot:[`card-${index}`]='{}')
      .mb-1 Email: {{ item.email }}
      div Convite enviado em: {{ item.created_at | dateTime }}
      button.btn-reinvite-user.icon--blue(
        @click='resendInvite(item.email)'
        v-tooltip:bottom='`Reenviar convite`'
      )
        icon(name='share-from-square')
      button.btn-delete-invite-user(
        @click.stop='openDeleteInviteUserModal(item.email)'
        v-tooltip:bottom='`Excluir usuário`'
      )
        icon.icon--canceled(name='trash-can')
    template(v-slot:table-head)
      th(style='width: 60%') Email
      th(style='width: 30%') Convite enviado em
      th(style='width: 10%')
    template(v-for='(item, index) in data' v-slot:[`table-row-${index}`]='{}')
      td {{ item.email }}
      td {{ item.created_at | dateTime }}
      td
        .text-end
          button.btn-reinvite(
            @click='resendInvite(item.email)'
            v-tooltip:bottom='`Reenviar convite`'
          )
            icon(name='share-from-square')
          button(
            @click.stop='openDeleteInviteUserModal(item.email)'
            v-tooltip:bottom='`Excluir usuário`'
          )
            icon.icon--canceled(name='trash-can')
  delete-invite-user-modal(
    :email='selectedInvitedEmail'
    @refresh='$emit("item-deleted", selectedInvitedEmail)'
    ref='deleteInviteUserModal'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import CardTable from '@/ui/organisms/CardTable.vue';
import DeleteInviteUserModal from '@/modules/account/components/organisms/modals/DeleteInviteUserModal.vue';
import InvitationService from '@/services/InvitationService';
import alert from '@/modules/account/common/alert';

export default {
  name: 'UsersGuestCardTable',
  components: {
    Icon,
    DeleteInviteUserModal,
    CardTable,
    InvitationService,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedInvitedEmail: '',
    };
  },
  methods: {
    resendInvite(email) {
      this.$loading(true);
      InvitationService.supressDefault = true;
      InvitationService.resend(email)
        .then(() => {
          alert.fireAlert('Convite reenviado com sucesso!', {
            classes: 'alert-success',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
            icon: 'circle-check',
          });
        })
        .catch((error) => {
          const message = error.response.data.message;
          alert.fireAlert(message, {
            classes: 'alert-danger',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
            icon: 'circle-exclamation',
          });
        })
        .finally(() => {
          InvitationService.supressDefault = false;
          this.$loading(false);
        });
    },
    openDeleteInviteUserModal(email) {
      this.setEmailToSendModal(email);
      this.$refs.deleteInviteUserModal.$refs.modal.open();
    },
    setEmailToSendModal(email) {
      this.selectedInvitedEmail = email;
    },
  },
};
</script>

<style lang="stylus">
.pending-invites-card-table
  .btn-reinvite-user
    position: absolute
    top: 0.5rem
    right: 0.5rem
  .btn-delete-invite-user
    position: absolute
    bottom: 0.8rem
    right: 0.6rem
  .owner__organization
    font-weight: 600
    color: #013D52
</style>
