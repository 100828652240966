export default class EnumBase {
  static getText(value = '') {
    const obj = this.values.find((item) => item.value === value);
    if (obj) {
      return obj.label;
    }
    return 'Desconhecido';
  }

  static getDescription(value = '') {
    const obj = this.values.find((item) => item.value === value);
    if (obj) {
      return obj.description;
    }
    return 'Desconhecido';
  }

  static getAllValues() {
    return this.values;
  }
}
