<template lang="pug">
.row
  .col-12.mb-3
    .documents__menu-tab
      ul#my-tab.nav.nav-tabs(role='tablist')
        li.nav-item(role='presentation')
          router-link.nav-link(
            :class='{ active: isTabActive("inbox") }'
            :to='{ name: "business.documents.inbox" }'
          ) Recebidos
        li.nav-item(role='presentation')
          router-link.nav-link(
            :class='{ active: isTabActive("sent") }'
            :to='{ name: "business.documents.sent" }'
          ) Enviados
        li.nav-item(role='presentation')
          router-link.nav-link(
            :class='{ active: isTabActive("draft") }'
            :to='{ name: "business.documents.draft" }'
          ) Rascunhos
        li.nav-item(role='presentation')
          router-link.nav-link(
            :class='{ active: isTabActive("template") }'
            :to='{ name: "business.documents.template" }'
          ) Modelos
    #myTabContent.tab-content.pt-3
      router-view
</template>

<script>
export default {
  name: 'DocumentsPage',
  methods: {
    isTabActive(type) {
      return this.$route.name.split('.').pop() === type;
    },
  },
};
</script>
<style lang="stylus">
.documents__menu-tab
  overflow-x: auto
  &::-webkit-scrollbar
    height: 5px
  .nav-tabs
    min-width: 100%
    display: inline-flex
</style>
