<template lang="pug">
span
  icon(:class='icon.class' :name='icon.name')
  span.ms-2 {{ icon.label }}
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'IconEnviromentClient',
  components: {
    Icon,
  },
  props: {
    isSandbox: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    icon() {
      if (this.isSandbox) {
        return {
          name: 'cube',
          label: 'Sandbox',
          class: 'icon--sandbox',
        };
      }
      return {
        name: 'cube',
        label: 'Produção',
        class: 'icon--production',
      };
    },
  },
};
</script>

<style scoped lang="stylus">
.icon--sandbox
  color: #3ECB90 !important
.icon--production
  color: #01AFEC !important
</style>
