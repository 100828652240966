<template lang="pug">
.local-plugin-signature-flow
  qualified-signature-load-plugin-modal(
    ref='qualifiedSignatureLoadPluginModal'
  )
  qualified-signature-loading-modal(ref='qualifiedSignatureLoadingModal')
  qualified-signature-download-plugin-modal(
    @close='init'
    ref='qualifiedSignatureDownloadPluginModal'
  )
  qualified-signature-plugin-authorize-modal(
    @closed='authorizeModalClosed'
    ref='qualifiedSignaturePluginAuthorizeModal'
  )
  qualified-signature-list-certificates-plugin-modal(
    :certificates='certificates'
    :user-email='envelope.value'
    @certificate-selected='onSelectCertificate'
    ref='qualifiedSignatureListCertificatesPluginModal'
  )
</template>
<script>
import LocalStorage from '@/services/LocalStorage';
import SignPluginService from '@/modules/business/services/http/SignPlugin/SignPluginService';
// eslint-disable-next-line max-len
import QualifiedSignatureLoadPluginModal from '@/modules/business/components/organisms/modals/QualifiedSignatureLoadPluginModal.vue';
// eslint-disable-next-line max-len
import QualifiedSignatureLoadingModal from '@/modules/business/components/organisms/modals/QualifiedSignatureLoadingModal.vue';
// eslint-disable-next-line max-len
import QualifiedSignatureDownloadPluginModal from '@/modules/business/components/organisms/modals/QualifiedSignatureDownloadPluginModal.vue';
// eslint-disable-next-line max-len
import QualifiedSignatureListCertificatesPluginModal from '@/modules/business/components/organisms/modals/QualifiedSignatureListCertificatesPluginModal.vue';

// eslint-disable-next-line max-len
import QualifiedSignaturePluginAuthorizeModal from '@/modules/business/components/organisms/modals/QualifiedSignaturePluginAuthorizeModal.vue';

import EnvelopesPublicService from '@/modules/business/services/http/envelopes/EnvelopesPublicService';

export default {
  name: 'LocalPluginSignatureFlow',
  components: {
    QualifiedSignatureLoadPluginModal,
    QualifiedSignatureLoadingModal,
    QualifiedSignatureDownloadPluginModal,
    QualifiedSignatureListCertificatesPluginModal,
    QualifiedSignaturePluginAuthorizeModal,
  },
  props: {
    envelope: {
      type: Object,
    },
    location: {
      type: Object,
    },
  },
  data() {
    return {
      nonce: '',
      challenge: '',
      app: '',
      token: '',
      certificates: [],
      license: '',
      documents: [],
    };
  },
  created() {
    this.app = LocalStorage.get('signg-plugin-app-data');
  },
  methods: {
    async init() {
      this.$refs.qualifiedSignatureLoadingModal.text =
        'Verificando execução do plugin...';
      this.$refs.qualifiedSignatureLoadingModal.$refs.modal.open();
      try {
        await this.hello();
      } catch (error) {
        this.$refs.qualifiedSignatureLoadingModal.$refs.modal.close();
        this.$refs.qualifiedSignatureDownloadPluginModal.$refs.modal.open();
        return;
      }

      if (!this.challenge || !this.nonce) {
        this.$refs.qualifiedSignatureLoadingModal.$refs.modal.close();
        return;
      }
      this.$refs.qualifiedSignatureLoadingModal.text = 'Autorizando plugin...';
      if (!this.app) {
        await this.authorize();
        return;
      }
      try {
        await this.login();
      } catch (error) {
        this.app = '';
        await this.authorize();
        return;
      }
      await this.listCertificates();
    },
    async hello() {
      const res = await SignPluginService.hello();
      this.nonce = res.data.Nonce;
      this.challenge = res.data.Challenge;
    },
    async authorize() {
      const res = await SignPluginService.authorize();
      this.app = res.data;
      LocalStorage.set('signg-plugin-app-data', res.data);
      this.$refs.qualifiedSignaturePluginAuthorizeModal.link =
        this.app.AuthorizationUrl;

      this.$refs.qualifiedSignatureLoadingModal.$refs.modal.close();
      this.$refs.qualifiedSignaturePluginAuthorizeModal.$refs.modal.open();
    },
    async login() {
      const res = await SignPluginService.login(
        this.app.Id,
        this.app.Key,
        this.nonce,
        this.challenge,
        this.license
      );
      this.token = res.data.token;
    },
    async authorizeModalClosed() {
      await this.login();
      await this.listCertificates();
    },
    async listCertificates() {
      try {
        const res = await SignPluginService.listCertificates(this.token);
        this.certificates = res.data.CertificateList;
      } catch (error) {
        this.CertificateList = [];
      }
      this.$refs.qualifiedSignatureLoadingModal.$refs.modal.close();
      this.$refs.qualifiedSignatureListCertificatesPluginModal.$refs.modal.open();
    },
    async getEnvelopeFilesHashes(x509) {
      return await EnvelopesPublicService.getSignableHashes(
        this.$route.params.token,
        x509,
        this.location.latitude,
        this.location.longitude
      );
    },
    async onSelectCertificate(cert) {
      this.$refs.qualifiedSignatureListCertificatesPluginModal.$refs.modal.close();
      this.$refs.qualifiedSignatureLoadingModal.text =
        'Processando assinatura...';
      this.$refs.qualifiedSignatureLoadingModal.$refs.modal.open();
      try {
        await SignPluginService.certificateAuth(this.token, cert.Id);
      } catch (error) {
        this.$emit('error', error.response);
        return;
      }
      const certRes = await SignPluginService.getCertificate(
        this.token,
        cert.Id
      );
      this.$emit('certificate', certRes.data.X509pem);
      const filesRes = await this.getEnvelopeFilesHashes(certRes.data.X509pem);
      this.documents = filesRes.data.documents;
      this.$emit('tcn', filesRes.data.tcn);
      const docs = [];
      for (let i = 0; i < this.documents.length; i++) {
        const document = this.documents[i];
        const res = await SignPluginService.sign(
          this.token,
          cert.Id,
          document.signable_hash
        );
        let signature = res.data.Signature.replace(
          '-----BEGIN PKCS1 SIGNATURE-----\n',
          ''
        );
        signature = signature.replace('\n-----END PKCS1 SIGNATURE-----', '');
        docs.push({
          id: document.id,
          signature,
        });
      }
      this.$refs.qualifiedSignatureLoadingModal.$refs.modal.close();
      this.$emit('signed-hashes', docs);
    },
  },
};
</script>
