<template lang="pug">
.table-base
  table.table(:class='{ "no-head": head.length === 0 }')
    thead(v-if='head.length > 0')
      tr
        th.font-color-blue(
          :key='index'
          :width='columSize[index]'
          v-for='(item, index) in head'
        )
          span.text {{ item.label }}
          span.sort-column(
            @click='sortByColumn(item.name, checkNextOrderByColumnIsDesc(item.name))'
            v-if='item.sort'
          )
            icon.icon-action.ms-2(name='angle-down' v-if='sort.desc')
            icon.icon-action.ms-2(name='angle-up' v-else)
          span.help-description(v-if='item.description')
            icon.ms-2(
              name='circle-question'
              style='cursor: help'
              v-tooltip:top='item.description'
            )
    tbody
      slot
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'TableOrganism',
  components: {
    Icon,
  },
  data() {
    return {
      sort: {
        column: null,
        desc: true,
      },
    };
  },
  props: {
    columSize: {
      type: Array,
    },
    head: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    sortByColumn(column, desc = false) {
      this.sort = { column, desc };
      this.$emit('sort', this.sort);
    },
    checkNextOrderByColumnIsDesc(column) {
      if (column === this.sort.column) {
        return !this.sort.desc;
      }
      return false;
    },
  },
};
</script>
<style lang="stylus">
.table-base
  border: none
  background: none
  .label
    display: none
.table
  &.no-head
    tbody
      tr
        &:first-child
          border-top: 1px solid #dee2e6
  tbody
    tr
      background-color: #fff
      border: 1px solid #dee2e6
      td
        border: none
  thead
    tr
      &:hover
        box-shadow: none
    th
      font-weight: normal
      background-color: transparent
      .help-description .icon
        color: #013d52
      .icon-action
        cursor: pointer
        color: #013d52
.table>:not(:last-child)>:last-child>*
  border-bottom-color: inherit
.table tr th
  &:first-child
    padding-left: 13px
.table tr td
  &:first-child
    padding-left: 13px
  &:last-child
    padding-right: 13px
@media screen and (max-width: 768px)
  .table-base .label
    display: inline-block
    font-weight: bold
  .table
    thead
      display: none
    tbody
      tr
        padding: 10px
        td
          padding: 0
</style>
