<template lang="pug">
modal.contact-loader-modal(
  :disableProcessButton='!selectedContacts.length > 0'
  :loading='loading'
  @closed='onCloseModal'
  @process='process'
  close-button-label='Cancelar'
  process-button-label='Adicionar'
  ref='modal'
  size='lg'
  title='Selecionar contato'
)
  .col-12.col-sm-6
    .form-group.input-search-recipient
      .form-control-icon--end-line
        input#name2.form-control(
          @keyup='onSearchContacts()'
          autocomplete='off'
          placeholder='Buscar contato'
          type='name'
          v-model.trim='queryContact'
        )
        icon(name='magnifying-glass')
  .contact-list
    .row.gx-3.gy-2
      .col-4(:key='index' v-for='(contact, index) in contacts.data')
        label.contact-list-item(:for='`input-${index}`')
          input.checkbox.form-check-input(
            :checked='contactIsSelected(contact)'
            :id='`input-${index}`'
            @change='triggerItem(contact)'
            type='checkbox'
          )
          .contact-info.d-flex.flex-column.ms-2
            span.text {{ contact.name }}
            span.text {{ contact.email }}
            span.text {{ formatedPhone(contact.phone) }}
    simple-paginator.pagination-space.mt-4(
      :payload='contacts'
      @navigate='listContacts'
    )
  .text-center.mt-3(v-if='!hasContacts && queryContact')
    img.img(src='/img/ilustrations/no-contacts-found.svg')
    p.font-color-blue.mt-4 Não encontramos resultados para sua pesquisa.
  .text-center.mt-3(v-if='!hasContacts && !queryContact')
    img.img(src='/img/ilustrations/no-contacts.svg')
    p.font-color-blue.mt-4 Você ainda não possui contatos armazenados.
</template>
<script>
import Modal from '@/ui/molecules/Modal.vue';
import ContactsService from '@/modules/account/services/http/ContactsService';
import Icon from '@/ui/atoms/Icon.vue';
import SimplePaginator from '@/ui/organisms/SimplePaginator.vue';
import debounce from 'debounce';

export default {
  components: {
    Modal,
    Icon,
    SimplePaginator,
  },
  data() {
    return {
      loading: true,
      queryContact: '',
      contacts: {
        data: [],
        links: {},
        meta: {},
      },
      selectedContacts: [],
    };
  },
  async mounted() {
    this.listContacts();
  },
  computed: {
    hasContacts() {
      return this.contacts.data.length > 0;
    },
  },
  methods: {
    async listContacts(page = 1) {
      this.$loading(true);
      const params = new URLSearchParams({
        q: this.queryContact,
        page,
      });
      this.selectedContacts = [];
      const contacts = await ContactsService.list(params);
      this.contacts = contacts.data;
      this.$loading(false);
    },
    onCloseModal() {
      this.selectedContacts = [];
    },
    process() {
      this.$emit('selected-contacts', this.selectedContacts);
      this.$refs.modal.close();
      this.loading = true;
    },
    triggerItem(contact) {
      const hasContact = this.selectedContacts
        .filter((item) => item.id === contact.id)
        .shift();
      if (hasContact) {
        this.selectedContacts = this.selectedContacts.filter(
          (item) => item.id !== contact.id
        );
      } else {
        this.selectedContacts.push(contact);
      }
    },
    contactIsSelected(contact) {
      return (
        this.selectedContacts.filter((item) => item.id === contact.id).length >
        0
      );
    },
    onSearchContacts: debounce(function search() {
      this.listContacts();
    }, 500),
    formatedPhone(phone) {
      return phone
        ? phone
            .toString()
            .replace(/(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, '$1 ($2) $3 $4-$5')
        : '';
    },
  },
};
</script>
<style lang="stylus">
.contact-list-item
  border-radius: 3px
  padding: 0.54rem 1rem
  box-shadow: 0px 0px 3px 1px #dadada
  cursor: pointer
  position: relative
  padding-left: 1.7rem
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  color: #555555
  width: 100%
  font-size: 0.875rem
  .checkbox
    position: absolute
    left: 7px
    top: 0.7rem
.input-search-recipient
  width: 16.25rem
  input
    border-radius: 0.313rem
  .icon
    top: 0.8em
    font-size: 1.25rem
.pagination-space
  display: inline-block
</style>
