<template lang="pug">
pdf-document.pdf-render(
  :class='{ loading: loading }'
  :src='src'
  @proxy-pages='onProxyPages'
)
  .pdf-render__loading.d-flex.justify-content-center(v-if='loading')
    span.text.align-self-center Carregando documento...
  .d-flex.pdf-render__bar
    .pdf-render__bar__actions.me-auto
      ul.actions-list.ms-2.d-flex.align-items-center
        li.list-item
          button(
            :disabled='selectedPage === pages.length'
            @click='goScrollToPage(selectedPage + 1)'
            title='Próxima Página'
          )
            icon(name='arrow-down')
        li.list-item
          span |
        li.list-item
          button(
            :disabled='selectedPage === 1'
            @click='goScrollToPage(selectedPage - 1)'
            title='Página Anterior'
          )
            icon(name='arrow-up')
        li.list-item
          input.input-page(
            @blur='scrollPageFromInput'
            @keyup='$event.keyCode === 13 ? scrollPageFromInput() : ""'
            pattern='[0-9]*'
            type='text'
            v-model='input'
          )
          span.mx-2 de
          span {{ pages.length }}
    button.me-2(
      @click='$emit("fullscreen")'
      v-if='showExpandOptions'
      v-tooltip:bottom='`Expandir`'
    )
      icon(name='arrow-up-right-and-arrow-down-left-from-center')
    .dropdown(v-if='showEnvelopeOptions' v-tooltip:bottom='`Outras opções`')
      button.dropdown-toggle.p-0(data-bs-toggle='dropdown' type='button')
        icon(name='ellipsis-vertical')
      ul.dropdown-menu(aria-labelledby='file-list-actions-dropdown')
        li
          button.dropdown-item(
            @click='$emit("download-envelope")'
            type='button'
          )
            span.text Baixar Envelope
        li
          span(
            data-placement='top'
            data-toggle='tooltip'
            tabindex='0'
            title='Disponível apenas quando o envelope estiver finalizado'
          )
            button.dropdown-item(
              @click='$emit("download-signatures")'
              type='button'
            )
              span.text Página de assinaturas
  .pdf-render__content.d-flex
    .pdf-render__content__pages(@scroll='onScrollPages')
      template(v-for='proxyPage in proxyPages')
        pdf-page.pdf-page(
          :key='`page-${proxyPage.pageNumber}-${version}`'
          :proxy-page='proxyPage'
        )
          slot(:name='`page-${proxyPage.pageNumber}`')
</template>
<script>
import debounce from 'debounce';
import Icon from '@/ui/atoms/Icon.vue';

import { PdfDocument, PdfPage } from 'assine-online-vue-library';

export default {
  name: 'PdfRender',
  components: {
    Icon,
    PdfDocument,
    PdfPage,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    showEnvelopeOptions: {
      type: Boolean,
      default: false,
    },
    showExpandOptions: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: true,
    proxyPages: [],
    pages: [],
    input: 1,
    selectedPage: 1,
    containerSize: 0,
    version: 1,
    currentPage: 0,
    numPages: '',
  }),
  mounted() {
    this.updateHeight();
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((e) => {
        this.updateContainerSize(e);
      });
    });
    resizeObserver.observe(
      document.querySelector('.pdf-render__content__pages')
    );
  },
  methods: {
    onProxyPages(proxyPages) {
      this.proxyPages = proxyPages;
      this.pages = this.proxyPages.map((pp) => ({
        pageNumber: pp.pageNumber,
        elementSize: {},
        pageSize: {},
      }));
      this.$emit('document-loaded', true);
      this.$emit('proxy-pages', this.proxyPages);
      this.loading = false;
    },
    onScrollPages(e) {
      this.pages.forEach((page) => {
        const el = document.querySelector(`#page-${page.pageNumber}`);
        if (e.target.scrollTop > el.offsetTop - el.clientHeight / 2) {
          this.selectedPage = page.pageNumber;
          this.input = this.selectedPage;
        }
      });
    },
    scrollPageFromInput() {
      if (this.input === 0 || this.input > this.pages.length) {
        this.input = this.selectedPage;
        return;
      }
      this.goScrollToPage(this.input);
    },
    goScrollToPage(pageNumber) {
      this.input = pageNumber;
      const pagesEl = document.querySelector('.pdf-render__content__pages');
      const pageEl = document.querySelector(`#page-${pageNumber}`);
      pagesEl.scrollTop = pageEl.offsetTop - 80;
    },
    updateContainerSize: debounce(function updateContainerSize(e) {
      if (this.containerSize !== e.contentRect.width) {
        this.updateHeight();
        this.containerSize = e.contentRect.width;
        this.$emit('version', this.version);
        this.$emit('size', {
          width: this.$el.clientWidth,
          height: this.$el.clientHeight,
        });
        this.version += 1;
      }
    }, 800),
    updateHeight() {
      const pagesEl = document.querySelector('.pdf-render__content__pages');
      const width = pagesEl.clientWidth;
      pagesEl.style.height = `${width * 1.5}px`;
    },
  },
};
</script>
<style lang="stylus">
.pdf-render
  .pdf-render__content__pages
    width: 100%
    canvas
      max-width: 100%
  &.loading
    .pdf-render__bar, .pdf-render__content
      opacity: 0
.pdf-render__bar, .pdf-render__content
  opacity: 1
  transition: opacity .3s
.pdf-render__content
  border: 1px solid #ccc
.pdf-render__content__pages
  max-height: 100vh
  overflow-y: auto
  overflow-x: hidden
  scroll-behavior: smooth
  .pdf-page
    box-shadow: 0 0 5px #dcdcdc
    position: relative
    width: auto !important
    height: auto !important
    margin-bottom: 1rem
    overflow: hidden

.pdf-render__bar
  align-items: center
  justify-content: end
  background-color: #EDEDF0
  min-height: 48px
  border: 1px solid #ccc
  border-bottom: none
  .pdf-render__bar__actions
    display: none
  .icon
    font-size: 1.5rem
.actions-list
  margin: 0
  padding: 0
  .list-item
    display: inline-block
    padding: .4rem .3rem
.pdf-render__loading
  background-color: #fff
  position: relative
  z-index: 1
  text-align: center
  min-height: inherit
  padding: 5%
  border: 1px solid #ccc
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, .1)
  .text
    font-size: 1.6rem
    color: #b7b7b7
.input-page
  width: 30px
  border-radius: 3px
  text-align: center
  border: 1px solid #BBBBBC
.pdf-render
  position: relative
.pdf-container
  position: relative
  border: 1px solid #ccc
.pdf-pages
  max-height: calc(100vh - 354px);
  min-height: 450px
  overflow: scroll
  width: 100%
  overflow-y: scroll
  overflow-x: hidden
.pdf-page
  box-shadow: 0px 0px 5px #dcdcdc
  margin: 15px
  position: relative
  width: auto
@media screen and (min-width: 576px)
  .pdf-render__bar
    justify-content: space-between
    .pdf-render__bar__actions
      display: block
</style>
