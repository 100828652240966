<template lang="pug">
.upload-image
  input(
    :accept='allowedFileType.join()'
    @change='loadFile'
    ref='inputFile'
    style='display: none'
    type='file'
  )
  template(v-if='this.errorExtension')
    .upload-area.upload-area--invalid
      img.mb-3(src='/images/invalid-format.svg' style='width: 130px')
      h6.upload-area__title Formato inválido
      p.mb-4 PNG, JPG, JPEG, ou BMP
      button.mb-1.btn.btn-thin(@click='selectFile') Selecionar Imagem
  template(v-else)
    .upload-area(:class='{ "no-file": file }')
      .image-preview.d-flex.align-items-center.justify-content-center(
        v-if='file'
      )
        img.img.img-fluid(:src='image' alt='Image Loaded')
        button.btn-remove(@click='removeFile')
          icon(name='trash-can')
      .card--upload-file(v-else)
        div(
          :class='["dropzone-area", dragging ? "dragenterClass" : ""]'
          @dragend='dragging = false'
          @dragenter='dragging = true'
          @dragleave='dragging = false'
          @dragover.prevent='dragover'
          @drop.prevent='drop'
        )
          label.notclickable.block.cursor-pointer(for='assetsFieldHandle')
            h6.upload-area__title Arraste aqui sua imagem
            p.mb-0 PNG, JPG, JPEG, ou BMP
            p.mb-2 ou
            button.btn.btn-thin(@click='selectFile') Selecionar Imagem
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';
import Alert from '@/ui/molecules/Alert.vue';

export default {
  components: {
    Icon,
    Alert,
  },
  props: {
    clear: Boolean,
    maxFileSizeMb: {
      type: Number,
      default: 5,
    },
    allowedFileType: {
      type: Array,
      default: () => ['image/png', 'image/jpeg', 'image/bmp'],
    },
  },
  data() {
    return {
      file: '',
      image: '',
      dragOnDrop: false,
      errorExtension: false,
      dragging: false,
    };
  },
  methods: {
    removeFile() {
      this.file = '';
      this.image = '';
      this.$refs.inputFile.value = '';
      this.$emit('change', '');
    },
    loadFile(e) {
      this.errorExtension = false;
      let file = '';
      if (!this.dragOnDrop) {
        file = e.target.files;
      } else {
        file = e.dataTransfer.files;
      }
      if (!this.allowedFileType.includes(file[0].type)) {
        this.errorExtension = true;
        this.removeFile();
        return;
      }
      this.file = file['0'];
      this.getImageBase64(this.file);
      this.$emit('change', this.file);
    },
    selectFile() {
      this.dragOnDrop = false;
      this.$refs.inputFile.click();
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.dragOnDrop = true;
      this.loadFile(event);
      this.dragging = false;
    },
    getImageBase64(file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.image = fileReader.result;
        this.$emit('change', {
          blob: file,
          image: this.image,
        });
      };
      fileReader.readAsDataURL(file);
    },
  },
  watch: {
    clear(value) {
      if (value) {
        this.src = '';
        this.file = '';
      }
    },
  },
};
</script>
<style lang="stylus">
.upload-image
  .upload-area
    min-height: 200px
    padding: 1rem 1rem
    border: 2px dashed #3ECB90
    text-align: center
    position: relative
    border-radius: 8px
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .1)
    &:hover
      background-color: #F7F7F7
    &.no-file
      border: 1px solid #E5E5E5
      box-shadow: none
      &:hover
        background-color: #fff
  .upload-area--invalid
    box-shadow: none
    border: none
    &:hover
      background: inherit
  .upload-area__title
    color: #013D52
    font-weight: 600
  .upload-area-img
    max-width: 100%
    max-height: 110px
  .remove-button
    position: absolute
    top: 0
    right: 0
    opacity: .2
  .card--upload-file
    label
      margin: 1rem 1rem
    .dragenterClass
      cursor: grabbing
      cursor: -webkit-grabbing
      background:rgba(0,0,0,0.1)
      .notclickable
        &:hover
          background:rgba(0,0,0,0.1)
        pointer-events: none;
        h3
          pointer-events: none;
        p
          pointer-events: none;
  .image-preview
    min-height: inherit
    margin: -1rem auto
    .img
      max-height: 400px
  .btn-remove
    color: #949494
    position: absolute
    right: .5rem
    left: auto
    top: .5rem
    font-size: 1.2rem
</style>
