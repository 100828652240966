<template lang="pug">
.simple-sidebar-new
  button#close-sidebar.close.d-xl-none.mb-3
    icon.me-2(name='bars-sort')
  .simple-sidebar__header
    template
      slot(name='sidebar-header')
  .simple-sidebar__menu-list
    ul.nav.flex-column.menu-list.list-unstyled
      li.menu-list__item.parent
        ul.nav.flex-column.menu-list.list-unstyled
          template(v-for='(item, key) in nav.items')
            router-link(
              :key='key'
              :to='{ name: item.route.name }'
              custom
              v-slot='{ href, navigate, isActive, isExactActive }'
            )
              li.menu-list__item
                a.mt-0.nav-link.d-flex(
                  :class='{ active: isExactActive }'
                  :href='href'
                  @click='navigate'
                )
                  icon(
                    :fix-width='true'
                    :name='item.icon'
                    v-if='item.icon'
                  )
                  span.text {{ item.label }}
                  icon.ms-auto(
                    :fix-width='true'
                    :name='item.auxIcon'
                    style='margin-right: -1px'
                    v-if='item.auxIcon'
                  )
  .simple-sidebar__middle
    template
      slot(name='sidebar-middle')
  .simple-sidebar__footer
    template
      slot(name='sidebar-footer')
</template>
<script>
import Icon from '@/ui/atoms/Icon.vue';

export default {
  name: 'SimpleSidebar',
  components: {
    Icon,
  },
  props: {
    nav: Object,
  },
};
</script>
<style lang="stylus">
.simple-sidebar-new
  position: fixed
  border-right: 1px solid #CCCCCC
  height: 100vh
  margin: 0
  padding: 1.5rem
  background-color: #fff
  .simple-sidebar__menu-list
    margin: 0 -1.5rem
    width: 243px
  .menu-list__item
    color: #013d52
    font-size: 1rem
    font-weight: 400
    &.parent
      font-size: 1rem
      color: #555555
      margin-top: 1rem
      &:first-child
        margin-top: 0.938rem
    .nav-link
      display: block
      color: inherit
      padding: 1.25rem 1.5rem 1.25rem 1rem
      height: auto
      line-height: 1em
      &.active
        background-color: #F0F0F0
        position: relative
        &:after
          background-color: #3ecb90
          position: absolute
          width: 0.188rem
          right: 0
          content: ''
          top: 0
          bottom: 0
      &:hover
        opacity: 1
        background-color: #F0F0F0
        position: relative
        &:after
          background-color: #3dcb90
          position: absolute
          width: 0.188rem
          right: 0
          content: ''
          top: 0
          bottom: 0
    .icon
      width: 1.125rem
      height: 1.125rem
      margin-right: 0.938rem
      padding-left: 0.75rem
    .text
      vertical-align: middle
.simple-sidebar__footer
  position: absolute
  bottom: 1.5rem
@media screen and (min-width: 1200px)
  .simple-sidebar-new
    height: calc(100vh - 60px)
    .menu-list__item
      .nav-link
        padding: 0.75rem 1.5rem 0.75rem 1rem
</style>
