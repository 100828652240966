import config from '@/config';
import Http from './Http';

const client = new Http('', config.api);

client.getSignature = (type, framed = false) =>
  new Promise((resolve) => {
    client
      .get(
        '',
        '',
        `/signature-profiles/${type}/preview?mode=${framed ? 'framed' : 'raw'}`,
        {
          responseType: 'blob',
          validateStatus: false,
        }
      )
      .then((res) => {
        if (res.status !== 200) {
          resolve('');
        }
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      })
      .catch(() => {
        resolve('');
      });
  });

client.saveSignature = (type, blob) => {
  const formData = new FormData();
  formData.append('file', blob, `${type}.png`);
  return client.post(formData, `/signature-profiles/${type}`);
};

client.getPublicSignature = (token, type) =>
  new Promise((resolve) => {
    client
      .get('', '', `/p/signature-profiles/${token}/${type}/preview`, {
        responseType: 'blob',
        validateStatus: false,
      })
      .then((res) => {
        if (res.status !== 200) {
          resolve('');
        }
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      })
      .catch(() => {
        resolve('');
      });
  });

client.savePublicSignature = (token, type, blob) => {
  const formData = new FormData();
  formData.append('file', blob, `${type}.png`);
  return client.post(formData, `/p/signature-profiles/${token}/${type}`);
};

export default client;
