<template lang="pug">
.storage--screen
  ul#my-tab.nav.nav-tabs(role='tablist')
    li.nav-item(role='presentation')
      button#my-files-tab.nav-link.active(
        aria-controls='my-files'
        aria-selected='true'
        data-bs-target='#my-files'
        data-bs-toggle='tab'
        role='tab'
        type='button'
      ) Meus arquivos
    //-li.nav-item(role='presentation')
    //-  button#shared-files-tab.nav-link(data-bs-toggle='tab' data-bs-target='#shared-files'
    //-    type='button' role='tab' aria-controls='shared-files'
    //-    aria-selected='true') Compartilhados comigo
  #myTabContent.tab-content.pt-3
    #my-files.tab-pane.fade.show.active(
      aria-labelledby='my-files-tab'
      role='tabpanel'
    )
      files-list(
        :folder-id='$route.params.itemId'
        v-on:folder-click='onFolderClick'
      )
    //-#shared-files.tab-pane.fade(role='tabpanel' aria-labelledby='shared-files-tab')
    //-  files-list(:shared-with-me='true')
  new-storage-folder-modal(v-on:new-folder='onCreateFolder')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import FilesList from '@/ui/organisms/FilesList.vue';
import NewStorageFolderModal from '@/modules/account/components/organisms/modals/NewStorageFolderModal.vue';

export default {
  name: 'StoragePage',
  components: {
    NewStorageFolderModal,
    FilesList,
    Icon,
  },
  created() {
    this.$store.dispatch('page/setTitle', 'Armazenamento');
    this.$store.dispatch('page/setClass', 'storage');
    this.$store.dispatch('page/setHead', { title: 'Armazenamento' });
  },
  methods: {
    onCreateFolder(folder) {
      console.log(folder);
    },
    onFolderClick(item) {
      this.$router.push(`/business/storage/${item.id}`);
    },
  },
};
</script>
