import Http from '@/services/Http';

const path = 'users';

const client = new Http(path);

client.list = (pathParams) =>
  client.get('', '', pathParams === undefined ? '' : `?${pathParams}`);

client.listEspecificUser = (userId) => client.get(userId, '');

client.search = (q) => client.get('', { q }, '');

client.remove = (userId) => client.delete(userId, '', '/users');

client.listExtended = (userId) => client.get(`${userId}?extended`);

client.listPermissions = (userId) =>
  client.get('', '', `${path}/${userId}/permissions`);

client.changePermissions = (userId, data) =>
  client.put('', data, `/${userId}/permissions`);

client.becomeOwner = (userId) => client.post('', `/${userId}/owner`);

client.revogeOwner = (userId) => client.delete('', '', `/${userId}/owner`);

export default client;
